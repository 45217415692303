.custom-color {
  background-color: #5664d2 !important;
  color: #fff !important;

  &:hover {
    opacity: 0.8;
  }
}

.btn.btn-persistent:focus,
.btn.btn-persistent.focus,
button.btn.btn-persistent:focus:not(:focus-visible) {
  border-color: #5664d2 !important;
  color: #5664d2 !important;
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-container:hover {
  cursor: pointer;
}
