@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&amp;display=swap";

/* src/assets/scss/styles.scss */
:root {
  --body-bg-rgb:
          249,
          250,
          252;
  --primary-rgb:
          92,
          103,
          247;
  --primary-tint1-rgb:
          227,
          84,
          212;
  --primary-tint2-rgb:
          255,
          93,
          159;
  --primary-tint3-rgb:
          255,
          142,
          111;
  --secondary-rgb:
          158,
          92,
          247;
  --warning-rgb:
          255,
          198,
          88;
  --info-rgb:
          14,
          165,
          232;
  --success-rgb:
          33,
          206,
          158;
  --danger-rgb:
          251,
          66,
          66;
  --light-rgb:
          249,
          249,
          250;
  --dark-rgb:
          10,
          10,
          10;
  --orange-rgb:
          254,
          124,
          88;
  --pink-rgb:
          254,
          84,
          155;
  --teal-rgb:
          0,
          216,
          216;
  --purple-rgb:
          123,
          118,
          254;
  --green-rgb:
          1,
          239,
          140;
  --default-body-bg-color: rgb(var(--body-bg-rgb));
  --primary-color: rgb(var(--primary-rgb));
  --primary-tint1-color: rgb(var(--primary-tint1-rgb));
  --primary-tint2-color: rgb(var(--primary-tint2-rgb));
  --primary-tint3-color: rgb(var(--primary-tint3-rgb));
  --primary-border: rgb(var(--primary-rgb));
  --primary01: rgba(var(--primary-rgb), 0.1);
  --primary02: rgba(var(--primary-rgb), 0.2);
  --primary03: rgba(var(--primary-rgb), 0.3);
  --primary04: rgba(var(--primary-rgb), 0.4);
  --primary05: rgba(var(--primary-rgb), 0.5);
  --primary06: rgba(var(--primary-rgb), 0.6);
  --primary07: rgba(var(--primary-rgb), 0.7);
  --primary08: rgba(var(--primary-rgb), 0.8);
  --primary09: rgba(var(--primary-rgb), 0.9);
  --primary005: rgba(var(--primary-rgb), 0.05);
  --default-font-family: "Poppins", sans-serif;
  --default-font-weight: 400;
  --default-text-color: #212b37;
  --default-border: #ecf3fb;
  --default-background: #f9fafb;
  --menu-bg: #fff;
  --menu-prime-color: #61748f;
  --menu-border-color: #e2e6f1;
  --header-bg: #fff;
  --header-prime-color: #61748f;
  --header-border-color: #e2e6f1;
  --custom-white: #fff;
  --custom-black: #000;
  --bootstrap-card-border: #ecf3fb;
  --list-hover-focus-bg: #f5f6f7;
  --text-muted: #6e829f;
  --input-border: #dee7f1;
  --form-control-bg: #ffffff;
  --bs-link-color-rgb: var(--default-text-color);
  --gray-1: #f9fafb;
  --gray-2: #f2f4f5;
  --gray-3: #e6eaeb;
  --gray-4: #dbdfe1;
  --gray-5: #949eb7;
  --gray-6: #7987a1;
  --gray-7: #4d5875;
  --gray-8: #383853;
  --gray-9: #323251;
  --white-1: rgba(255, 255, 255, 0.1);
  --white-2: rgba(255, 255, 255, 0.2);
  --white-3: rgba(255, 255, 255, 0.3);
  --white-4: rgba(255, 255, 255, 0.4);
  --white-5: rgba(255, 255, 255, 0.5);
  --white-6: rgba(255, 255, 255, 0.6);
  --white-7: rgba(255, 255, 255, 0.7);
  --white-8: rgba(255, 255, 255, 0.8);
  --white-9: rgba(255, 255, 255, 0.9);
  --black-1: rgba(0, 0, 0, 0.1);
  --black-2: rgba(0, 0, 0, 0.2);
  --black-3: rgba(0, 0, 0, 0.3);
  --black-4: rgba(0, 0, 0, 0.4);
  --black-5: rgba(0, 0, 0, 0.5);
  --black-6: rgba(0, 0, 0, 0.6);
  --black-7: rgba(0, 0, 0, 0.7);
  --black-8: rgba(0, 0, 0, 0.8);
  --black-9: rgba(0, 0, 0, 0.9);
}
[data-theme-mode=dark] {
  --body-bg-rgb:
          25,
          25,
          28;
  --body-bg-rgb2:
          45,
          45,
          48;
  --menu-bg: rgb(var(--body-bg-rgb));
  --menu-border-color: rgba(255, 255, 255, 0.1);
  --menu-prime-color: rgba(255,255,255,0.4);
  --header-bg: rgb(var(--body-bg-rgb));
  --header-prime-color: rgba(255,255,255,0.4);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --custom-white: rgb(var(--body-bg-rgb));
  --custom-black: #fff;
  --default-border: rgba(255, 255, 255, 0.1);
  --default-text-color: rgba(255, 255, 255, 0.8);
  --light-rgb:
          43,
          46,
          49;
  --dark-rgb:
          240,
          245,
          248;
  --bootstrap-card-border: rgba(255, 255, 255, 0.1);
  --list-hover-focus-bg: rgba(255, 255, 255, 0.1);
  --default-background: rgb(var(--body-bg-rgb2));
  --default-body-bg-color: rgb(var(--body-bg-rgb2));
  --text-muted: rgba(255, 255, 255, 0.5);
  --input-border: rgba(255, 255, 255, 0.1);
  --form-control-bg: rgb(var(--body-bg-rgb));
  color-scheme: dark;
  --gray-1: #110f0f;
  --gray-2: rgba(255, 255, 255, 0.1);
  --gray-3: #393946;
  --gray-4: rgba(255, 255, 255, 0.2);
  --gray-5: #73738c;
  --gray-6: #8f8fa3;
  --gray-7: #ababba;
  --gray-8: #c7c7d1;
  --gray-9: #e3e3e8;
  --white-1: rgba(0, 0, 0, 0.1);
  --white-2: rgba(0, 0, 0, 0.2);
  --white-3: rgba(0, 0, 0, 0.3);
  --white-4: rgba(0, 0, 0, 0.4);
  --white-5: rgba(0, 0, 0, 0.5);
  --white-6: rgba(0, 0, 0, 0.6);
  --white-7: rgba(0, 0, 0, 0.7);
  --white-8: rgba(0, 0, 0, 0.8);
  --white-9: rgba(0, 0, 0, 0.9);
  --black-1: rgba(255, 255, 255, 0.1);
  --black-2: rgba(255, 255, 255, 0.2);
  --black-3: rgba(255, 255, 255, 0.3);
  --black-4: rgba(255, 255, 255, 0.4);
  --black-5: rgba(255, 255, 255, 0.5);
  --black-6: rgba(255, 255, 255, 0.6);
  --black-7: rgba(255, 255, 255, 0.7);
  --black-8: rgba(255, 255, 255, 0.8);
  --black-9: rgba(255, 255, 255, 0.9);
}
@media (max-width: 991.98px) {
  .sidemenu-layout-styles {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .app-header .horizontal-logo .header-logo img {
    height: 1.7rem;
    line-height: 1.7rem;
  }
}
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-theme-mode=dark] .btn-close {
  filter: invert(1);
}
[data-theme-mode=dark] .alert .btn-close {
  filter: unset;
}
[data-theme-mode=dark] .dropdown-menu {
  box-shadow: 0 1rem 1.125rem rgba(40, 40, 40, 0.15);
}
[data-theme-mode=dark] button.gridjs-sort,
[data-theme-mode=dark] button.gridjs-sort-desc,
[data-theme-mode=dark] button.gridjs-sort-asc {
  filter: invert(1);
}
[data-theme-mode=dark] .choices__list--dropdown,
[data-theme-mode=dark] .choices__list[aria-expanded] {
  box-shadow: rgba(10, 10, 10, 0.2) 0 0.5rem 1.5rem !important;
}
[data-theme-mode=dark][data-menu-styles=light] .app-sidebar .side-menu__label1 a {
  color: var(--menu-prime-color);
}
[data-theme-mode=dark][data-menu-styles=light] .app-sidebar .slide.has-sub .slide-menu {
  --menu-prime-color: #61748f;
}
[data-theme-mode=dark][data-header-styles=light] .animated-arrow span {
  background: var(--header-prime-color);
}
[data-theme-mode=dark][data-header-styles=light] .animated-arrow span:before,
[data-theme-mode=dark][data-header-styles=light] .animated-arrow span:after {
  background: var(--header-prime-color);
}
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: block !important;
}
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-theme-mode=dark][data-menu-styles=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none !important;
}
@media (max-width: 991.98px) {
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-dark {
    display: block;
  }
}
@media (max-width: 991.98px) {
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-white {
    display: block;
  }
}
@media (max-width: 991.98px) {
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-logo {
    display: block;
  }
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-dark {
    display: none;
  }
}
@media (max-width: 991.98px) {
  [data-toggled=close] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(-15rem);
  }
  [data-toggled=close] .app-header {
    padding-inline-start: 0rem;
  }
  [data-toggled=close] .app-header .horizontal-logo {
    display: block;
  }
  [data-toggled=close][dir=rtl] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(15rem);
  }
}
@media (max-width: 991.98px) {
  [data-toggled=open] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(0rem);
  }
  [data-toggled=open] .app-header {
    padding-inline-start: 0rem;
  }
  [data-toggled=open] .app-header .horizontal-logo {
    display: block;
  }
}
[data-page-style=classic] {
  --default-border: #e2e6f1;
  --header-border-color: #e2e6f1;
  --menu-border-color: #e2e6f1;
}
[data-page-style=classic][data-theme-mode=dark] {
  --default-border: rgba(255, 255, 255, 0.1);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}
[data-page-style=classic] body {
  background-color: var(--custom-white);
}
[data-page-style=classic] .card.custom-card {
  box-shadow: none;
  border: 1px solid var(--default-border);
}
[data-page-style=classic] .app-sidebar,
[data-page-style=classic] .footer,
[data-page-style=classic] .app-header,
[data-page-style=classic] .app-sidebar .main-sidebar {
  box-shadow: none;
}
[data-page-style=classic][data-nav-layout=horizontal] .app-sidebar {
  border-block-end: 1px solid var(--default-border);
}
[data-page-style=modern] {
  --light-rgb:
          255,
          255,
          255;
  --custom-white: rgb(243, 246, 248);
  --default-border: #e6ebf1;
  --input-border: var(--black-1);
}
[data-page-style=modern] .dropdown-menu {
  background-color: rgb(255, 255, 255);
}
[data-page-style=modern] body {
  background-color: #fff;
}
[data-page-style=modern] .card.custom-card {
  box-shadow: none;
  background-color: rgb(243, 246, 248);
}
[data-page-style=modern][data-theme-mode=dark] {
  --light-rgb:
          20,
          20,
          20;
  --custom-white: rgb(var(--body-bg-rgb));
  --default-border: rgb(255, 255, 255, 0.1);
}
[data-page-style=modern][data-theme-mode=dark] .card.custom-card {
  box-shadow: none;
  background-color: var(--custom-white);
}
[data-page-style=modern][data-theme-mode=dark] .dropdown-menu {
  background-color: rgb(var(--light-rgb));
}
[data-page-style=modern][data-theme-mode=dark] body {
  background-color: var(--default-body-bg-color);
}
[data-page-style=modern][data-theme-mode=dark] .btn-light {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
[data-page-style=modern][data-theme-mode=dark] .btn-light:hover,
[data-page-style=modern][data-theme-mode=dark] .btn-light:focus,
[data-page-style=modern][data-theme-mode=dark] .btn-light:active {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
[data-page-style=modern] .app-sidebar,
[data-page-style=modern] .footer,
[data-page-style=modern] .app-header,
[data-page-style=modern] .app-sidebar .main-sidebar {
  box-shadow: none;
}
@media (min-width: 1600px) {
  [data-width=default] .main-content {
    padding: 0 10rem;
  }
  [data-width=default][data-nav-layout=vertical] .app-header .main-header-container {
    padding-inline: 10rem;
  }
}
@media (max-width: 1599.98px) and (min-width: 992px) {
  [data-width=default] .main-content {
    padding: 0 0.5rem;
  }
}
@media (min-width: 1600px) {
  [data-width=default][data-nav-layout=horizontal] .app-sidebar,
  [data-width=default][data-nav-layout=horizontal] .main-header-container {
    padding-inline: 10rem;
  }
}
@media (min-width: 1400px) {
  [data-width=boxed] body {
    background-color: #c1c6e1;
  }
  [data-width=boxed] .page {
    width: 1400px;
    margin: 0 auto;
    background-color: var(--default-body-bg-color);
    position: relative;
    box-shadow: 0 0 1rem var(--black-1);
  }
  [data-width=boxed] .page .app-header {
    width: 1400px;
    margin: 0 auto;
  }
  [data-width=boxed] .page .app-sidebar {
    inset-inline-start: auto;
  }
  [data-width=boxed][dir=rtl] body {
    padding-right: 0 !important;
  }
  [data-width=boxed][data-nav-layout=horizontal] .app-sidebar {
    width: 1400px !important;
  }
  [data-width=boxed][data-page-style=classic] .page {
    background-color: var(--custom-white);
  }
  [data-width=boxed][data-page-style=classic] .card {
    box-shadow: none;
  }
  [data-width=boxed][data-theme-mode=dark] body {
    background-color: rgba(var(--body-bg-rgb), 0.6);
  }
  [data-width=boxed][data-theme-mode=dark] .page {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  }
  [data-width=boxed][data-vertical-style=detached] .page {
    width: 1400px;
    box-shadow: none;
  }
  [data-width=boxed][data-vertical-style=detached] .page .app-header {
    width: 1400px;
  }
  [data-width=boxed][data-vertical-style=detached][data-toggled=detached-close] .app-content {
    margin-inline-start: 5rem;
  }
  [data-width=boxed][data-vertical-style=detached] .app-header .main-header-container {
    width: 99%;
  }
  [data-width=boxed][data-vertical-style=detached] .app-content {
    margin-inline-start: 15rem;
  }
  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
    height: 100%;
  }
  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  [data-width=boxed] .mail-info-body {
    max-height: calc(100vh - 19.3rem);
  }
  [data-width=boxed] #Invoice .list-group-item > a > div {
    display: block !important;
  }
  [data-width=boxed] .leads-source-labels .col {
    flex: 100%;
  }
}
[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar {
  position: absolute;
}
[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar .main-sidebar-header {
  position: absolute;
}
[data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
  position: relative;
  z-index: 102;
}
[data-menu-position=scrollable][data-nav-layout=horizontal] .app-content {
  margin-block-start: 4rem;
}
[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-sidebar {
  position: relative;
}
[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-header {
  position: relative;
}
@media (max-width: 991.98px) {
  [data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: absolute;
    height: 100%;
  }
  [data-menu-position=scrollable][data-nav-layout=horizontal] .main-content {
    margin-block-start: 0;
  }
  [data-menu-position=scrollable][data-nav-layout=horizontal] .main-sidebar {
    height: 100%;
  }
}
@media (min-width: 992px) {
  [data-menu-position=scrollable] .app-content {
    min-height: calc(100vh - 2.5rem);
  }
}
[data-header-position=scrollable][data-nav-layout=vertical] .app-header {
  position: absolute;
}
[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
  position: relative;
  top: 0;
}
[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar.sticky {
  position: sticky;
  top: 0;
}
[data-header-position=scrollable][data-nav-layout=horizontal] .app-content {
  margin-block-start: 0;
}
[data-header-position=scrollable][data-nav-layout=horizontal] .app-header {
  position: relative;
}
[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-sidebar {
  position: relative;
}
[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-header {
  position: relative;
}
[data-header-position=scrollable][data-nav-layout=horizontal][data-width=boxed] .app-header {
  margin: 0;
}
@media (max-width: 991.98px) {
  [data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: absolute !important;
    z-index: 102;
    height: 100%;
  }
  [data-header-position=scrollable][data-nav-layout=horizontal] .main-content {
    padding-block-start: 0;
  }
  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar {
    position: fixed !important;
  }
  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar .main-sidebar {
    margin-block-start: 3.72rem;
  }
  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar.sticky .main-sidebar {
    margin-block-start: 0;
  }
  [data-header-position=scrollable][data-nav-layout=vertical] .app-sidebar.sticky {
    inset-block-start: 0;
  }
}
[data-menu-styles=light] {
  --menu-bg: #fff;
  --menu-prime-color: #61748f;
  --menu-border-color: #e2e6f1;
}
[data-menu-styles=light][data-theme-mode=dark] {
  --menu-prime-color: #61748f;
}
[data-menu-styles=light] .app-sidebar .side-menu__icon {
  color: var(--menu-prime-color);
}
[data-menu-styles=light] .app-sidebar .side-menu__item.active .side-menu__icon {
  color: var(--primary-color);
}
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=light][data-nav-layout=vertical][data-theme-mode=dark] .main-menu > .slide.active > .side-menu__item,
[data-menu-styles=light][data-nav-layout=vertical][data-theme-mode=dark] .main-menu > .slide:hover > .side-menu__item {
  background-color: transparent;
}
[data-menu-styles=light] .side-menu__label1 a {
  color: #222528;
  font-weight: 500;
}
[data-menu-styles=light] .side-menu__label1 {
  border-block-end: 1px solid #e2e6f1 !important;
}
@media (min-width: 992px) {
  [data-menu-styles=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid #e2e6f1;
  }
}
[data-menu-styles=dark] {
  --menu-bg: var(--custom-white);
  --menu-prime-color: rgba(255,255,255,0.5);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}
[data-menu-styles=dark][data-nav-layout=vertical] .main-menu > .slide.active .slide-menu .side-menu__item:hover .side-menu__angle,
[data-menu-styles=dark][data-nav-layout=vertical] .main-menu > .slide:hover .slide-menu .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: block;
}
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=dark] .side-menu__label1 a {
  color: rgb(255, 255, 255);
  font-weight: 500;
}
[data-menu-styles=dark] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}
[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__icon,
[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__label,
[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__angle,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__icon,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__label,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__angle {
  color: #fff;
}
[data-menu-styles=dark] .app-sidebar .side-menu__item.active:before,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover:before {
  color: #fff;
  opacity: 1;
}
[data-menu-styles=dark] .app-sidebar .side-menu__item:before {
  color: var(--menu-prime-color);
}
[data-menu-styles=dark] .app-sidebar .slide-menu .side-menu__item.active .side-menu__angle,
[data-menu-styles=dark] .app-sidebar .slide-menu .side-menu__item:hover .side-menu__angle {
  color: #fff;
}
[data-menu-styles=dark] .app-sidebar .slide-menu .side-menu__item .side-menu__label {
  color: #fff;
}
[data-menu-styles=dark] .app-sidebar .slide-menu.child1 .side-menu__item:hover,
[data-menu-styles=dark] .app-sidebar .slide-menu.child2 .side-menu__item:hover,
[data-menu-styles=dark] .app-sidebar .slide-menu.child3 .side-menu__item:hover {
  color: #fff;
  background: transparent;
}
[data-menu-styles=dark] .app-sidebar .side-menu__item.active,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover {
  color: #fff;
}
/*[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] {
  --menu-bg: url("bg-img1-5KUQ4VEU.jpg");
}
[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] {
  --menu-bg: url("bg-img2-5SFAKJMF.jpg");
}
[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] {
  --menu-bg: url("bg-img3-KUR64AKE.jpg");
}
[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] {
  --menu-bg: url("bg-img4-DWDYL2DY.jpg");
}
[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] {
  --menu-bg: url("bg-img5-SWTGIEEB.jpg");
}*/
@media (min-width: 992px) {
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: #fff;
  }
  [data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }
}
[data-menu-styles=dark][data-theme-mode=light] {
  --menu-bg: #202947;
  --menu-prime-color: #b2b8c7;
}
[data-menu-styles=dark][data-theme-mode=light] .main-menu > .slide.active > .side-menu__item,
[data-menu-styles=dark][data-theme-mode=light] .main-menu > .slide:hover > .side-menu__item {
  background-color: transparent;
}
[data-menu-styles=color] {
  --menu-bg: var(--primary-color);
  --menu-prime-color: rgba(255, 255, 255, 0.6);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=color][data-nav-layout=vertical] .main-menu > .slide.active > .side-menu__item,
[data-menu-styles=color][data-nav-layout=vertical] .main-menu > .slide:hover > .side-menu__item {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span {
  background: #61748f;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span:before,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span:after {
  background: #61748f;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span {
  background: #fff;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span:before,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span:after {
  background: #fff;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block !important;
}
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=color][data-nav-layout=vertical] .main-menu > .slide:hover .slide-menu .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}
[data-menu-styles=color] .side-menu__item.active {
  color: #fff !important;
}
[data-menu-styles=color] .side-menu__item.active .side-menu__icon,
[data-menu-styles=color] .side-menu__item.active .side-menu__label,
[data-menu-styles=color] .side-menu__item.active .side-menu__angle {
  color: #fff !important;
}
[data-menu-styles=color] .side-menu__item:hover {
  color: #fff !important;
}
[data-menu-styles=color] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=color] .side-menu__item:hover .side-menu__label,
[data-menu-styles=color] .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
}
[data-menu-styles=color] .slide-menu {
  --menu-bg: var(--primary-color);
}
[data-menu-styles=color] .side-menu__label1 a {
  color: rgb(255, 255, 255);
  font-weight: 500;
}
[data-menu-styles=color] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}
[data-menu-styles=color] .app-sidebar .main-menu > .slide > .side-menu__item.active,
[data-menu-styles=color] .app-sidebar .main-menu > .slide > .side-menu__item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-menu-styles=color] .app-sidebar .main-menu .slide .side-menu__item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
@media (min-width: 992px) {
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: #fff;
  }
  [data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }
}
[data-menu-styles=gradient] {
  --menu-bg:
          linear-gradient(
                  to bottom,
                  var(--primary-color) 0%,
                  #e354d4 100%);
  --menu-prime-color: rgba(255, 255, 255, 0.6);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}
[data-menu-styles=gradient][data-nav-layout=horizontal] {
  --menu-bg:
          linear-gradient(
                  to right,
                  var(--primary-color) 0%,
                  #e354d4 100%);
}
[data-menu-styles=gradient][data-nav-layout=vertical] .main-menu > .slide:hover .slide-menu .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}
[data-menu-styles=gradient] .app-sidebar .main-sidebar-header {
  background: var(--primary-color);
}
[data-menu-styles=gradient][data-nav-layout=vertical] .main-menu > .slide.has-sub.active > .side-menu__item,
[data-menu-styles=gradient][data-nav-layout=vertical] .main-menu > .slide:hover > .side-menu__item {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span {
  background: #61748f;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span:before,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-header-styles=light][data-theme-mode=dark] .animated-arrow span:after {
  background: #61748f;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span {
  background: #fff;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span:before,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .animated-arrow span:after {
  background: #fff;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block !important;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=gradient] .side-menu__item.active {
  color: #fff !important;
}
[data-menu-styles=gradient] .side-menu__item.active .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__label,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__angle {
  color: #fff !important;
}
[data-menu-styles=gradient] .side-menu__item:hover {
  color: #fff !important;
}
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__label,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
}
[data-menu-styles=gradient] .slide-menu {
  --menu-bg: var(--primary-color);
}
[data-menu-styles=gradient] .side-menu__label1 a {
  color: rgb(255, 255, 255);
  font-weight: 500;
}
[data-menu-styles=gradient] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}
[data-menu-styles=gradient] .app-sidebar .side-menu__item.active,
[data-menu-styles=gradient] .app-sidebar .side-menu__item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
@media (min-width: 992px) {
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: #fff;
  }
  [data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }
}
[data-menu-styles=transparent] {
  --menu-bg: var(--default-body-bg-color);
}
[data-menu-styles=transparent][data-theme-mode=light] {
  --menu-border-color: rgba(0, 0, 0, 0.07);
}
@media (min-width: 992px) {
  [data-menu-styles=transparent][data-theme-mode=light][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=transparent][data-theme-mode=light][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(0, 0, 0, 0.07);
    border-color: rgba(0, 0, 0, 0.09);
  }
  [data-menu-styles=transparent][data-theme-mode=light][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=transparent][data-theme-mode=light][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: var(--primary-color);
  }
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: block !important;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: block !important;
}
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}
[data-menu-styles=transparent] .app-sidebar .side-menu__item.active .side-menu__icon {
  color: var(--primary-color);
}
[data-menu-styles=transparent] .app-sidebar .main-sidebar {
  box-shadow: none;
}
[data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-prime-color: rgba(255, 255, 255, 0.6);
  --menu-border-color: rgba(255, 255, 255, 0.1);
  --menu-bg: rgb(var(--body-bg-rgb2));
}
[data-menu-styles=transparent][data-theme-mode=dark][icon-overlay=open] {
  --menu-bg: rgb(var(--body-bg-rgb2));
}
[data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar .side-menu__item.active,
[data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar .side-menu__item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
}
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
}
@media (min-width: 992px) {
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: var(--primary-color);
  }
}
[data-header-styles=light] {
  --header-bg: #fff;
  --header-border-color: #e2e6f1;
  --header-prime-color: #61748f;
}
[data-header-styles=light] .app-header .header-search-bar {
  background-color: var(--header-bg) !important;
  border: 1px solid var(--input-border) !important;
  color: var(--header-prime-color) !important;
}
[data-header-styles=light] .app-header .header-search-bar::-webkit-input-placeholder {
  color: var(--header-prime-color) !important;
}
[data-header-styles=light] .app-header .header-search-bar:focus {
  border: 1px solid #eff2ff !important;
  color: var(--header-prime-color) !important;
  box-shadow: none !important;
}
[data-header-styles=light] .app-header .bg-body {
  background-color: #f7f8f9 !important;
}
[data-header-styles=light] .header-search-icon {
  color: var(--header-prime-color);
}
[data-header-styles=light] .header-search-icon:hover {
  color: var(--header-prime-color);
}
[data-header-styles=dark] {
  --header-bg: var(--custom-white);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
}
[data-header-styles=dark][data-theme-mode=light] .app-header .bg-body {
  background-color: rgb(43, 43, 48) !important;
}
[data-header-styles=dark] .app-header .header-search-bar {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
}
[data-header-styles=dark] .app-header .header-search-bar::-webkit-input-placeholder {
  color: var(--header-prime-color) !important;
}
[data-header-styles=dark] .app-header .header-search-bar:focus {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
  box-shadow: none !important;
}
[data-header-styles=dark] .header-search-icon {
  color: var(--header-prime-color);
}
[data-header-styles=dark] .header-search-icon:hover {
  color: var(--header-prime-color);
}
@media (min-width: 992px) {
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
    display: block;
  }
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
}
[data-header-styles=dark][data-theme-mode=light] {
  --header-bg: #202947;
}
[data-header-styles=color] {
  --header-bg: var(--primary-color);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
}
[data-header-styles=color] .app-header .bg-body {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
[data-header-styles=color] .app-header .header-search-bar {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
}
[data-header-styles=color] .app-header .header-search-bar::-webkit-input-placeholder {
  color: var(--header-prime-color) !important;
}
[data-header-styles=color] .app-header .header-search-bar:focus {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
  box-shadow: none !important;
}
[data-header-styles=color] .header-search-icon {
  color: var(--header-prime-color);
}
[data-header-styles=color] .header-search-icon:hover {
  color: var(--header-prime-color);
}
[data-header-styles=color][data-nav-style=menu-click] .animated-arrow span,
[data-header-styles=color][data-nav-style=menu-hover] .animated-arrow span,
[data-header-styles=color][data-nav-style=icon-hover] .animated-arrow span,
[data-header-styles=color][data-nav-style=icon-click] .animated-arrow span,
[data-header-styles=color][data-vertical-style=closed] .animated-arrow span,
[data-header-styles=color][data-vertical-style=icontext] .animated-arrow span,
[data-header-styles=color][data-vertical-style=overlay] .animated-arrow span,
[data-header-styles=color][data-vertical-style=detached] .animated-arrow span,
[data-header-styles=color][data-vertical-style=doublemenu] .animated-arrow span {
  background: #fff;
}
[data-header-styles=color][data-nav-style=menu-click] .animated-arrow span:before,
[data-header-styles=color][data-nav-style=menu-click] .animated-arrow span:after,
[data-header-styles=color][data-nav-style=menu-hover] .animated-arrow span:before,
[data-header-styles=color][data-nav-style=menu-hover] .animated-arrow span:after,
[data-header-styles=color][data-nav-style=icon-hover] .animated-arrow span:before,
[data-header-styles=color][data-nav-style=icon-hover] .animated-arrow span:after,
[data-header-styles=color][data-nav-style=icon-click] .animated-arrow span:before,
[data-header-styles=color][data-nav-style=icon-click] .animated-arrow span:after,
[data-header-styles=color][data-vertical-style=closed] .animated-arrow span:before,
[data-header-styles=color][data-vertical-style=closed] .animated-arrow span:after,
[data-header-styles=color][data-vertical-style=icontext] .animated-arrow span:before,
[data-header-styles=color][data-vertical-style=icontext] .animated-arrow span:after,
[data-header-styles=color][data-vertical-style=overlay] .animated-arrow span:before,
[data-header-styles=color][data-vertical-style=overlay] .animated-arrow span:after,
[data-header-styles=color][data-vertical-style=detached] .animated-arrow span:before,
[data-header-styles=color][data-vertical-style=detached] .animated-arrow span:after,
[data-header-styles=color][data-vertical-style=doublemenu] .animated-arrow span:before,
[data-header-styles=color][data-vertical-style=doublemenu] .animated-arrow span:after {
  background: #fff;
}
@media (min-width: 992px) {
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
    display: block;
  }
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
}
[data-header-styles=gradient] {
  --header-bg:
          linear-gradient(
                  to right,
                  var(--primary-color) 0%,
                  #e354d4 100%);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
}
[data-header-styles=gradient] .app-header .bg-body {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
[data-header-styles=gradient] .app-header .header-search-bar {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
}
[data-header-styles=gradient] .app-header .header-search-bar::-webkit-input-placeholder {
  color: var(--header-prime-color) !important;
}
[data-header-styles=gradient] .app-header .header-search-bar:focus {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--header-prime-color) !important;
  box-shadow: none !important;
}
[data-header-styles=gradient] .header-search-icon {
  color: var(--header-prime-color);
}
[data-header-styles=gradient] .header-search-icon:hover {
  color: var(--header-prime-color);
}
[data-header-styles=gradient][data-nav-style=menu-click] .animated-arrow span,
[data-header-styles=gradient][data-nav-style=menu-hover] .animated-arrow span,
[data-header-styles=gradient][data-nav-style=icon-hover] .animated-arrow span,
[data-header-styles=gradient][data-nav-style=icon-click] .animated-arrow span,
[data-header-styles=gradient][data-vertical-style=closed] .animated-arrow span,
[data-header-styles=gradient][data-vertical-style=icontext] .animated-arrow span,
[data-header-styles=gradient][data-vertical-style=overlay] .animated-arrow span,
[data-header-styles=gradient][data-vertical-style=detached] .animated-arrow span,
[data-header-styles=gradient][data-vertical-style=doublemenu] .animated-arrow span {
  background: #fff;
}
[data-header-styles=gradient][data-nav-style=menu-click] .animated-arrow span:before,
[data-header-styles=gradient][data-nav-style=menu-click] .animated-arrow span:after,
[data-header-styles=gradient][data-nav-style=menu-hover] .animated-arrow span:before,
[data-header-styles=gradient][data-nav-style=menu-hover] .animated-arrow span:after,
[data-header-styles=gradient][data-nav-style=icon-hover] .animated-arrow span:before,
[data-header-styles=gradient][data-nav-style=icon-hover] .animated-arrow span:after,
[data-header-styles=gradient][data-nav-style=icon-click] .animated-arrow span:before,
[data-header-styles=gradient][data-nav-style=icon-click] .animated-arrow span:after,
[data-header-styles=gradient][data-vertical-style=closed] .animated-arrow span:before,
[data-header-styles=gradient][data-vertical-style=closed] .animated-arrow span:after,
[data-header-styles=gradient][data-vertical-style=icontext] .animated-arrow span:before,
[data-header-styles=gradient][data-vertical-style=icontext] .animated-arrow span:after,
[data-header-styles=gradient][data-vertical-style=overlay] .animated-arrow span:before,
[data-header-styles=gradient][data-vertical-style=overlay] .animated-arrow span:after,
[data-header-styles=gradient][data-vertical-style=detached] .animated-arrow span:before,
[data-header-styles=gradient][data-vertical-style=detached] .animated-arrow span:after,
[data-header-styles=gradient][data-vertical-style=doublemenu] .animated-arrow span:before,
[data-header-styles=gradient][data-vertical-style=doublemenu] .animated-arrow span:after {
  background: #fff;
}
@media (min-width: 992px) {
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
    display: block;
  }
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
}
[data-header-styles=transparent] {
  --header-bg: rgb(var(--body-bg-rgb2));
}
[data-header-styles=transparent] .app-header {
  box-shadow: none;
}
[data-header-styles=transparent][data-theme-mode=light] {
  --header-border-color: rgba(0, 0, 0, 0.07);
  --header-bg: rgb(var(--body-bg-rgb));
}
[data-header-styles=transparent][data-theme-mode=light] .app-header {
  --default-body-bg-color: rgba(0, 0, 0 ,0.05);
  --default-background: rgba(0, 0, 0 ,0.05);
}
[data-header-styles=transparent][data-theme-mode=dark] {
  --header-prime-color: rgba(255, 255, 255, 0.6);
  --header-border-color: rgba(255, 255, 255, 0.1);
}
[data-header-styles=transparent][data-theme-mode=dark] .app-header {
  --default-body-bg-color: rgba(255,255,255,0.1);
  --default-background: rgba(255,255,255,0.1);
}
@media (min-width: 992px) {
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
}
.bg-img .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(var(--body-bg-rgb), 0.8);
}
@media (min-width: 992px) {
  .bg-img[data-nav-layout=horizontal] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  .bg-img[data-nav-layout=horizontal] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }
  .bg-img[data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before {
    display: none;
  }
  .bg-img[data-nav-layout=horizontal][data-menu-styles=transparent] .app-sidebar,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar {
    border-block-end: 0px solid var(--menu-border-color);
  }
  .bg-img[data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
  .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }
  .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }
  .bg-img[data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #202025;
  }
}
.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}
.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background:
          linear-gradient(
                  to right,
                  var(--primary-color) 0,
                  #e354d4 100%);
}
.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  border-block-end: 1px solid rgba(0, 0, 0, 0.04);
}
.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
.bg-img[data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-img[data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
@media (min-width: 991.98px) {
  .bg-img[data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
  [data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
  [data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
  [data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
  [data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
  [data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
    background-color: #fff;
    background-image: none;
  }
}
.bg-img[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: #fff;
  background-image: none;
}
.bg-img[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar {
  position: absolute;
}
.bg-img[data-menu-styles=light] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(255, 255, 255, 0.85);
}
@media (min-width: 991.98px) {
  .bg-img[data-menu-styles=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
  [data-menu-styles=light][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
  [data-menu-styles=light][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
  [data-menu-styles=light][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
  [data-menu-styles=light][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
  [data-menu-styles=light][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
    background-color: #fff !important;
    background-image: none;
  }
}
@media (min-width: 992px) {
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before {
    display: none;
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent] .app-sidebar,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar {
    border-block-end: 0px solid var(--menu-border-color);
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #202025;
  }
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background:
          linear-gradient(
                  to right,
                  var(--primary-color) 0,
                  #e354d4 100%);
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  border-block-end: 1px solid rgba(0, 0, 0, 0.04);
}
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
@media (min-width: 991.98px) {
  .bg-img[data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
    background-color: var(--custom-white);
    background-image: none;
  }
}
.bg-img[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: #202025;
}
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: #202025;
  background-image: none;
}
@media (min-width: 991.98px) {
  .bg-img[data-theme-mode=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
  [data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
  [data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
  [data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
  [data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
  [data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
    background-color: rgb(var(--body-bg-rgb));
  }
}
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
  background-image: none;
}
.bg-img[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-menu-styles=light][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: #fff;
  background-image: none;
}
.bg-img[data-menu-styles=color] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: var(--primary-color);
  opacity: 0.9;
}
.bg-img[data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}
.bg-img[data-menu-styles=gradient] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg1] .app-sidebar:before {
  background:
          linear-gradient(
                  to right,
                  var(--primary-color) 0%,
                  #e354d4 100%);
  opacity: 0.9;
}
.bg-img[data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}
.bg-img[data-menu-styles=transparent][data-theme-mode=dark],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg5],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg4],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg3],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg2],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg1] {
  --menu-prime-color: rgba(255, 255, 255, 0.6);
}
.bg-img[data-menu-styles=transparent] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: var(--default-body-bg-color);
  opacity: 0.9;
}
@media (min-width: 992px) {
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before {
    display: none;
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent] .app-sidebar,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar {
    border-block-end: 0px solid var(--menu-border-color);
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg5] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg4] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg3] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg2] .slide.side-menu__label1,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-bg-img=bgimg1] .slide.side-menu__label1,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #202025;
  }
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background:
          linear-gradient(
                  to right,
                  var(--primary-color) 0,
                  #e354d4 100%);
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: transparent;
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  border-block-end: 1px solid rgba(0, 0, 0, 0.04);
}
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: transparent;
}
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--default-body-bg-color) !important;
}
.bg-img[data-menu-styles=transparent][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: transparent;
  background-image: none;
}
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--default-body-bg-color);
  background-image: none;
}
.bg-img .app-sidebar,
[data-bg-img=bgimg5] .app-sidebar,
[data-bg-img=bgimg4] .app-sidebar,
[data-bg-img=bgimg3] .app-sidebar,
[data-bg-img=bgimg2] .app-sidebar,
[data-bg-img=bgimg1] .app-sidebar {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-inline-end: 0;
}
.bg-img .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background: transparent;
  border-inline-end: 0;
}
.bg-img .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/*[data-bg-img=bgimg1] {
  --menu-bg: url("bg-img1-5KUQ4VEU.jpg");
}
[data-bg-img=bgimg1][data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-bg: url("bg-img1-5KUQ4VEU.jpg");
}
[data-bg-img=bgimg2] {
  --menu-bg: url("bg-img2-5SFAKJMF.jpg");
}
[data-bg-img=bgimg2][data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-bg: url("bg-img2-5SFAKJMF.jpg");
}
[data-bg-img=bgimg3] {
  --menu-bg: url("bg-img3-KUR64AKE.jpg");
}
[data-bg-img=bgimg3][data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-bg: url("bg-img3-KUR64AKE.jpg");
}
[data-bg-img=bgimg4] {
  --menu-bg: url("bg-img4-DWDYL2DY.jpg");
}
[data-bg-img=bgimg4][data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-bg: url("bg-img4-DWDYL2DY.jpg");
}
[data-bg-img=bgimg5] {
  --menu-bg: url("bg-img5-SWTGIEEB.jpg");
}
[data-bg-img=bgimg5][data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-bg: url("bg-img5-SWTGIEEB.jpg");
}*/
[data-vertical-style=icontext][data-bg-img=bgimg1] .app-sidebar.sticky .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg2] .app-sidebar.sticky .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg3] .app-sidebar.sticky .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg4] .app-sidebar.sticky .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg5] .app-sidebar.sticky .main-sidebar-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}
[loader=disable] #loader {
  display: none;
}
[data-toggled=close-menu-close] .animated-arrow span,
[data-toggled=detached-close] .animated-arrow span,
[data-toggled=icon-click-closed] .animated-arrow span,
[data-toggled=icon-hover-closed] .animated-arrow span,
[data-toggled=icon-overlay-close] .animated-arrow span,
[data-toggled=icon-text-close] .animated-arrow span,
[data-toggled=menu-click-closed] .animated-arrow span,
[data-toggled=menu-hover-closed] .animated-arrow span {
  background-color: transparent;
  background: 0 0 !important;
}
[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before,
[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before {
  inset-block-start: -1px;
}
[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after,
[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after {
  width: 1.0625rem;
  inset-block-start: -1px;
}
[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after,
[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after {
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before,
[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before {
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  bottom: 0;
}
.accordion-button {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  padding: 0.75rem 1rem;
  font-size: 0.85rem;
  font-weight: 500;
}
.accordion-button:not(.collapsed) {
  color: var(--default-text-color);
  background-color: var(--default-background);
  box-shadow: none;
}
.accordion-button:focus {
  border-color: var(--primary03);
  box-shadow: none;
}
.accordion-button:after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
}
.accordion-button:after {
  content: "\ea78";
  font-family: remixicon !important;
  font-size: 1rem;
  line-height: 1;
  background-color: var(--default-background);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  color: var(--default-text-color);
}
.accordion-body {
  padding: 0.75rem 1rem;
  font-size: 0.8125rem;
  color: var(--text-muted);
}
.accordion-item {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}
.accordions-items-seperate .accordion-item:not(:first-of-type) {
  margin-block-start: 0.5rem;
}
.accordions-items-seperate .accordion-item {
  border-block-start: 1px solid var(--default-border);
  border-radius: 0.3rem;
  overflow: hidden;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.accordion.accordion-primary .accordion-button {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.accordion.accordion-primary .accordion-button:after {
  background-color: var(--primary-color);
  color: #fff;
}
.accordion.accordion-primary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-primary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-secondary .accordion-button {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}
.accordion.accordion-secondary .accordion-button:after {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.accordion.accordion-secondary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-secondary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-success .accordion-button {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}
.accordion.accordion-success .accordion-button:after {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.accordion.accordion-success .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-success .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-danger .accordion-button {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}
.accordion.accordion-danger .accordion-button:after {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.accordion.accordion-danger .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-danger .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-warning .accordion-button {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}
.accordion.accordion-warning .accordion-button:after {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.accordion.accordion-warning .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-warning .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-info .accordion-button {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}
.accordion.accordion-info .accordion-button:after {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.accordion.accordion-info .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-info .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-primary .accordion-button {
  background-color: var(--primary-color);
  color: #fff;
}
.accordion.accordion-solid-primary .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-color);
}
.accordion.accordion-solid-primary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-primary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-secondary .accordion-button {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.accordion.accordion-solid-secondary .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
}
.accordion.accordion-solid-secondary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-success .accordion-button {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.accordion.accordion-solid-success .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
}
.accordion.accordion-solid-success .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-success .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-danger .accordion-button {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.accordion.accordion-solid-danger .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
}
.accordion.accordion-solid-danger .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-danger .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-warning .accordion-button {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.accordion.accordion-solid-warning .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
}
.accordion.accordion-solid-warning .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-warning .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-solid-info .accordion-button {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.accordion.accordion-solid-info .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
}
.accordion.accordion-solid-info .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.accordion.accordion-solid-info .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}
.accordion.accordion-border-primary .accordion-item {
  border: 1px solid var(--primary-color);
}
.accordion.accordion-border-primary .accordion-button {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border-block-end: 0px;
}
.accordion.accordion-border-primary .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid var(--primary-color);
}
.accordion.accordion-border-primary1 .accordion-item {
  border: 1px solid var(--primary-tint1-color);
}
.accordion.accordion-border-primary1 .accordion-button {
  background-color: var(--custom-white);
  color: var(--primary-tint1-color);
  border-block-end: 0px;
}
.accordion.accordion-border-primary1 .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-tint1-color);
  border: 1px solid var(--primary-tint1-color);
}
.accordion.accordion-border-primary1 .accordion-button:not(.collapsed) {
  border-block-end: 1px solid var(--primary-tint1-color);
}
.accordion.accordion-border-primary2 .accordion-item {
  border: 1px solid var(--primary-tint2-color);
}
.accordion.accordion-border-primary2 .accordion-button {
  background-color: var(--custom-white);
  color: var(--primary-tint2-color);
  border-block-end: 0px;
}
.accordion.accordion-border-primary2 .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-tint2-color);
  border: 1px solid var(--primary-tint2-color);
}
.accordion.accordion-border-primary2 .accordion-button:not(.collapsed) {
  border-block-end: 1px solid var(--primary-tint2-color);
}
.accordion.accordion-border-primary3 .accordion-item {
  border: 1px solid var(--primary-tint3-color);
}
.accordion.accordion-border-primary3 .accordion-button {
  background-color: var(--custom-white);
  color: var(--primary-tint3-color);
  border-block-end: 0px;
}
.accordion.accordion-border-primary3 .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-tint3-color);
  border: 1px solid var(--primary-tint3-color);
}
.accordion.accordion-border-primary3 .accordion-button:not(.collapsed) {
  border-block-end: 1px solid var(--primary-tint3-color);
}
.accordion.accordion-border-secondary .accordion-item {
  border: 1px solid rgb(var(--secondary-rgb));
}
.accordion.accordion-border-secondary .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border-block-end: 0px;
}
.accordion.accordion-border-secondary .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}
.accordion.accordion-border-secondary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--secondary-rgb));
}
.accordion.accordion-border-success .accordion-item {
  border: 1px solid rgb(var(--success-rgb));
}
.accordion.accordion-border-success .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border-block-end: 0px;
}
.accordion.accordion-border-success .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}
.accordion.accordion-border-success .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--success-rgb));
}
.accordion.accordion-border-danger .accordion-item {
  border: 1px solid rgb(var(--danger-rgb));
}
.accordion.accordion-border-danger .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border-block-end: 0px;
}
.accordion.accordion-border-danger .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}
.accordion.accordion-border-danger .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--danger-rgb));
}
.accordion.accordion-border-warning .accordion-item {
  border: 1px solid rgb(var(--warning-rgb));
}
.accordion.accordion-border-warning .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border-block-end: 0px;
}
.accordion.accordion-border-warning .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}
.accordion.accordion-border-warning .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--warning-rgb));
}
.accordion.accordion-border-info .accordion-item {
  border: 1px solid rgb(var(--info-rgb));
}
.accordion.accordion-border-info .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border-block-end: 0px;
}
.accordion.accordion-border-info .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}
.accordion.accordion-border-info .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--info-rgb));
}
.accordion.accordionicon-none .accordion-button:after {
  display: none;
}
.accordion.accordionicon-left .accordion-button {
  padding-inline-start: 2.5rem;
}
.accordion.accordionicon-left .accordion-button:after {
  position: absolute;
  inset-inline-start: 0.625rem;
}
.accordion.accordion-customicon1 .accordion-button:after {
  content: "\ea13";
  font-family: remixicon !important;
}
.accordion.accordion-customicon1 .accordion-button:not(.collapsed)::after {
  content: "\f1af";
  font-family: remixicon !important;
}
.customized-accordion .accordion-item.custom-accordion-primary .accordion-button {
  background-color: var(--primary01);
  border-inline-start: 0.25rem solid var(--primary06);
}
.customized-accordion .accordion-item.custom-accordion-primary .accordion-button:after {
  background-color: var(--primary06);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
  background-color: var(--primary01);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid var(--primary06);
}
.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--secondary-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button:after {
  background-color: rgba(var(--secondary-rgb), 0.6);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-secondary .accordion-body {
  background-color: rgba(var(--secondary-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--secondary-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-warning .accordion-button {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--warning-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-warning .accordion-button:after {
  background-color: rgba(var(--warning-rgb), 0.6);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-warning .accordion-body {
  background-color: rgba(var(--warning-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--warning-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-info .accordion-button {
  background-color: rgba(var(--info-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--info-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
  background-color: rgba(var(--info-rgb), 0.6);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-info .accordion-body {
  background-color: rgba(var(--info-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--info-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-success .accordion-button {
  background-color: rgba(var(--success-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--success-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-success .accordion-button:after {
  background-color: rgba(var(--success-rgb), 0.6);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-success .accordion-body {
  background-color: rgba(var(--success-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--success-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-danger .accordion-button {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--danger-rgb), 0.6);
}
.customized-accordion .accordion-item.custom-accordion-danger .accordion-button:after {
  background-color: rgba(var(--danger-rgb), 0.6);
  color: #fff;
}
.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
  background-color: rgba(var(--danger-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--danger-rgb), 0.6);
}
.alert {
  padding: 0.625rem 0.85rem;
  border-radius: 0.3rem;
  font-size: 0.8125rem;
}
.alert.alert-dismissible {
  padding: 0.625rem 3rem 0.625rem 0.85rem;
}
.alert .btn-close {
  background-image: none;
  padding: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert .btn-close i {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.alert:last-child {
  margin-bottom: 0;
}
.alert .alert-link {
  font-weight: 400;
}
[dir=rtl] .alert.alert-dismissible {
  padding: 0.625rem 0.85rem 0.625rem 3rem;
}
.alert-warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
  border-color: rgba(var(--warning-rgb), 0.1);
}
.alert-warning .alert-link {
  color: rgb(var(--warning-rgb));
}
.alert-warning .btn-close {
  color: rgb(var(--warning-rgb));
}
.alert-warning .btn-close.custom-close {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-primary {
  background-color: var(--primary01);
  color: var(--primary-color);
  border-color: var(--primary01);
}
.alert-primary .alert-link {
  color: var(--primary-color);
}
.alert-primary .btn-close {
  color: var(--primary-color);
}
.alert-primary .btn-close.custom-close {
  background-color: var(--primary-color);
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-primary1 {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--primary-tint1-color);
  border-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.alert-primary1 .alert-link {
  color: var(--primary-tint1-color);
}
.alert-primary1 .btn-close {
  color: var(--primary-tint1-color);
}
.alert-primary1 .btn-close.custom-close {
  background-color: var(--primary-tint1-color);
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-primary2 {
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  color: var(--primary-tint2-color);
  border-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.alert-primary2 .alert-link {
  color: var(--primary-tint2-color);
}
.alert-primary2 .btn-close {
  color: var(--primary-tint2-color);
}
.alert-primary2 .btn-close.custom-close {
  background-color: var(--primary-tint2-color);
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-primary3 {
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
  color: var(--primary-tint3-color);
  border-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.alert-primary3 .alert-link {
  color: var(--primary-tint3-color);
}
.alert-primary3 .btn-close {
  color: var(--primary-tint3-color);
}
.alert-primary3 .btn-close.custom-close {
  background-color: var(--primary-tint3-color);
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
  border-color: rgba(var(--secondary-rgb), 0.1);
}
.alert-secondary .alert-link {
  color: rgb(var(--secondary-rgb));
}
.alert-secondary .btn-close {
  color: rgb(var(--secondary-rgb));
}
.alert-secondary .btn-close.custom-close {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-success {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
  border-color: rgba(var(--success-rgb), 0.1);
}
.alert-success .alert-link {
  color: rgb(var(--success-rgb));
}
.alert-success .btn-close {
  color: rgb(var(--success-rgb));
}
.alert-success .btn-close.custom-close {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-info {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
  border-color: rgba(var(--info-rgb), 0.1);
}
.alert-info .alert-link {
  color: rgb(var(--info-rgb));
}
.alert-info .btn-close {
  color: rgb(var(--info-rgb));
}
.alert-info .btn-close.custom-close {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
  border-color: rgba(var(--danger-rgb), 0.1);
}
.alert-danger .alert-link {
  color: rgb(var(--danger-rgb));
}
.alert-danger .btn-close {
  color: rgb(var(--danger-rgb));
}
.alert-danger .btn-close.custom-close {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}
.alert-light .alert-link {
  color: var(--default-text-color);
}
.alert-light .btn-close {
  color: var(--default-text-color);
}
.alert-light .btn-close.custom-close {
  background-color: rgb(var(--light-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-dark {
  background-color: rgba(var(--dark-rgb), 0.1);
  color: var(--default-text-color);
  border-color: rgba(var(--dark-rgb), 0.1);
}
.alert-dark .alert-link {
  color: var(--default-text-color);
}
.alert-dark .btn-close {
  color: #fff;
}
.alert-dark .btn-close.custom-close {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  padding: 0.85rem;
}
.alert-solid-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.alert-solid-primary .btn-close {
  color: #fff;
}
.alert-solid-primary1 {
  background-color: var(--primary-tint1-color);
  color: #fff;
  border-color: var(--primary-tint1-color);
}
.alert-solid-primary1 .btn-close {
  color: #fff;
}
.alert-solid-primary2 {
  background-color: var(--primary-tint2-color);
  color: #fff;
  border-color: var(--primary-tint2-color);
}
.alert-solid-primary2 .btn-close {
  color: #fff;
}
.alert-solid-primary3 {
  background-color: var(--primary-tint3-color);
  color: #fff;
  border-color: var(--primary-tint3-color);
}
.alert-solid-primary3 .btn-close {
  color: #fff;
}
.alert-solid-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}
.alert-solid-secondary .btn-close {
  color: #fff;
}
.alert-solid-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}
.alert-solid-warning .btn-close {
  color: #fff;
}
.alert-solid-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}
.alert-solid-info .btn-close {
  color: #fff;
}
.alert-solid-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}
.alert-solid-success .btn-close {
  color: #fff;
}
.alert-solid-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}
.alert-solid-danger .btn-close {
  color: #fff;
}
.alert-solid-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: var(--default-border);
}
.alert-solid-dark {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  border-color: rgb(var(--dark-rgb));
}
.alert-solid-dark .btn-close {
  color: #fff;
}
.alert-outline-primary {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.alert-outline-primary .btn-close {
  color: var(--primary-color);
}
.alert-outline-primary1 {
  background-color: var(--custom-white);
  color: var(--primary-tint1-color);
  border-color: var(--primary-tint1-color);
}
.alert-outline-primary1 .btn-close {
  color: var(--primary-tint1-color);
}
.alert-outline-primary2 {
  background-color: var(--custom-white);
  color: var(--primary-tint2-color);
  border-color: var(--primary-tint2-color);
}
.alert-outline-primary2 .btn-close {
  color: var(--primary-tint2-color);
}
.alert-outline-primary3 {
  background-color: var(--custom-white);
  color: var(--primary-tint3-color);
  border-color: var(--primary-tint3-color);
}
.alert-outline-primary3 .btn-close {
  color: var(--primary-tint3-color);
}
.alert-outline-secondary {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.alert-outline-secondary .btn-close {
  color: rgb(var(--secondary-rgb));
}
.alert-outline-info {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}
.alert-outline-info .btn-close {
  color: rgb(var(--info-rgb));
}
.alert-outline-warning {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}
.alert-outline-warning .btn-close {
  color: rgb(var(--warning-rgb));
}
.alert-outline-success {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}
.alert-outline-success .btn-close {
  color: rgb(var(--success-rgb));
}
.alert-outline-danger {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}
.alert-outline-danger .btn-close {
  color: rgb(var(--danger-rgb));
}
.alert-outline-light {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}
.alert-outline-dark {
  background-color: var(--custom-white);
  color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}
.alert-outline-dark .btn-close {
  color: rgb(var(--dark-rgb));
}
.alert-primary.custom-alert-icon {
  border-inline-start: 0.313rem solid var(--primary-color) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-primary.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-primary1.custom-alert-icon {
  border-inline-start: 0.313rem solid var(--primary-tint1-color) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-primary1.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-primary2.custom-alert-icon {
  border-inline-start: 0.313rem solid var(--primary-tint2-color) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-primary2.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-primary3.custom-alert-icon {
  border-inline-start: 0.313rem solid var(--primary-tint3-color) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-primary3.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-secondary.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--secondary-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-secondary.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-warning.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--warning-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-warning.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-danger.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--danger-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-danger.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-success.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--success-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-success.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-info.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--info-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-info.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-light.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--light-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-light.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.alert-dark.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--dark-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}
.alert-dark.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}
.custom-alert1 {
  margin-block-end: 0;
  background-color: var(--custom-white);
  border: 0;
  padding: 1.25rem;
  color: var(--default-text-color);
}
.custom-alert1 p {
  margin-block-end: 2.5rem;
  color: var(--text-muted);
  font-size: 0.8rem;
}
.custom-alert1 .custom-alert-icon {
  width: 3.125rem;
  height: 3.125rem;
  margin-bottom: 0.85rem;
}
.custom-alert1 .btn-close {
  padding: 0;
  margin-block-end: 1rem;
}
.custom-alert1.alert-primary {
  border-block-start: 0.313rem solid var(--primary-color);
}
.custom-alert1.alert-secondary {
  border-block-start: 0.313rem solid rgb(var(--secondary-rgb));
}
.custom-alert1.alert-warning {
  border-block-start: 0.313rem solid rgb(var(--warning-rgb));
}
.custom-alert1.alert-danger {
  border-block-start: 0.313rem solid rgb(var(--danger-rgb));
}
.alert-img {
  display: flex;
  align-items: center;
}
.alert-img .avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 400px) {
  .btn-close.custom-close {
    margin: 9px 9px 9px 0;
  }
}
.app-sidebar .badge {
  padding: 0.15rem 0.3rem;
  font-weight: 500;
  border-radius: 0.25rem;
}
.badge {
  padding: 0.25rem 0.45rem;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 11px;
}
.badge-sm {
  font-weight: normal;
  font-size: 0.625rem;
}
.min-w-badge {
  min-width: 80px;
}
.btn.btn-outline-primary .badge {
  background-color: var(--primary-color);
  color: #fff;
}
.btn.btn-outline-primary:hover .badge,
.btn.btn-outline-primary:focus .badge,
.btn.btn-outline-primary:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-primary1 .badge {
  background-color: var(--primary-tint1-color);
  color: #fff;
}
.btn.btn-outline-primary1:hover .badge,
.btn.btn-outline-primary1:focus .badge,
.btn.btn-outline-primary1:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-primary2 .badge {
  background-color: var(--primary-tint2-color);
  color: #fff;
}
.btn.btn-outline-primary2:hover .badge,
.btn.btn-outline-primary2:focus .badge,
.btn.btn-outline-primary2:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-primary3 .badge {
  background-color: var(--primary-tint3-color);
  color: #fff;
}
.btn.btn-outline-primary3:hover .badge,
.btn.btn-outline-primary3:focus .badge,
.btn.btn-outline-primary3:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-secondary .badge {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.btn.btn-outline-secondary:hover .badge,
.btn.btn-outline-secondary:focus .badge,
.btn.btn-outline-secondary:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-warning .badge {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.btn.btn-outline-warning:hover .badge,
.btn.btn-outline-warning:focus .badge,
.btn.btn-outline-warning:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-info .badge {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.btn.btn-outline-info:hover .badge,
.btn.btn-outline-info:focus .badge,
.btn.btn-outline-info:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-success .badge {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.btn.btn-outline-success:hover .badge,
.btn.btn-outline-success:focus .badge,
.btn.btn-outline-success:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.btn.btn-outline-danger .badge {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.btn.btn-outline-danger:hover .badge,
.btn.btn-outline-danger:focus .badge,
.btn.btn-outline-danger:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}
.custom-badge {
  font-size: 0.7rem;
  font-weight: 400;
}
.icon-badge {
  position: relative;
}
.icon-badge .icon {
  width: 2rem;
  height: 2rem;
  fill: var(--text-muted);
  font-size: 2rem;
}
.icon-badge .badge {
  position: absolute;
  inset-inline-end: -0.5rem;
}
.text-badge {
  position: relative;
}
.text-badge .badge {
  position: absolute;
  inset-inline-end: -1rem;
  inset-block-start: -1rem;
}
.page-header-breadcrumb {
  margin-block: 1.75rem;
}
/*.page-header-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea1c");
  font-family: "tabler-icons" !important;
}*/
.page-header-breadcrumb .form-group {
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
}
.page-header-breadcrumb .btn-list .btn {
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
}
.page-header-breadcrumb .btn-list .btn:focus {
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
}
/*[dir=rtl] .page-header-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea1c");
  font-family: "tabler-icons" !important;
}*/
.breadcrumb .breadcrumb-item a {
  color: var(--primary-color);
}
.breadcrumb .breadcrumb-item.active {
  color: var(--default-text-color);
  font-weight: 500;
}
.page-header-breadcrumb .breadcrumb-icon svg {
  width: 1rem;
  height: 1rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: var(--text-muted);
}
/*.breadcrumb-example1 .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea65");
  font-family: "tabler-icons" !important;
}
.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea1c");
  font-family: "tabler-icons" !important;
}
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea61");
  font-family: "tabler-icons" !important;
}*/
.embedded-breadcrumb:before {
  opacity: 0.7;
}
[data-theme-mode=dark] .embedded-breadcrumb:before {
  filter: invert(1);
}
/*[dir=rtl] .breadcrumb-example1 .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea1c");
}
[dir=rtl] .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea1b");
}
[dir=rtl] .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea60");
}*/
[dir=rtl] .embedded-breadcrumb:before {
  transform: scaleX(-1);
  padding-inline-start: 0.5rem;
  padding-inline-end: 0rem !important;
}
.form-control.breadcrumb-input {
  min-width: 12.5rem;
  border: 1px solid var(--default-border);
}
.form-control.breadcrumb-input:focus {
  border: 1px solid var(--default-border);
  box-shadow: none;
}
.btn {
  font-size: 0.85rem;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-shadow: none;
  font-weight: 500;
}
.btn.btn-sm {
  padding: 0.26rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}
.btn.btn-lg {
  padding: 0.65rem 1rem;
  border-radius: 0.3rem;
  font-size: 0.95rem;
}
.btn-group .btn {
  padding: 0.45rem 0.75rem;
}
.btn-group-lg .btn {
  padding: 0.65rem 1rem;
}
.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  color: #fff;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.btn-primary {
  background-color: rgb(var(--primary-rgb)) !important;
  border-color: rgb(var(--primary-rgb)) !important;
  color: #fff !important;
}
.btn-primary:hover {
  background-color: rgba(var(--primary-rgb), 0.9) !important;
  border-color: rgb(var(--primary-rgb)) !important;
  color: #fff;
}
.btn-primary:focus {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  color: #fff;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary.show:focus,
.btn-primary:active:focus {
  box-shadow: 0 0.25rem 1rem var(--primary05);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-border) !important;
}
.btn-primary1 {
  background-color: rgb(var(--primary-tint1-rgb)) !important;
  border-color: rgb(var(--primary-tint1-rgb)) !important;
  color: #fff !important;
}
.btn-primary1:hover {
  background-color: rgba(var(--primary-tint1-rgb), 0.9) !important;
  border-color: rgb(var(--primary-tint1-rgb)) !important;
  color: #fff;
}
.btn-primary1:focus {
  background-color: rgb(var(--primary-tint1-rgb));
  border-color: rgb(var(--primary-tint1-rgb));
  color: #fff;
}
.btn-check:focus + .btn-primary1,
.btn-primary1:focus {
  background-color: rgb(var(--primary-tint1-rgb));
  border-color: rgb(var(--primary-tint1-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-primary1:focus,
.btn-check:checked + .btn-primary1:focus,
.btn-primary1.active:focus,
.btn-primary1.show:focus,
.btn-primary1:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-tint1-rgb), 0.05);
}
.btn-check:active + .btn-primary1,
.btn-check:checked + .btn-primary1,
.btn-primary1.active,
.btn-primary1.show,
.btn-primary1:active {
  background-color: var(--primary-tint1-color) !important;
  border-color: var(--primary-tint1-color) !important;
}
.btn-primary2 {
  background-color: rgb(var(--primary-tint2-rgb)) !important;
  border-color: rgb(var(--primary-tint2-rgb)) !important;
  color: #fff !important;
}
.btn-primary2:hover {
  background-color: rgba(var(--primary-tint2-rgb), 0.9) !important;
  border-color: rgb(var(--primary-tint2-rgb)) !important;
  color: #fff;
}
.btn-primary2:focus {
  background-color: rgb(var(--primary-tint2-rgb));
  border-color: rgb(var(--primary-tint2-rgb));
  color: #fff;
}
.btn-check:focus + .btn-primary2,
.btn-primary2:focus {
  background-color: rgb(var(--primary-tint2-rgb));
  border-color: rgb(var(--primary-tint2-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-primary2:focus,
.btn-check:checked + .btn-primary2:focus,
.btn-primary2.active:focus,
.btn-primary2.show:focus,
.btn-primary2:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-tint2-rgb), 0.05);
}
.btn-check:active + .btn-primary2,
.btn-check:checked + .btn-primary2,
.btn-primary2.active,
.btn-primary2.show,
.btn-primary2:active {
  background-color: var(--primary-tint2-color) !important;
  border-color: var(--primary-tint2-color) !important;
}
.btn-primary3 {
  background-color: rgb(var(--primary-tint3-rgb)) !important;
  border-color: rgb(var(--primary-tint3-rgb)) !important;
  color: #fff !important;
}
.btn-primary3:hover {
  background-color: rgba(var(--primary-tint3-rgb), 0.9) !important;
  border-color: rgb(var(--primary-tint3-rgb)) !important;
  color: #fff;
}
.btn-primary3:focus {
  background-color: rgb(var(--primary-tint3-rgb));
  border-color: rgb(var(--primary-tint3-rgb));
  color: #fff;
}
.btn-check:focus + .btn-primary3,
.btn-primary3:focus {
  background-color: rgb(var(--primary-tint3-rgb));
  border-color: rgb(var(--primary-tint3-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-primary3:focus,
.btn-check:checked + .btn-primary3:focus,
.btn-primary3.active:focus,
.btn-primary3.show:focus,
.btn-primary3:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-tint3-rgb), 0.05);
}
.btn-check:active + .btn-primary3,
.btn-check:checked + .btn-primary3,
.btn-primary3.active,
.btn-primary3.show,
.btn-primary3:active {
  background-color: var(--primary-tint3-color) !important;
  border-color: var(--primary-tint3-color) !important;
}
.btn-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
  color: #fff !important;
}
.btn-secondary:hover {
  background-color: rgba(var(--secondary-rgb), 0.9) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
  color: #fff;
}
.btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary.show:focus,
.btn-secondary:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary.show,
.btn-secondary:active {
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}
.btn-warning {
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
  color: #fff !important;
}
.btn-warning:hover {
  background-color: rgba(var(--warning-rgb), 0.9) !important;
  border-color: rgb(var(--warning-rgb)) !important;
  color: #fff;
}
.btn-warning:focus {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  color: #fff;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning.show:focus,
.btn-warning:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning.show,
.btn-warning:active {
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
}
.btn-info {
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
  color: #fff !important;
}
.btn-info:hover {
  background-color: rgba(var(--info-rgb), 0.9) !important;
  border-color: rgb(var(--info-rgb)) !important;
  color: #fff;
}
.btn-info:focus {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  color: #fff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info.show:focus,
.btn-info:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info.show,
.btn-info:active {
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
}
.btn-danger {
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
  color: #fff !important;
}
.btn-danger:hover {
  background-color: rgba(var(--danger-rgb), 0.9) !important;
  border-color: rgb(var(--danger-rgb)) !important;
  color: #fff;
}
.btn-danger:focus {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger.show:focus,
.btn-danger:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger.show,
.btn-danger:active {
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
}
.btn-success {
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
  color: #fff !important;
}
.btn-success:hover {
  background-color: rgba(var(--success-rgb), 0.9) !important;
  border-color: rgb(var(--success-rgb)) !important;
  color: #fff;
}
.btn-success:focus {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success.show:focus,
.btn-success:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success.show,
.btn-success:active {
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
}
.btn-dark {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  color: var(--custom-white) !important;
}
.btn-dark svg {
  fill: var(--custom-black);
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark.show:focus,
.btn-dark:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark.show,
.btn-dark:active {
  background-color: rgb(var(--dark-rgb)) !important;
  border-color: rgb(var(--dark-rgb)) !important;
  color: var(--custom-white);
}
.btn-light {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.btn-light:hover {
  background-color: #f2f2f3;
  border-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.btn-light:focus {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  box-shadow: none;
  color: var(--default-text-color);
}
[data-theme-mode=dark] .btn-light:hover {
  background-color: rgba(var(--light-rgb), 0.8);
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light.show:focus,
.btn-light:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light.show,
.btn-light:active {
  background-color: rgb(var(--light-rgb)) !important;
  border-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color) !important;
}
.btn-teal {
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
  color: #fff !important;
}
.btn-teal:hover {
  background-color: rgba(var(--teal-rgb), 0.9) !important;
  border-color: rgb(var(--teal-rgb)) !important;
  color: #fff;
}
.btn-teal:focus {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  color: #fff;
}
.btn-check:focus + .btn-teal,
.btn-teal:focus {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-teal:focus,
.btn-check:checked + .btn-teal:focus,
.btn-teal.active:focus,
.btn-teal.show:focus,
.btn-teal:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:active + .btn-teal,
.btn-check:checked + .btn-teal,
.btn-teal.active,
.btn-teal.show,
.btn-teal:active {
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
}
.btn-purple {
  background-color: rgb(var(--purple-rgb)) !important;
  border-color: rgb(var(--purple-rgb)) !important;
  color: #fff !important;
}
.btn-purple:hover {
  background-color: rgba(var(--purple-rgb), 0.9) !important;
  border-color: rgb(var(--purple-rgb)) !important;
  color: #fff;
}
.btn-purple:focus {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  color: #fff;
}
.btn-check:focus + .btn-purple,
.btn-purple:focus {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-purple:focus,
.btn-check:checked + .btn-purple:focus,
.btn-purple.active:focus,
.btn-purple.show:focus,
.btn-purple:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}
.btn-check:active + .btn-purple,
.btn-check:checked + .btn-purple,
.btn-purple.active,
.btn-purple.show,
.btn-purple:active {
  background-color: rgb(var(--purple-rgb)) !important;
  border-color: rgb(var(--purple-rgb)) !important;
}
.btn-orange {
  background-color: rgb(var(--orange-rgb)) !important;
  border-color: rgb(var(--orange-rgb)) !important;
  color: #fff !important;
}
.btn-orange:hover {
  background-color: rgba(var(--orange-rgb), 0.9) !important;
  border-color: rgb(var(--orange-rgb)) !important;
  color: #fff;
}
.btn-orange:focus {
  background-color: rgb(var(--orange-rgb));
  border-color: rgb(var(--orange-rgb));
  color: #fff;
}
.btn-check:focus + .btn-orange,
.btn-orange:focus {
  background-color: rgb(var(--orange-rgb));
  border-color: rgb(var(--orange-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-orange:focus,
.btn-check:checked + .btn-orange:focus,
.btn-orange.active:focus,
.btn-orange.show:focus,
.btn-orange:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--orange-rgb), 0.5);
}
.btn-check:active + .btn-orange,
.btn-check:checked + .btn-orange,
.btn-orange.active,
.btn-orange.show,
.btn-orange:active {
  background-color: rgb(var(--orange-rgb)) !important;
  border-color: rgb(var(--orange-rgb)) !important;
}
.btn-gray-500 {
  background-color: var(--gray-5) !important;
  border-color: var(--gray-5) !important;
  color: #fff !important;
}
.btn-gray-500:hover {
  background-color: var(--gray-5) !important;
  border-color: var(--gray-5) !important;
  color: #fff;
}
.btn-gray-500:focus {
  background-color: var(--gray-5);
  border-color: var(--gray-5);
  color: #fff;
}
.btn-check:focus + .btn-gray-500,
.btn-gray-500:focus {
  background-color: rgb(var(--gray-5));
  border-color: rgb(var(--gray-5));
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn-gray-500:focus,
.btn-check:checked + .btn-gray-500:focus,
.btn-gray-500.active:focus,
.btn-gray-500.show:focus,
.btn-gray-500:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--gray-5), 0.05);
}
.btn-check:active + .btn-gray-500,
.btn-check:checked + .btn-gray-500,
.btn-gray-500.active,
.btn-gray-500.show,
.btn-gray-500:active {
  background-color: var(--gray-5) !important;
  border-color: var(--gray-5) !important;
}
.btn-outline-primary {
  color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: #fff;
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
}
.btn-outline-primary:focus {
  color: #fff;
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  box-shadow: none;
}
.btn-outline-primary:focus:active {
  box-shadow: none;
}
.btn-outline-primary1 {
  color: rgb(var(--primary-tint1-rgb));
  border-color: rgb(var(--primary-tint1-rgb));
}
.btn-outline-primary1:hover,
.btn-outline-primary1:active {
  color: #fff;
  background-color: rgb(var(--primary-tint1-rgb));
  border-color: rgb(var(--primary-tint1-rgb));
}
.btn-outline-primary1:focus {
  color: #fff;
  background-color: rgb(var(--primary-tint1-rgb));
  border-color: rgb(var(--primary-tint1-rgb));
  box-shadow: none;
}
.btn-outline-primary1:focus:active {
  box-shadow: none;
}
.btn-outline-primary2 {
  color: rgb(var(--primary-tint2-rgb));
  border-color: rgb(var(--primary-tint2-rgb));
}
.btn-outline-primary2:hover,
.btn-outline-primary2:active {
  color: #fff;
  background-color: rgb(var(--primary-tint2-rgb));
  border-color: rgb(var(--primary-tint2-rgb));
}
.btn-outline-primary2:focus {
  color: #fff;
  background-color: rgb(var(--primary-tint2-rgb));
  border-color: rgb(var(--primary-tint2-rgb));
  box-shadow: none;
}
.btn-outline-primary2:focus:active {
  box-shadow: none;
}
.btn-outline-primary3 {
  color: rgb(var(--primary-tint3-rgb));
  border-color: rgb(var(--primary-tint3-rgb));
}
.btn-outline-primary3:hover,
.btn-outline-primary3:active {
  color: #fff;
  background-color: rgb(var(--primary-tint3-rgb));
  border-color: rgb(var(--primary-tint3-rgb));
}
.btn-outline-primary3:focus {
  color: #fff;
  background-color: rgb(var(--primary-tint3-rgb));
  border-color: rgb(var(--primary-tint3-rgb));
  box-shadow: none;
}
.btn-outline-primary3:focus:active {
  box-shadow: none;
}
.btn-outline-secondary {
  color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.btn-outline-secondary:focus {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  box-shadow: none;
}
.btn-outline-secondary:focus:active {
  box-shadow: none;
}
.btn-outline-warning {
  color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}
.btn-outline-warning:hover,
.btn-outline-warning:active {
  color: #fff;
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}
.btn-outline-warning:focus {
  color: #fff;
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  box-shadow: none;
}
.btn-outline-warning:focus:active {
  box-shadow: none;
}
.btn-outline-danger {
  color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}
.btn-outline-danger:hover,
.btn-outline-danger:active {
  color: #fff;
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}
.btn-outline-danger:focus {
  color: #fff;
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  box-shadow: none;
}
.btn-outline-danger:focus:active {
  box-shadow: none;
}
.btn-outline-success {
  color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}
.btn-outline-success:hover,
.btn-outline-success:active {
  color: #fff;
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}
.btn-outline-success:focus {
  color: #fff;
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  box-shadow: none;
}
.btn-outline-success:focus:active {
  box-shadow: none;
}
.btn-outline-info {
  color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}
.btn-outline-info:hover,
.btn-outline-info:active {
  color: #fff;
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}
.btn-outline-info:focus {
  color: #fff;
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  box-shadow: none;
}
.btn-outline-info:focus:active {
  box-shadow: none;
}
.btn-outline-light {
  color: var(--default-text-color) !important;
  border-color: rgb(var(--light-rgb));
}
.btn-outline-light:hover,
.btn-outline-light:active {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
}
.btn-outline-light:focus {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  box-shadow: none;
}
.btn-outline-light:focus:active {
  box-shadow: none;
}
.btn-outline-dark {
  color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}
.btn-outline-dark:hover,
.btn-outline-dark:active {
  color: var(--custom-white);
  background-color: var(--custom-black);
  border-color: var(--custom-black);
}
.btn-outline-dark:focus {
  color: var(--custom-white);
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  box-shadow: none;
}
.btn-outline-dark:focus:active {
  box-shadow: none;
}
.btn-outline-teal {
  color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
}
.btn-outline-teal:hover,
.btn-outline-teal:active {
  color: #fff;
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
}
.btn-outline-teal:focus {
  color: #fff;
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  box-shadow: none;
}
.btn-outline-teal:focus:active {
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.btn-primary-transparent {
  background-color: rgba(var(--primary-rgb), 0.1);
  border-color: rgba(var(--primary-rgb), 0.1);
  color: rgb(var(--primary-rgb));
}
.btn-primary-transparent:hover,
.btn-primary-transparent:focus:hover {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  color: #fff;
}
.btn-check:focus + .btn-primary-transparent,
.btn-primary-transparent:focus {
  background-color: rgba(var(--primary-rgb), 0.1);
  border-color: rgba(var(--primary-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-rgb), 0.3);
  color: rgb(var(--primary-rgb));
}
.btn-check:active + .btn-primary-transparent:focus,
.btn-check:checked + .btn-primary-transparent:focus,
.btn-primary-transparent.active:focus,
.btn-primary-transparent.show:focus,
.btn-primary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem var(--primary05);
}
.btn-check:active + .btn-primary-transparent,
.btn-check:checked + .btn-primary-transparent,
.btn-primary-transparent.active,
.btn-primary-transparent.show,
.btn-primary-transparent:active {
  background-color: var(--primary-color);
  border-color: var(--primary01);
  color: #fff;
}
.btn-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}
.btn-secondary-transparent:hover,
.btn-secondary-transparent:focus:hover {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.btn-check:focus + .btn-secondary-transparent,
.btn-secondary-transparent:focus {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-color: rgba(var(--secondary-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3);
  color: rgb(var(--secondary-rgb));
}
.btn-check:active + .btn-secondary-transparent:focus,
.btn-check:checked + .btn-secondary-transparent:focus,
.btn-secondary-transparent.active:focus,
.btn-secondary-transparent.show:focus,
.btn-secondary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:active + .btn-secondary-transparent,
.btn-check:checked + .btn-secondary-transparent,
.btn-secondary-transparent.active,
.btn-secondary-transparent.show,
.btn-secondary-transparent:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgba(var(--secondary-rgb), 0.1);
  color: #fff;
}
.btn-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}
.btn-warning-transparent:hover,
.btn-warning-transparent:focus:hover {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  color: #fff;
}
.btn-check:focus + .btn-warning-transparent,
.btn-warning-transparent:focus {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-color: rgba(var(--warning-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.3);
  color: rgb(var(--warning-rgb));
}
.btn-check:active + .btn-warning-transparent:focus,
.btn-check:checked + .btn-warning-transparent:focus,
.btn-warning-transparent.active:focus,
.btn-warning-transparent.show:focus,
.btn-warning-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:active + .btn-warning-transparent,
.btn-check:checked + .btn-warning-transparent,
.btn-warning-transparent.active,
.btn-warning-transparent.show,
.btn-warning-transparent:active {
  background-color: rgb(var(--warning-rgb));
  border-color: rgba(var(--warning-rgb), 0.1);
  color: #fff;
}
.btn-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1);
  border-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}
.btn-info-transparent:hover,
.btn-info-transparent:focus:hover {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  color: #fff;
}
.btn-check:focus + .btn-info-transparent,
.btn-info-transparent:focus {
  background-color: rgba(var(--info-rgb), 0.1);
  border-color: rgba(var(--info-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.3);
  color: rgb(var(--info-rgb));
}
.btn-check:active + .btn-info-transparent:focus,
.btn-check:checked + .btn-info-transparent:focus,
.btn-info-transparent.active:focus,
.btn-info-transparent.show:focus,
.btn-info-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:active + .btn-info-transparent,
.btn-check:checked + .btn-info-transparent,
.btn-info-transparent.active,
.btn-info-transparent.show,
.btn-info-transparent:active {
  background-color: rgb(var(--info-rgb));
  border-color: rgba(var(--info-rgb), 0.1);
  color: #fff;
}
.btn-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1);
  border-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}
.btn-success-transparent:hover,
.btn-success-transparent:focus:hover {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}
.btn-check:focus + .btn-success-transparent,
.btn-success-transparent:focus {
  background-color: rgba(var(--success-rgb), 0.1);
  border-color: rgba(var(--success-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.3);
  color: rgb(var(--success-rgb));
}
.btn-check:active + .btn-success-transparent:focus,
.btn-check:checked + .btn-success-transparent:focus,
.btn-success-transparent.active:focus,
.btn-success-transparent.show:focus,
.btn-success-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:active + .btn-success-transparent,
.btn-check:checked + .btn-success-transparent,
.btn-success-transparent.active,
.btn-success-transparent.show,
.btn-success-transparent:active {
  background-color: rgb(var(--success-rgb));
  border-color: rgba(var(--success-rgb), 0.1);
  color: #fff;
}
.btn-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}
.btn-danger-transparent:hover,
.btn-danger-transparent:focus:hover {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}
.btn-check:focus + .btn-danger-transparent,
.btn-danger-transparent:focus {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-color: rgba(var(--danger-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.3);
  color: rgb(var(--danger-rgb));
}
.btn-check:active + .btn-danger-transparent:focus,
.btn-check:checked + .btn-danger-transparent:focus,
.btn-danger-transparent.active:focus,
.btn-danger-transparent.show:focus,
.btn-danger-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:active + .btn-danger-transparent,
.btn-check:checked + .btn-danger-transparent,
.btn-danger-transparent.active,
.btn-danger-transparent.show,
.btn-danger-transparent:active {
  background-color: rgb(var(--danger-rgb));
  border-color: rgba(var(--danger-rgb), 0.1);
  color: #fff;
}
.btn-teal-transparent {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}
.btn-teal-transparent:hover,
.btn-teal-transparent:focus:hover {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  color: #fff;
}
.btn-check:focus + .btn-teal-transparent,
.btn-teal-transparent:focus {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-color: rgba(var(--teal-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.3);
  color: rgb(var(--teal-rgb));
}
.btn-check:active + .btn-teal-transparent:focus,
.btn-check:checked + .btn-teal-transparent:focus,
.btn-teal-transparent.active:focus,
.btn-teal-transparent.show:focus,
.btn-teal-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:active + .btn-teal-transparent,
.btn-check:checked + .btn-teal-transparent,
.btn-teal-transparent.active,
.btn-teal-transparent.show,
.btn-teal-transparent:active {
  background-color: rgb(var(--teal-rgb));
  border-color: rgba(var(--teal-rgb), 0.1);
  color: #fff;
}
.btn-purple-transparent {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}
.btn-purple-transparent:hover,
.btn-purple-transparent:focus:hover {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  color: #fff;
}
.btn-check:focus + .btn-purple-transparent,
.btn-purple-transparent:focus {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-color: rgba(var(--purple-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.3);
  color: rgb(var(--purple-rgb));
}
.btn-check:active + .btn-purple-transparent:focus,
.btn-check:checked + .btn-purple-transparent:focus,
.btn-purple-transparent.active:focus,
.btn-purple-transparent.show:focus,
.btn-purple-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}
.btn-check:active + .btn-purple-transparent,
.btn-check:checked + .btn-purple-transparent,
.btn-purple-transparent.active,
.btn-purple-transparent.show,
.btn-purple-transparent:active {
  background-color: rgb(var(--purple-rgb));
  border-color: rgba(var(--purple-rgb), 0.1);
  color: #fff;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
}
.btn-icon {
  width: 2.1rem;
  height: 2.1rem;
  font-size: 0.95rem;
  padding: 0.375rem;
  flex-shrink: 0;
}
.btn-icon i {
  padding: 0rem;
  margin: 0 -0.5rem;
}
.btn-icon.btn-sm {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.8rem;
  padding: 0.1875rem;
}
.btn-icon.btn-lg {
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.2rem;
  padding: 0.5rem;
}
.btn-list button,
.btn-list div,
.btn-list a,
.btn-list input {
  margin: 0 0.375rem 0.375rem 0;
}
[dir=rtl] .btn-list button,
[dir=rtl] .btn-list div,
[dir=rtl] .btn-list a,
[dir=rtl] .btn-list input {
  margin: 0 0 0.375rem 0.375rem;
}
.btn-list {
  margin-block-end: -0.5rem;
}
.btn-bd-primary {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--primary-color);
  --bs-btn-hover-bg: rgba(var(--primary-color), 0.9);
  --bs-btn-hover-border-color: rgba(var(--primary-color), 0.9);
  --bs-btn-active-color: var(--primary-color);
  --bs-btn-active-bg: rgba(var(--primary-color), 0.9);
  --bs-btn-active-border-color: rgba(var(--primary-color), 0.9);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.btn-check + .btn.btn-outline-primary:hover {
  color: #fff !important;
  background-color: rgb(var(--primary-rgb)) !important;
  border-color: rgb(var(--primary-rgb)) !important;
}
.btn-check:active + .btn-outline-primary1,
.btn-check:checked + .btn-outline-primary1,
.btn-outline-primary1.active,
.btn-outline-primary1.dropdown-toggle.show,
.btn-outline-primary1:active,
.btn-check + .btn.btn-outline-primary1:hover {
  color: #fff !important;
  background-color: rgb(var(--primary-tint1-rgb)) !important;
  border-color: rgb(var(--primary-tint1-rgb)) !important;
}
.btn-check:active + .btn-outline-primary2,
.btn-check:checked + .btn-outline-primary2,
.btn-outline-primary2.active,
.btn-outline-primary2.dropdown-toggle.show,
.btn-outline-primary2:active,
.btn-check + .btn.btn-outline-primary2:hover {
  color: #fff !important;
  background-color: rgb(var(--primary-tint2-rgb)) !important;
  border-color: rgb(var(--primary-tint2-rgb)) !important;
}
.btn-check:active + .btn-outline-primary3,
.btn-check:checked + .btn-outline-primary3,
.btn-outline-primary3.active,
.btn-outline-primary3.dropdown-toggle.show,
.btn-outline-primary3:active,
.btn-check + .btn.btn-outline-primary3:hover {
  color: #fff !important;
  background-color: rgb(var(--primary-tint3-rgb)) !important;
  border-color: rgb(var(--primary-tint3-rgb)) !important;
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active,
.btn-check + .btn.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active,
.btn-check + .btn.btn-outline-info:hover {
  color: #fff !important;
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active,
.btn-check + .btn.btn-outline-warning:hover {
  color: #fff !important;
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active,
.btn-check + .btn.btn-outline-danger:hover {
  color: #fff !important;
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-check + .btn.btn-outline-success:hover {
  color: #fff !important;
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover,
.btn-check + .btn.btn-outline-light:hover {
  color: var(--default-text-color) !important;
  border-color: rgb(var(--light-rgb)) !important;
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active,
.btn-check + .btn.btn-outline-dark:hover {
  color: var(--custom-white) !important;
  background-color: var(--custom-black) !important;
  border-color: var(--custom-black) !important;
}
.btn-check:active + .btn-outline-teal,
.btn-check:checked + .btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active,
.btn-check + .btn.btn-outline-teal:hover {
  color: #fff !important;
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
}
.btn-check:checked + .btn-outline-primary:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-color), 0.5);
}
.btn-check:checked + .btn-outline-secondary:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:checked + .btn-outline-success:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:checked + .btn-outline-warning:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:checked + .btn-outline-info:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:checked + .btn-outline-danger:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:checked + .btn-outline-light:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}
.btn-check:checked + .btn-outline-dark:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}
.btn-check:checked + .btn-outline-teal:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:focus + .btn.btn-outline-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: none;
}
.btn-check:checked + .btn.btn-outline-secondary {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3);
}
.btn.show.btn-outline-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}
.btn.btn-primary-light {
  background-color: rgba(var(--primary-rgb), 0.1);
  color: var(--primary-color);
}
.btn.btn-primary-light:hover,
.btn.btn-primary-light:focus,
.btn.btn-primary-light:active {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.btn.btn-primary1-light {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--primary-tint1-color);
}
.btn.btn-primary1-light:hover,
.btn.btn-primary1-light:focus,
.btn.btn-primary1-light:active {
  background-color: var(--primary-tint1-color);
  color: #fff;
  border-color: var(--primary-tint1-color);
}
.btn.btn-primary2-light {
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  color: var(--primary-tint2-color);
}
.btn.btn-primary2-light:hover,
.btn.btn-primary2-light:focus,
.btn.btn-primary2-light:active {
  background-color: var(--primary-tint2-color);
  color: #fff;
  border-color: var(--primary-tint2-color);
}
.btn.btn-primary3-light {
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
  color: var(--primary-tint3-color);
}
.btn.btn-primary3-light:hover,
.btn.btn-primary3-light:focus,
.btn.btn-primary3-light:active {
  background-color: var(--primary-tint3-color);
  color: #fff;
  border-color: var(--primary-tint3-color);
}
.btn.btn-secondary-light {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}
.btn.btn-secondary-light:hover,
.btn.btn-secondary-light:focus,
.btn.btn-secondary-light:active {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}
.btn.btn-warning-light {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}
.btn.btn-warning-light:hover,
.btn.btn-warning-light:focus,
.btn.btn-warning-light:active {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}
.btn.btn-info-light {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}
.btn.btn-info-light:hover,
.btn.btn-info-light:focus,
.btn.btn-info-light:active {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}
.btn.btn-success-light {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}
.btn.btn-success-light:hover,
.btn.btn-success-light:focus,
.btn.btn-success-light:active {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}
.btn.btn-danger-light {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}
.btn.btn-danger-light:hover,
.btn.btn-danger-light:focus,
.btn.btn-danger-light:active {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}
.btn.btn-purple-light {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}
.btn.btn-purple-light:hover,
.btn.btn-purple-light:focus,
.btn.btn-purple-light:active {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
  border-color: rgb(var(--purple-rgb));
}
.btn.btn-teal-light {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}
.btn.btn-teal-light:hover,
.btn.btn-teal-light:focus,
.btn.btn-teal-light:active {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
  border-color: rgb(var(--teal-rgb));
}
.btn.btn-orange-light {
  background-color: rgba(var(--orange-rgb), 0.1);
  color: rgb(var(--orange-rgb));
}
.btn.btn-orange-light:hover,
.btn.btn-orange-light:focus,
.btn.btn-orange-light:active {
  background-color: rgb(var(--orange-rgb));
  color: #fff;
  border-color: rgb(var(--orange-rgb));
}
.btn.btn-primary-gradient {
  background:
          linear-gradient(
                  to right,
                  var(--primary-color),
                  rgb(var(--secondary-rgb)));
  color: #fff !important;
  border: 0;
}
.btn.btn-primary-gradient:hover {
  border: 0;
}
.btn.btn-primary1-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint1-color) 0%,
                  #AB54E3 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-primary1-gradient:hover {
  border: 0;
}
.btn.btn-primary2-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint2-color) 0%,
                  #FF6C5D 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-primary2-gradient:hover {
  border: 0;
}
.btn.btn-primary3-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint3-color) 0%,
                  #FF663C 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-primary3-gradient:hover {
  border: 0;
}
.btn.btn-secondary-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--secondary-rgb)) 0%,
                  #7289FF 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-secondary-gradient:hover {
  border: 0;
}
.btn.btn-warning-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--warning-rgb)) 0%,
                  #9EA53C 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-warning-gradient:hover {
  border: 0;
}
.btn.btn-info-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--info-rgb)) 0%,
                  #4990E1 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-info-gradient:hover {
  border: 0;
}
.btn.btn-success-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--success-rgb)) 0%,
                  #009CA4 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-success-gradient:hover {
  border: 0;
}
.btn.btn-danger-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--danger-rgb)) 0%,
                  #DE4980 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-danger-gradient:hover {
  border: 0;
}
.btn.btn-orange-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--orange-rgb)) 0%,
                  #E5647E 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-orange-gradient:hover {
  border: 0;
}
.btn.btn-teal-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--teal-rgb)) 0%,
                  #3AE3C7 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-teal-gradient:hover {
  border: 0;
}
.btn.btn-purple-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--purple-rgb)) 0%,
                  #0086FF 100%);
  color: #fff !important;
  border: 0;
}
.btn.btn-purple-gradient:hover {
  border: 0;
}
.btn.btn-primary-ghost {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.btn.btn-primary-ghost:active {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.btn.btn-primary1-ghost {
  background-color: transparent;
  color: var(--primary-tint1-color);
  border: 1px solid var(--primary-tint1-color);
}
.btn.btn-primary1-ghost:active {
  color: var(--primary-tint1-color);
  border: 1px solid var(--primary-tint1-color);
}
.btn.btn-primary2-ghost {
  background-color: transparent;
  color: var(--primary-tint2-color);
  border: 1px solid var(--primary-tint2-color);
}
.btn.btn-primary2-ghost:active {
  color: var(--primary-tint2-color);
  border: 1px solid var(--primary-tint2-color);
}
.btn.btn-primary3-ghost {
  background-color: transparent;
  color: var(--primary-tint3-color);
  border: 1px solid var(--primary-tint3-color);
}
.btn.btn-primary3-ghost:active {
  color: var(--primary-tint3-color);
  border: 1px solid var(--primary-tint3-color);
}
.btn.btn-secondary-ghost {
  background-color: transparent;
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}
.btn.btn-secondary-ghost:active {
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}
.btn.btn-warning-ghost {
  background-color: transparent;
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}
.btn.btn-warning-ghost:active {
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}
.btn.btn-info-ghost {
  background-color: transparent;
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}
.btn.btn-info-ghost:active {
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}
.btn.btn-success-ghost {
  background-color: transparent;
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}
.btn.btn-success-ghost:active {
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}
.btn.btn-danger-ghost {
  background-color: transparent;
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}
.btn.btn-danger-ghost:active {
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}
.btn.btn-orange-ghost {
  background-color: transparent;
  color: rgb(var(--orange-rgb));
  border: 1px solid rgb(var(--orange-rgb));
}
.btn.btn-orange-ghost:active {
  color: rgb(var(--orange-rgb));
  border: 1px solid rgb(var(--orange-rgb));
}
.btn.btn-teal-ghost {
  background-color: transparent;
  color: rgb(var(--teal-rgb));
  border: 1px solid rgb(var(--teal-rgb));
}
.btn.btn-teal-ghost:active {
  color: rgb(var(--teal-rgb));
  border: 1px solid rgb(var(--teal-rgb));
}
.btn.btn-purple-ghost {
  background-color: transparent;
  color: rgb(var(--purple-rgb));
  border: 1px solid rgb(var(--purple-rgb));
}
.btn.btn-purple-ghost:active {
  color: rgb(var(--purple-rgb));
  border: 1px solid rgb(var(--purple-rgb));
}
.btn.btn-w-xs {
  min-width: 5.625rem;
}
.btn.btn-w-sm {
  min-width: 6.975rem;
}
.btn.btn-w-md {
  min-width: 8.125rem;
}
.btn.btn-w-lg {
  min-width: 9.375rem;
}
.btn-loader {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
}
.btn-loader .loading {
  margin-inline-end: 0.75rem;
}
.btn-loader i {
  animation: loader 1000ms infinite linear;
  position: absolute !important;
  top: 0.25rem;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-raised-shadow:hover {
  box-shadow: 0 0.5rem 1rem 0.5rem var(--black-1);
}
.label-btn {
  position: relative;
  padding-inline-start: 2.6rem;
}
.label-btn-icon {
  position: absolute;
  width: 2.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-inline-start: -1px;
  inset-block-start: -1px;
  inset-block-end: -1px;
  background-color: var(--white-2);
}
.label-btn.label-end {
  padding-inline-start: 1rem;
  padding-inline-end: 2.6rem;
}
.label-btn.label-end .label-btn-icon {
  inset-inline-end: -1px;
  inset-inline-start: auto;
}
.custom-button {
  position: relative;
  padding-inline-start: 2.75rem;
}
.custom-button .custom-btn-icons {
  box-shadow: 0px 0px 1px 0.25rem rgba(0, 0, 0, 0.1);
  position: absolute;
  inset-inline-start: -0.125rem;
  inset-block-start: 0px;
  background-color: var(--custom-white);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.375rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  width: 2.25rem;
  height: 2.25rem;
}
.custom-button .custom-btn-icons i {
  position: absolute;
}
.btn-hover {
  position: relative;
}
.btn-hover.btn-hover-animate {
  transition: all 0.2s linear 0s;
}
.btn-hover.btn-hover-animate:before {
  content: "\f417";
  font-family: bootstrap-icons !important;
  font-size: 0.8125rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-inline-end: 0;
  inset-block-start: 0;
  opacity: 0;
  height: 100%;
  width: 2rem;
  transition: all 0.2s linear 0s;
}
.btn-hover.btn-hover-animate:hover {
  padding-inline-end: 2rem;
}
.btn-hover.btn-hover-animate:hover:before {
  opacity: 1;
  text-indent: 0;
}
.btn-glare {
  border: 1px solid;
  overflow: hidden;
  position: relative;
}
.btn-glare span {
  z-index: 20;
}
.btn-glare:after {
  background: var(--custom-white);
  content: "";
  height: 4.6875rem;
  inset-inline-start: -1.625rem;
  opacity: 0.15;
  position: absolute;
  inset-block-start: -3.125rem;
  transform: rotate(35deg);
  transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
  width: 1.875rem;
  z-index: 30;
}
.btn-glare:hover:after {
  inset-inline-start: 100%;
  inset-block-start: 0;
  transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
}
.btn-darken-hover {
  position: relative;
}
.btn-darken-hover:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  inset-block-start: 0;
  inset-inline-start: 0;
}
.btn-custom-border {
  border: 0;
  box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0);
  outline: 1px solid;
  outline-color: rgba(var(--warning-rgb), 0.5);
  outline-offset: 0;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.btn-custom-border:hover {
  border: 0 solid;
  box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0.5), 0 0 1.25rem rgba(var(--warning-rgb), 0.2);
  outline-color: rgba(var(--warning-rgb), 0);
  outline-offset: 0.938rem;
}
.btn-border-down.btn-primary-light {
  border-block-end: 0.1875rem solid var(--primary-color);
}
.btn-border-down.btn-secondary-light {
  border-block-end: 0.1875rem solid rgb(var(--secondary-rgb));
}
.btn-border-down.btn-info-light {
  border-block-end: 0.1875rem solid rgb(var(--info-rgb));
}
.btn-border-down.btn-warning-light {
  border-block-end: 0.1875rem solid rgb(var(--warning-rgb));
}
.btn-border-down.btn-success-light {
  border-block-end: 0.1875rem solid rgb(var(--success-rgb));
}
.btn-border-down.btn-danger-light {
  border-block-end: 0.1875rem solid rgb(var(--danger-rgb));
}
.btn-border-down.btn-dark-light {
  border-block-end: 0.1875rem solid var(--primary-color);
}
.btn-border-down.btn-teal-light {
  border-block-end: 0.1875rem solid rgb(var(--teal-rgb));
}
.btn-border-down.btn-purple-light {
  border-block-end: 0.1875rem solid rgb(var(--purple-rgb));
}
.btn-border-start.btn-primary-light {
  border-inline-start: 0.1875rem solid var(--primary-color);
}
.btn-border-start.btn-secondary-light {
  border-inline-start: 0.1875rem solid rgb(var(--secondary-rgb));
}
.btn-border-start.btn-info-light {
  border-inline-start: 0.1875rem solid rgb(var(--info-rgb));
}
.btn-border-start.btn-warning-light {
  border-inline-start: 0.1875rem solid rgb(var(--warning-rgb));
}
.btn-border-start.btn-success-light {
  border-inline-start: 0.1875rem solid rgb(var(--success-rgb));
}
.btn-border-start.btn-danger-light {
  border-inline-start: 0.1875rem solid rgb(var(--danger-rgb));
}
.btn-border-start.btn-dark-light {
  border-inline-start: 0.1875rem solid var(--primary-color);
}
.btn-border-start.btn-teal-light {
  border-inline-start: 0.1875rem solid rgb(var(--teal-rgb));
}
.btn-border-start.btn-purple-light {
  border-inline-start: 0.1875rem solid rgb(var(--purple-rgb));
}
.btn-border-end.btn-primary-light {
  border-inline-end: 0.1875rem solid var(--primary-color);
}
.btn-border-end.btn-secondary-light {
  border-inline-end: 0.1875rem solid rgb(var(--secondary-rgb));
}
.btn-border-end.btn-info-light {
  border-inline-end: 0.1875rem solid rgb(var(--info-rgb));
}
.btn-border-end.btn-warning-light {
  border-inline-end: 0.1875rem solid rgb(var(--warning-rgb));
}
.btn-border-end.btn-success-light {
  border-inline-end: 0.1875rem solid rgb(var(--success-rgb));
}
.btn-border-end.btn-danger-light {
  border-inline-end: 0.1875rem solid rgb(var(--danger-rgb));
}
.btn-border-end.btn-dark-light {
  border-inline-end: 0.1875rem solid var(--primary-color);
}
.btn-border-end.btn-teal-light {
  border-inline-end: 0.1875rem solid rgb(var(--teal-rgb));
}
.btn-border-end.btn-purple-light {
  border-inline-end: 0.1875rem solid rgb(var(--purple-rgb));
}
.btn-border-top.btn-primary-light {
  border-block-start: 0.1875rem solid var(--primary-color);
}
.btn-border-top.btn-secondary-light {
  border-block-start: 0.1875rem solid rgb(var(--secondary-rgb));
}
.btn-border-top.btn-info-light {
  border-block-start: 0.1875rem solid rgb(var(--info-rgb));
}
.btn-border-top.btn-warning-light {
  border-block-start: 0.1875rem solid rgb(var(--warning-rgb));
}
.btn-border-top.btn-success-light {
  border-block-start: 0.1875rem solid rgb(var(--success-rgb));
}
.btn-border-top.btn-danger-light {
  border-block-start: 0.1875rem solid rgb(var(--danger-rgb));
}
.btn-border-top.btn-dark-light {
  border-block-start: 0.1875rem solid var(--primary-color);
}
.btn-border-top.btn-teal-light {
  border-block-start: 0.1875rem solid rgb(var(--teal-rgb));
}
.btn-border-top.btn-purple-light {
  border-block-start: 0.1875rem solid rgb(var(--purple-rgb));
}
.btn-facebook {
  background-color: #3b5998;
  color: #fff;
  border: 1px solid #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
  background-color: #3b5998 !important;
  color: #fff !important;
  border: 1px solid #3b5998 !important;
}
.btn-google {
  background-color: #CF4E43;
  color: #fff;
  border: 1px solid #CF4E43;
}
.btn-google:hover,
.btn-google:focus,
.btn-google:active {
  background-color: #CF4E43 !important;
  color: #fff !important;
  border: 1px solid #CF4E43 !important;
}
.btn-twitter {
  background-color: #00acee;
  color: #fff;
  border: 1px solid #00acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
  background-color: #00acee !important;
  color: #fff !important;
  border: 1px solid #00acee !important;
}
.btn-github {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}
.btn-github:hover,
.btn-github:focus,
.btn-github:active {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #333 !important;
}
.btn-youtube {
  background-color: #FF0000;
  color: #fff;
  border: 1px solid #FF0000;
}
.btn-youtube:hover,
.btn-youtube:focus,
.btn-youtube:active {
  background-color: #FF0000 !important;
  color: #fff !important;
  border: 1px solid #FF0000 !important;
}
.btn-instagram {
  background: #f09433;
  background:
          linear-gradient(
                  45deg,
                  #f09433 0%,
                  #e6683c 25%,
                  #dc2743 50%,
                  #cc2366 75%,
                  #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
  color: #fff;
  border: 1px solid transparent;
}
.btn-instagram:hover,
.btn-instagram:focus,
.btn-instagram:active {
  color: #fff;
  border: 1px solid transparent;
}
@media (max-width: 575.9808px) {
  .checkbox-button-group .btn-group label,
  .radio-button-group .btn-group label {
    font-size: 0.563rem;
  }
}
.btn-outline-secondary {
  --bs-btn-hover-color: rgb(var(--secondary-rgb));
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: rgb(var(--secondary-rgb));
  --bs-btn-active-color: rgb(var(--secondary-rgb));
}
.btn-check:checked + .btn.btn-outline-light,
.btn.btn-outline-light.active,
.btn.btn-outline-light.show,
.btn.btn-outline-light:first-child:active,
:not(.btn-check) + .btn.btn-outline-light:active {
  background-color: rgb(var(--light-rgb));
}
.btn-white-transparent {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.btn-white-transparent:hover,
.btn-white-transparent:active,
.btn-white-transparent:focus {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
  border: 1px solid var(--default-border) !important;
}
.card {
  margin-block-end: 1.5rem;
}
.card .card-header,
.card .card-footer {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color);
}
[data-theme-mode=dark] .card.custom-card {
  box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.05);
}
.card.custom-card {
  border-radius: 0.5rem;
  background-color: var(--custom-white);
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
  border: 0;
  position: relative;
  margin-block-end: 1.5rem;
  width: 100%;
}
.card.custom-card .card-header {
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: transparent !important;
  border-block-end: 0 solid var(--default-border);
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.card.custom-card .card-header:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}
.card.custom-card .card-header .card-title {
  position: relative;
  margin-block-end: 0;
  font-size: 0.95rem;
  font-weight: 500;
}
.card.custom-card .card-header .card-title .subtitle {
  margin-block-end: 0;
  text-transform: initial;
}
.card.custom-card .card-footer {
  background-color: transparent !important;
  border-block-start: 1px solid var(--default-border);
  padding: 1rem 1rem;
  font-size: 0.8125rem;
}
.card.custom-card .card-body {
  padding: 1rem;
  color: var(--default-text-color);
}
.card.custom-card .card-link {
  font-size: 0.75rem;
}
.card.custom-card .card-text {
  font-size: 0.813rem;
}
.card.custom-card .footer-card-icon {
  width: 60px;
  height: 60px;
}
.card.custom-card.overlay-card {
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.9);
}
.card.custom-card.overlay-card .card-header {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.overlay-card .card-footer {
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.overlay-card .over-content-bottom {
  top: auto;
}
.card.custom-card.overlay-card:before {
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 0.3rem;
}
.card.custom-card.card-bg-primary {
  background-color: var(--primary-color);
  color: #fff;
}
.card.custom-card.card-bg-primary .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-primary .card-body {
  color: #fff;
}
.card.custom-card.card-bg-primary .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary1 {
  background-color: var(--primary-tint1-color);
  color: #fff;
}
.card.custom-card.card-bg-primary1 .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary1 .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-primary1 .card-body {
  color: #fff;
}
.card.custom-card.card-bg-primary1 .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary2 {
  background-color: var(--primary-tint2-color);
  color: #fff;
}
.card.custom-card.card-bg-primary2 .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary2 .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-primary2 .card-body {
  color: #fff;
}
.card.custom-card.card-bg-primary2 .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary3 {
  background-color: var(--primary-tint3-color);
  color: #fff;
}
.card.custom-card.card-bg-primary3 .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-primary3 .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-primary3 .card-body {
  color: #fff;
}
.card.custom-card.card-bg-primary3 .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.card.custom-card.card-bg-secondary .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-secondary .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-secondary .card-body {
  color: #fff;
}
.card.custom-card.card-bg-secondary .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.card.custom-card.card-bg-warning .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-warning .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-warning .card-body {
  color: #fff;
}
.card.custom-card.card-bg-warning .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.card.custom-card.card-bg-info .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-info .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-info .card-body {
  color: #fff;
}
.card.custom-card.card-bg-info .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.card.custom-card.card-bg-success .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-success .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-success .card-body {
  color: #fff;
}
.card.custom-card.card-bg-success .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.card.custom-card.card-bg-danger .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-danger .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-danger .card-body {
  color: #fff;
}
.card.custom-card.card-bg-danger .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-orange {
  background-color: rgb(var(--orange-rgb));
  color: #fff;
}
.card.custom-card.card-bg-orange .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-orange .card-header:before {
  background-color: #fff;
}
.card.custom-card.card-bg-orange .card-body {
  color: #fff;
}
.card.custom-card.card-bg-orange .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}
.card.custom-card.card-bg-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.card.custom-card.card-bg-light .card-header {
  color: var(--default-text-color);
  border-block-end: var(--default-border);
}
.card.custom-card.card-bg-light .card-body {
  color: var(--default-text-color);
}
.card.custom-card.card-bg-light .card-footer {
  color: var(--default-text-color);
  border-block-start: var(--default-border);
}
.card.custom-card.card-bg-dark {
  background-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}
.card.custom-card.card-bg-dark .card-header {
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}
.card.custom-card.card-bg-dark .card-header:before {
  background-color: var(--custom-white);
}
.card.custom-card.card-bg-dark .card-body {
  color: #fff;
}
.card.custom-card.card-bg-dark .card-footer {
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}
.card.custom-card .card-anchor {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
}
.card.custom-card .collapse-close {
  display: none;
}
.card.custom-card.card .collapsed .collapse-close {
  display: block;
}
.card.custom-card.card .collapsed .collapse-open {
  display: none;
}
.card.custom-card.card-fullscreen {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-start: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}
@media (max-width: 575px) {
  .overlay-card .card-text {
    font-size: 0.613rem !important;
  }
}
.card-img,
.card-img-top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app-header .dropdown-toggle {
  position: relative;
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
.dropdown-menu {
  border-color: var(--default-border);
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  color: var(--default-text-color);
  background-color: var(--custom-white);
  padding: 0;
  z-index: 10;
  border-radius: 0.3rem;
  overflow: hidden;
  animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: slideIn;
}
.dropdown-divider {
  border-color: var(--default-border);
}
.dropdown-item-text {
  color: var(--default-text-color);
}
.dropdown-header {
  color: var(--default-text-color);
}
.dropdown-item {
  color: var(--default-text-color);
  white-space: unset;
  padding: 0.5rem 0.9375rem;
  font-size: 0.85rem;
  font-weight: 400;
}
.dropdown-item:not(.active):hover,
.dropdown-item:not(.active):focus,
.dropdown-item:not(.active):active {
  color: var(--primary-color);
  background-color: var(--primary005);
}
.dropdown-item.active {
  background-color: var(--primary005) !important;
  color: var(--primary-color) !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f282";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 400;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f286";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 400;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f285";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 400;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f284";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 400;
}
.btn.dropdown-toggle-split {
  opacity: 0.85;
}
.dropdown-menu-dark {
  background-color: rgb(var(--dark-rgb));
  border-color: rgba(255, 255, 255, 0.1);
}
.dropdown-menu-dark li a {
  color: var(--custom-white);
}
.btn-list a.dropdown-item {
  margin-block-end: 0;
}
.dropdown-menu.dropdown-menu-primary {
  background-color: var(--primary-color);
}
.dropdown-menu.dropdown-menu-primary li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-primary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-primary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-primary .dropdown-item:active,
.dropdown-menu.dropdown-menu-primary .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropdown-menu-secondary {
  background-color: rgb(var(--secondary-rgb));
}
.dropdown-menu.dropdown-menu-secondary li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-secondary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:active,
.dropdown-menu.dropdown-menu-secondary .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropdown-menu-warning {
  background-color: rgb(var(--warning-rgb));
}
.dropdown-menu.dropdown-menu-warning li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-warning .dropdown-item:hover,
.dropdown-menu.dropdown-menu-warning .dropdown-item:focus,
.dropdown-menu.dropdown-menu-warning .dropdown-item:active,
.dropdown-menu.dropdown-menu-warning .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropdown-menu-info {
  background-color: rgb(var(--info-rgb));
}
.dropdown-menu.dropdown-menu-info li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-info .dropdown-item:hover,
.dropdown-menu.dropdown-menu-info .dropdown-item:focus,
.dropdown-menu.dropdown-menu-info .dropdown-item:active,
.dropdown-menu.dropdown-menu-info .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropdown-menu-success {
  background-color: rgb(var(--success-rgb));
}
.dropdown-menu.dropdown-menu-success li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-success .dropdown-item:hover,
.dropdown-menu.dropdown-menu-success .dropdown-item:focus,
.dropdown-menu.dropdown-menu-success .dropdown-item:active,
.dropdown-menu.dropdown-menu-success .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropdown-menu-danger {
  background-color: rgb(var(--danger-rgb));
}
.dropdown-menu.dropdown-menu-danger li a {
  color: rgba(255, 255, 255, 0.9);
}
.dropdown-menu.dropdown-menu-danger .dropdown-item:hover,
.dropdown-menu.dropdown-menu-danger .dropdown-item:focus,
.dropdown-menu.dropdown-menu-danger .dropdown-item:active,
.dropdown-menu.dropdown-menu-danger .dropdown-item.active {
  color: #fff;
}
.dropdown-menu.dropmenu-item-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-primary .dropdown-item:active,
.dropdown-menu.dropmenu-item-primary .dropdown-item.active {
  background-color: var(--primary01) !important;
  color: var(--primary-color);
}
.dropdown-menu.dropmenu-item-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-item-secondary .dropdown-item.active {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb));
}
.dropdown-menu.dropmenu-item-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-item-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-item-warning .dropdown-item:active,
.dropdown-menu.dropmenu-item-warning .dropdown-item.active {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb));
}
.dropdown-menu.dropmenu-item-info .dropdown-item:hover,
.dropdown-menu.dropmenu-item-info .dropdown-item:focus,
.dropdown-menu.dropmenu-item-info .dropdown-item:active,
.dropdown-menu.dropmenu-item-info .dropdown-item.active {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb));
}
.dropdown-menu.dropmenu-item-success .dropdown-item:hover,
.dropdown-menu.dropmenu-item-success .dropdown-item:focus,
.dropdown-menu.dropmenu-item-success .dropdown-item:active,
.dropdown-menu.dropmenu-item-success .dropdown-item.active {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb));
}
.dropdown-menu.dropmenu-item-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-item-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-item-danger .dropdown-item:active,
.dropdown-menu.dropmenu-item-danger .dropdown-item.active {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb));
}
.dropdown-menu.dropmenu-light-primary {
  background-color: var(--primary01);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-primary .dropdown-item:active,
.dropdown-menu.dropmenu-light-primary .dropdown-item.active {
  color: #fff;
  background-color: var(--primary-color) !important;
}
.dropdown-menu.dropmenu-light-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-light-secondary .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--secondary-rgb)) !important;
}
.dropdown-menu.dropmenu-light-info {
  background-color: rgba(var(--info-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-info .dropdown-item:hover,
.dropdown-menu.dropmenu-light-info .dropdown-item:focus,
.dropdown-menu.dropmenu-light-info .dropdown-item:active,
.dropdown-menu.dropmenu-light-info .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--info-rgb)) !important;
}
.dropdown-menu.dropmenu-light-warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-light-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-light-warning .dropdown-item:active,
.dropdown-menu.dropmenu-light-warning .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--warning-rgb)) !important;
}
.dropdown-menu.dropmenu-light-success {
  background-color: rgba(var(--success-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-success .dropdown-item:hover,
.dropdown-menu.dropmenu-light-success .dropdown-item:focus,
.dropdown-menu.dropmenu-light-success .dropdown-item:active,
.dropdown-menu.dropmenu-light-success .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--success-rgb)) !important;
}
.dropdown-menu.dropmenu-light-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}
.dropdown-menu.dropmenu-light-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-light-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-light-danger .dropdown-item:active,
.dropdown-menu.dropmenu-light-danger .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--danger-rgb)) !important;
}
.dropdown-divider {
  margin: 0;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--default-text-color);
  opacity: 0.5;
}
.form-control {
  border-color: var(--input-border);
  color: var(--default-text-color);
  background-color: var(--form-control-bg);
  font-size: 0.8125rem;
  font-weight: var(--default-font-weight);
  line-height: 1.7;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.form-control:focus {
  border-color: var(--primary05);
  background-color: var(--custom-white);
  box-shadow: 0 0 4px var(--primary05);
  color: var(--default-text-color);
}
.form-control.border-dotted {
  border-style: dotted;
}
.form-control.border-dashed {
  border-style: dashed;
}
.form-label {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--default-text-color);
}
.form-text {
  color: var(--text-muted);
}
.form-control-light {
  background-color: rgb(var(--light-rgb));
  border: 0;
}
.form-control-light:focus {
  background-color: rgb(var(--light-rgb));
}
.form-control-primary {
  background-color: var(--primary01);
  border: 0;
  color: var(--primary-color);
}
.form-control-primary:focus {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.form-input-color {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.3rem;
  overflow: hidden;
  padding: 0;
}
/*.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: var(--text-muted);
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: var(--text-muted);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  background-color: transparent;
}*/
.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: transparent;
}
.form-select {
  background-color: var(--form-control-bg);
  border: 1px solid var(--input-border);
  color: var(--default-text-color);
  font-size: 0.8125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.25rem;
}
.form-select option {
  background-color: transparent;
  padding: 0.35rem 0.75rem;
  border-radius: 0.25rem;
}
.form-select option:checked {
  background-color: var(--primary02);
  color: var(--primary-color);
}
.form-check-input {
  width: 1.1em;
  height: 1.1em;
  background-color: var(--custom-white);
  border: 1px solid var(--input-border);
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input.form-checked-outline:checked {
  background-color: transparent;
  border-color: var(--primary-color);
}
.form-check-input.form-checked-gray:checked {
  background-color: var(--gray-3);
  border-color: var(--gray-3);
}
.form-check-input.form-checked-secondary:checked {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.form-check-input.form-checked-warning:checked {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}
.form-check-input.form-checked-info:checked {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}
.form-check-input.form-checked-success:checked {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}
.form-check-input.form-checked-danger:checked {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}
.form-check-input.form-checked-light:checked {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
}
.form-check-input.form-checked-dark:checked {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}
.form-check-input:focus {
  box-shadow: none;
}
.form-checked-outline:checked[type=checkbox] {
  background-image: none;
  position: relative;
  background-color: transparent;
}
.form-checked-outline:checked[type=checkbox]:before {
  content: "\f633";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: var(--primary-color);
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1px;
  inset-inline-start: 0px;
  font-size: 0.688rem;
}
.form-checked-outline:checked[type=checkbox].form-checked-secondary:before {
  color: rgb(var(--secondary-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-warning:before {
  color: rgb(var(--warning-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-info:before {
  color: rgb(var(--info-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-success:before {
  color: rgb(var(--success-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-danger:before {
  color: rgb(var(--danger-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-light:before {
  color: rgb(var(--light-rgb));
}
.form-checked-outline:checked[type=checkbox].form-checked-dark:before {
  color: rgb(var(--dark-rgb));
}
.form-checked-outline:checked[type=radio] {
  background-image: none;
  position: relative;
  background-color: transparent;
}
.form-checked-outline:checked[type=radio]:before {
  content: "\f309";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: var(--primary-color);
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1.08rem;
  inset-inline-start: -0.59rem;
  font-size: 2rem;
}
.form-checked-outline:checked[type=radio].form-checked-secondary:before {
  color: rgb(var(--secondary-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-warning:before {
  color: rgb(var(--warning-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-info:before {
  color: rgb(var(--info-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-success:before {
  color: rgb(var(--success-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-danger:before {
  color: rgb(var(--danger-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-light:before {
  color: rgb(var(--light-rgb));
}
.form-checked-outline:checked[type=radio].form-checked-dark:before {
  color: rgb(var(--dark-rgb));
}
fieldset:disabled .btn {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-select:focus {
  border-color: var(--primary05);
  outline: 0;
  box-shadow: none;
}
.btn-check:focus + .btn,
.btn:focus {
  background-color: unset;
  border-color: unset;
  outline: 0;
}
.btn-check:active + .btn:focus,
.btn-check:checked + .btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: none;
}
.form-control:disabled,
.form-select:disabled {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.form-control-plaintext {
  color: var(--default-text-color);
}
.form-control::-webkit-file-upload-button {
  color: var(--default-text-color);
  background-color: var(--default-background);
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--default-background);
}
.form-control::-webkit-input-placeholder {
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color);
  padding-inline-start: 0px;
  -webkit-transition: padding-inline-start 0.1s ease-in;
  transition: padding-inline-start 0.1s ease-in;
}
.form-control:-moz-placeholder {
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color);
}
.form-control::-moz-placeholder {
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color);
}
.form-control:-ms-input-placeholder {
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color);
}
.form-control-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  line-height: inherit;
  border-radius: 0.25rem;
}
.form-control-lg {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}
.form-check-md .form-check-input {
  width: 1.15rem;
  height: 1.15rem;
}
.form-check-md.form-switch .form-check-input {
  width: 2.25rem;
}
.form-check-md label {
  margin-inline-start: 0.5rem;
  font-size: 0.95rem;
  margin-block-start: 2px;
}
.form-check-lg .form-check-input {
  width: 1.35rem;
  height: 1.35rem;
}
.form-check-lg.form-switch .form-check-input {
  width: 2.5rem;
}
.form-check-lg label {
  margin-inline-start: 0.5rem;
  font-size: 1rem;
  margin-block-start: 3px;
}
.toggle {
  width: 3.75rem;
  height: 1.563rem;
  background-color: rgb(var(--light-rgb));
  margin-inline-start: 0.625rem;
  margin-block-end: 0.313rem;
  padding: 0.125rem;
  border-radius: 0.188rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}
.toggle span {
  position: absolute;
  inset-block-start: 0.188rem;
  inset-block-end: 0.25rem;
  inset-inline-start: 0.2rem;
  display: block;
  width: 1.25rem;
  border-radius: 0.125rem;
  background-color: var(--custom-white);
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s ease;
}
.toggle span::before {
  content: "on";
  inset-inline-start: -1.563rem;
}
.toggle span::after {
  content: "off";
  inset-inline-end: -1.813rem;
  color: var(--text-muted);
}
.toggle span::before,
.toggle span::after {
  position: absolute;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  inset-block-start: 0.188rem;
  line-height: 1.38;
  transition: all 0.2s ease;
}
.toggle.on {
  background-color: var(--primary03);
}
.toggle.on span {
  background-color: var(--primary-color);
}
.toggle.on span::before {
  color: var(--primary-color);
}
.toggle.on.toggle-primary1 {
  background-color: rgba(var(--primary-tint1-rgb), 0.3);
}
.toggle.on.toggle-primary1 span {
  background-color: var(--primary-tint1-color);
}
.toggle.on.toggle-primary1 span::before {
  color: var(--primary-tint1-color);
}
.toggle.on.toggle-primary2 {
  background-color: rgba(var(--primary-tint2-rgb), 0.3);
}
.toggle.on.toggle-primary2 span {
  background-color: var(--primary-tint2-color);
}
.toggle.on.toggle-primary2 span::before {
  color: var(--primary-tint2-color);
}
.toggle.on.toggle-primary3 {
  background-color: rgba(var(--primary-tint3-rgb), 0.3);
}
.toggle.on.toggle-primary3 span {
  background-color: var(--primary-tint3-color);
}
.toggle.on.toggle-primary3 span::before {
  color: var(--primary-tint3-color);
}
.toggle.on.toggle-secondary {
  background-color: rgba(var(--secondary-rgb), 0.3);
}
.toggle.on.toggle-secondary span {
  background-color: rgb(var(--secondary-rgb));
}
.toggle.on.toggle-secondary span::before {
  color: rgb(var(--secondary-rgb));
}
.toggle.on.toggle-warning {
  background-color: rgba(var(--warning-rgb), 0.3);
}
.toggle.on.toggle-warning span {
  background-color: rgb(var(--warning-rgb));
}
.toggle.on.toggle-warning span::before {
  color: rgb(var(--warning-rgb));
}
.toggle.on.toggle-info {
  background-color: rgba(var(--info-rgb), 0.3);
}
.toggle.on.toggle-info span {
  background-color: rgb(var(--info-rgb));
}
.toggle.on.toggle-info span::before {
  color: rgb(var(--info-rgb));
}
.toggle.on.toggle-success {
  background-color: rgba(var(--success-rgb), 0.3);
}
.toggle.on.toggle-success span {
  background-color: rgb(var(--success-rgb));
}
.toggle.on.toggle-success span::before {
  color: rgb(var(--success-rgb));
}
.toggle.on.toggle-danger {
  background-color: rgba(var(--danger-rgb), 0.3);
}
.toggle.on.toggle-danger span {
  background-color: rgb(var(--danger-rgb));
}
.toggle.on.toggle-danger span::before {
  color: rgb(var(--danger-rgb));
}
.toggle.on.toggle-light {
  background-color: rgba(var(--light-rgb), 0.3);
}
.toggle.on.toggle-light span {
  background-color: rgb(var(--light-rgb));
}
.toggle.on.toggle-light span::before,
.toggle.on.toggle-light span::after {
  color: var(--text-muted);
}
.toggle.on.toggle-dark {
  background-color: rgba(var(--dark-rgb), 0.3);
}
.toggle.on.toggle-dark span {
  background-color: rgb(var(--dark-rgb));
}
.toggle.on.toggle-dark span::before {
  color: var(--custom-white);
}
.toggle.on span {
  inset-inline-start: 2.313rem;
}
.toggle.on.toggle-sm span {
  inset-inline-start: 2.313rem;
}
.toggle.on.toggle-sm span::before {
  inset-block-start: -1px;
  inset-inline-start: -1.563rem;
}
.toggle.on.toggle-lg span {
  inset-inline-start: 2.563rem;
}
.toggle.on.toggle-lg span::before {
  inset-block-start: 0.5rem;
  inset-inline-start: -1.75rem;
}
.toggle.toggle-sm {
  height: 1.063rem;
  width: 3.125rem;
}
.toggle.toggle-sm span {
  width: 0.625rem;
  height: 0.625rem;
}
.toggle.toggle-sm span::after {
  inset-inline-end: -1.875rem;
  inset-block-start: -1px;
}
.toggle.toggle-lg {
  height: 2.125rem;
  width: 4.5rem;
}
.toggle.toggle-lg span {
  width: 1.75rem;
}
.toggle.toggle-lg span::after {
  inset-block-start: 0.5rem;
  inset-inline-end: -1.938rem;
}
.custom-toggle-switch > input[type=checkbox] {
  display: none;
}
.custom-toggle-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 2.5rem;
}
.label-primary {
  background: var(--primary-color);
  color: #fff;
}
.label-secondary {
  background: rgb(var(--secondary-rgb));
  color: #fff;
}
.label-warning {
  background: rgb(var(--warning-rgb));
  color: #fff;
}
.label-info {
  background: rgb(var(--info-rgb));
  color: #fff;
}
.label-success {
  background: rgb(var(--success-rgb));
  color: #fff;
}
.label-danger {
  background: rgb(var(--danger-rgb));
  color: #fff;
}
.label-light {
  background: rgb(var(--light-rgb));
  color: #fff;
}
.label-dark {
  background: rgb(var(--dark-rgb));
  color: #fff;
}
.custom-toggle-switch > input[type=checkbox]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.custom-toggle-switch > label::before {
  background: var(--text-muted);
  box-shadow: inset 0px 0px 0.625rem var(--white-5);
  border-radius: 0.5rem;
  content: "";
  height: 1rem;
  margin-block-start: -0.5rem;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 2.5rem;
}
.custom-toggle-switch > label::after {
  background: var(--custom-white);
  border-radius: 1rem;
  box-shadow: 0 0 0.313rem rgba(228, 229, 237, 0.8);
  content: "";
  height: 1.5rem;
  inset-inline-start: -0.25rem;
  margin-block-start: -0.5rem;
  position: absolute;
  inset-block-start: -0.25rem;
  transition: all 0.3s ease-in-out;
  width: 1.5rem;
}
.custom-toggle-switch > input[type=checkbox]:checked + label::after {
  background: inherit;
  inset-inline-start: 50%;
}
.custom-toggle-switch.toggle-sm > label::before {
  height: 10px;
  width: 27px;
  border-radius: 10px;
}
.custom-toggle-switch.toggle-sm input[type=checkbox]:checked + label::after {
  inset-inline-start: 13px;
}
.custom-toggle-switch.toggle-sm > label::after {
  height: 17px;
  width: 17px;
  border-radius: 50%;
}
.custom-toggle-switch.toggle-lg > label::before {
  height: 27px;
  width: 55px;
  border-radius: 20px;
}
.custom-toggle-switch.toggle-lg input[type=checkbox]:checked + label::after {
  inset-inline-start: 77%;
}
.custom-toggle-switch.toggle-lg > label::after {
  height: 35px;
  width: 35px;
  margin-block-start: -8px;
  border-radius: 50%;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem - 4px);
}
.form-floating.floating-primary label {
  color: var(--primary-color);
  opacity: 1;
}
.form-floating.floating-primary input {
  border: 1px solid var(--primary-color);
}
.form-floating.floating-primary > .form-control:focus ~ label {
  color: var(--primary-color) !important;
}
.form-floating.floating-secondary label {
  color: rgb(var(--secondary-rgb));
  opacity: 1;
}
.form-floating.floating-secondary input {
  border: 1px solid rgb(var(--secondary-rgb));
}
.form-floating.floating-secondary > .form-control:focus ~ label {
  color: rgb(var(--secondary-rgb)) !important;
}
.form-floating.floating-warning label {
  color: rgb(var(--warning-rgb));
  opacity: 1;
}
.form-floating.floating-warning input {
  border: 1px solid rgb(var(--warning-rgb));
}
.form-floating.floating-warning > .form-control:focus ~ label {
  color: rgb(var(--warning-rgb)) !important;
}
.form-floating.floating-info label {
  color: rgb(var(--info-rgb));
  opacity: 1;
}
.form-floating.floating-info input {
  border: 1px solid rgb(var(--info-rgb));
}
.form-floating.floating-info > .form-control:focus ~ label {
  color: rgb(var(--info-rgb)) !important;
}
.form-floating.floating-success label {
  color: rgb(var(--success-rgb));
  opacity: 1;
}
.form-floating.floating-success input {
  border: 1px solid rgb(var(--success-rgb));
}
.form-floating.floating-success > .form-control:focus ~ label {
  color: rgb(var(--success-rgb)) !important;
}
.form-floating.floating-danger label {
  color: rgb(var(--danger-rgb));
  opacity: 1;
}
.form-floating.floating-danger input {
  border: 1px solid rgb(var(--danger-rgb));
}
.form-floating.floating-danger > .form-control:focus ~ label {
  color: rgb(var(--danger-rgb)) !important;
}
/*.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  background-color: var(--custom-white);
  border-radius: 0.3rem;
}*/
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: var(--custom-white);
  border-radius: 0.3rem;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: rgb(var(--success-rgb));
  box-shadow: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: rgb(var(--success-rgb));
}
.form-control-color {
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  padding: 0;
}
.example-picker .pcr-button {
  border-radius: 0.35rem !important;
  overflow: hidden;
}
[dir=rtl] [type=email],
[dir=rtl] [type=number],
[dir=rtl] [type=tel],
[dir=rtl] [type=url] {
  direction: rtl;
}
.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 1.125rem;
  background-size: 16px 28px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.invoice-quantity-container .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.input-group.input-btn-outline .form-control:focus {
  z-index: 2;
}
input[type=range]::-webkit-slider-runnable-track {
  background-color: rgb(var(--light-rgb));
}
[dir=rtl] .form-checked-outline:checked[type=checkbox]:before {
  inset-inline-start: 1px;
}
.form-check {
  min-height: inherit !important;
}
.col-form-label-lg,
.col-form-label-sm,
.col-form-label {
  font-weight: 400;
}
#exampleColorInput::-webkit-color-swatch-wrapper {
  direction: rtl;
}
[dir=rtl] input[type=week],
[dir=rtl] input[type=month],
[dir=rtl] input[type=date],
[dir=rtl] input[type=datetime-local],
[dir=rtl] input[type=time] {
  text-align: end;
}
.wizard-tab {
  transition: all 0.3s ease-in;
  display: none;
}
.wizard-tab .wizard-nav {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in;
  margin-block-start: 60px;
  padding-inline: 1.5rem;
}
.wizard-tab .wizard-nav .wizard-step {
  cursor: pointer;
}
.wizard-tab .wizard-nav .wizard-step.nav-buttons {
  cursor: default;
}
.wizard-tab .wizard-nav .wizard-step.nav-buttons span {
  cursor: default;
}
.wizard-tab .wizard-nav.tabs .wizard-step {
  position: relative;
  font-size: 14px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--gray-3);
  padding: 0.5rem 1rem;
}
.wizard-tab .wizard-nav.tabs .wizard-step.active {
  border: 1px solid var(--gray-3);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 0px;
}
.wizard-tab .wizard-nav.progress {
  height: auto;
  overflow: auto;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.wizard-tab .wizard-nav.progress .wizard-step {
  position: relative;
  font-size: 14px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  border: 1px solid var(--gray-3);
  background-color: rgb(var(--success-rgb));
  color: #fff;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in;
}
.wizard-tab .wizard-nav.progress .wizard-step.active ~ .wizard-step {
  background-color: var(--custom-white);
  color: #000;
}
.wizard-tab .wizard-nav.dots .wizard-step {
  position: relative;
  font-size: 14px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  transition: all 0.3s ease-in;
}
.wizard-tab .wizard-nav.dots .wizard-step:last-child:before {
  display: none;
}
.wizard-tab .wizard-nav.dots .wizard-step:last-child:after {
  display: none;
}
.wizard-tab .wizard-nav.dots .wizard-step span {
  cursor: pointer;
  font-weight: 500;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(1).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.1s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(2).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.2s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(3).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.3s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(4).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.4s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(5).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.5s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(6).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.6s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(7).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.7s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(8).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.8s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(9).dot {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.9s;
}
.wizard-tab .wizard-nav.dots .wizard-step .dot {
  content: "";
  position: absolute;
  top: -20px;
  inset-inline-start: 50%;
  z-index: 3;
  height: 10px;
  width: 10px;
  background: var(--primary-color);
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--custom-white);
  transition: all 0.5s ease-in-out;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(1):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.1s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(2):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.2s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(3):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.3s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(4):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.4s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(5):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.5s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(6):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.6s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(7):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.7s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(8):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.8s;
}
.wizard-tab .wizard-nav.dots .wizard-step:nth-of-type(9):before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0.9s;
}
.wizard-tab .wizard-nav.dots .wizard-step:before {
  content: "";
  position: absolute;
  top: -16px;
  inset-inline-start: 50%;
  width: 100%;
  height: 2px;
  z-index: 2;
  background: var(--primary-color);
}
.wizard-tab .wizard-nav.dots .wizard-step:after {
  content: "";
  position: absolute;
  top: -16px;
  inset-inline-start: 50%;
  width: 100%;
  height: 2px;
  background: var(--gray-3);
  z-index: 1;
}
.wizard-tab .wizard-nav.dots .wizard-step.active ~ .wizard-step .dot {
  background: var(--gray-3);
}
.wizard-tab .wizard-nav.dots .wizard-step.active ~ .wizard-step:before {
  background: var(--gray-3);
  width: 0%;
}
.wizard-tab .wizard-nav.dots .wizard-step.active ~ .wizard-step:after {
  background: var(--gray-3);
}
.wizard-tab .wizard-nav.dots .wizard-step.active .dot {
  background-color: var(--primary-color);
  box-shadow: 0 0 0 3px var(--primary02);
}
.wizard-tab .wizard-nav.dots .wizard-step.active:before {
  background: var(--gray-3);
}
.wizard-tab .wizard-nav.dots .wizard-step.active:after {
  background: var(--gray-3);
}
.wizard-tab .wizard-content {
  transition: all 0.3s ease-in;
  padding: 3rem;
}
.wizard-tab .wizard-content .wizard-step {
  transition: all 0.3s ease-in;
  display: none;
}
.wizard-tab .wizard-content .wizard-step.active {
  display: block;
}
.wizard-tab .wizard-buttons {
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wizard-tab .wizard-buttons .wizard-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.15rem;
  transition: all 0.3s ease-in;
  margin: 0 10px;
  background-color: var(--primary-color);
  color: #fff;
}
.wizard-tab .wizard-buttons .wizard-btn:hover {
  background-color: rgba(25, 143, 209, 0.8);
}
.wizard-tab .wizard-buttons .wizard-btn:disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.65;
}
.wizard.vertical {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.3s ease-in;
}
.wizard.vertical .wizard-nav {
  flex-direction: column;
  flex: 1;
  padding: 0 3rem;
  transition: all 0.3s linear;
}
.wizard.vertical .wizard-nav .wizard-step:before {
  top: 7px;
  inset-inline-start: -12px;
  width: 2px;
  height: 100%;
}
.wizard.vertical .wizard-nav .wizard-step:after {
  top: 7px;
  inset-inline-start: -12px;
  width: 2px;
  height: 100%;
}
.wizard.vertical .wizard-nav .wizard-step .dot {
  top: 7px;
  inset-inline-start: -15px;
}
.wizard.vertical .wizard-nav .wizard-step.active ~ .wizard-step:before {
  height: 0%;
}
.wizard.vertical .wizard-content {
  width: 75%;
  transition: all 0.3s ease-in;
  padding: 0 0 3rem 0;
}
.wizard.vertical .wizard-buttons {
  flex-basis: 100%;
  transition: all 0.3s ease-in;
}
.highlight-error {
  outline: 1px solid rgb(var(--danger-rgb));
}
@media screen and (min-width: 1024px) {
  .wizard.vertical .wizard-nav {
    max-width: 250px;
  }
}
@media screen and (max-width: 767px) {
  .wizard.vertical .wizard-nav .wizard-step {
    text-align: left;
    padding-inline-start: 1rem;
  }
  .wizard.vertical .wizard-content {
    padding: 2rem 0;
  }
}
.wizard.wizard-tab .wizard-nav.dots .wizard-step.active ~ .wizard-step:before {
  width: 35%;
  padding: 25px;
  background: transparent;
}
.wizard.wizard-tab .wizard-nav.dots .wizard-step:before {
  content: "";
  position: absolute;
  top: -16px;
}
.wizard.wizard-tab .wizard-nav.dots .wizard-step .dot {
  top: -25px;
  inset-inline-start: 46%;
  height: 20px;
  width: 20px;
}
.wizard.wizard-tab .wizard-nav.dots .wizard-step.active ~ .wizard-step .dot {
  background: var(--custom-white);
  border: 2px solid var(--gray-3);
}
.wizard-btn.btn.finish {
  display: none !important;
}
.wizard.wizard-tab .wizard-buttons {
  border-top: 1px solid var(--default-border);
  justify-content: space-between;
  padding: 1rem;
}
.wizard.wizard-tab .wizard-buttons .wizard-btn {
  margin: 0px;
  padding: 0.5rem 0.85rem;
  min-width: 100px;
  border-radius: 0.35rem;
}
.wizard.wizard-tab .wizard-buttons .wizard-btn:hover {
  background-color: var(--primary-color);
}
.wizard.wizard-tab .wizard-buttons .wizard-btn:disabled {
  opacity: 0;
}
.wizard-tab .wizard-buttons .wizard-btn.prev {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.wizard-tab .wizard-buttons .wizard-btn.prev:hover {
  background-color: rgb(var(--light-rgb));
}
.payment-card-container {
  position: relative;
  padding: 0.625rem;
  border: 1px solid var(--input-border);
  border-radius: 0.3rem;
}
.payment-card-container .form-check-input {
  position: absolute;
  inset-inline-end: 0.75rem;
  inset-block-start: 1.2rem;
}
@media (max-width: 575.98px) {
  .wizard-tab .wizard-nav {
    flex-direction: column;
    gap: 1.5rem;
  }
  .wizard.wizard-tab .wizard-nav.dots .wizard-step:before {
    top: 1.5rem !important;
    width: 2px !important;
    height: 100%;
    inset-inline-start: 2.55rem;
  }
  .wizard.wizard-tab .wizard-nav.dots .wizard-step:after {
    top: 1.5rem !important;
    width: 2px !important;
    height: 100%;
    inset-inline-start: 2.55rem;
  }
  .wizard.wizard-tab .wizard-nav.dots .wizard-step .dot {
    top: 6px !important;
    inset-inline-start: 2.25rem !important;
    width: 12px !important;
    height: 12px !important;
  }
  .wizard-tab .wizard-nav.dots .wizard-step {
    text-align: start !important;
    padding-inline-start: 4rem !important;
  }
}
.input-group-text {
  border-color: var(--input-border);
  font-size: 0.875rem;
  border-radius: 0.25rem;
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  letter-spacing: 0;
}
.input-group-text .form-control {
  border: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.list-group-item {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  padding: 0.8rem 1rem;
}
.list-group-item.active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  opacity: 0.5;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: var(--default-text-color);
  background-color: var(--list-hover-focus-bg);
}
.list-group-item-primary {
  color: var(--primary-color);
  background-color: var(--primary01);
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: var(--primary-color);
  background-color: var(--primary02);
}
.list-group-item-primary1 {
  color: var(--primary-tint1-color);
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.list-group-item-primary1.list-group-item-action:focus,
.list-group-item-primary1.list-group-item-action:hover {
  color: var(--primary-tint1-color);
  background-color: rgba(var(--primary-tint1-rgb), 0.2);
}
.list-group-item-primary2 {
  color: var(--primary-tint2-color);
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.list-group-item-primary2.list-group-item-action:focus,
.list-group-item-primary2.list-group-item-action:hover {
  color: var(--primary-tint2-color);
  background-color: rgba(var(--primary-tint2-rgb), 0.2);
}
.list-group-item-primary3 {
  color: var(--primary-tint3-color);
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.list-group-item-primary3.list-group-item-action:focus,
.list-group-item-primary3.list-group-item-action:hover {
  color: var(--primary-tint3-color);
  background-color: rgba(var(--primary-tint3-rgb), 0.2);
}
.list-group-item-secondary {
  color: rgb(var(--secondary-rgb));
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: rgb(var(--secondary-rgb));
  background-color: rgba(var(--secondary-rgb), 0.2);
}
.list-group-item-info {
  color: rgb(var(--info-rgb));
  background-color: rgba(var(--info-rgb), 0.1);
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: rgb(var(--info-rgb));
  background-color: rgba(var(--info-rgb), 0.2);
}
.list-group-item-warning {
  color: rgb(var(--warning-rgb));
  background-color: rgba(var(--warning-rgb), 0.1);
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: rgb(var(--warning-rgb));
  background-color: rgba(var(--warning-rgb), 0.2);
}
.list-group-item-danger {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.1);
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.2);
}
.list-group-item-success {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.1);
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.2);
}
.list-group-item-dark {
  color: rgb(var(--dark-rgb));
  background-color: rgba(var(--dark-rgb), 0.1);
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: rgb(var(--dark-rgb));
  background-color: rgba(var(--dark-rgb), 0.2);
}
.list-group-item-light {
  color: var(--default-text-color);
  background-color: rgba(var(--light-rgb), 0.1);
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: var(--default-text-color);
  background-color: rgba(var(--light-rgb), 0.2);
}
.list-item-solid-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.list-item-solid-primary1 {
  background-color: var(--primary-tint1-color);
  color: #fff;
  border-color: var(--primary-tint1-color);
}
.list-item-solid-primary2 {
  background-color: var(--primary-tint2-color);
  color: #fff;
  border-color: var(--primary-tint2-color);
}
.list-item-solid-primary3 {
  background-color: var(--primary-tint3-color);
  color: #fff;
  border-color: var(--primary-tint3-color);
}
.list-item-solid-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}
.list-item-solid-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}
.list-item-solid-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}
.list-item-solid-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}
.list-item-solid-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}
.list-item-solid-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}
.list-item-solid-dark {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  border-color: rgb(var(--dark-rgb));
}
.modal {
  z-index: 105;
}
.modal-backdrop {
  z-index: 104;
}
.modal-content {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  border-radius: 0.3rem;
}
.modal-header {
  padding: 1rem 1rem;
  border-block-end: 1px solid var(--default-border);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .modal-title {
  font-weight: 500;
  line-height: 1;
}
.modal-header .btn-close {
  font-size: 0.625rem;
}
.modal-footer {
  padding: 1rem 1rem;
  border-block-start: 1px solid var(--default-border);
}
.modal.animated .modal-dialog {
  transform: translate(0, 0);
}
.modal.effect-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}
.modal.effect-scale.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}
.modal.effect-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
.modal.effect-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}
.modal.effect-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}
.modal.effect-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}
.modal.effect-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
}
.modal.effect-newspaper.show ~ .modal-backdrop,
.modal.effect-newspaper .modal-dialog {
  transition: all 0.5s;
}
.modal.effect-newspaper.show .modal-dialog {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}
.modal.effect-fall {
  perspective: 1300px;
}
.modal.effect-fall .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}
.modal.effect-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}
.modal.effect-flip-horizontal {
  perspective: 1300px;
}
.modal.effect-flip-horizontal .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}
.modal.effect-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}
.modal.effect-flip-vertical {
  perspective: 1300px;
}
.modal.effect-flip-vertical .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}
.modal.effect-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}
.modal.effect-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}
.modal.effect-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}
.modal.effect-sign {
  perspective: 1300px;
}
.modal.effect-sign .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}
.modal.effect-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}
.modal.effect-rotate-bottom {
  perspective: 1300px;
}
.modal.effect-rotate-bottom .modal-dialog {
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.modal.effect-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}
.modal.effect-rotate-left {
  perspective: 1300px;
}
.modal.effect-rotate-left .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}
.modal.effect-rotate-left.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}
.navbar {
  border-radius: 0.3rem;
}
.navbar .navbar-nav .nav-link {
  line-height: 1;
  padding: 0.4rem 1rem;
  font-weight: 500;
}
.navbar .navbar-toggler {
  padding: 0.4rem;
  font-size: 1rem;
  line-height: 1;
  color: var(--custom-black);
  border: 1px solid var(--default-border);
  border-radius: 0.3rem;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  width: 1rem;
  height: 1rem;
  position: relative;
  background-image: none;
}
.navbar .navbar-toggler .navbar-toggler-icon:before {
  content: "\f479";
  font-family: bootstrap-icons !important;
  position: absolute;
  font-size: 1rem;
  color: var(--default-text-color);
  inset-inline-start: 0;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--custom-black);
}
.navbar-brand {
  margin-inline-end: 1.5rem;
}
.navbar-brand img {
  height: 1.5rem;
  line-height: 1.5rem;
}
.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--default-text-color);
}
.navbar-primary-transparent {
  background-color: var(--primary01);
  color: var(--default-text-color);
}
.navbar-primary-transparent .nav-link.active {
  background-color: var(--primary-color);
  color: #fff !important;
}
.navbar-primary-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-primary-transparent .form-control:focus {
  border-color: var(--primary-color);
}
.navbar-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-secondary-transparent .nav-link.active {
  background-color: rgb(var(--secondary-rgb));
  color: #fff !important;
}
.navbar-secondary-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-secondary-transparent .form-control:focus {
  border-color: rgb(var(--secondary-rgb));
}
.navbar-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-warning-transparent .nav-link.active {
  background-color: rgb(var(--warning-rgb));
  color: #fff !important;
}
.navbar-warning-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-warning-transparent .form-control:focus {
  border-color: rgb(var(--warning-rgb));
}
.navbar-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-info-transparent .nav-link.active {
  background-color: rgb(var(--info-rgb));
  color: #fff !important;
}
.navbar-info-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-info-transparent .form-control:focus {
  border-color: rgb(var(--info-rgb));
}
.navbar-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-success-transparent .nav-link.active {
  background-color: rgb(var(--success-rgb));
  color: #fff !important;
}
.navbar-success-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-success-transparent .form-control:focus {
  border-color: rgb(var(--success-rgb));
}
.navbar-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-danger-transparent .nav-link.active {
  background-color: rgb(var(--danger-rgb));
  color: #fff !important;
}
.navbar-danger-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-danger-transparent .form-control:focus {
  border-color: rgb(var(--danger-rgb));
}
.navbar-dark-transparent {
  background-color: rgba(var(--dark-rgb), 0.1);
  color: var(--default-text-color);
}
.navbar-dark-transparent .nav-link.active {
  background-color: var(--custom-black);
  color: var(--custom-white) !important;
}
.navbar-dark-transparent .nav-link:hover {
  color: var(--default-text-color);
}
.navbar-dark-transparent .form-control:focus {
  border-color: rgb(var(--dark-rgb));
}
.navbar-primary {
  background-color: var(--primary-color);
  color: #fff;
}
.navbar-primary .nav-link {
  color: #fff !important;
}
.navbar-primary .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-primary .nav-link:hover {
  color: #fff !important;
}
.navbar-primary .form-control:focus {
  border-color: var(--white-2);
}
.navbar-primary .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-primary .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.navbar-secondary .nav-link {
  color: #fff !important;
}
.navbar-secondary .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-secondary .nav-link:hover {
  color: #fff !important;
}
.navbar-secondary .form-control:focus {
  border-color: var(--white-2);
}
.navbar-secondary .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-secondary .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.navbar-warning .nav-link {
  color: #fff !important;
}
.navbar-warning .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-warning .nav-link:hover {
  color: #fff !important;
}
.navbar-warning .form-control:focus {
  border-color: var(--white-2);
}
.navbar-warning .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-warning .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.navbar-info .nav-link {
  color: #fff !important;
}
.navbar-info .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-info .nav-link:hover {
  color: #fff !important;
}
.navbar-info .form-control:focus {
  border-color: var(--white-2);
}
.navbar-info .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-info .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.navbar-success .nav-link {
  color: #fff !important;
}
.navbar-success .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-success .nav-link:hover {
  color: #fff !important;
}
.navbar-success .form-control:focus {
  border-color: var(--white-2);
}
.navbar-success .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-success .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.navbar-danger .nav-link {
  color: #fff !important;
}
.navbar-danger .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}
.navbar-danger .nav-link:hover {
  color: #fff !important;
}
.navbar-danger .form-control:focus {
  border-color: var(--white-2);
}
.navbar-danger .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-danger .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}
.navbar-dark {
  background-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}
.navbar-dark .nav-link {
  color: var(--custom-white) !important;
}
.navbar-dark .nav-link.active {
  background-color: var(--white-2);
  color: var(--custom-white) !important;
}
.navbar-dark .nav-link:hover {
  color: var(--custom-white) !important;
}
.navbar-dark .form-control:focus {
  border-color: var(--white-2);
}
.navbar-dark .navbar-toggler {
  border: 1px solid var(--white-1);
}
.navbar-dark .navbar-toggler .navbar-toggler-icon:before {
  color: var(--custom-white);
}
.nav-item {
  margin-inline-end: 0.5rem;
}
.nav-item:last-child {
  margin-inline-end: 0;
}
.nav-link {
  color: var(--default-text-color);
  border-radius: 0.375rem;
  font-weight: 500;
}
.nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.navbar-brand {
  color: var(--default-text-color);
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--primary-color);
}
.nav-link.disabled {
  color: var(--default-text-color);
  opacity: 0.3;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: rgba(var(--dark-rgb), 0.5);
}
.navbar-text {
  color: var(--default-text-color);
}
.navbar-dark.bg-dark .nav-link,
.navbar-dark.bg-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--primary-color);
}
.nav.nav-style-1 {
  border: 0;
}
.nav.nav-style-1 .nav-item {
  margin-inline-end: 0.25rem;
}
.nav.nav-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}
.nav.nav-style-1 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
}
.nav.nav-style-2 .nav-item .nav-link {
  border-block-start: 3px solid transparent;
}
.nav.nav-style-2 .nav-item .nav-link.active {
  background-color: var(--primary01);
  border-block-start: 3px solid var(--primary-color);
  color: var(--primary-color);
}
.nav.nav-style-3 .nav-link {
  border-radius: 0;
}
.nav.nav-style-3 .nav-link {
  border-block-end: 3px solid transparent;
}
.nav.nav-style-3 .nav-link.active {
  background-color: transparent;
  border-block-end: 3px solid var(--primary-color);
  color: var(--primary-color);
}
.nav.nav-style-4 {
  border: 0;
}
.nav.nav-style-4 .nav-link {
  color: var(--text-muted);
  border: 0;
}
.nav.nav-style-4 .nav-link.active {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border: 0;
}
.nav.nav-style-4 .nav-link:hover,
.nav.nav-style-4 .nav-link:focus {
  border: 0;
}
.nav-tabs.nav-style-5 {
  border: 0;
}
.nav-tabs.nav-style-5 .nav-link {
  color: var(--text-muted);
  border: 0;
}
.nav-tabs.nav-style-5 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}
.nav-tabs.nav-style-5 .nav-link:hover,
.nav-tabs.nav-style-5 .nav-link:focus {
  border: 0;
}
.nav-style-6 {
  border: 0;
}
.nav-style-6.nav-pills .nav-link.active {
  border: 1px solid var(--primary-color);
  border-block-end: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}
.nav-tabs-header {
  border: 0;
}
.nav-tabs-header .nav-item {
  margin-inline-end: 0.5rem;
}
.nav-tabs-header .nav-item:last-child {
  margin-inline-end: 0;
}
.nav-tabs-header .nav-item .nav-link {
  border: 1px solid transparent;
  font-size: 0.8rem;
  padding: 0.4rem 0.5rem;
  font-weight: 500;
}
.nav-tabs-header .nav-item .nav-link.active {
  background: var(--primary01);
  color: var(--primary-color);
  border: 1px solid transparent;
}
.nav-tabs-header .nav-item .nav-link:hover,
.nav-tabs-header .nav-item .nav-link:focus {
  border: 1px solid transparent;
}
.nav-tabs-footer {
  border: 0;
}
.nav-tabs-footer .nav-item {
  margin-inline-end: 0.5rem;
}
.nav-tabs-footer .nav-item:last-child {
  margin-inline-end: 0;
}
.nav-tabs-footer .nav-item .nav-link {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 0.8rem;
}
.nav-tabs-footer .nav-item .nav-link.active {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.nav-tabs-footer .nav-item .nav-link:hover,
.nav-tabs-footer .nav-item .nav-link:focus {
  border: 1px solid var(--primary-color);
}
.vertical-tabs-2 {
  border: 0;
  margin-block-end: 1rem;
}
.vertical-tabs-2 .nav-item {
  margin-inline-end: 0.5rem;
}
.vertical-tabs-2 .nav-item:last-child {
  margin-inline-end: 0;
}
.vertical-tabs-2 .nav-item .nav-link {
  text-align: center;
  border: 1px solid var(--default-border);
  margin-bottom: 0.5rem;
  color: var(--text-muted);
  background-color: rgb(var(--light-rgb));
}
.vertical-tabs-2 .nav-item .nav-link i {
  font-size: 1rem;
}
.vertical-tabs-2 .nav-item .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}
.vertical-tabs-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-end: -0.5rem;
  inset-block-start: 38%;
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
  background-color: var(--primary-color);
}
@media (min-width: 768px) {
  .vertical-tabs-2 {
    margin-block-end: 0;
  }
  .vertical-tabs-2 .nav-item .nav-link {
    min-width: 7.5rem;
    max-width: 7.5rem;
  }
}
.vertical-tabs-3 {
  border: 0;
}
.vertical-tabs-3 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}
.vertical-tabs-3 .nav-item:last-child {
  margin-inline-end: 0;
}
.vertical-tabs-3 .nav-item .nav-link {
  min-width: 160px;
  max-width: 160px;
  border: 1px solid var(--primary-color);
  padding: 1.3rem;
  margin-block-end: 0.5rem;
}
.vertical-tabs-3 .nav-item .nav-link i {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  background: var(--primary-color);
  color: #fff;
  border-radius: 3.125rem;
  font-weight: 500;
}
.vertical-tabs-3 .nav-item .nav-link.active {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
}
.vertical-tabs-3 .nav-item .nav-link.active i {
  background: #fff;
  color: var(--primary-color);
}
.vertical-tabs-3 .nav-item .nav-link:hover,
.vertical-tabs-3 .nav-item .nav-link:focus {
  border: 1px solid var(--primary-color);
}
@media screen and (max-width: 767px) {
  .vertical-tabs-3 .nav-item {
    margin-inline-end: 0rem;
  }
  .vertical-tabs-3 .nav-item .nav-link {
    min-width: auto;
    max-width: inherit;
    width: 100%;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: var(--primary-color);
}
.nav-tabs {
  border-color: var(--default-border);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: var(--default-border);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--default-border);
}
.nav.tab-style-1 {
  border-block-end: 0;
  padding: 0.65rem;
  background-color: rgb(var(--light-rgb));
  border-radius: 0.3rem;
  margin-block-end: 1rem;
}
.nav.tab-style-1 .nav-item {
  margin-inline-end: 0.5rem;
}
.nav.tab-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}
.nav.tab-style-1 .nav-link {
  border-radius: 0.3rem;
  padding: 0.35rem 1rem;
}
.nav.tab-style-1 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  transition: color 0.15s ease-in;
}
.tab-content .tab-pane {
  padding: 1rem;
  border: 1px solid var(--default-border);
  border-radius: 0.3rem;
  color: var(--default-text-color);
}
.tab-style-2 {
  border-block-end: 0;
}
.tab-style-2 .nav-item {
  margin-inline-end: 0.5rem;
}
.tab-style-2 .nav-item:last-child {
  margin-inline-end: 0;
}
.tab-style-2 .nav-item .nav-link {
  border: 0;
}
.tab-style-2 .nav-item .nav-link i {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.35rem;
  border-radius: 50px;
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  display: inline-block;
}
.tab-style-2 .nav-item .nav-link:hover {
  border: 0;
}
.tab-style-2 .nav-item .nav-link.active {
  background-color: transparent;
  position: relative;
  border: 0;
  color: var(--primary-color);
}
.tab-style-2 .nav-item .nav-link.active i {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.tab-style-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  width: 100%;
  height: 0.175rem;
  background-color: var(--primary-color);
  border-radius: 50px;
}
.tab-style-3 {
  border-block-end: 0;
}
.tab-style-3 .nav-item {
  position: relative;
  margin-inline-end: 0.5rem;
}
.tab-style-3 .nav-item:last-child {
  margin-inline-end: 0;
}
.tab-style-3 .nav-item .nav-link {
  position: relative;
  border: 0;
  font-size: 0.75rem;
}
.tab-style-3 .nav-item .nav-link:before {
  position: absolute;
  content: "";
  inset-block-end: -1.063rem;
  inset-inline-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: rgb(var(--light-rgb));
  border-radius: 50%;
}
.tab-style-3 .nav-item .nav-link:after {
  position: absolute;
  content: "";
  inset-block-end: -2rem;
  inset-inline-start: 1.75rem;
  inset-inline-end: 0;
  inset-inline-start: 30%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--custom-white);
  border-radius: 50%;
  border: 2px solid var(--default-border);
}
.tab-style-3 .nav-item .nav-link.home:after {
  content: "1";
}
.tab-style-3 .nav-item .nav-link.about:after {
  content: "2";
}
.tab-style-3 .nav-item .nav-link.services:after {
  content: "3";
}
.tab-style-3 .nav-item .nav-link:hover {
  border: 0;
}
.tab-style-3 .nav-item .nav-link.active {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
}
.tab-style-3 .nav-item .nav-link.active:before {
  background-color: var(--primary-color);
}
.tab-style-3 .nav-item .nav-link.active:after {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.tab-style-4.nav-tabs {
  border: 0;
}
.tab-style-4.nav-tabs .nav-item {
  margin-inline-end: 0.5rem;
}
.tab-style-4.nav-tabs .nav-item:last-child {
  margin-inline-end: 0;
}
.tab-style-4.nav-tabs .nav-link {
  border: 0;
}
.tab-style-4.nav-tabs .nav-link:hover {
  border: 0;
}
.tab-style-4.nav-tabs .nav-link.active {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
}
.tab-style-4.nav-tabs .nav-link.active::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  inset-block-start: 31px;
  transform: rotate(45deg);
  inset-inline-start: 47%;
  overflow: hidden;
  z-index: 1;
  height: 0.75rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.tab-style-4.nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  width: 40%;
  height: 1px;
  inset-inline-start: 30%;
  inset-block-end: 0;
  background-color: var(--primary-color);
}
#tab-style-4 .tab-pane.active {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}
.tab-style-5 .nav-item {
  margin-inline-end: 0.5rem;
}
.tab-style-5 .nav-item:last-child {
  margin-inline-end: 0;
}
.tab-style-5 .nav-item .nav-link.active {
  background: var(--primary-color);
}
.tab-style-6 {
  border: 0;
  background-color: var(--custom-white);
  border-radius: 0.3rem;
  color: var(--primary-color);
  padding: 0.5rem;
}
.tab-style-6 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}
.tab-style-6 .nav-item:last-child {
  margin-inline-end: 0;
}
.tab-style-6 .nav-item .nav-link {
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: 0.813rem;
  border: 0;
  font-weight: 500;
}
.tab-style-6 .nav-item .nav-link.active {
  border: 0;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  box-shadow: 0px 3px 10px 0px rgba(var(--dark-rgb), 0.05);
}
.tab-style-6 .nav-item .nav-link:hover,
.tab-style-6 .nav-item .nav-link:focus {
  border: 0;
}
.tab-style-7 .nav-link {
  margin-block-end: 0.5rem;
  font-size: 0.813rem;
  color: var(--text-muted);
  text-align: left;
}
.tab-style-7 .nav-link:active,
.tab-style-7 .nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.tab-style-8.nav-tabs {
  border-bottom: none;
  padding: 0px !important;
}
.tab-style-8.nav-tabs > .nav-item > .nav-link {
  border-bottom: 3px solid transparent;
  color: var(--default-text-color);
}
.tab-style-8.nav-tabs > .nav-item > .nav-link:not(.active):hover,
.tab-style-8.nav-tabs > .nav-item > .nav-link:not(.active):focus {
  color: var(--primary-color);
  background: none;
  border-color: transparent;
  border-bottom-color: transparent;
}
.tab-style-8.nav-tabs > .nav-item > .nav-link.active {
  color: var(--primary-color);
  background-color: transparent;
  border-color: transparent transparent var(--primary-color) transparent;
}
.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link {
  position: relative;
  border-bottom: none;
  padding: 0.5rem;
}
.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link:focus::before,
.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link.active::before {
  transform: scaleX(1);
}
.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  background-color: var(--primary-color);
  transition: transform ease 300ms;
}
.nav-style-1 .nav-item .nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.page-link {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}
.page-link:focus {
  box-shadow: none;
  background-color: rgb(var(--light-rgb));
}
.page-link:hover {
  color: var(--primary-color);
  background-color: rgb(var(--light-rgb));
  border-color: var(--default-border);
}
.page-item.active .page-link {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.disabled > .page-link,
.page-link.disabled {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border-color: var(--default-border);
  opacity: 0.7;
}
[dir=rtl] .pagination .page-link .bx-chevron-left::before {
  content: "\ea50";
}
[dir=rtl] .pagination .page-link .bx-chevron-right::before {
  content: "\ea4d";
}
[dir=rtl] .pagination .page-link .ri-arrow-right-s-line:before {
  content: "\ea64";
}
[dir=rtl] .pagination .page-link .ri-arrow-left-s-line:before {
  content: "\ea6e";
}
.pagination-style-1 .pagination .page-item {
  margin: 0 0.25rem;
}
.pagination-style-1 .pagination .page-item .page-link {
  border: 0;
  border-radius: 0.3rem;
  font-size: 0.8125rem;
}
.pagination-style-1 .pagination .page-item .page-link i {
  font-weight: 400;
}
.pagination-style-1 .pagination .page-item.active .page-link {
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: #fff;
}
.pagination-style-1 .pagination .page-item.active:hover .page-link {
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: #fff;
}
.pagination-style-1 .pagination .page-item:hover .page-link {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.pagination-style-2 .pagination {
  border-radius: 0.3rem;
}
.pagination-style-2 .pagination .page-item {
  margin: 0 0.25rem;
}
.pagination-style-2 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
}
.pagination-style-2 .pagination .page-item.active .page-link {
  background-color: var(--custom-white);
  color: var(--primary-color);
  position: relative;
  font-weight: bold;
}
.pagination-style-2 .pagination .page-item.active .page-link:before {
  position: absolute;
  content: "";
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: var(--primary-color);
}
.pagination-style-2 .pagination .page-item:hover .page-link {
  background-color: transparent;
}
.pagination-style-3 .pagination {
  border-radius: 50px;
  padding: 0.25rem;
  align-items: center;
}
.pagination-style-3 .pagination .page-item {
  margin: 0 0.25rem;
}
.pagination-style-3 .pagination .page-item .page-link {
  border: 0;
  border-radius: 50px;
  font-size: 0.8125rem;
}
.pagination-style-3 .pagination .page-item .page-link i {
  font-weight: 400;
}
.pagination-style-3 .pagination .page-item.active .page-link {
  background-color: var(--primary-color);
  color: #fff;
}
.pagination-style-3 .pagination .page-item.active:hover .page-link {
  background-color: var(--primary-color);
}
.pagination-style-3 .pagination .page-item:hover .page-link {
  background-color: rgb(var(--light-rgb));
}
.pagination-style-4 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}
.pagination-style-4 .pagination .page-item .page-link i {
  font-weight: 400;
}
.pagination-style-4 .pagination .page-item.active .page-link {
  border: 0;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: #fff;
}
.pagination-style-4 .pagination .page-item.active:hover .page-link {
  background-color: var(--primary-color);
}
.pagination-style-4 .pagination .page-item:hover .page-link {
  background-color: transparent;
}
.custom-popover {
  --bs-popover-max-width: 12.5rem;
  --bs-popover-border-color: var(--primary-color);
  --bs-popover-header-bg: var(--primary-color);
  --bs-popover-header-color: #fff;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
}
.popover {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  font-size: 0.8rem;
  border-radius: 0.3rem;
  z-index: 999;
}
.popover .popover-header {
  background-color: var(--custom-white);
  border-block-end: 1px solid var(--default-border);
}
.popover .popover-body {
  color: var(--default-text-color);
}
.popover.only-body {
  border-radius: 0.3rem;
}
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--light-rgb));
}
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--light-rgb));
}
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--light-rgb));
}
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--light-rgb));
}
.header-primary .popover-header {
  background-color: var(--primary-color);
  color: #fff;
}
.header-primary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--primary-color);
}
.header-primary1 .popover-header {
  background-color: var(--primary-tint1-color);
  color: #fff;
}
.header-primary1.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-primary1.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--primary-tint1-color);
}
.header-primary2 .popover-header {
  background-color: var(--primary-tint2-color);
  color: #fff;
}
.header-primary2.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-primary2.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--primary-tint2-color);
}
.header-primary3 .popover-header {
  background-color: var(--primary-tint3-color);
  color: #fff;
}
.header-primary3.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-primary3.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--primary-tint3-color);
}
.header-secondary .popover-header {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.header-secondary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}
.header-warning .popover-header {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.header-warning.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--warning-rgb));
}
.header-info .popover-header {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.header-info.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--info-rgb));
}
.header-success .popover-header {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.header-success.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--success-rgb));
}
.header-danger .popover-header {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.header-danger.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.header-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--danger-rgb));
}
.popover-primary.popover {
  border: 1px solid var(--primary-color);
}
.popover-primary.popover .popover-header {
  background-color: var(--primary-color);
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-primary.popover .popover-body {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-primary.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary.bs-popover-top > .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary.bs-popover-top > .popover-arrow::before {
  border-top-color: var(--primary-color);
}
.popover-primary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary.bs-popover-end > .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary.bs-popover-end > .popover-arrow::before {
  border-right-color: var(--primary-color);
}
.popover-primary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary.bs-popover-start > .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary.bs-popover-start > .popover-arrow::before {
  border-left-color: var(--primary-color);
}
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary.bs-popover-bottom > .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary.bs-popover-bottom > .popover-arrow::before,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary-color);
}
.popover-primary1.popover {
  border: 1px solid var(--primary-tint1-color);
}
.popover-primary1.popover .popover-header {
  background-color: var(--primary-tint1-color);
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-primary1.popover .popover-body {
  background-color: var(--primary-tint1-color);
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-primary1.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary1.bs-popover-top > .popover-arrow::after,
.popover-primary1.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary1.bs-popover-top > .popover-arrow::before {
  border-top-color: var(--primary-tint1-color);
}
.popover-primary1.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary1.bs-popover-end > .popover-arrow::after,
.popover-primary1.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary1.bs-popover-end > .popover-arrow::before {
  border-right-color: var(--primary-tint1-color);
}
.popover-primary1.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary1.bs-popover-start > .popover-arrow::after,
.popover-primary1.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary1.bs-popover-start > .popover-arrow::before {
  border-left-color: var(--primary-tint1-color);
}
.popover-primary1.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary1.bs-popover-bottom > .popover-arrow::after,
.popover-primary1.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary1.bs-popover-bottom > .popover-arrow::before,
.popover-primary1.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary1.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary-tint1-color);
}
.popover-primary2.popover {
  border: 1px solid var(--primary-tint2-color);
}
.popover-primary2.popover .popover-header {
  background-color: var(--primary-tint2-color);
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-primary2.popover .popover-body {
  background-color: var(--primary-tint2-color);
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-primary2.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary2.bs-popover-top > .popover-arrow::after,
.popover-primary2.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary2.bs-popover-top > .popover-arrow::before {
  border-top-color: var(--primary-tint2-color);
}
.popover-primary2.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary2.bs-popover-end > .popover-arrow::after,
.popover-primary2.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary2.bs-popover-end > .popover-arrow::before {
  border-right-color: var(--primary-tint2-color);
}
.popover-primary2.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary2.bs-popover-start > .popover-arrow::after,
.popover-primary2.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary2.bs-popover-start > .popover-arrow::before {
  border-left-color: var(--primary-tint2-color);
}
.popover-primary2.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary2.bs-popover-bottom > .popover-arrow::after,
.popover-primary2.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary2.bs-popover-bottom > .popover-arrow::before,
.popover-primary2.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary2.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary-tint2-color);
}
.popover-primary3.popover {
  border: 1px solid var(--primary-tint3-color);
}
.popover-primary3.popover .popover-header {
  background-color: var(--primary-tint3-color);
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-primary3.popover .popover-body {
  background-color: var(--primary-tint3-color);
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-primary3.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary3.bs-popover-top > .popover-arrow::after,
.popover-primary3.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary3.bs-popover-top > .popover-arrow::before {
  border-top-color: var(--primary-tint3-color);
}
.popover-primary3.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary3.bs-popover-end > .popover-arrow::after,
.popover-primary3.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary3.bs-popover-end > .popover-arrow::before {
  border-right-color: var(--primary-tint3-color);
}
.popover-primary3.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary3.bs-popover-start > .popover-arrow::after,
.popover-primary3.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary3.bs-popover-start > .popover-arrow::before {
  border-left-color: var(--primary-tint3-color);
}
.popover-primary3.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary3.bs-popover-bottom > .popover-arrow::after,
.popover-primary3.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary3.bs-popover-bottom > .popover-arrow::before,
.popover-primary3.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary3.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary-tint3-color);
}
.popover-secondary.popover {
  border: 1px solid rgb(var(--secondary-rgb));
}
.popover-secondary.popover .popover-header {
  background-color: rgb(var(--secondary-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-secondary.popover .popover-body {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-secondary.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-secondary.bs-popover-top > .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-secondary.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--secondary-rgb));
}
.popover-secondary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-secondary.bs-popover-end > .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-secondary.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--secondary-rgb));
}
.popover-secondary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-secondary.bs-popover-start > .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-secondary.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--secondary-rgb));
}
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-secondary.bs-popover-bottom > .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-secondary.bs-popover-bottom > .popover-arrow::before,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}
.popover-warning.popover {
  border: 1px solid rgb(var(--warning-rgb));
}
.popover-warning.popover .popover-header {
  background-color: rgb(var(--warning-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-warning.popover .popover-body {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-warning.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-warning.bs-popover-top > .popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-warning.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--warning-rgb));
}
.popover-warning.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-warning.bs-popover-end > .popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-warning.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--warning-rgb));
}
.popover-warning.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-warning.bs-popover-start > .popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-warning.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--warning-rgb));
}
.popover-warning.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-warning.bs-popover-bottom > .popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-warning.bs-popover-bottom > .popover-arrow::before,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--warning-rgb));
}
.popover-info.popover {
  border: 1px solid rgb(var(--info-rgb));
}
.popover-info.popover .popover-header {
  background-color: rgb(var(--info-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-info.popover .popover-body {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-info.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-info.bs-popover-top > .popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-info.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--info-rgb));
}
.popover-info.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-info.bs-popover-end > .popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-info.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--info-rgb));
}
.popover-info.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-info.bs-popover-start > .popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-info.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--info-rgb));
}
.popover-info.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-info.bs-popover-bottom > .popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-info.bs-popover-bottom > .popover-arrow::before,
.popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--info-rgb));
}
.popover-success.popover {
  border: 1px solid rgb(var(--success-rgb));
}
.popover-success.popover .popover-header {
  background-color: rgb(var(--success-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-success.popover .popover-body {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-success.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-success.bs-popover-top > .popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-success.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--success-rgb));
}
.popover-success.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-success.bs-popover-end > .popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-success.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--success-rgb));
}
.popover-success.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-success.bs-popover-start > .popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-success.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--success-rgb));
}
.popover-success.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-success.bs-popover-bottom > .popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-success.bs-popover-bottom > .popover-arrow::before,
.popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--success-rgb));
}
.popover-danger.popover {
  border: 1px solid rgb(var(--danger-rgb));
}
.popover-danger.popover .popover-header {
  background-color: rgb(var(--danger-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-danger.popover .popover-body {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-danger.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-danger.bs-popover-top > .popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-danger.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--danger-rgb));
}
.popover-danger.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-danger.bs-popover-end > .popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-danger.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--danger-rgb));
}
.popover-danger.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-danger.bs-popover-start > .popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-danger.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--danger-rgb));
}
.popover-danger.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-danger.bs-popover-bottom > .popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-danger.bs-popover-bottom > .popover-arrow::before,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--danger-rgb));
}
.popover-purple.popover {
  border: 1px solid rgb(var(--purple-rgb));
}
.popover-purple.popover .popover-header {
  background-color: rgb(var(--purple-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-purple.popover .popover-body {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-purple.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-purple.bs-popover-top > .popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-purple.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--purple-rgb));
}
.popover-purple.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-purple.bs-popover-end > .popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-purple.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--purple-rgb));
}
.popover-purple.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-purple.bs-popover-start > .popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-purple.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--purple-rgb));
}
.popover-purple.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-purple.bs-popover-bottom > .popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-purple.bs-popover-bottom > .popover-arrow::before,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--purple-rgb));
}
.popover-teal.popover {
  border: 1px solid rgb(var(--teal-rgb));
}
.popover-teal.popover .popover-header {
  background-color: rgb(var(--teal-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}
.popover-teal.popover .popover-body {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}
.popover-teal.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-teal.bs-popover-top > .popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-teal.bs-popover-top > .popover-arrow::before {
  border-top-color: rgb(var(--teal-rgb));
}
.popover-teal.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-teal.bs-popover-end > .popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-teal.bs-popover-end > .popover-arrow::before {
  border-right-color: rgb(var(--teal-rgb));
}
.popover-teal.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-teal.bs-popover-start > .popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-teal.bs-popover-start > .popover-arrow::before {
  border-left-color: rgb(var(--teal-rgb));
}
.popover-teal.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-teal.bs-popover-bottom > .popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-teal.bs-popover-bottom > .popover-arrow::before,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--teal-rgb));
}
.popover-primary-light.popover {
  border: 1px solid var(--primary01);
}
.popover-primary-light.popover .popover-header {
  background-color: var(--primary01);
  border-block-end: 1px solid var(--primary01);
  color: var(--primary-color);
  border-radius: 0;
}
.popover-primary-light.popover .popover-body {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.popover-primary-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary-light.bs-popover-top > .popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary-light.bs-popover-top > .popover-arrow::before {
  border-top-color: var(--primary01);
}
.popover-primary-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary-light.bs-popover-end > .popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary-light.bs-popover-end > .popover-arrow::before {
  border-right-color: var(--primary01);
}
.popover-primary-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary-light.bs-popover-start > .popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary-light.bs-popover-start > .popover-arrow::before {
  border-left-color: var(--primary01);
}
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary-light.bs-popover-bottom > .popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary-light.bs-popover-bottom > .popover-arrow::before,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary01);
}
.popover-primary1-light.popover {
  border: 1px solid rgba(var(--primary-tint1-rgb), 0.1);
}
.popover-primary1-light.popover .popover-header {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--primary-tint1-color);
  border-radius: 0;
}
.popover-primary1-light.popover .popover-body {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--primary-tint1-color);
}
.popover-primary1-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary1-light.bs-popover-top > .popover-arrow::after,
.popover-primary1-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary1-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.popover-primary1-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary1-light.bs-popover-end > .popover-arrow::after,
.popover-primary1-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary1-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.popover-primary1-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary1-light.bs-popover-start > .popover-arrow::after,
.popover-primary1-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary1-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.popover-primary1-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary1-light.bs-popover-bottom > .popover-arrow::after,
.popover-primary1-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary1-light.bs-popover-bottom > .popover-arrow::before,
.popover-primary1-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary1-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.popover-primary2-light.popover {
  border: 1px solid rgba(var(--primary-tint2-rgb), 0.1);
}
.popover-primary2-light.popover .popover-header {
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--primary-tint2-rgb), 0.1);
  color: var(--primary-tint2-color);
  border-radius: 0;
}
.popover-primary2-light.popover .popover-body {
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  color: var(--primary-tint2-color);
}
.popover-primary2-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary2-light.bs-popover-top > .popover-arrow::after,
.popover-primary2-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary2-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.popover-primary2-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary2-light.bs-popover-end > .popover-arrow::after,
.popover-primary2-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary2-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.popover-primary2-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary2-light.bs-popover-start > .popover-arrow::after,
.popover-primary2-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary2-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.popover-primary2-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary2-light.bs-popover-bottom > .popover-arrow::after,
.popover-primary2-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary2-light.bs-popover-bottom > .popover-arrow::before,
.popover-primary2-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary2-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--primary-tint2-rgb), 0.1);
}
.popover-primary3-light.popover {
  border: 1px solid rgba(var(--primary-tint3-rgb), 0.1);
}
.popover-primary3-light.popover .popover-header {
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--primary-tint3-rgb), 0.1);
  color: var(--primary-tint3-color);
  border-radius: 0;
}
.popover-primary3-light.popover .popover-body {
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
  color: var(--primary-tint3-color);
}
.popover-primary3-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-primary3-light.bs-popover-top > .popover-arrow::after,
.popover-primary3-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-primary3-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.popover-primary3-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-primary3-light.bs-popover-end > .popover-arrow::after,
.popover-primary3-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-primary3-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.popover-primary3-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-primary3-light.bs-popover-start > .popover-arrow::after,
.popover-primary3-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-primary3-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.popover-primary3-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-primary3-light.bs-popover-bottom > .popover-arrow::after,
.popover-primary3-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-primary3-light.bs-popover-bottom > .popover-arrow::before,
.popover-primary3-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary3-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.popover-secondary-light.popover {
  border: 1px solid rgba(var(--secondary-rgb), 0.1);
}
.popover-secondary-light.popover .popover-header {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
  border-radius: 0;
}
.popover-secondary-light.popover .popover-body {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}
.popover-secondary-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-secondary-light.bs-popover-top > .popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-secondary-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--secondary-rgb), 0.1);
}
.popover-secondary-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-secondary-light.bs-popover-end > .popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-secondary-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--secondary-rgb), 0.1);
}
.popover-secondary-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-secondary-light.bs-popover-start > .popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-secondary-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--secondary-rgb), 0.1);
}
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-secondary-light.bs-popover-bottom > .popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-secondary-light.bs-popover-bottom > .popover-arrow::before,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--secondary-rgb), 0.1);
}
.popover-warning-light.popover {
  border: 1px solid rgba(var(--warning-rgb), 0.1);
}
.popover-warning-light.popover .popover-header {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
  border-radius: 0;
}
.popover-warning-light.popover .popover-body {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}
.popover-warning-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-warning-light.bs-popover-top > .popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-warning-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--warning-rgb), 0.1);
}
.popover-warning-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-warning-light.bs-popover-end > .popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-warning-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--warning-rgb), 0.1);
}
.popover-warning-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-warning-light.bs-popover-start > .popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-warning-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--warning-rgb), 0.1);
}
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-warning-light.bs-popover-bottom > .popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-warning-light.bs-popover-bottom > .popover-arrow::before,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--warning-rgb), 0.1);
}
.popover-info-light.popover {
  border: 1px solid rgba(var(--info-rgb), 0.1);
}
.popover-info-light.popover .popover-header {
  background-color: rgba(var(--info-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
  border-radius: 0;
}
.popover-info-light.popover .popover-body {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}
.popover-info-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-info-light.bs-popover-top > .popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-info-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--info-rgb), 0.1);
}
.popover-info-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-info-light.bs-popover-end > .popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-info-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--info-rgb), 0.1);
}
.popover-info-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-info-light.bs-popover-start > .popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-info-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--info-rgb), 0.1);
}
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-info-light.bs-popover-bottom > .popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-info-light.bs-popover-bottom > .popover-arrow::before,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--info-rgb), 0.1);
}
.popover-success-light.popover {
  border: 1px solid rgba(var(--success-rgb), 0.1);
}
.popover-success-light.popover .popover-header {
  background-color: rgba(var(--success-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
  border-radius: 0;
}
.popover-success-light.popover .popover-body {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}
.popover-success-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-success-light.bs-popover-top > .popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-success-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--success-rgb), 0.1);
}
.popover-success-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-success-light.bs-popover-end > .popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-success-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--success-rgb), 0.1);
}
.popover-success-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-success-light.bs-popover-start > .popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-success-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--success-rgb), 0.1);
}
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-success-light.bs-popover-bottom > .popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-success-light.bs-popover-bottom > .popover-arrow::before,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--success-rgb), 0.1);
}
.popover-danger-light.popover {
  border: 1px solid rgba(var(--danger-rgb), 0.1);
}
.popover-danger-light.popover .popover-header {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
  border-radius: 0;
}
.popover-danger-light.popover .popover-body {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}
.popover-danger-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-danger-light.bs-popover-top > .popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-danger-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--danger-rgb), 0.1);
}
.popover-danger-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-danger-light.bs-popover-end > .popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-danger-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--danger-rgb), 0.1);
}
.popover-danger-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-danger-light.bs-popover-start > .popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-danger-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--danger-rgb), 0.1);
}
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-danger-light.bs-popover-bottom > .popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-danger-light.bs-popover-bottom > .popover-arrow::before,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--danger-rgb), 0.1);
}
.popover-purple-light.popover {
  border: 1px solid rgba(var(--purple-rgb), 0.1);
}
.popover-purple-light.popover .popover-header {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
  border-radius: 0;
}
.popover-purple-light.popover .popover-body {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}
.popover-purple-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-purple-light.bs-popover-top > .popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-purple-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--purple-rgb), 0.1);
}
.popover-purple-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-purple-light.bs-popover-end > .popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-purple-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--purple-rgb), 0.1);
}
.popover-purple-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-purple-light.bs-popover-start > .popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-purple-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--purple-rgb), 0.1);
}
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-purple-light.bs-popover-bottom > .popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-purple-light.bs-popover-bottom > .popover-arrow::before,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--purple-rgb), 0.1);
}
.popover-teal-light.popover {
  border: 1px solid rgba(var(--teal-rgb), 0.1);
}
.popover-teal-light.popover .popover-header {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
  border-radius: 0;
}
.popover-teal-light.popover .popover-body {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}
.popover-teal-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.popover-teal-light.bs-popover-top > .popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.popover-teal-light.bs-popover-top > .popover-arrow::before {
  border-top-color: rgba(var(--teal-rgb), 0.1);
}
.popover-teal-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.popover-teal-light.bs-popover-end > .popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.popover-teal-light.bs-popover-end > .popover-arrow::before {
  border-right-color: rgba(var(--teal-rgb), 0.1);
}
.popover-teal-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.popover-teal-light.bs-popover-start > .popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.popover-teal-light.bs-popover-start > .popover-arrow::before {
  border-left-color: rgba(var(--teal-rgb), 0.1);
}
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.popover-teal-light.bs-popover-bottom > .popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.popover-teal-light.bs-popover-bottom > .popover-arrow::before,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--teal-rgb), 0.1);
}
.progress {
  background-color: rgb(var(--light-rgb));
  height: 0.75rem;
  border-radius: 0.25rem;
}
.progress-stacked {
  background-color: rgb(var(--light-rgb));
}
.progress-bar {
  color: #fff;
  background-color: var(--primary-color);
}
@keyframes progress-animate {
  0% {
    width: 0;
  }
}
.progress-animate {
  position: relative;
  border-radius: 0.625rem;
}
.progress-animate .progress-bar {
  position: relative;
  border-radius: 0.625rem;
  animation: 2s progress-animate;
}
.progress.progress-xs,
.progress-stacked.progress-xs {
  height: 0.25rem;
}
.progress.progress-sm,
.progress-stacked.progress-sm {
  height: 0.4rem;
}
.progress.progress-lg,
.progress-stacked.progress-lg {
  height: 1rem;
}
.progress.progress-xl,
.progress-stacked.progress-xl {
  height: 1.25rem;
}
.progress-custom {
  overflow: visible;
  position: relative;
}
.progress-custom .progress-bar {
  position: relative;
  overflow: visible;
}
.progress-custom .progress-bar:after {
  content: "";
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  box-shadow: 0 0.313rem 0.313rem var(--primary02);
  background: #fff;
  position: absolute;
  inset-inline-end: -0.375rem;
  inset-block-start: -0.375rem;
  border: 0.25rem solid var(--primary-color);
}
.progress-custom .progress-bar.bg-secondary:after {
  border: 0.25rem solid rgb(var(--secondary-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--secondary-rgb), 0.2);
}
.progress-custom .progress-bar.bg-warning:after {
  border: 0.25rem solid rgb(var(--warning-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--warning-rgb), 0.2);
}
.progress-custom .progress-bar.bg-info:after {
  border: 0.25rem solid rgb(var(--info-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--info-rgb), 0.2);
}
.progress-custom .progress-bar.bg-success:after {
  border: 0.25rem solid rgb(var(--success-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--success-rgb), 0.2);
}
.progress-custom .progress-bar.bg-danger:after {
  border: 0.25rem solid rgb(var(--danger-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--danger-rgb), 0.2);
}
.progress-custom .progress-bar-title {
  padding: 0.313rem 0.625rem;
  margin: 0;
  background: var(--primary-color);
  border-radius: 0.313rem 0 0 0.313rem;
  font-size: 0.625rem;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  inset-block-start: -0.438rem;
  inset-inline-start: 0;
  z-index: 1;
}
.progress-custom .progress-bar-title:after {
  content: "";
  border-inline-start: 1.063rem solid var(--primary-color);
  border-block-start: 0.688rem solid transparent;
  border-block-end: 0.688rem solid transparent;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: -1.063rem;
}
.progress-custom .progress-bar-title.bg-secondary {
  background-color: rgb(var(--secondary-rgb));
}
.progress-custom .progress-bar-title.bg-secondary:after {
  border-inline-start: 1.063rem solid rgb(var(--secondary-rgb));
}
.progress-custom .progress-bar-title.bg-success {
  background-color: rgb(var(--success-rgb));
}
.progress-custom .progress-bar-title.bg-success:after {
  border-inline-start: 1.063rem solid rgb(var(--success-rgb));
}
.progress-custom .progress-bar-title.bg-warning {
  background-color: rgb(var(--warning-rgb));
}
.progress-custom .progress-bar-title.bg-warning:after {
  border-inline-start: 1.063rem solid rgb(var(--warning-rgb));
}
.progress-custom .progress-bar-title.bg-danger {
  background-color: rgb(var(--danger-rgb));
}
.progress-custom .progress-bar-title.bg-danger:after {
  border-inline-start: 1.063rem solid rgb(var(--danger-rgb));
}
.progress-custom .progress-bar-title.bg-info {
  background-color: rgb(var(--info-rgb));
}
.progress-custom .progress-bar-title.bg-info:after {
  border-inline-start: 1.063rem solid rgb(var(--info-rgb));
}
.progress-custom .progress-bar-value {
  width: 1.875rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 0.188rem;
  background: var(--primary-color);
  box-shadow: 0 0.313rem 0.313rem rgba(0, 0, 0, 0.4);
  font-size: 0.625rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  position: absolute;
  inset-block-end: 1.5rem;
  inset-inline-end: -0.688rem;
}
.progress-custom .progress-bar-value:after {
  content: "";
  border-block-start: 0.438rem solid var(--primary-color);
  border-inline-start: 0.438rem solid transparent;
  border-inline-end: 0.438rem solid transparent;
  position: absolute;
  inset-block-end: -0.375rem;
  inset-inline-start: 28%;
}
.progress-custom .progress-bar-value.bg-secondary {
  background-color: rgb(var(--secondary-rgb));
}
.progress-custom .progress-bar-value.bg-secondary:after {
  border-block-start: 0.438rem solid rgb(var(--secondary-rgb));
}
.progress-custom .progress-bar-value.bg-success {
  background-color: rgb(var(--success-rgb));
}
.progress-custom .progress-bar-value.bg-success:after {
  border-block-start: 0.438rem solid rgb(var(--success-rgb));
}
.progress-custom .progress-bar-value.bg-warning {
  background-color: rgb(var(--warning-rgb));
}
.progress-custom .progress-bar-value.bg-warning:after {
  border-block-start: 0.438rem solid rgb(var(--warning-rgb));
}
.progress-custom .progress-bar-value.bg-danger {
  background-color: rgb(var(--danger-rgb));
}
.progress-custom .progress-bar-value.bg-danger:after {
  border-block-start: 0.438rem solid rgb(var(--danger-rgb));
}
.progress-custom .progress-bar-value.bg-info {
  background-color: rgb(var(--info-rgb));
}
.progress-custom .progress-bar-value.bg-info:after {
  border-block-start: 0.438rem solid rgb(var(--info-rgb));
}
[dir=rtl] .progress-bar-title {
  border-radius: 0 0.313rem 0.313rem 0;
}
.progress-item-1,
.progress-item-2,
.progress-item-3 {
  position: absolute;
  margin-block-start: -0.25rem;
  z-index: 1;
  height: 0.938rem;
  width: 0.938rem;
  border-radius: 1.563rem;
  background-color: rgb(var(--light-rgb));
}
.progress-item-1 {
  inset-inline-start: 25%;
}
.progress-item-2 {
  inset-inline-start: 50%;
}
.progress-item-3 {
  inset-inline-start: 75%;
}
.custom-progress-3 {
  overflow: visible;
  border-radius: 0.625rem;
}
.custom-progress-3 .progress-bar {
  position: relative;
  overflow: visible;
  margin: 0.313rem;
  border-radius: 0.25rem;
}
.custom-progress-3 .progress-bar:before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 0.25rem;
  inset-inline-start: 0.125rem;
  background-color: var(--custom-white);
  border-radius: 50%;
}
.custom-progress-3 .progress-bar .progress-bar-value {
  border: 0.125rem solid var(--primary-color);
  width: 2.5rem;
  height: 1.563rem;
  line-height: 1.313rem;
  border-radius: 3.125rem;
  background: var(--custom-white);
  font-size: 0.625rem;
  font-weight: 400;
  color: var(--primary-color);
  text-align: center;
  position: absolute;
  inset-inline-end: -0.625rem;
}
.custom-progress-3 .progress-bar .progress-bar-value.secondary {
  border: 0.125rem solid rgb(var(--secondary-rgb));
  color: rgb(var(--secondary-rgb));
}
.custom-progress-3 .progress-bar .progress-bar-value.warning {
  border: 0.125rem solid rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}
.custom-progress-3 .progress-bar .progress-bar-value.info {
  border: 0.125rem solid rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}
.custom-progress-3 .progress-bar .progress-bar-value.success {
  border: 0.125rem solid rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}
.custom-progress-3 .progress-bar .progress-bar-value.danger {
  border: 0.125rem solid rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}
.custom-progress-4.progress {
  background-color: var(--primary01);
  border-radius: 0.25rem;
}
.custom-progress-4.progress.secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.custom-progress-4.progress.secondary .progress-bar-label {
  color: rgb(var(--secondary-rgb));
}
.custom-progress-4.progress.warning {
  background-color: rgba(var(--warning-rgb), 0.1);
}
.custom-progress-4.progress.warning .progress-bar-label {
  color: rgb(var(--warning-rgb));
}
.custom-progress-4.progress.info {
  background-color: rgba(var(--info-rgb), 0.1);
}
.custom-progress-4.progress.info .progress-bar-label {
  color: rgb(var(--info-rgb));
}
.custom-progress-4.progress.success {
  background-color: rgba(var(--success-rgb), 0.1);
}
.custom-progress-4.progress.success .progress-bar-label {
  color: rgb(var(--success-rgb));
}
.custom-progress-4.progress.danger {
  background-color: rgba(var(--danger-rgb), 0.1);
}
.custom-progress-4.progress.danger .progress-bar-label {
  color: rgb(var(--danger-rgb));
}
.custom-progress-4.progress .progress-bar {
  border-radius: 0.25rem;
}
.custom-progress-4.progress .progress-bar-label {
  position: absolute;
  inset-inline-end: 0.313rem;
  font-weight: 400;
  font-size: 0.813rem;
  color: var(--primary-color);
}
caption {
  color: var(--text-muted);
  font-weight: 700;
}
.table {
  --bs-table-bg: var(--custom-white);
  color: var(--default-text-color);
  border-color: var(--default-border);
  margin-block-end: 0;
}
.table tbody tr th {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.85rem 1rem;
  vertical-align: middle;
  line-height: 1.462;
  font-size: 0.813rem;
  font-weight: 400;
}
.table thead tr th {
  font-weight: 500;
  font-size: 0.85rem;
}
.table.table-sm > :not(caption) > * > * {
  padding: 0.3rem;
}
.table.table-dark {
  color: var(--white-7);
  border-color: rgba(255, 255, 255, 0.1);
}
.table.table-primary {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.2);
}
.table.table-primary > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  color: #000;
}
.table.table-primary.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.2);
  color: #000;
}
.table.table-primary.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.4);
  color: #fff;
}
.table.table-primary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--primary-rgb));
  color: #fff;
}
.table.table-secondary {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.2);
}
.table.table-secondary > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  color: #000;
}
.table.table-secondary.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.2);
  color: #000;
}
.table.table-secondary.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.4);
  color: #fff;
}
.table.table-secondary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--secondary-rgb));
  color: #fff;
}
.table.table-warning {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.2);
}
.table.table-warning > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  color: #000;
}
.table.table-warning.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.2);
  color: #000;
}
.table.table-warning.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.4);
  color: #fff;
}
.table.table-warning .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--warning-rgb));
  color: #fff;
}
.table.table-success {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.2);
}
.table.table-success > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  color: #000;
}
.table.table-success.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.2);
  color: #000;
}
.table.table-success.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.4);
  color: #fff;
}
.table.table-success .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--success-rgb));
  color: #fff;
}
.table.table-danger {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.2);
}
.table.table-danger > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  color: #000;
}
.table.table-danger.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.2);
  color: #000;
}
.table.table-danger.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.4);
  color: #fff;
}
.table.table-danger .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--danger-rgb));
  color: #fff;
}
.table.table-info {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.2);
}
.table.table-info > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  color: #000;
}
.table.table-info.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.2);
  color: #000;
}
.table.table-info.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.4);
  color: #fff;
}
.table.table-info .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--info-rgb));
  color: #fff;
}
.table.table-light {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.2);
}
.table.table-light > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  color: #000;
}
.table.table-light.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.2);
  color: #000;
}
.table.table-light.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.4);
  color: #fff;
}
.table.table-light .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--light-rgb));
  color: #fff;
}
.table.table-dark {
  background-color: #fff;
  color: #000;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.2);
}
.table.table-dark > :not(caption) > * > * {
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  color: #000;
}
.table.table-dark.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.2);
  color: #000;
}
.table.table-dark.table.table-hover > tbody > tr:hover > * {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.4);
  color: #fff;
}
.table.table-dark .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--dark-rgb));
  color: #fff;
}
.table.table-bordered.border-primary tbody,
.table.table-bordered.border-primary td,
.table.table-bordered.border-primary tfoot,
.table.table-bordered.border-primary th,
.table.table-bordered.border-primary thead,
.table.table-bordered.border-primary tr {
  border-color: rgba(var(--primary-rgb), 0.1);
}
.table.table-bordered.border-info tbody,
.table.table-bordered.border-info td,
.table.table-bordered.border-info tfoot,
.table.table-bordered.border-info th,
.table.table-bordered.border-info thead,
.table.table-bordered.border-info tr {
  border-color: rgba(var(--info-rgb), 0.1);
}
.table.table-bordered.border-secondary tbody,
.table.table-bordered.border-secondary td,
.table.table-bordered.border-secondary tfoot,
.table.table-bordered.border-secondary th,
.table.table-bordered.border-secondary thead,
.table.table-bordered.border-secondary tr {
  border-color: rgba(var(--secondary-rgb), 0.1);
}
.table.table-bordered.border-warning tbody,
.table.table-bordered.border-warning td,
.table.table-bordered.border-warning tfoot,
.table.table-bordered.border-warning th,
.table.table-bordered.border-warning thead,
.table.table-bordered.border-warning tr {
  border-color: rgba(var(--warning-rgb), 0.1);
}
.table.table-bordered.border-success tbody,
.table.table-bordered.border-success td,
.table.table-bordered.border-success tfoot,
.table.table-bordered.border-success th,
.table.table-bordered.border-success thead,
.table.table-bordered.border-success tr {
  border-color: rgba(var(--success-rgb), 0.1);
}
.table.table-bordered.border-danger tbody,
.table.table-bordered.border-danger td,
.table.table-bordered.border-danger tfoot,
.table.table-bordered.border-danger th,
.table.table-bordered.border-danger thead,
.table.table-bordered.border-danger tr {
  border-color: rgba(var(--danger-rgb), 0.1);
}
.table.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.08);
  color: var(--default-text-color);
  --bs-table-bg-type: rgba(var(--dark-rgb), 0.08);
}
.table.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.08);
  color: var(--default-text-color);
  --bs-table-bg-type: rgba(var(--dark-rgb), 0.08);
}
.table tbody.table-group-divider {
  border-top: 1px solid var(--default-border);
}
.table.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--default-background);
  --bs-table-bg-state: var(--default-background);
  color: var(--default-text-color);
}
.table .table-active {
  --bs-table-accent-bg: rgb(var(--light-rgb));
  color: var(--default-text-color);
  --bs-table-bg-state: rgb(var(--light-rgb));
}
.table thead.table-primary {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-secondary {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-warning {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-success {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--success-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-danger {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-info {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--info-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-light {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--light-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table thead.table-dark {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color: #000 !important;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1) !important;
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--primary-rgb), 0.1);
  background-color: #fff;
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--secondary-rgb), 0.1);
  background-color: #fff;
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--warning-rgb), 0.1);
  background-color: #fff;
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--success-rgb), 0.1);
  background-color: #fff;
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--info-rgb), 0.1);
  background-color: #fff;
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--danger-rgb), 0.1);
  background-color: #fff;
}
.table-dark {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--dark-rgb), 0.1);
  background-color: #fff;
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--light-rgb), 0.1);
  background-color: #fff;
}
[data-theme-mode=dark] .table-dark {
  border-color: rgba(0, 0, 0, 0.025);
}
[data-theme-mode=dark] div.dtr-modal div.dtr-modal-display {
  background-color: var(--custom-white);
}
[dir=rtl] .table {
  margin-inline-end: 1px;
}
.table > :not(caption) > * > * {
  --bs-body-bg: var(--custom-white);
  color: inherit;
}
.toast {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
}
.toast .toast-header {
  background-color: rgb(var(--light-rgb));
  padding: 0.375rem 0.75rem;
}
.toast .toast-header img {
  width: 1.25rem;
  height: 1.25rem;
}
.toast .toast-header {
  border-bottom: 1px solid var(--default-border);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.toast .toast-header .btn-close {
  margin-inline-end: 0.125rem;
}
.toast .toast-body {
  font-size: 0.8rem;
}
.toast .btn-close {
  font-size: 0.563rem;
}
.toast.colored-toast {
  border: 0 !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.toast.colored-toast .btn-close {
  filter: invert(1);
}
.toast.colored-toast .toast-header {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}
.bd-example-toasts {
  min-height: 15rem;
}
.tooltip.tooltip-primary .tooltip-inner {
  background-color: var(--primary-color);
}
.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-color);
}
.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-color);
}
.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-color);
}
.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: var(--primary-color);
}
.tooltip.tooltip-primary1 .tooltip-inner {
  background-color: var(--primary-tint1-color);
}
.tooltip.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary1.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-tint1-color);
}
.tooltip.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary1.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-tint1-color);
}
.tooltip.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary1.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-tint1-color);
}
.tooltip.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary1.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: var(--primary-tint1-color);
}
.tooltip.tooltip-primary2 .tooltip-inner {
  background-color: var(--primary-tint2-color);
}
.tooltip.tooltip-primary2.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary2.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-tint2-color);
}
.tooltip.tooltip-primary2.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary2.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-tint2-color);
}
.tooltip.tooltip-primary2.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary2.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-tint2-color);
}
.tooltip.tooltip-primary2.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary2.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: var(--primary-tint2-color);
}
.tooltip.tooltip-primary3 .tooltip-inner {
  background-color: var(--primary-tint3-color);
}
.tooltip.tooltip-primary3.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary3.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-tint3-color);
}
.tooltip.tooltip-primary3.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary3.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-tint3-color);
}
.tooltip.tooltip-primary3.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary3.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-tint3-color);
}
.tooltip.tooltip-primary3.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary3.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: var(--primary-tint3-color);
}
.tooltip.tooltip-secondary .tooltip-inner {
  background-color: rgb(var(--secondary-rgb));
}
.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--secondary-rgb));
}
.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--secondary-rgb));
}
.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--secondary-rgb));
}
.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}
.tooltip.tooltip-warning .tooltip-inner {
  background-color: rgb(var(--warning-rgb));
}
.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--warning-rgb));
}
.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--warning-rgb));
}
.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--warning-rgb));
}
.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--warning-rgb));
}
.tooltip.tooltip-info .tooltip-inner {
  background-color: rgb(var(--info-rgb));
}
.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--info-rgb));
}
.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--info-rgb));
}
.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--info-rgb));
}
.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--info-rgb));
}
.tooltip.tooltip-success .tooltip-inner {
  background-color: rgb(var(--success-rgb));
}
.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--success-rgb));
}
.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--success-rgb));
}
.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--success-rgb));
}
.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--success-rgb));
}
.tooltip.tooltip-danger .tooltip-inner {
  background-color: rgb(var(--danger-rgb));
}
.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--danger-rgb));
}
.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--danger-rgb));
}
.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--danger-rgb));
}
.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--danger-rgb));
}
.tooltip.tooltip-dark .tooltip-inner {
  background-color: rgb(var(--dark-rgb));
}
.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--dark-rgb));
}
.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--dark-rgb));
}
.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--dark-rgb));
}
.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--dark-rgb));
}
.tooltip.tooltip-dark .tooltip-inner {
  color: var(--custom-white);
}
.tooltip.tooltip-light .tooltip-inner {
  background-color: rgb(var(--light-rgb));
}
.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--light-rgb));
}
.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--light-rgb));
}
.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--light-rgb));
}
.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--light-rgb));
}
.tooltip.tooltip-light .tooltip-inner {
  color: var(--default-text-color);
}
.authentication {
  min-height: 100vh;
}
.authentication .authentication-brand.desktop-dark {
  display: block;
}
.authentication .form-control {
  padding-inline-end: 2.5rem;
}
.authentication .swiper-button-next,
.authentication .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5) !important;
}
.authentication .swiper-pagination-bullet {
  opacity: 0.1;
}
.authentication .swiper-pagination-bullet-active {
  opacity: 0.5;
}
.authentication .google-svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-inline-end: 0.5rem;
}
.authentication .authentication-barrier {
  position: relative;
}
.authentication .authentication-barrier span {
  position: relative;
  z-index: 2;
}
.authentication .authentication-barrier:before {
  position: absolute;
  content: "";
  width: 45%;
  height: 0.125rem;
  background-image:
          linear-gradient(
                  to left,
                  transparent,
                  rgb(var(--light-rgb)));
  border-radius: 50%;
  inset-inline-end: -35px;
  inset-block-start: 10px;
  z-index: 1;
}
.authentication .authentication-barrier:after {
  position: absolute;
  content: "";
  width: 45%;
  height: 0.125rem;
  background-image:
          linear-gradient(
                  to left,
                  rgb(var(--light-rgb)),
                  transparent);
  border-radius: 50%;
  inset-inline-start: -35px;
  inset-block-start: 10px;
  z-index: 1;
}
.authentication.coming-soon .authentication-cover,
.authentication.under-maintenance .authentication-cover {
  background-image: none;
  background-color: var(--custom-white);
}
.authentication.coming-soon .authentication-cover:before,
.authentication.coming-soon .authentication-cover:after,
.authentication.under-maintenance .authentication-cover:before,
.authentication.under-maintenance .authentication-cover:after {
  display: none;
}
.authentication.coming-soon .authentication-cover .aunthentication-cover-content,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content {
  width: 100%;
  height: 100%;
  padding: 3rem;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}
.authentication.coming-soon .authentication-cover .aunthentication-cover-content:before,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content:after,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:before,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:after {
  display: none;
}
.authentication.coming-soon .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content .under-maintenance-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .under-maintenance-time {
  border: 2px dashed rgba(var(--primary-rgb), 0.2);
}
.authentication.coming-soon .authentication-cover .aunthentication-cover-content .authentication-brand,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .authentication-brand {
  width: auto;
  height: 2rem;
  border: 0;
}
.authentication .coming-soom-image-container img,
.authentication .under-maintenance-image-container img {
  width: 100%;
  height: auto;
}
.authentication .authentication-cover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: rgba(var(--primary-rgb), 0.95);
}
/*.authentication .authentication-cover:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-image: url("media-84-Q72G4JX5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}*/
.authentication .authentication-cover .aunthentication-cover-content {
  width: 75%;
  height: 20rem;
  padding: 2rem;
  position: relative;
}
.authentication.authentication-basic .desktop-dark {
  display: none;
}
.authentication .form-control-lg#one,
.authentication .form-control-lg#two,
.authentication .form-control-lg#three,
.authentication .form-control-lg#four {
  padding-inline: 0;
}
@media (min-width: 992px) {
  .aunthentication-cover-content {
    border-radius: 0.3rem;
  }
}
[data-theme-mode=dark] .authentication.authentication-basic .desktop-white {
  display: block;
}
[data-theme-mode=dark] .authentication.authentication-basic .desktop-logo {
  display: none;
}
[data-theme-mode=dark] .authentication .authentication-brand.desktop-white {
  display: block;
}
[data-theme-mode=dark] .authentication.authentication-cover.desktop-white {
  display: block;
}
.authentication .desktop-logo,
.authentication .desktop-white {
  height: 1.7rem;
  line-height: 1.7rem;
}
.authentication.authentication-basic .desktop-white {
  display: none;
}
.authentication.coming-soon .form-control {
  padding-inline-end: 1rem;
}
.coming-soon-main {
  position: relative;
  background: var(--custom-white);
}
/*.coming-soon-main:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("pattern-2-IKXV2CJT.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}*/
.footer.authentication-footer {
  background-color: transparent;
  box-shadow: none;
  z-index: 100;
  padding-inline-start: 0;
}
.anim,
.anim svg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.anim path {
  stroke-width: 1;
}
.show-password-button {
  position: absolute;
  padding: 0.4rem;
  inset-block-start: 0;
  inset-inline-end: 4px;
}
.authentication.authentication-cover-main .show-password-button {
  padding: 0.65rem;
}
.authentication-background {
  position: relative;
  background-color: var(--primary-color);
}
/*.authentication-background:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("1-6YN2QKZ7.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.15;
}*/
.authentication-cover-image {
  border-radius: 50%;
}
.authentication-cover-icon {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
  border: 0 !important;
}
.authentication-cover-icon:hover,
.authentication-cover-icon:active,
.authentication-cover-icon:focus {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
  border: 0 !important;
}
.authentication.two-step-verification .form-control {
  padding-inline-end: 0.75rem;
}
.authentication-cover .authentication-cover-logo {
  position: absolute;
  top: 30px;
  inset-inline-end: 30px;
}
body {
  font-size: 0.8125rem;
  font-family: var(--default-font-family);
  font-weight: var(--default-font-weight);
  background-color: var(--default-body-bg-color);
  color: var(--default-text-color);
  line-height: 1.5;
  text-align: start;
  overflow-x: clip;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
body ::-webkit-scrollbar {
  width: 0;
  height: 0.25rem;
  -webkit-transition: all ease 0.05s;
  transition: all ease 0.05s;
  background: rgb(var(--light-rgb));
}
body :hover::-webkit-scrollbar-thumb {
  background: var(--input-border);
}
a,
button {
  outline: 0 !important;
}
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
@media (min-width: 992px) {
  .app-content {
    min-height: calc(100vh - 8rem);
    margin-block-end: 0;
    margin-block-start: 4.25rem;
    margin-inline-start: 15rem;
    transition: all 0.05s ease;
  }
}
@media (min-width: 1600px) {
  .main-content {
    padding: 0 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .main-content {
    padding-block-start: 3.75rem;
  }
}
.bd-example > .dropdown-menu {
  position: static;
  display: block;
}
/*.min-w-fit-content {
  min-width: -moz-fit-content;
  min-width: fit-content;
}*/
.scrollspy-example {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}
.scrollspy-example-2 {
  height: 21.875rem;
  overflow: auto;
}
.scrollspy-example-3 {
  height: 13.75rem;
  overflow: auto;
}
.simple-list-example-scrollspy .active {
  background-color: var(--primary-color);
  color: #fff;
}
.scrollspy-example-4 {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}
.carousel-inner {
  border-radius: 0.35rem;
}
.carousel-caption {
  color: #fff;
}
.fixed-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
}
.fixed-bottom {
  position: static;
  margin: 1rem 0rem -1rem -1rem;
}
.sticky-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
}
.bd-example-ratios .ratio {
  display: inline-block;
  width: 10rem;
  color: var(--text-muted);
  background-color: var(--primary01);
  border: var(--default-border);
  border-radius: 0.3rem;
}
@media (min-width: 768px) {
  .bd-example-ratios-breakpoint .ratio-4x3 {
    --bs-aspect-ratio: 50%;
  }
}
.bd-example-ratios-breakpoint .ratio-4x3 {
  width: 15rem;
}
.bd-example-row [class^=col],
.bd-example-cssgrid .grid > * {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
  background-color: rgba(var(--light-rgb), 0.75);
  border: 1px solid var(--default-border);
}
.bd-example-cssgrid .grid > * {
  border-radius: 0.25rem;
}
@media (max-width: 576px) {
  .nesting-grid-reponsive .grid {
    --bs-columns: 1 !important;
  }
  .grid-col-mixed-responsive {
    grid-template-columns: repeat(6, 1fr) !important;
    gap: 10px !important;
  }
}
.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(var(--light-rgb), 0.5);
}
.grid {
  display: grid;
  /*grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);*/
}
.grid .g-start-2 {
  grid-column-start: 2 !important;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4,
.grid .g-col-md-4 {
  grid-column: auto/span 4;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: rgb(var(--light-rgb));
  border-inline-start: 0.25rem solid var(--default-border);
}
.callout-info {
  background-color: rgba(var(--info-rgb), 0.075);
  border-color: rgba(var(--info-rgb), 0.5);
}
.callout-warning {
  background-color: rgba(var(--warning-rgb), 0.075);
  border-color: rgba(var(--warning-rgb), 0.5);
}
.callout-danger {
  background-color: rgba(var(--danger-rgb), 0.075);
  border-color: rgba(var(--danger-rgb), 0.5);
}
.flex-container div {
  background-color: transparent;
  border: 0;
}
.flex-container div > div {
  background-color: rgb(var(--light-rgb));
  border: 1px solid var(--custom-white);
}
.bd-example-position-utils {
  position: relative;
  padding: 2rem;
}
.bd-example-position-utils .position-relative {
  height: 12.5rem;
  background-color: var(--default-background);
}
.bd-example-position-utils .position-absolute {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary01);
  border-radius: 0.375rem;
}
.bd-placeholder-img-lg {
  font-size: 3.5rem;
}
.figure-caption {
  color: var(--text-muted);
}
@media (min-width: 992px) {
  [data-nav-layout=vertical] .footer {
    padding-inline-start: 15rem;
  }
}
.footer {
  border-block-start: 1px solid var(--default-border);
}
.offcanvas {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  border-color: var(--default-border) !important;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#switcher-main-tab {
  border-block-end: 0;
}
#switcher-canvas {
  width: 27.5rem;
}
#switcher-canvas .offcanvas-body {
  padding: 0 0 7rem 0;
}
#switcher-canvas .canvas-footer {
  padding: 0.75rem 1.563rem;
  position: absolute;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--custom-white);
  border-block-start: 1px dashed var(--default-border);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}
#switcher-canvas #switcher-main-tab button.nav-link {
  border-radius: 0;
  color: var(--default-text-color);
  font-weight: 400;
}
#switcher-canvas #switcher-main-tab button.nav-link.active {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.2);
  border-color: transparent;
}
#switcher-canvas #switcher-main-tab button.nav-link:hover {
  border-color: transparent;
}
.switcher-style {
  padding: 0.875rem 1.563rem;
}
.switcher-style h6 {
  margin-block-end: 0.625rem;
}
.switcher-icon i {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.switch-select .form-check-label {
  font-size: 0.813rem;
  font-weight: 400;
}
.switch-select.form-check {
  margin-block-end: 0;
  min-height: auto;
}
.menu-image .bgimage-input {
  width: 3.5rem;
  height: 5.625rem;
  border-radius: 0.3rem;
  border: 0;
}
.menu-image .bgimage-input.form-check-input:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem var(--black-1);
}
/*.menu-image .bgimage-input.bg-img1 {
  background-image: url("bg-img1-5KUQ4VEU.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.menu-image .bgimage-input.bg-img1.form-check-input:checked[type=radio] {
  background-color: unset;
}
/*.menu-image .bgimage-input.bg-img2 {
  background-image: url("bg-img2-5SFAKJMF.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.menu-image .bgimage-input.bg-img2.form-check-input:checked[type=radio] {
  background-color: unset;
}
/*.menu-image .bgimage-input.bg-img3 {
  background-image: url("bg-img3-KUR64AKE.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.menu-image .bgimage-input.bg-img3.form-check-input:checked[type=radio] {
  background-color: unset;
}
/*.menu-image .bgimage-input.bg-img4 {
  background-image: url("bg-img4-DWDYL2DY.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.menu-image .bgimage-input.bg-img4.form-check-input:checked[type=radio] {
  background-color: unset;
}
/*.menu-image .bgimage-input.bg-img5 {
  background-image: url("bg-img5-SWTGIEEB.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.menu-image .bgimage-input.bg-img5.form-check-input:checked[type=radio] {
  background-color: unset;
}
.theme-colors.switcher-style {
  padding: 0.938rem 1.563rem;
}
.theme-colors .switch-select .color-input {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.theme-colors .switch-select .color-input.form-check-input:checked {
  border: 1px solid var(--input-border);
  position: relative;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}
.theme-colors .switch-select .color-input.form-check-input:checked:before {
  position: absolute;
  content: "\ea5e";
  font-family: tabler-icons !important;
  color: rgb(var(--success-rgb));
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
  font-weight: 400;
}
.theme-colors .switch-select .color-input.color-white {
  background-color: #fff;
}
.theme-colors .switch-select .color-input.color-dark {
  background-color: #000;
}
.theme-colors .switch-select .color-input.color-primary {
  background-color: var(--primary-color);
}
.theme-colors .switch-select .color-input.color-primary-1 {
  background-color: rgb(118, 71, 229);
}
.theme-colors .switch-select .color-input.color-primary-2 {
  background-color: rgb(63, 75, 236);
}
.theme-colors .switch-select .color-input.color-primary-3 {
  background-color: rgb(55, 125, 206);
}
.theme-colors .switch-select .color-input.color-primary-4 {
  background-color: rgb(1, 159, 162);
}
.theme-colors .switch-select .color-input.color-primary-5 {
  background-color: rgb(139, 149, 4);
}
.theme-colors .switch-select .color-input.color-gradient {
  background-image:
          linear-gradient(
                  to bottom right,
                  var(--primary-color) 0%,
                  rgb(var(--secondary-rgb)) 100%);
}
/*.theme-colors .switch-select .color-input.color-transparent {
  background-image: url("transparent-YCQASJQ4.png") !important;
}*/
.theme-colors .switch-select .color-input.color-bg-1 {
  background-color: rgb(12, 23, 91);
}
.theme-colors .switch-select .color-input.color-bg-2 {
  background-color: rgb(50, 11, 110);
}
.theme-colors .switch-select .color-input.color-bg-3 {
  background-color: rgb(8, 81, 113);
}
.theme-colors .switch-select .color-input.color-bg-4 {
  background-color: rgb(3, 81, 60);
}
.theme-colors .switch-select .color-input.color-bg-5 {
  background-color: rgb(73, 78, 1);
}
.theme-colors .switch-select .form-check-input:checked[type=radio] {
  background-image: none;
}
.theme-colors .switch-select .form-check-input:focus {
  box-shadow: none;
}
.theme-colors .switch-select .form-check-input:active {
  filter: brightness(100%);
}
.switcher-style-head {
  font-size: 0.8rem;
  font-weight: 500;
  margin-block-end: 0;
  background: rgb(var(--light-rgb));
  padding: 0.313rem 0.625rem;
  color: var(--default-text-color);
}
.switcher-style-head .switcher-style-description {
  float: right;
  font-size: 0.625rem;
  background-color: rgba(var(--secondary-rgb), 0.2);
  color: rgb(var(--secondary-rgb));
  padding: 0.125rem 0.313rem;
  border-radius: 0.3rem;
}
#switcher-home,
#switcher-profile {
  padding: 0;
}
.theme-container-primary button,
.theme-container-background button {
  display: none;
}
.pickr-container-primary .pickr .pcr-button,
.pickr-container-background .pickr .pcr-button {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid var(--input-border);
}
.pickr-container-primary .pickr .pcr-button:focus,
.pickr-container-background .pickr .pcr-button:focus {
  box-shadow: none;
}
.pickr-container-primary .pickr .pcr-button::after,
.pickr-container-background .pickr .pcr-button::after {
  content: "\efc5";
  font-family: remixicon !important;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  font-size: 1.25rem;
}
.card {
  background-color: var(--custom-white);
  border: 1px solid var(--bootstrap-card-border);
}
.img-thumbnail {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}
.apexcharts-title-text {
  fill: var(--text-muted);
}
@media (max-width: 991.98px) {
  .navigation-menu-styles {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .cart-dropdown,
  .notifications-dropdown,
  .header-fullscreen {
    display: none !important;
  }
}
.btn-close:focus {
  box-shadow: none !important;
}
.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.icons-list .icons-list-item {
  text-align: center;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--default-border);
  margin: 0.25rem;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}
.icons-list .icons-list-item i {
  font-size: 1.05rem;
  color: var(--default-text-color);
}
.fe {
  font-size: inherit !important;
}
.bd-placeholder-img {
  margin: 0.125rem;
}
.shadow-primary {
  box-shadow: 0 4px 12px rgba(var(--primary-rgb), 0.3) !important;
}
.shadow-primary1 {
  box-shadow: 0 4px 12px rgba(var(--primary-tint1-rgb), 0.3) !important;
}
.shadow-primary2 {
  box-shadow: 0 4px 12px rgba(var(--primary-tint2-rgb), 0.3) !important;
}
.shadow-primary3 {
  box-shadow: 0 4px 12px rgba(var(--primary-tint3-rgb), 0.3) !important;
}
.shadow-secondary {
  box-shadow: 0 4px 12px rgba(var(--secondary-rgb), 0.3) !important;
}
.shadow-success {
  box-shadow: 0 4px 12px rgba(var(--success-rgb), 0.3) !important;
}
.shadow-info {
  box-shadow: 0 4px 12px rgba(var(--info-rgb), 0.3) !important;
}
.shadow-warning {
  box-shadow: 0 4px 12px rgba(var(--warning-rgb), 0.3) !important;
}
.shadow-danger {
  box-shadow: 0 4px 12px rgba(var(--danger-rgb), 0.3) !important;
}
.shadow-orange {
  box-shadow: 0 4px 12px rgba(var(--orange-rgb), 0.3) !important;
}
.shadow-purple {
  box-shadow: 0 4px 12px rgba(var(--purple-rgb), 0.3) !important;
}
.placeholder-xl {
  min-height: 1.5em;
}
.placeholder {
  background-color: var(--gray-7);
}
.scrollspy-example-2 {
  height: 21.875rem;
  border: 1px solid var(--default-border);
  padding: 0.75rem;
  border-radius: 0.3rem;
}
.object-fit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.object-fit-container img,
.object-fit-container video {
  width: 15.625rem;
  height: 15.625rem;
}
.invoice-amount-input {
  width: 9.375rem;
}
.choices-control .choices__inner {
  background-color: rgb(var(--light-rgb)) !important;
  border: 0 !important;
}
.svg-icon-background {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-icon-background svg {
  width: 1.25rem;
  height: 1.25rem;
}
.invoice-quantity-container {
  width: 8.75rem;
}
.pricing-card:hover,
.pricing-card.hover {
  box-shadow: 0px 7px 28px 0px rgba(100, 100, 110, 0.1);
}
.pricing-table-item-icon {
  font-size: 15px;
  height: 25px;
  border-radius: 4px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  inset-block-start: -9px;
  inset-inline-start: auto;
  inset-inline-end: 16px;
  background-color: var(--primary-tint1-color);
  color: #fff;
}
.pricing-body {
  margin-block-end: 0;
}
.pricing-body li {
  margin-block-end: 1rem;
}
.pricing-body li:last-child {
  margin-block-end: 0;
}
.pricing-card .ribbon-2.ribbon-right {
  inset-block-start: 20px;
}
.pricing-card .ribbon-2.ribbon-right:before {
  border-inline-end: 9px solid transparent;
}
.switcher-box {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-block-end: 1rem;
}
.switcher-box .pricing-time-span {
  padding: 0 12px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
}
.switcher-pricing .pricing-toggle:checked:after {
  transform: translatex(28px);
}
[dir=rtl] .switcher-pricing .pricing-toggle:checked:after {
  transform: translatex(-28px);
}
.switcher-pricing .pricing-toggle {
  width: 59px;
  height: 30px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 2px solid var(--default-border);
  transition: all 0.2s ease;
  border-radius: 43px;
  background-color: var(--primary-color);
}
.switcher-pricing .pricing-toggle:after {
  content: "";
  position: absolute;
  inset-block-start: 3px;
  inset-inline-start: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.25s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}
.switcher-pricing input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
#convertable-pricing .tab-content {
  display: none;
}
#convertable-pricing .tab-content.show {
  display: block;
}
.team-member {
  z-index: 0;
  overflow: hidden;
}
.team-member .avatar.avatar-xl {
  border-radius: 50%;
}
.task-navigation ul.task-main-nav li {
  padding: 0.55rem 0.8rem;
  border-radius: var(--default-border);
  border-radius: 0.3rem;
  font-weight: 400;
}
.task-navigation ul.task-main-nav li:hover a {
  color: var(--primary-color);
}
.task-navigation ul.task-main-nav li.active {
  background-color: var(--primary005);
  border-radius: 0.3rem;
}
.task-navigation ul.task-main-nav li.active div {
  color: var(--primary-color);
}
th.todolist-progress {
  width: 150px;
}
.todolist-handle-drag {
  width: 50px;
}
.todo-box {
  touch-action: none;
}
button.btn.todo-handle {
  cursor: move;
}
.todo-list-card {
  z-index: 0;
  position: relative;
}
/*.todo-list-card:before {
  position: absolute;
  content: "";
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-image: url("media-67-X6NWQLGT.png");
  opacity: 0.05;
  background-repeat: no-repeat;
  z-index: -1;
}*/
.terms-list > li {
  margin-block-end: 1.35rem;
}
.terms-list > li:last-child {
  margin-block-end: 0;
}
.terms-list > li li {
  margin-block-end: 0.5rem;
}
.terms-list > li li:last-child {
  margin-block-end: 0;
}
.faq-accordion.accordion.accordion-primary .accordion-button.collapsed {
  color: var(--default-text-color);
}
.faq-accordion.accordion.accordion-primary .accordion-button.collapsed:after {
  color: var(--text-muted);
  font-size: 0.8125rem;
}
.faq-accordion .accordion-body {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  font-size: 0.8125rem;
}
.faq-accordion .accordion-button {
  font-weight: 600;
}
.faq-nav.nav-tabs-header .nav-item .nav-link {
  background-color: rgb(var(--light-rgb));
}
.timeline,
.timeline .timeline-container {
  position: relative;
  width: 100%;
}
.timeline .timeline-end {
  position: relative;
  margin-inline-start: -16px;
  margin-block-start: 3px;
}
.timeline .timeline-end .avatar {
  display: inline-block;
}
.timeline .timeline-content {
  margin-inline-start: 4rem;
  margin-inline-end: 6%;
  position: relative;
}
.timeline .timeline-content::after {
  display: block;
  content: "";
  width: 12px;
  position: absolute;
  inset-block-start: 0.1875rem;
  height: 12px;
  background-color: var(--custom-white);
  border: 3px solid var(--primary-color);
  z-index: 1;
  border-radius: 50%;
  inset-inline-start: -2.83rem;
  box-shadow: 0px 0px 1px 3px var(--primary03);
}
.timeline .timeline-continue {
  position: relative;
  padding-block-start: 2rem;
  width: 100%;
}
.timeline .timeline-continue::after {
  content: "";
  position: absolute;
  height: 100%;
  inset-inline-start: 24px;
  inset-block-start: 2px;
  margin-inline-start: -1px;
  border-inline-start: 1px dashed var(--primary04);
}
.timeline .timeline-date {
  font-size: 0.875rem;
  position: relative;
}
.timeline .timeline-box {
  position: relative;
  padding: 1rem;
  margin-block-end: 15px;
  border-radius: 6px;
  border: 1px solid var(--default-border);
  background: var(--custom-white);
  box-shadow: 0px 2px 0px rgba(118, 138, 254, 0.03);
}
.timeline .timeline-box .timeline-text {
  position: relative;
}
.timeline .timeline-right .timeline-time {
  text-align: end;
}
@media (max-width: 1199.98px) {
  .timeline-2 .notification .notification-time {
    margin-block-start: 0.35rem;
    margin-block-end: 0.35rem;
  }
  .timeline-2 .notification .notification-body {
    margin-inline-end: 0;
  }
  .timeline-2 .notification > li {
    padding: 9px 0;
  }
  .timeline-2 .notification {
    padding-inline-start: 2.5rem !important;
  }
  .timeline-2 .notification .notification-time.content-end {
    margin-inline-end: 0;
  }
  .timeline-2 .notification .notification-icon {
    inset-inline-end: auto;
    inset-inline-start: -7.5%;
  }
  .timeline-2 .notification:before {
    inset-inline-start: 2.05%;
  }
}
@media (max-width: 1365px) {
  .timeline-3 .timeline-steps .content-top {
    margin-block-start: 0;
  }
}
@media (min-width: 991.98px) and (max-width: 1150px) {
  .timeline-2 .notification:before {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .timeline-2 .notification .notification-icon {
    inset-inline-start: -9%;
  }
}
@media (max-width: 767.98px) {
  .timeline .timeline-continue::after {
    inset-inline-start: 1.5rem;
  }
  .timeline .row.timeline-right .timeline-date::after {
    inset-inline-start: -2.4rem;
  }
  .timeline .row.timeline-right .timeline-date,
  .timeline .row.timeline-right .timeline-time {
    text-align: start;
  }
  .timeline .timeline-end {
    text-align: start;
  }
  .timeline .timeline-date {
    margin-block-start: 0;
  }
  .timeline-2 .notification .notification-icon {
    inset-inline-start: -3.7rem;
  }
  .timeline-2 .notification:before {
    inset-inline-start: 0.4rem;
  }
}
[dir=rtl] .timeline .row.timeline-right .timeline-box::after {
  border-color: transparent transparent transparent var(--custom-white);
}
[dir=rtl] .timeline .row.timeline-right .timeline-box::before {
  border-color: transparent transparent transparent var(--default-border);
}
[dir=rtl] .timeline .timeline-box::before {
  border-color: transparent var(--default-border) transparent transparent;
}
[dir=rtl] .timeline .timeline-box::after {
  border-color: transparent var(--custom-white) transparent transparent;
}
.notification {
  list-style-type: none;
  padding: 0;
  position: relative;
}
.notification:before {
  content: "";
  position: absolute;
  inset-block-start: 55px;
  inset-block-end: 64px;
  border-inline-start: 1px dashed var(--primary04);
  inset-inline-start: 49.05%;
  margin-inline-start: -1px;
}
.notification > li {
  position: relative;
  min-height: 50px;
  padding: 12px 0;
}
.notification .notification-time {
  margin-block: 2.1rem;
}
.notification .notification-time.content-end {
  text-align: end;
  margin-inline-end: 2.1rem;
  justify-content: end;
}
.notification .notification-time .date,
.notification .notification-time .time {
  display: block;
  font-weight: 500;
}
.notification .notification-time .date {
  line-height: 16px;
  color: var(--text-muted);
}
.notification .notification-time .time {
  line-height: 24px;
  font-size: 18px;
  color: var(--text-muted);
}
.notification .notification-icon {
  inset-inline-end: 46%;
  position: absolute;
  width: 10%;
  text-align: center;
  inset-block-start: 51px;
}
.notification .notification-icon a {
  text-decoration: none;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 50%;
  background: var(--custom-white);
  line-height: 10px;
  color: var(--custom-white);
  font-size: 14px;
  border: 3px solid var(--primary-color);
  transition: border-color 0.2s linear;
}
.notification .notification-icon a.primary1 {
  border-color: var(--primary-tint1-color);
}
.notification .notification-icon a.primary2 {
  border-color: var(--primary-tint2-color);
}
.notification .notification-icon a.primary3 {
  border-color: var(--primary-tint3-color);
}
.notification .notification-icon a.warning {
  border-color: rgb(var(--warning-rgb));
}
.notification .notification-icon a.secondary {
  border-color: rgb(var(--secondary-rgb));
}
.notification .notification-icon a.success {
  border-color: rgb(var(--success-rgb));
}
.notification .notification-icon a.info {
  border-color: rgb(var(--info-rgb));
}
.notification .notification-body {
  margin-inline-start: 0;
  margin-inline-end: 4%;
  background: var(--custom-white);
  border: 1px solid var(--default-border);
  box-shadow: 0px 2px 0px rgba(118, 138, 254, 0.03);
  position: relative;
  padding: 1rem;
  border-radius: 6px;
}
.notification .notification-body.notification-body-end {
  margin-inline-end: 0;
}
.notification .notification-body > div + div {
  margin-block-start: 15px;
}
.timeline .profile-activity-media img {
  width: 4rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin: 0.25rem;
}
@media (max-width: 576px) {
  .notification .notification-body:before {
    display: none;
  }
  .notification .notification-icon a {
    display: none;
  }
  .notification:before {
    display: none;
  }
  .notification-body .media {
    flex-direction: column;
  }
  .notification-body .media .main-img-user {
    margin-block-end: 10px !important;
  }
  .notification .notification-body {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    position: relative;
  }
  .notification-badge {
    position: absolute;
    inset-inline-start: 10px;
    inset-block-start: 8px;
  }
  .notification .notification-time .date,
  .notification .notification-time .time {
    display: inline;
  }
  .notification .notification-time .time {
    line-height: 16px;
    font-size: 11px;
    margin-inline-start: 5px;
    margin-inline-end: 10px;
    color: var(--text-muted);
  }
}
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.timeline-steps .content-top {
  margin-block-start: -10.25rem;
}
.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}
@media (min-width: 576px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-block-start: 0.125rem dotted rgba(var(--secondary-rgb), 0.2);
    width: 6.46rem;
    position: absolute;
    inset-inline-start: 8rem;
    inset-block-start: 0.35rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-block-start: 0.125rem dotted rgba(var(--secondary-rgb), 0.2);
    width: 5.6875rem;
    position: absolute;
    inset-inline-end: 8rem;
    inset-block-start: 0.35rem;
  }
}
.timeline-steps .timeline-content {
  width: 13rem;
  text-align: center;
}
.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 0.5rem;
  width: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
}
.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: rgba(var(--primary-rgb), 0.15);
  display: inline-block;
  height: 1rem;
  width: 2rem;
  min-width: 3rem;
  border-radius: 6.25rem;
}
.popular-blog-content {
  max-width: 14rem;
}
.blog-popular-tags .badge {
  margin: 0.313rem;
  font-size: 0.65rem;
}
#blog-details-comment-list {
  max-height: 265px;
}
.blog-images-container .filepond--root {
  width: 100%;
}
.blog-images-container .filepond--panel-root {
  border-color: var(--input-border) !important;
  border-radius: 0.3rem;
}
.blog-images-container .filepond--root .filepond--drop-label label {
  color: var(--text-muted);
}
#blog-content {
  height: auto;
}
@media screen and (max-width: 400px) {
  .choices__inner .choices__list--multiple .choices__item {
    margin-block-end: 0.25rem !important;
  }
}
@media screen and (max-width: 991px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-block-end: 0.25rem !important;
  }
}
.profile-card .avatar.avatar-xxl img {
  border: 4px solid rgba(var(--primary-tint1-rgb), 0.3);
}
.profile-card {
  overflow: hidden;
}
.profile-card .profile-banner-img {
  position: relative;
}
.profile-card .profile-banner-img::before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary03);
}
@media (max-width: 576px) {
  .profile-card .profile-content {
    margin-block-start: -3rem;
  }
}
.profile-content {
  margin-block-start: -5rem;
}
.profile-timeline {
  margin-block-end: 0;
}
.profile-timeline li {
  padding-inline-start: 3rem;
  position: relative;
  margin-block-end: 1.85rem;
}
.profile-timeline li .profile-timeline-avatar {
  position: absolute;
  inset-inline-start: 0.125rem;
  inset-block-start: 0;
}
.profile-timeline li:last-child {
  margin-block-end: 0;
}
.profile-timeline li:last-child::before {
  display: none;
}
.profile-timeline li::before {
  content: "";
  background-color: transparent;
  border: 1px dashed rgba(var(--dark-rgb), 0.1);
  height: 100%;
  position: absolute;
  inset-inline-start: 0.813rem;
  inset-block-start: 1.813rem;
}
.profile-timeline .profile-activity-media {
  background-color: rgb(var(--light-rgb));
  padding: 0.3rem;
  border-radius: 0.5rem;
}
.profile-timeline .profile-activity-media img {
  width: 4rem;
  height: 3rem;
  border-radius: 0.3rem;
  margin: 0.25rem;
}
.profile-settings-tab.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link {
  border: 0;
}
.profile-settings-tab.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link.active {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  border: 0;
}
.profile-settings-tab.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link.active:before {
  inset-block-end: 1px;
}
.profile-settings-tab.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link:hover,
.profile-settings-tab.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link:focus {
  border: 0;
}
[dir=rtl] .fullcalendar-events-activity li {
  padding: 0.25rem 2rem 0.25rem 1rem;
}
.fullcalendar-events-activity li {
  margin-block-end: 0.75rem;
  font-size: 0.8125rem;
  padding-block-start: 0.25rem;
  padding-inline-end: 0px;
  padding-block-end: 0.25rem;
  padding-inline-start: 2rem;
  position: relative;
}
.fullcalendar-events-activity li::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid var(--primary-color);
  border-radius: 3.125rem;
  background-color: var(--custom-white);
  inset-inline-start: 0.1rem;
  inset-block-start: 0.563rem;
}
.fullcalendar-events-activity li::after {
  position: absolute;
  content: "";
  height: 100%;
  background-color: transparent;
  border-inline-end: 2px dashed var(--primary01);
  inset-inline-start: 0.4rem;
  inset-block-start: 1.25rem;
}
.fullcalendar-events-activity li:last-child {
  margin-block-end: 0;
}
.fullcalendar-events-activity li:last-child::after {
  border-inline-end: 0px dashed var(--default-border);
}
#full-calendar-activity {
  max-height: 24rem;
}
#draggable-left .card,
#draggable-right .card {
  cursor: move;
}
.scrollToTop {
  background-color: var(--primary01);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  position: fixed;
  inset-block-end: 20px;
  inset-inline-end: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
  z-index: 10000;
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  border-radius: 0.3rem;
  box-shadow: none;
}
[dir=rtl] .rtl-rotate {
  transform: rotate(180deg);
}
.project-list-title {
  max-width: 13.375rem;
}
#project-descriptioin-editor {
  height: 200px;
  overflow: auto;
}
.project-list-main .choices__inner {
  width: 150px !important;
}
.project-list-description {
  max-width: 350px;
  min-width: 350px;
  white-space: pre-wrap !important;
}
.swiper-related-jobs .swiper-button-next,
.swiper-related-jobs .swiper-button-prev {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color) !important;
}
.card.custom-card.job-info-banner {
  background-color: var(--primary-color);
  z-index: 0;
  height: 10rem;
}
/*.card.custom-card.job-info-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: repeat;
  background-image: url("media-69-H2WJXLHF.jpg");
  z-index: -1;
  opacity: 0.3;
}*/
.card.custom-card.job-info-data {
  margin-block-start: -5rem;
  width: auto;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
}
.swiper-vertical.swiper-related-jobs {
  height: 38rem;
}
@media (max-width: 349px) {
  .swiper-vertical.swiper-related-jobs {
    height: 32rem;
  }
}
@media screen and (min-width: 623px) {
  .input-group.companies-search-input .choices {
    margin-block-end: 0;
    border: 1px solid var(--input-border);
  }
  .input-group.companies-search-input .choices__inner {
    border: 0 !important;
    border-radius: 0 !important;
    min-height: 100% !important;
  }
  .input-group.companies-search-input .choices__list--dropdown .choices__item--selectable {
    padding-inline-end: 1rem;
  }
  [dir=rtl] .input-group.companies-search-input .choices__inner {
    border-radius: 0 !important;
  }
}
@media screen and (max-width: 622px) {
  .input-group.companies-search-input {
    display: block;
  }
  .input-group.companies-search-input .form-control {
    width: 100% !important;
    border-radius: 0.3rem !important;
    margin-block-end: 0.5rem;
  }
  .input-group.companies-search-input .choices {
    border-radius: 0.3rem !important;
    margin-block-end: 0.5rem;
  }
  .input-group.companies-search-input .choices .choices__inner {
    border-radius: 0.3rem !important;
  }
  .input-group.companies-search-input .btn {
    width: 100% !important;
    border-radius: 0.3rem !important;
    z-index: 0;
  }
}
.companies-search-input .choices__list.choices__list--single {
  line-height: 2.25;
}
.companies-search-input1 .choices {
  flex: 1 1 auto !important;
}
.list-bullets li {
  position: relative;
  margin-block-end: -1px;
  border: 1px solid var(--default-border);
  list-style-type: circle;
  list-style-position: inside;
  padding: 0.75rem 1.25rem;
}
.swiper-vertical.swiper-related-profiles {
  height: 39.75rem;
}
@media (min-width: 1400px) and (max-width: 1433px) {
  .swiper-vertical.swiper-related-profiles {
    height: 35.75rem;
  }
}
@media (max-width: 398px) {
  .swiper-vertical.swiper-related-profiles {
    height: 16rem;
  }
}
.job-candidate-details {
  overflow: hidden;
  z-index: 0;
}
.job-candidate-details .candidate-bg-shape {
  position: absolute;
  width: 100%;
  height: 115px;
  inset-inline-start: 0px;
  z-index: -1;
  opacity: 0.94;
  background: url("../images/dashboard/media-70-YRXV2UKN.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#offcanvasExample {
  border-color: transparent !important;
}
#leads-discovered .card.custom-card {
  border-block-start: 3px solid rgba(var(--primary-rgb), 0.18) !important;
}
#leads-discovered .card.custom-card .company-name {
  color: var(--primary-color);
}
#leads-discovered .card.custom-card .deal-description {
  background: var(--primary005);
}
#leads-discovered .card.custom-card .avatar {
  background-color: var(--primary-color);
}
#leads-qualified .card.custom-card {
  border-block-start: 3px solid rgba(var(--primary-tint1-rgb), 0.18) !important;
}
#leads-qualified .card.custom-card .company-name {
  color: var(--primary-tint1-color);
}
#leads-qualified .card.custom-card .deal-description {
  background: rgba(var(--primary-tint1-rgb), 0.05);
}
#leads-qualified .card.custom-card .avatar {
  background-color: var(--primary-tint1-color);
}
#contact-initiated .card.custom-card {
  border-block-start: 3px solid rgba(var(--primary-tint2-rgb), 0.18) !important;
}
#contact-initiated .card.custom-card .company-name {
  color: var(--primary-tint2-color);
}
#contact-initiated .card.custom-card .deal-description {
  background: rgba(var(--primary-tint2-rgb), 0.05);
}
#contact-initiated .card.custom-card .avatar {
  background-color: var(--primary-tint2-color);
}
#needs-identified .card.custom-card {
  border-block-start: 3px solid rgba(var(--primary-tint3-rgb), 0.18) !important;
}
#needs-identified .card.custom-card .company-name {
  color: var(--primary-tint3-color);
}
#needs-identified .card.custom-card .deal-description {
  background: rgba(var(--primary-tint3-rgb), 0.05);
}
#needs-identified .card.custom-card .avatar {
  background-color: var(--primary-tint3-color);
}
#negotiation .card.custom-card {
  border-block-start: 3px solid rgba(var(--secondary-rgb), 0.18) !important;
}
#negotiation .card.custom-card .company-name {
  color: rgb(var(--secondary-rgb));
}
#negotiation .card.custom-card .deal-description {
  background: rgba(var(--secondary-rgb), 0.05);
}
#negotiation .card.custom-card .avatar {
  background-color: rgb(var(--secondary-rgb));
}
#deal-finalized .card.custom-card {
  border-block-start: 3px solid rgba(var(--success-rgb), 0.18) !important;
}
#deal-finalized .card.custom-card .company-name {
  color: rgb(var(--success-rgb));
}
#deal-finalized .card.custom-card .deal-description {
  background: rgba(var(--success-rgb), 0.05);
}
#deal-finalized .card.custom-card .avatar {
  background-color: rgb(var(--success-rgb));
}
#leads-discovered .card.custom-card,
#leads-qualified .card.custom-card,
#contact-initiated .card.custom-card,
#needs-identified .card.custom-card,
#negotiation .card.custom-card,
#deal-finalized .card.custom-card {
  margin-block-end: 0.5rem;
  touch-action: none;
}
#leads-discovered .card.custom-card:last-child,
#leads-qualified .card.custom-card:last-child,
#contact-initiated .card.custom-card:last-child,
#needs-identified .card.custom-card:last-child,
#negotiation .card.custom-card:last-child,
#deal-finalized .card.custom-card:last-child {
  margin-block-end: 1.5rem;
}
#leads-discovered .card.custom-card .card-body,
#leads-qualified .card.custom-card .card-body,
#contact-initiated .card.custom-card .card-body,
#needs-identified .card.custom-card .card-body,
#negotiation .card.custom-card .card-body,
#deal-finalized .card.custom-card .card-body {
  padding: 1rem;
}
#leads-discovered .card.custom-card .card-body .deal-description,
#leads-qualified .card.custom-card .card-body .deal-description,
#contact-initiated .card.custom-card .card-body .deal-description,
#needs-identified .card.custom-card .card-body .deal-description,
#negotiation .card.custom-card .card-body .deal-description,
#deal-finalized .card.custom-card .card-body .deal-description {
  padding: 3px 9px;
  border-radius: 4px;
}
#leads-discovered .card.custom-card .card-body .deal-description .company-name,
#leads-qualified .card.custom-card .card-body .deal-description .company-name,
#contact-initiated .card.custom-card .card-body .deal-description .company-name,
#needs-identified .card.custom-card .card-body .deal-description .company-name,
#negotiation .card.custom-card .card-body .deal-description .company-name,
#deal-finalized .card.custom-card .card-body .deal-description .company-name {
  font-size: 12px;
}
.create-nft-item .filepond--root[data-style-panel-layout~=circle] {
  border-radius: 0.3rem;
  width: 100%;
}
.create-nft-item .filepond--drop-label.filepond--drop-label label {
  padding: 2em;
}
.create-nft-item .filepond--drop-label {
  color: var(--text-muted) !important;
}
.create-nft-item .filepond--panel-root {
  border: 0.125rem dashed var(--input-border) !important;
}
.create-nft-item .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .create-nft-item .filepond--root[data-style-panel-layout~=circle] {
    height: 15.08rem !important;
    width: 22.25rem !important;
  }
  .create-nft-item .filepond--drop-label label {
    font-size: 0.75rem;
  }
  .create-nft-item .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
  .create-nft-item .filepond--file {
    height: 15.08rem;
    width: 22.25rem !important;
  }
}
.nft-wallet {
  position: relative;
  text-align: center;
  box-shadow: none !important;
  border: 1px solid var(--default-border) !important;
  border-radius: 0.5rem;
}
.nft-wallet:hover {
  background-color: var(--primary01);
}
.nft-wallet.active {
  border-color: var(--primary02) !important;
  background-color: var(--primary01);
}
.nft-list li {
  overflow: hidden;
  border-radius: 6px;
}
.nft-list li:hover,
.nft-list li.active {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 6px;
}
.nft-wallet-card {
  position: relative;
  z-index: 0;
}
.nft-wallet-card::before {
  content: "";
  position: absolute;
  inset-inline-start: -1.5rem;
  inset-block-start: -1.5rem;
  width: 5rem;
  height: 5rem;
  border: 13px solid rgba(255, 255, 255, 0.05);
  border-radius: 2.35rem;
}
.nft-wallet-card::after {
  content: "";
  position: absolute;
  inset-inline-end: -1.5rem;
  inset-block-end: -1.5rem;
  width: 5rem;
  height: 5rem;
  border: 13px solid rgba(255, 255, 255, 0.05);
  border-radius: 2.35rem;
}
.nft-tag.nft-tag-primary:hover,
.nft-tag.nft-tag-primary.active {
  color: var(--primary-color);
}
.nft-tag.nft-tag-primary:hover .nft-tag-icon,
.nft-tag.nft-tag-primary.active .nft-tag-icon {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-primary .nft-tag-icon {
  color: var(--primary-color);
  background-color: var(--primary01);
}
.nft-tag.nft-tag-primary1:hover,
.nft-tag.nft-tag-primary1.active {
  color: var(--primary-tint1-color);
}
.nft-tag.nft-tag-primary1:hover .nft-tag-icon,
.nft-tag.nft-tag-primary1.active .nft-tag-icon {
  background-color: var(--primary-tint1-color);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-primary1 .nft-tag-icon {
  color: var(--primary-tint1-color);
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.nft-tag.nft-tag-primary2:hover,
.nft-tag.nft-tag-primary2.active {
  color: var(--primary-tint2-color);
}
.nft-tag.nft-tag-primary2:hover .nft-tag-icon,
.nft-tag.nft-tag-primary2.active .nft-tag-icon {
  background-color: var(--primary-tint2-color);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-primary2 .nft-tag-icon {
  color: var(--primary-tint2-color);
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.nft-tag.nft-tag-primary3:hover,
.nft-tag.nft-tag-primary3.active {
  color: var(--primary-tint3-color);
}
.nft-tag.nft-tag-primary3:hover .nft-tag-icon,
.nft-tag.nft-tag-primary3.active .nft-tag-icon {
  background-color: var(--primary-tint3-color);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-primary3 .nft-tag-icon {
  color: var(--primary-tint3-color);
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
}
.nft-tag.nft-tag-secondary:hover,
.nft-tag.nft-tag-secondary.active {
  color: rgb(var(--secondary-rgb));
}
.nft-tag.nft-tag-secondary:hover .nft-tag-icon,
.nft-tag.nft-tag-secondary.active .nft-tag-icon {
  background-color: rgba(var(--secondary-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-secondary .nft-tag-icon {
  color: rgb(var(--secondary-rgb));
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.nft-tag.nft-tag-warning:hover,
.nft-tag.nft-tag-warning.active {
  color: rgb(var(--warning-rgb));
}
.nft-tag.nft-tag-warning:hover .nft-tag-icon,
.nft-tag.nft-tag-warning.active .nft-tag-icon {
  background-color: rgba(var(--warning-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-warning .nft-tag-icon {
  color: rgb(var(--warning-rgb));
  background-color: rgba(var(--warning-rgb), 0.1);
}
.nft-tag.nft-tag-info:hover,
.nft-tag.nft-tag-info.active {
  color: rgb(var(--info-rgb));
}
.nft-tag.nft-tag-info:hover .nft-tag-icon,
.nft-tag.nft-tag-info.active .nft-tag-icon {
  background-color: rgba(var(--info-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-info .nft-tag-icon {
  color: rgb(var(--info-rgb));
  background-color: rgba(var(--info-rgb), 0.1);
}
.nft-tag.nft-tag-success:hover,
.nft-tag.nft-tag-success.active {
  color: rgb(var(--success-rgb));
}
.nft-tag.nft-tag-success:hover .nft-tag-icon,
.nft-tag.nft-tag-success.active .nft-tag-icon {
  background-color: rgba(var(--success-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-success .nft-tag-icon {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.1);
}
.nft-tag.nft-tag-danger:hover,
.nft-tag.nft-tag-danger.active {
  color: rgb(var(--danger-rgb));
}
.nft-tag.nft-tag-danger:hover .nft-tag-icon,
.nft-tag.nft-tag-danger.active .nft-tag-icon {
  background-color: rgba(var(--danger-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-danger .nft-tag-icon {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.1);
}
.nft-tag.nft-tag-dark:hover,
.nft-tag.nft-tag-dark.active {
  color: rgb(var(--dark-rgb));
}
.nft-tag.nft-tag-dark:hover .nft-tag-icon,
.nft-tag.nft-tag-dark.active .nft-tag-icon {
  background-color: rgba(var(--dark-rgb), 1);
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
.nft-tag.nft-tag-dark .nft-tag-icon {
  color: rgb(var(--dark-rgb));
  background-color: rgba(var(--dark-rgb), 0.1);
}
.nft-tag .nft-tag-text {
  font-weight: 400;
  display: inline-block;
  padding: 0px 1.25rem 0px 0.5rem;
}
.nft-tag .nft-tag-icon {
  display: inline-block;
  padding: 0.4rem;
  line-height: 1;
  border-radius: 50px;
  background-color: rgb(var(--light-rgb));
}
.nft-tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border: 1px solid var(--default-border);
  border-radius: 50rem;
  background-color: var(--custom-white);
  color: var(--default-text-color);
  box-shadow: 0px 2px 0px rgba(118, 138, 254, 0.03);
}
[data-theme-mode=dark] .qr-image {
  filter: invert(1);
}
.currency-exchange-card {
  position: relative;
  background-color: var(--primary-color) !important;
  box-shadow: none;
  min-height: 21.875rem;
  z-index: 10;
}
/*.currency-exchange-card:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("media-69-H2WJXLHF.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0.15;
}*/
.currency-exchange-card .currency-exchange-area {
  background-color: rgba(var(--dark-rgb), 0.1);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 1;
  position: relative;
}
.currency-exchange-card .currency-exchange-area .form-control {
  line-height: 1.96;
}
#buy_sell-statistics .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series .apexcharts-bar-area {
  stroke: transparent;
}
#bitcoin-chart .apexcharts-grid line,
#etherium-chart .apexcharts-grid line,
#dashcoin-chart .apexcharts-grid line,
#btc-chart .apexcharts-grid line,
#eth-chart .apexcharts-grid line,
#glm-chart .apexcharts-grid line,
#dash-chart .apexcharts-grid line,
#lite-chart .apexcharts-grid line,
#ripple-chart .apexcharts-grid line,
#eos-chart .apexcharts-grid line,
#bytecoin-chart .apexcharts-grid line,
#iota-chart .apexcharts-grid line,
#monero-chart .apexcharts-grid line {
  stroke: transparent;
}
#loader {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: var(--custom-white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
@media (min-width: 992px) {
  body {
    padding-inline-end: 0 !important;
    overflow: auto !important;
  }
}
@media (min-width: 1400px) {
  .kanban-board {
    width: 370px;
  }
}
[class^=ri-],
[class*=" ri-"] {
  display: inline-flex;
}
.badge-task {
  background-color: rgba(0, 0, 0, 0.2);
}
[data-theme-mode=dark] .bg-dark .h1,
[data-theme-mode=dark] .bg-dark .h2,
[data-theme-mode=dark] .bg-dark .h3,
[data-theme-mode=dark] .bg-dark .h4,
[data-theme-mode=dark] .bg-dark .h5,
[data-theme-mode=dark] .bg-dark .h6,
[data-theme-mode=dark] .bg-dark h1,
[data-theme-mode=dark] .bg-dark h2,
[data-theme-mode=dark] .bg-dark h3,
[data-theme-mode=dark] .bg-dark h4,
[data-theme-mode=dark] .bg-dark h5,
[data-theme-mode=dark] .bg-dark h6,
[data-theme-mode=dark] .card-bg-dark .h1,
[data-theme-mode=dark] .card-bg-dark .h2,
[data-theme-mode=dark] .card-bg-dark .h3,
[data-theme-mode=dark] .card-bg-dark .h4,
[data-theme-mode=dark] .card-bg-dark .h5,
[data-theme-mode=dark] .card-bg-dark .h6,
[data-theme-mode=dark] .card-bg-dark h1,
[data-theme-mode=dark] .card-bg-dark h2,
[data-theme-mode=dark] .card-bg-dark h3,
[data-theme-mode=dark] .card-bg-dark h4,
[data-theme-mode=dark] .card-bg-dark h5,
[data-theme-mode=dark] .card-bg-dark h6 {
  color: var(--custom-white) !important;
}
[data-theme-mode=dark] .bg-dark .text-fixed-white {
  color: #fff !important;
}
[data-theme-mode=dark] .bg-dark.card.custom-card,
[data-theme-mode=dark] .card-bg-dark.card.custom-card {
  color: var(--custom-white) !important;
}
[data-theme-mode=dark] .bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .bg-dark.card.custom-card .card-footer,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-footer {
  color: var(--custom-white) !important;
}
[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}
[data-theme-mode=dark] .apexcharts-tooltip * {
  color: var(--default-text-color);
}
[data-theme-mode=dark] #circle-custom .apexcharts-legend.apx-legend-position-left {
  background-color: var(--custom-white);
}
[data-theme-mode=dark] .navbar-nav .nav-link.active,
[data-theme-mode=dark] .navbar-nav .nav-link.show {
  color: #fff;
}
.d-inline-table {
  display: inline-table;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--default-text-color);
}
.card.custom-card {
  color: var(--default-text-color) !important;
}
[data-theme-mode=light][data-header-styles=transparent] .page-header-breadcrumb h4,
[data-theme-mode=light][data-default-header-styles=transparent] .page-header-breadcrumb h4 {
  color: var(--default-text-color) !important;
}
[data-theme-mode=light][data-header-styles=transparent] .app-header .main-header-container .form-control,
[data-theme-mode=light][data-default-header-styles=transparent] .app-header .main-header-container .form-control {
  background: #fff !important;
  color: var(--default-text-color);
  box-shadow: 0px 2px 0px rgba(118, 138, 254, 0.03);
}
[data-theme-mode=light][data-header-styles=transparent] .app-header .main-header-container .form-control:focus,
[data-theme-mode=light][data-default-header-styles=transparent] .app-header .main-header-container .form-control:focus {
  box-shadow: 0px 2px 0px rgba(118, 138, 254, 0.03) !important;
}
[data-theme-mode=light] .page-header-breadcrumb h4 {
  color: #fff !important;
}
[data-header-styles=dark] .header-link.dropdown-toggle .user-name,
[data-header-styles=color] .header-link.dropdown-toggle .user-name,
[data-header-styles=gradient] .header-link.dropdown-toggle .user-name {
  color: #fff !important;
}
[data-default-header-styles=transparent] .page-header-breadcrumb,
[data-default-header-styles=light] .page-header-breadcrumb,
[data-default-header-styles=dark] .page-header-breadcrumb,
[data-default-header-styles=color] .page-header-breadcrumb,
[data-default-header-styles=gradient] .page-header-breadcrumb {
  margin: 0px 0px -32px 0px;
}
/*.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  background-color: transparent !important;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: var(--text-muted) !important;
  font-weight: 400;
}*/
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: var(--text-muted) !important;
  font-weight: 400;
}
.dropdown-item-text {
  color: var(--default-text-color) !important;
}
.blockquote-footer {
  color: var(--default-text-color) !important;
}
hr {
  border-color: var(--default-border);
  opacity: 1;
}
[dir=rtl] .dropdown-menu {
  --bs-position: start;
}
[dir=rtl] .dropdown-menu-end {
  --bs-position: end;
}
[dir=rtl] .transform-arrow {
  transform: rotate(180deg);
}
[dir=rtl] .bi-skip-backward::before {
  content: "\f55e";
}
[dir=rtl] .bi-skip-forward::before {
  content: "\f552";
}
.testimonialSwiper01 {
  padding: 0 0 4rem 0 !important;
}
.swiper-pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.swiper-pagination-custom .swiper-pagination-bullet {
  width: 1.75rem !important;
  height: 1.75rem !important;
  border-radius: 50% !important;
  overflow: hidden;
}
.swiper-pagination-custom .swiper-pagination-bullet img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.swiper-pagination-custom .swiper-pagination-bullet.swiper-pagination-bullet-active img {
  border: 3px solid var(--primary-color);
  border-radius: 50%;
}
.testimonialSwiper01 .swiper-slide {
  scale: 0.8;
}
.testimonialSwiper01 .swiper-slide.swiper-slide-active {
  opacity: 1;
  scale: 1;
  max-width: 500px !important;
}
.testimonialSwiper01 .swiper-slide.swiper-slide-active .review-quote {
  color: rgba(255, 255, 255, 0.2);
}
.review-quote {
  z-index: 0;
}
.testimonialSwiper01 .swiper-slide.swiper-slide-active .card {
  background: var(--primary-color);
  color: var(--custom-white);
}
.testimonialSwiper01 .swiper-slide.swiper-slide-active .card .card-body {
  color: rgb(255, 255, 255) !important;
}
.testimonialSwiper01 .swiper-slide.swiper-slide-active .card h6 {
  color: #fff !important;
}
.review-quote {
  color: var(--primary005);
  opacity: 1;
  position: absolute;
  font-size: 3rem;
  inset-inline-start: auto;
  inset-inline-end: 3px;
  inset-block-start: 7.25rem;
}
.review-quote.primary {
  color: var(--primary04);
}
.review-quote.primary1 {
  color: rgba(var(--primary-tint1-rgb), 0.4);
}
.review-quote.primary2 {
  color: rgba(var(--primary-tint2-rgb), 0.4);
}
.review-quote.primary3 {
  color: rgba(var(--primary-tint3-rgb), 0.4);
}
.review-quote.secondary {
  color: rgba(var(--secondary-rgb), 0.4);
}
.review-quote.success {
  color: rgba(var(--success-rgb), 0.4);
}
.review-quote.warning {
  color: rgba(var(--warning-rgb), 0.4);
}
.reviews-container .card {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  margin-block-end: 0;
}
.review-style-2 .testimonialSwiperService {
  padding: 0 0 1.75rem 0 !important;
}
.review-style-2 .testimonialSwiperService .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--primary-tint1-color) !important;
}
.review-style-2 .testimonialSwiperService .swiper-pagination-bullet {
  background: rgba(var(--primary-tint1-rgb), 0.8) !important;
}
ul.sortable-list li,
ol.sortable-list li {
  background-color: var(--primary005);
  margin: 3px 0;
  border: 1px solid var(--primary005);
  border-radius: 0.3rem;
  font-weight: 500;
}
ul.sortable-list li.filtered,
ol.sortable-list li.filtered {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
ul.sortable-list li.selected,
ol.sortable-list li.selected {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
}
ul.sortable-list .list-group-item + .list-group-item,
ol.sortable-list .list-group-item + .list-group-item {
  border-top-width: 1px;
}
ul#shared-right li,
ul#cloning-right li,
ul#disabling-sorting-right li,
ol#shared-right li,
ol#cloning-right li,
ol#disabling-sorting-right li {
  background-color: rgba(var(--secondary-rgb), 0.05);
  border: 1px solid rgba(var(--secondary-rgb), 0.05);
}
ul .handle,
ol .handle {
  cursor: grab;
}
.grid-square {
  width: 100px;
  height: 100px;
  display: inline-block;
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  border-radius: 0.5rem;
  margin-block-end: 1.5rem;
  margin-inline-end: 1.5rem;
  padding: 20px;
}
.nested-sortable,
.nested-1,
.nested-2,
.nested-3 {
  margin-block-start: 5px;
}
.nested-sortable .list-group-item + .list-group-item,
.nested-1 .list-group-item + .list-group-item,
.nested-2 .list-group-item + .list-group-item,
.nested-3 .list-group-item + .list-group-item {
  border-top-width: 1px;
}
.nested-1,
.nested-2,
.nested-3 {
  background-color: rgba(var(--primary-rgb), 0.05);
  border: 1px solid rgba(var(--primary-rgb), 0.05);
  font-weight: 500;
}
.search-result-input .form-control {
  padding-inline-start: 2.25rem;
}
.search-tab.tab-style-6 .nav-item .nav-link {
  background-color: var(--primary01);
}
.search-tab.tab-style-6 .nav-item .nav-link.active {
  background-color: var(--primary-color);
}
.search-result-icon {
  z-index: 5;
}
.searched-item-main-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.glightbox.card.search-images-card:hover:after {
  content: none;
}
.avatar.avatar-search {
  height: 120px;
  width: inherit;
}
.search-images-card {
  transition: all 0.4s;
}
.search-images-card:hover {
  box-shadow: 0px 4px 16px var(--black-1);
  transition: all 0.4s;
}
.ad-gallery {
  display: none;
}
@media (max-width: 767.98px) {
  .swiper-related-jobs .swiper-button-next,
  .swiper-related-jobs .swiper-button-prev {
    display: none;
  }
}
#btc-chart .apexcharts-canvas,
#btc-chart .apexcharts-svg,
#bytecoin-chart .apexcharts-canvas,
#bytecoin-chart .apexcharts-svg,
#dash-chart .apexcharts-canvas,
#dash-chart .apexcharts-svg,
#dash-price-graph .apexcharts-canvas,
#dash-price-graph .apexcharts-svg,
#eos-chart .apexcharts-canvas,
#eos-chart .apexcharts-svg,
#eth-chart .apexcharts-canvas,
#eth-chart .apexcharts-svg,
#glm-chart .apexcharts-canvas,
#glm-chart .apexcharts-svg,
#iota-chart .apexcharts-canvas,
#iota-chart .apexcharts-svg,
#monero-chart .apexcharts-canvas,
#monero-chart .apexcharts-svg,
#ripple-chart .apexcharts-canvas,
#ripple-chart .apexcharts-svg {
  width: 120px !important;
}
@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
    border: 1px solid var(--default-border);
    page-break-inside: avoid;
  }
  blockquote {
    border: 1px solid var(--default-border);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a4;
  }
  body,
  .container {
    min-width: 992px !important;
  }
  .table,
  .text-wrap table {
    border-collapse: collapse !important;
  }
  .table td,
  .text-wrap table td,
  .table th,
  .text-wrap table th {
    background-color: var(--custom-white) !important;
  }
  .table-bordered th,
  .text-wrap table th,
  .table-bordered td,
  .text-wrap table td {
    border: 1px solid var(--default-border) !important;
  }
  .app-sidebar,
  .app-content .page-header-breadcrumb,
  .app-header,
  .footer {
    display: none !important;
  }
  .main-content.app-content {
    padding-block-start: 10px !important;
  }
}
#referrals-chart .apexcharts-pie text,
#job-acceptance .apexcharts-pie text,
#portfolio .apexcharts-pie text,
#patients-chart .apexcharts-pie text,
#follow-on-device .apexcharts-pie text {
  fill: var(--default-text-color) !important;
}
ul.analytics-activity li {
  margin-block-end: 1.25rem;
}
#orders .apexcharts-pie line,
#orders .apexcharts-pie circle {
  stroke: transparent;
}
#orders .apexcharts-pie text {
  fill: var(--default-text-color) !important;
}
#orders .apexcharts-legend {
  padding: 0 2.25rem !important;
}
.sales-country-list {
  margin-block-end: 0;
}
.sales-country-list li {
  margin-block-end: 1.05rem;
}
.sales-country-list li:last-child {
  margin-block-end: 0;
}
.top-category-name {
  padding-inline-start: 1rem;
  position: relative;
}
.top-category-name:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  inset-inline-start: -2px;
  inset-block-start: 6px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
}
.top-category-name.one:before {
  border: 2px solid var(--primary-color);
}
.top-category-name.two:before {
  border: 2px solid var(--primary-tint1-color);
}
.top-category-name.three:before {
  border: 2px solid var(--primary-tint2-color);
}
.top-category-name.four:before {
  border: 2px solid var(--primary-tint3-color);
}
.top-category-name.five:before {
  border: 2px solid rgb(var(--warning-rgb));
}
.card.custom-card.main-dashboard-banner {
  z-index: 1;
  box-shadow: none;
  border: 0;
}
.card.custom-card.main-dashboard-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image:
          linear-gradient(
                  to right top,
                  #ff9794,
                  #fd8dac,
                  #e58cc6,
                  #bf7cdc,
                  #6f78f4);
  z-index: -1;
}
/*.card.custom-card.main-dashboard-banner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("media-87-MJVL2VA5.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  z-index: -1;
  opacity: 0.1;
}*/
.card.custom-card.main-dashboard-banner img {
  position: absolute;
  inset-inline-end: 10px;
  width: 200px;
  height: 200px;
  inset-block-end: -30px;
}
.card.custom-card.main-dashboard-banner.main-dashboard-banner2:before {
  background-color: var(--primary-color);
  background-image: none;
}
/*.card.custom-card.main-dashboard-banner.main-dashboard-banner2:after {
  background-image: url("media-87-MJVL2VA5.png");
}*/
.card.custom-card.main-dashboard-banner.main-dashboard-banner2 img {
  width: 170px;
  height: 170px;
  inset-block-end: -9px;
}
.recent-activity-list {
  margin-block-end: 0;
  position: relative;
}
.recent-activity-list:before {
  position: absolute;
  content: "";
  width: 1px;
  background-color: var(--default-border);
  height: 82%;
  inset-inline-start: 65px;
  inset-block-start: 12px;
}
.recent-activity-list li {
  position: relative;
  margin-block-end: 1.1rem;
  padding-inline-start: 5.75rem;
}
.recent-activity-list li .activity-time {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 2px;
}
.recent-activity-list li:nth-child(1):before {
  background-color: rgba(var(--primary-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.15);
}
.recent-activity-list li:nth-child(2):before {
  background-color: rgba(var(--primary-tint1-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint1-rgb), 0.15);
}
.recent-activity-list li:nth-child(3):before {
  background-color: rgba(var(--primary-tint2-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint2-rgb), 0.15);
}
.recent-activity-list li:nth-child(4):before {
  background-color: rgba(var(--primary-tint3-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint3-rgb), 0.15);
}
.recent-activity-list li:nth-child(5):before {
  background-color: rgba(var(--secondary-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--secondary-rgb), 0.15);
}
.recent-activity-list li:nth-child(6):before {
  background-color: rgba(var(--warning-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--warning-rgb), 0.15);
}
.recent-activity-list li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--custom-white);
  inset-inline-start: 62px;
  inset-block-start: 7px;
}
.recent-activity-list li:last-child {
  margin-block-end: 0;
}
.main-content-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid var(--default-border);
}
/*.main-content-card:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-image: url("media-89-HFIGZSQC.png");
  background-position: center;
  background-repeat: repeat;
  z-index: -1;
  opacity: 0.6;
  background-size: cover;
}*/
[data-theme-mode=dark] .main-content-card:before {
  filter: invert(1);
  opacity: 0;
}
/*.offer-card {
  z-index: 1;
  background-image: url("1-6YN2QKZ7.jpg");
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: bottom;
}*/
.offer-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary07);
  z-index: -1;
}
.offer-card-details .offer-item-img {
  position: absolute;
  inset-inline-end: -2px;
  inset-block-end: 18px;
  height: 12rem;
}
@media (max-width: 380px) {
  .offer-card-details .offer-item-img {
    display: none;
  }
}
.order-content {
  margin-block-end: -25px;
}
.ecommerce-recent-activity {
  position: relative;
  overflow: hidden;
}
.ecommerce-recent-activity::before {
  position: absolute;
  content: "";
  width: 1px;
  background-color: transparent;
  inset-block-start: 1.4375rem;
  inset-inline-start: 0.8rem;
  border: 1px dashed var(--default-border);
  height: 100%;
}
.ecommerce-recent-activity li {
  padding-block-end: 1.5rem;
}
#revenue-report {
  padding-block-start: 6.1rem;
}
#profit-report {
  position: absolute;
  inset-block-end: 0;
  width: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 1;
}
.task-list-tab li {
  margin-block-end: 1.4rem;
}
.task-list-tab li:last-child {
  margin-block-end: 0;
}
#Leads-overview .apexcharts-yaxis .apexcharts-text {
  fill: #b6c5d3;
}
.timeline-widget-list {
  margin-block-end: 0.5rem;
}
.timeline-widget-list:last-child {
  margin-block-end: 0;
}
.schedule-list li,
.courses-instructors li {
  margin-block-end: 1.3rem;
}
.schedule-list li:last-child,
.courses-instructors li:last-child {
  margin-block-end: 0;
}
.market-cap-list li {
  margin-block-end: 1.25rem;
}
.market-cap-list li:last-child {
  margin-block-end: 0;
}
.nft-banner-card {
  z-index: 1;
}
/*.nft-banner-card:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("35-VQPM2VDM.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  border-radius: 0.5rem;
  z-index: -1;
}*/
.nft-banner-card:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-image:
          linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0.5) 30%,
                  transparent);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
}
.nft-main-banner-image {
  height: 12rem;
  border-radius: 50%;
}
.nft-auction-time {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  padding: 5px 8px;
  border-end-end-radius: 15px;
  font-size: 11px;
  z-index: 1;
}
.nft-like-badge {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.bid-amt svg {
  width: 11px !important;
  height: 17px !important;
  margin-bottom: 4px;
}
.bg-crypto-balance {
  position: relative;
  z-index: 1;
}
/*.bg-crypto-balance::before {
  content: "";
  inset-inline-start: 0;
  inset-block-start: 0;
  position: absolute;
  background-image: url("media-88-XWXEPDVK.jpg");
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  transform: scaleY(-1);
  background-size: cover;
  background-position: bottom;
  border-radius: 0.375rem;
}*/
/*.bg-crypto-balance:after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  inset-block-start: 0;
  inset-inline-end: 0.15rem;
  background-image: url("media-73-7NMLD3WI.png");
  background-repeat: no-repeat;
  opacity: 0.07;
  transform: scaleX(-1);
}*/
.card.custom-card.main-dashboard-banner.project-dashboard-banner:before {
  background-color: var(--primary-color);
  background-image: none;
}
/*.card.custom-card.main-dashboard-banner.project-dashboard-banner:after {
  background-image: url("media-82-JP4UDPYT.jpg");
  opacity: 0.35;
}*/
.card.custom-card.main-dashboard-banner.project-dashboard-banner img {
  width: 197px;
  height: 168px;
  inset-block-end: -4px;
  inset-inline-end: -5px;
  filter: saturate(0.9);
  opacity: 0.95;
}
.daily-tasks-time {
  width: 4.5rem;
  padding: 0.25rem;
  background: rgb(var(--light-rgb));
  text-align: center;
  font-size: 13px;
  border-radius: 7px;
}
.card.custom-card.course-main {
  z-index: 1;
}
/*.card.custom-card.course-main:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: repeat;
  z-index: -1;
  background-position: bottom;
  opacity: 0.2;
  transform: scaleX(-1);
  background-image: url("media-80-XSQK7UY5.jpg");
}*/
.card.custom-card.course-main:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: repeat;
  z-index: -1;
  right: 0;
  transform: scaleX(-1);
  background-color: rgba(0, 0, 0, 0.06);
}
.Upcoming-courses-schedule > .list-item {
  margin-block-end: 1.25rem;
}
.Upcoming-courses-schedule > .list-item:last-child {
  margin-block-end: 0;
}
.Upcoming-courses-schedule > .list-item:nth-child(2) .course-schedule::before {
  background-color: var(--primary-color);
}
.Upcoming-courses-schedule > .list-item:nth-child(3) .course-schedule::before {
  background-color: var(--primary-tint1-color);
}
.Upcoming-courses-schedule > .list-item:nth-child(4) .course-schedule::before {
  background-color: var(--primary-tint2-color);
}
.Upcoming-courses-schedule > .list-item:nth-child(5) .course-schedule::before {
  background-color: var(--primary-tint3-color);
}
.Upcoming-courses-schedule > .list-item:nth-child(6) .course-schedule::before {
  background-color: rgb(var(--secondary-rgb));
}
.course-schedule {
  position: relative;
}
.course-schedule::before {
  content: "";
  position: absolute;
  inset-inline-start: -15px;
  inset-block-start: 0;
  height: 96%;
  width: 0.2rem;
  border-radius: 0.25rem;
  background-color: rgb(var(--info-rgb));
}
.svg-icon-med {
  width: 5rem;
  height: 5rem;
  margin-inline-end: -1.6875rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1.1875rem;
  border-radius: 50%;
  margin-block-end: -22px;
}
.svg-icon-med.opacity-1 {
  opacity: 0.7 !important;
}
.svg-icon-med.text-primary {
  background-color: var(--primary02);
}
.svg-icon-med.text-primary1 {
  background-color: rgba(var(--primary-tint1-rgb), 0.2);
}
.svg-icon-med.text-primary2 {
  background-color: rgba(var(--primary-tint2-rgb), 0.2);
}
.svg-icon-med.text-primary3 {
  background-color: rgba(var(--primary-tint3-rgb), 0.2);
}
.med-banner-card {
  position: relative;
  overflow: hidden;
  background-color: var(--primary-color);
  z-index: 0;
}
/*.med-banner-card::before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-image: url("media-72-ODIDMACJ.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.25;
}*/
.med-banner-card:after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 11rem;
  width: 11rem;
  inset-inline-end: 5.15rem;
  background: #fff;
  border-radius: 50%;
  top: 20px;
  opacity: 0.05;
}
.med-banner-img {
  height: 11rem;
  margin-block-start: 0;
  position: absolute;
  inset-inline-end: 42px;
  content: "";
  inset-block-start: 5px;
}
.upcoming-shedule .sh-link {
  padding: 0.6rem;
  border-radius: 3px !important;
  min-width: 70px;
  max-width: 70px;
  transition: all ease 0.3s;
  background-color: rgb(var(--light-rgb));
}
.upcoming-shedule .sh-shedule-container li {
  margin-block-end: 1.4rem;
}
.upcoming-shedule .sh-shedule-container li:last-child {
  margin-block-end: 0;
}
.pos-category .card.custom-card.active,
.pos-category .card.custom-card:hover {
  border: 1px solid var(--primary03);
  background-color: var(--primary005);
}
.pos-category .card.custom-card {
  border: 1px solid transparent;
}
.pos-category .card.custom-card .categorymenu-icon {
  background-color: var(--primary04);
}
.pos-category .card.custom-card .avatar svg {
  width: 4.25rem;
  height: 4.25rem;
  fill: var(--primary-color);
}
.pos-card-image img {
  height: 10.5rem;
  width: 10rem;
  position: relative;
  margin-block-start: -54px;
}
.categories-arrow {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--custom-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
}
.categories-arrow i {
  font-size: 16px;
  color: var(--default-text-color);
}
[dir=rtl] .categories-arrow,
[dir=rtl] .table-icon i {
  transform: scaleX(-1);
}
[dir=rtl] .podcast-banner:before {
  transform: scaleX(1);
}
.card.custom-card.out-of-stock {
  opacity: 0.65;
}
.order-summ.product-quantity-container {
  width: 7rem;
}
.podcast-banner {
  position: relative;
  z-index: 1;
}
/*.podcast-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("media-70-YRXV2UKN.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  border-radius: 0.5rem;
  transform: scaleX(-1);
  z-index: -1;
}*/
.podcast-banner:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-image:
          linear-gradient(
                  to bottom right,
                  var(--primary-color) 0%,
                  rgb(var(--secondary-rgb)) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  z-index: -1;
  opacity: 0.75;
}
.podcasters {
  margin-block-end: 0;
}
.podcasters li {
  margin-block-end: 1rem;
}
.podcasters li:last-child {
  margin-block-end: 0;
}
.podcast-playing-now {
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.podcast-playing-now img {
  border-radius: 12px;
}
.podcast-playing-progress {
  width: 100%;
}
.podcast-playing-progress .progress-custom .progress-bar:after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  box-shadow: 0 0.313rem 0.313rem var(--primary02);
  background: #fff;
  position: absolute;
  inset-inline-end: -0.375rem;
  inset-block-start: -4px;
  border: 0.25rem solid var(--primary-color);
}
.podcast-recently-played-list {
  margin-block-end: 0;
}
.podcast-recently-played-list li {
  margin-block-end: 1rem;
}
.podcast-recently-played-list li:last-child {
  margin-block-end: 0;
}
/*.bg-playing-image {
  background-image: url("5-HXPH4CED.jpg");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 0.5rem;
  color: white;
  position: relative;
  z-index: 0;
}*/
.bg-playing-image:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  opacity: 0.7;
}
.school-card svg {
  width: 2.95rem;
  height: 2.95rem;
}
.school-activity-list {
  margin-block-end: 0;
  position: relative;
}
.school-activity-list:before {
  position: absolute;
  content: "";
  width: 1px;
  background-color: var(--default-border);
  height: 82%;
  inset-inline-start: 18px;
  inset-block-start: 12px;
}
.school-activity-list li {
  position: relative;
  margin-block-end: 1.1rem;
  padding-inline-start: 2.75rem;
}
.school-activity-list li .activity-time {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 2px;
}
.school-activity-list li:nth-child(1):before {
  background-color: rgba(var(--primary-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.15);
}
.school-activity-list li:nth-child(2):before {
  background-color: rgba(var(--primary-tint1-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint1-rgb), 0.15);
}
.school-activity-list li:nth-child(3):before {
  background-color: rgba(var(--primary-tint2-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint2-rgb), 0.15);
}
.school-activity-list li:nth-child(4):before {
  background-color: rgba(var(--primary-tint3-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--primary-tint3-rgb), 0.15);
}
.school-activity-list li:nth-child(5):before {
  background-color: rgba(var(--secondary-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--secondary-rgb), 0.15);
}
.school-activity-list li:nth-child(6):before {
  background-color: rgba(var(--warning-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--warning-rgb), 0.15);
}
.school-activity-list li:nth-child(7):before {
  background-color: rgba(var(--info-rgb), 1);
  box-shadow: 0px 0px 0px 4px rgba(var(--info-rgb), 0.15);
}
.school-activity-list li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--custom-white);
  inset-inline-start: 15px;
  inset-block-start: 7px;
}
.school-activity-list li:last-child {
  margin-block-end: 0;
}
.personal-favourite {
  margin-block-end: 0;
}
.personal-favourite li {
  margin-block-end: 1.5rem;
}
.personal-favourite li:last-child {
  margin-block-end: 0;
}
.social-cards::after {
  content: "";
  position: absolute;
  inset-inline-end: -18px;
  inset-block-start: -32px;
  color: rgb(var(--light-rgb));
  font-size: 100px;
  line-height: 1;
  font-weight: 500;
  z-index: -1;
  opacity: 0.09;
  font-family: "remixicon" !important;
}
.social-cards {
  z-index: 1;
  overflow: hidden;
}
.social-cards.insta::after {
  content: "\ee66";
  color: var(--primary-tint1-color);
}
.social-cards.linkedin::after {
  content: "\eeb6";
  color: rgb(var(--info-rgb));
}
.social-cards.fb::after {
  content: "\ecbd";
  color: var(--primary-color);
}
.social-cards.twit::after {
  content: "\f3e7";
  color: rgb(var(--dark-rgb));
}
.social-cards.youtube::after {
  content: "\f2d5";
  color: rgb(var(--danger-rgb));
}
.social-cards.msgr::after {
  content: "\ef4a";
  color: rgb(var(--secondary-rgb));
}
.stock-sparkline-charts {
  inset-block-end: -21px;
  inset-inline-end: -16px;
  position: relative;
}
#stockCap .apexcharts-selection-rect {
  fill: rgba(0, 0, 0, 0.4);
}
.crm-card {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
/*.crm-card::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  inset-inline-end: -1.85rem;
  background-image: url("media-73-7NMLD3WI.png");
  background-repeat: no-repeat;
  opacity: 0.075;
  transform: scaleX(-1);
}*/
.buy-crypto .choices .choices__inner {
  padding-bottom: 7.5px !important;
}
@media (min-width: 1400px) {
  .banner10-img {
    position: absolute;
    height: 7rem;
    inset-block-start: 39px;
    inset-inline-end: 18px;
  }
}
[dir=rtl] #chart-21 .apexcharts-canvas,
[dir=rtl] #chart-22 .apexcharts-canvas,
[dir=rtl] #chart-23 .apexcharts-canvas,
[dir=rtl] #chart-24 .apexcharts-canvas {
  transform: scaleX(-1);
}
[dir=rtl] #chart-21 .apexcharts-tooltip,
[dir=rtl] #chart-22 .apexcharts-tooltip,
[dir=rtl] #chart-23 .apexcharts-tooltip,
[dir=rtl] #chart-24 .apexcharts-tooltip {
  transform: scaleX(-1);
}
.card-headertabs.nav-tabs-header .nav-item .nav-link {
  background-color: rgb(var(--light-rgb));
}
.card-headertabs.nav-tabs-header .nav-item .nav-link.active {
  background-color: var(--primary01);
}
[data-width=boxed] .events-width {
  width: 200px;
}
[data-width=boxed] .events-width .timeline-widget-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
[data-theme-mode=dark] .xrp-logo {
  filter: invert(1);
}
#balanceAnalysis .apexcharts-radialbar text,
#monthly-target .apexcharts-radialbar text {
  fill: var(--default-text-color) !important;
}
@media (max-width: 767.98px) {
  .med-banner-img {
    display: none;
  }
  .med-banner-card:after {
    content: none;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .offer-card-details .offer-item-img {
    display: none;
  }
}
#bitcoin-change .apexcharts-svg,
#bitcoin-change .apexcharts-canvas,
#etherium-change .apexcharts-svg,
#etherium-change .apexcharts-canvas,
#tether-change .apexcharts-svg,
#tether-change .apexcharts-canvas,
#solana-change .apexcharts-svg,
#solana-change .apexcharts-canvas,
#cardano-change .apexcharts-svg,
#cardano-change .apexcharts-canvas,
#binance-change .apexcharts-svg,
#binance-change .apexcharts-canvas {
  width: 120px !important;
}
.error-bg {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.77);
}
.error-bg:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  /*background-image: url("pattern-2-IKXV2CJT.svg");*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
  opacity: 0.15;
}
.error-page {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.error-text {
  font-size: 11rem;
  font-weight: 500;
  line-height: 1;
}
@media (max-width: 575.98px) {
  .error-text {
    font-size: 3rem;
  }
}
[data-theme-mode=dark] .error-bg {
  background-color: rgba(0, 0, 0, 0.4);
}
.app-header {
  max-width: 100%;
  height: 4.25rem;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: var(--header-bg);
  border-block-end: 1px solid var(--header-border-color);
  transition: all 0.1s ease;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
}
.app-header #mainHeaderProfile {
  color: var(--header-prime-color);
}
@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 15rem;
  }
}
.header-icon-badge {
  position: absolute;
  padding: 0.15rem 0.25rem;
  inset-block-start: 0;
  inset-inline-end: 0.45rem;
  font-size: 0.625rem;
}
.header-icon-pulse {
  position: absolute;
  width: 6px;
  height: 6px;
  inset-block-start: 5px;
  inset-inline-end: 18px;
}
.header-profile-dropdown {
  min-width: 11rem;
}
.main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  padding-inline: 1rem;
}
.main-header-container .header-content-left,
.main-header-container .header-content-right {
  display: flex;
  align-items: stretch;
  margin-block: auto;
}
.main-header-container .header-content-right {
  padding-inline-start: 0 !important;
}
.main-header-container .header-element {
  display: flex;
  align-items: stretch;
}
.main-header-container .header-link {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.main-header-container .header-link:hover .header-link-icon,
.main-header-container .header-link.show .header-link-icon {
  color: var(--header-prime-color);
}
.main-header-container .header-link-icon {
  font-size: 1rem;
  color: var(--header-prime-color);
  transition: all ease 0.05s;
  position: relative;
  padding: 0.4rem;
  border-radius: 0.3rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--header-border-color);
}
.main-header-container .dropdown-toggle {
  text-decoration: none;
}
.main-header-container .dropdown-toggle::after {
  content: none;
}
.main-header-container .main-profile-user .dropdown-menu {
  width: 13rem;
}
.main-header-container .main-profile-user .dropdown-item {
  font-weight: 400;
  font-size: 0.813rem;
  color: var(--default-text-color);
  padding: 1.25rem 1rem;
  height: 2.375rem;
  display: flex;
  align-items: center;
}
.main-header-container .main-profile-user .dropdown-item:hover {
  color: var(--primary-color);
}
.main-header-container .main-profile-user .dropdown-item:hover i {
  color: var(--primary-color);
  opacity: 1;
}
.main-header-container .main-header-dropdown {
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  inset-block-start: 0.9375rem !important;
}
.main-header-container .main-header-dropdown li {
  border-bottom: 1px solid var(--default-border);
}
.main-header-container .main-header-dropdown li:last-child {
  border-bottom: 0;
}
.main-header-container .main-header-dropdown .dropdown-item {
  padding: 0.6rem 1rem;
}
.main-header-container .main-header-dropdown .dropdown-item:last-child {
  border-block-end: 0;
}
.main-header-container .cart-dropdown .main-header-dropdown {
  width: 25rem;
}
.main-header-container .country-selector .main-header-dropdown {
  min-width: 12rem;
}
.main-header-container .notifications-dropdown .main-header-dropdown {
  width: 21rem;
}
.main-header-container .header-profile-dropdown.main-header-dropdown li {
  border-bottom: 0px solid var(--default-border);
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}
[dir=rtl] .sidemenu-toggle .open-toggle {
  transform: rotate(180deg);
}
[dir=rtl] .app-header .dropdown-menu-end {
  --bs-position: end;
}
.header-cart-remove i,
.dropdown-item-close1 i {
  color: var(--text-muted);
  opacity: 0.6;
}
.header-cart-remove:hover i,
.dropdown-item-close1:hover i {
  color: rgb(var(--danger-rgb));
  opacity: 1;
}
@media (max-width: 575.98px) {
  .header-element.dropdown {
    position: initial;
  }
}
.auto-complete-search input.header-search-bar {
  border-radius: 0.3rem !important;
}
.header-search {
  position: relative;
}
.auto-complete-search .header-search-bar {
  position: relative;
  min-width: 20rem !important;
  padding-block-start: 0.375rem !important;
  padding-inline-end: 0.75rem !important;
  padding-block-end: 0.375rem !important;
  padding-inline-start: 2.25rem !important;
  border: 1px solid var(--default-border) !important;
  background-color: transparent;
}
.auto-complete-search .header-search-bar:focus {
  border: 1px solid var(--default-border) !important;
  background-color: transparent;
}
.header-search-icon {
  position: absolute;
  top: 0.5rem;
  inset-inline-start: 0.75rem;
  color: var(--text-muted);
}
.country-selector .header-link img {
  width: 1.75rem;
  height: 1.75rem;
}
#header-notification-scroll,
#header-cart-items-scroll {
  max-height: 20rem;
}
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-secondary 2s infinite;
}
.pulse.pulse-secondary {
  box-shadow: 0 0 0 rgba(var(--primary-tint2-rgb), 0.4);
}
@keyframes pulse-secondary {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--primary-tint2-rgb), 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--primary-tint2-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--primary-tint2-rgb), 0);
  }
}
[data-theme-mode=light] .layout-setting .dark-layout {
  display: none;
}
[data-theme-mode=light] .layout-setting .light-layout {
  display: block;
}
.layout-setting .dark-layout {
  display: none;
}
.layout-setting .light-layout {
  display: block;
}
[data-theme-mode=dark] .layout-setting .light-layout {
  display: none;
}
[data-theme-mode=dark] .layout-setting .dark-layout {
  display: block;
}
@media (max-width: 767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .app-header .dropdown-menu {
    width: 100%;
  }
}
.animated-arrow.hor-toggle {
  text-align: center;
  width: 2rem;
  font-size: 1.2rem;
  position: relative;
  margin: 0.3125rem 0;
  margin-inline-end: 1rem;
}
.animated-arrow {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: 102;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  padding: 0.3125rem;
  margin-block-start: 0.375rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
}
.animated-arrow.hor-toggle span {
  vertical-align: middle;
}
.animated-arrow span {
  cursor: pointer;
  height: 0.075rem;
  width: 0.75rem;
  background: var(--text-muted);
  position: absolute;
  display: block;
  content: "";
  transition: all 0.05s ease-in-out;
}
.animated-arrow span:before,
.animated-arrow span:after {
  transition: all 0.05s ease-in-out;
}
.animated-arrow span:before {
  inset-block-start: -0.375rem;
  width: 1.4375rem;
}
.animated-arrow span:after {
  bottom: -0.375rem;
  width: 15px;
}
.animated-arrow span:before,
.animated-arrow span:after {
  cursor: pointer;
  height: 0.075rem;
  width: 1rem;
  background: var(--text-muted);
  position: absolute;
  display: block;
  content: "";
}
.header-notification-text {
  max-width: 14.5rem;
}
.header-cart-text {
  max-width: 13rem;
}
@media (max-width: 1199.98px) {
  .header-search-bar {
    width: 11rem;
  }
}
@media (max-width: 575.98px) {
  .main-header-container .main-header-dropdown {
    inset-block-start: -0.0625rem !important;
    border-radius: 0 0 0.3rem 0.3rem;
  }
}
@media (max-width: 575.98px) {
  .main-header-container .header-element .header-link {
    padding: 0.5rem 0.3rem;
  }
}
.text-cart-headset {
  color: #19719e;
}
.text-cart-handbag {
  color: #de8cb2;
}
.text-cart-alaramclock {
  color: #06a7ef;
}
.text-cart-sweatshirt {
  color: #decac1;
}
.text-cart-smartwatch {
  color: #fb6c67;
}
.choices__inner {
  padding: 0.375rem 0.75rem !important;
  background-color: var(--form-control-bg) !important;
  border-radius: 0.35rem !important;
  font-size: 0.8125rem !important;
  border: 1px solid var(--input-border) !important;
  min-height: auto !important;
  line-height: 1.6;
}
.choices__input {
  padding: 0 !important;
}
.choices__list--single {
  padding-block: 0 !important;
  padding-inline-start: 0;
  padding-inline-end: 1rem !important;
}
.choices[data-type*=select-one]::after {
  inset-inline-end: 0.7188rem !important;
}
.choices[data-type*=select-one]::after {
  color: var(--text-muted) !important;
}
.choices[data-type*=select-one] .choices__input {
  padding: 0.625rem !important;
}
.choices__list--dropdown {
  display: none;
}
.choices__list--dropdown.is-active {
  display: block;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  z-index: 1 !important;
  border-radius: 0 0 0.3rem 0.3rem !important;
}
.choices[data-type*=select-one] .choices__input {
  border-block-end: 1px solid var(--default-border) !important;
  background-color: var(--custom-white) !important;
  color: var(--default-text-color);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.choices__list--dropdown .choices__item--selectable.is-selected {
  color: var(--primary-color) !important;
}
.choices__list--dropdown .choices__item--selectable.is-selected.is-highlighted {
  color: #fff !important;
}
.choices__list--dropdown .choices__list {
  max-height: 240px !important;
}
.choices[data-type*=select-one]::after {
  border-color: var(--text-muted) transparent transparent !important;
}
.choices__input {
  background-color: transparent !important;
  color: var(--custom-black);
}
.choices__list--multiple .choices__item {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  border-inline-start: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.choices__list--multiple .choices__item {
  margin-block-end: 3px !important;
}
.choices__list--single .choices__item {
  color: var(--default-text-color) !important;
}
.choices__input {
  margin-block-end: 0 !important;
}
.choices__list--multiple .choices__item {
  border-radius: 0.25rem !important;
  padding: 0.025rem 0.625rem !important;
}
.form-control-select-sm .choices__inner {
  padding: 0.275rem !important;
}
.choices[data-type*=select-one].is-open::after {
  margin-top: -0.156rem !important;
}
.input-group .choices__inner {
  border-radius: 0 0.3rem 0.3rem 0 !important;
}
.choices__heading {
  border-bottom: 1px solid var(--default-border) !important;
  color: var(--text-muted) !important;
}
[dir=rtl] .input-group .choices__inner {
  border-radius: 0.3rem 0 0 0.3rem !important;
}
[dir=rtl] .choices[data-type*=select-one]::after {
  left: 0.7188rem;
  right: inherit;
}
[dir=rtl] .choices[data-type*=select-one] .choices__button {
  left: 0;
  right: inherit;
  margin-left: 1.5625rem;
  margin-right: inherit;
}
[dir=rtl][data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}
[dir=rtl] .choices[data-type*=select-multiple] .choices__button,
[dir=rtl] .choices[data-type*=text] .choices__button {
  margin: 0 8px 0 -4px;
  padding-right: 1rem;
  padding-left: inherit;
  border-right: 0px solid #008fa1;
  border-left: 0;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  box-shadow: rgba(149, 157, 165, 0.2) 0 0.5rem 1.5rem !important;
}
[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}
#donut-pattern .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label,
#donut-pattern .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: var(--default-text-color) !important;
}
#radialbar-multiple .apexcharts-radialbar .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--default-text-color);
}
.apexcharts-menu-item {
  font-size: 11px !important;
}
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  color: var(--default-text-color) !important;
  border-radius: 0.25rem !important;
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: var(--default-border) !important;
}
.apexcharts-yaxistooltip-left:before {
  border-left-color: var(--default-border) !important;
}
#marketCap .apexcharts-canvas line {
  stroke: var(--default-border);
}
.apexcharts-legend {
  padding: 0 1.25rem !important;
}
.apexcharts-tooltip {
  box-shadow: none !important;
}
.apexcharts-tooltip-marker {
  margin-inline-end: 0.625rem;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid var(--default-border) !important;
  background: var(--custom-white) !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--custom-white) !important;
  border-block-end: 1px solid var(--default-border) !important;
}
.apexcharts-xaxis line,
.apexcharts-grid-borders line {
  stroke: var(--default-border);
}
.apexcharts-radialbar-track.apexcharts-track path {
  stroke: rgb(var(--light-rgb));
}
.apexcharts-selection-rect {
  fill: var(--custom-black);
}
.apexcharts-menu {
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  border-radius: 0.3rem !important;
}
.apexcharts-menu-item {
  padding: 6px !important;
}
.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: rgb(var(--light-rgb)) !important;
}
.apexcharts-inner.apexcharts-graphical line.apexcharts-xaxis-tick {
  stroke: transparent !important;
}
#column-rotated-labels .apexcharts-xaxis-texts-g {
  transform: translateY(2.5rem);
}
#chart-year,
#chart-quarter {
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
}
.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series .apexcharts-bar-area {
  stroke: transparent !important;
}
.apexcharts-treemap .apexcharts-series.apexcharts-treemap-series rect {
  stroke: var(--custom-white) !important;
}
.apexcharts-series.apexcharts-pie-series .apexcharts-pie-area {
  stroke: var(--custom-white) !important;
}
.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: var(--default-text-color);
}
.apexcharts-radialbar-hollow {
  fill: var(--custom-white);
}
.apexcharts-radar-series.apexcharts-plot-series polygon,
.apexcharts-radar-series.apexcharts-plot-series line {
  stroke: var(--default-border);
}
.apexcharts-pie line,
.apexcharts-pie circle {
  stroke: var(--default-border);
}
.apexcharts-pie text {
  fill: #fff !important;
}
.apexcharts-canvas .apexcharts-toolbar {
  z-index: 1;
}
.apexcharts-subtitle-text {
  fill: var(--text-muted);
}
#polararea-basic .apexcharts-pie text {
  fill: var(--custom-black) !important;
}
.apexcharts-pie .apexcharts-datalabels rect {
  fill: transparent;
}
[dir=rtl] .apexcharts-canvas {
  direction: ltr;
}
.apexcharts-boxPlot-area {
  stroke: var(--default-text-color) !important;
}
.apexcharts-gridline {
  stroke: var(--default-border);
}
.apexcharts-legend-text {
  font-family: var(--default-font-family) !important;
  font-weight: 500 !important;
}
.fc-v-event .fc-event-main {
  color: var(--default-text-color);
}
.fc-event-selected,
.fc-event:focus {
  box-shadow: none !important;
}
.fc-daygrid-event {
  padding: 0.25rem;
}
.fc-daygrid-event .fc-event-title {
  font-size: 13px;
  font-weight: 300;
}
.fc-h-event.bg-primary-transparent .fc-event-title,
.fc-h-event.bg-primary-transparent .fc-event-time {
  color: var(--primary-color);
}
.fc-h-event.bg-secondary-transparent .fc-event-title,
.fc-h-event.bg-secondary-transparent .fc-event-time {
  color: rgb(var(--secondary-rgb));
}
.fc-h-event.bg-warning-transparent .fc-event-title,
.fc-h-event.bg-warning-transparent .fc-event-time {
  color: rgb(var(--warning-rgb));
}
.fc-h-event.bg-info-transparent .fc-event-title,
.fc-h-event.bg-info-transparent .fc-event-time {
  color: rgb(var(--info-rgb));
}
.fc-h-event.bg-success-transparent .fc-event-title,
.fc-h-event.bg-success-transparent .fc-event-time {
  color: rgb(var(--success-rgb));
}
.fc-h-event.bg-danger-transparent .fc-event-title,
.fc-h-event.bg-danger-transparent .fc-event-time {
  color: rgb(var(--danger-rgb));
}
.fc-h-event {
  background-color: var(--primary01) !important;
}
.fc-h-event .fc-event-title {
  color: var(--primary-color);
}
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0px !important;
  border-top: 1px solid var(--default-border) !important;
}
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  border-bottom-width: 0 !important;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 0 0.25rem !important;
}
.fc .fc-button-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.fc .fc-non-business {
  background: var(--custom-white) !important;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 0.9;
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--default-border) !important;
  border-top: 0px !important;
}
.fc-list-table td,
.fc-list-table th {
  border-left: 0 !important;
  border-right: 0 !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--primary01) !important;
}
.fc-theme-standard .fc-list {
  border: 1px solid var(--default-border) !important;
}
.fc .fc-list-event:hover td {
  background-color: rgb(var(--light-rgb)) !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgb(var(--light-rgb)) !important;
}
.fc .fc-list-sticky .fc-list-day > * {
  z-index: 9;
}
.fc-theme-standard .fc-scrollgrid {
  border: 1px solid var(--default-border) !important;
}
.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid var(--default-border) !important;
}
@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    overflow: scroll !important;
  }
}
@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    font-size: 0.75em !important;
    padding: 0px 3px 0 !important;
  }
  .fc .fc-daygrid-more-link {
    z-index: 99 !important;
  }
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }
  .fc-toolbar-chunk {
    margin-block-start: 0.5rem;
  }
}
.fc-daygrid-block-event {
  color: #fff !important;
  border: 0 !important;
}
.fc-daygrid-block-event .fc-list-event-dot {
  border-color: #fff !important;
}
.fc-daygrid-block-event:hover {
  color: #000 !important;
}
.fc-daygrid-block-event:hover .fc-list-event-dot {
  border-color: #000 !important;
}
/*[dir=rtl] .flatpickr-input {
  text-align: start;
}
.flatpickr-calendar {
  background-color: var(--custom-white) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  border: 1px solid var(--default-border) !important;
  font-size: 0.8125rem !important;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: var(--black-9) !important;
  fill: var(--black-9) !important;
}
.rangeMode .flatpickr-day {
  margin-top: 4px !important;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px !important;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0 !important;
}
.flatpickr-monthDropdown-months,
.numInput {
  color: var(--custom-black) !important;
}
.flatpickr-day.today.inRange {
  color: var(--primary-color) !important;
}
.dayContainer {
  padding: 0.25rem !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--custom-white) !important;
  font-size: 0.813rem;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 1.25rem !important;
  height: 1.25rem !important;
  padding: 0.25rem;
  fill: var(--primary-color);
}
.flatpickr-day.inRange {
  box-shadow: none !important;
}
.flatpickr-calendar.open {
  z-index: 105 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  border-radius: 0.25rem !important;
  border: 1px dashed var(--primary03) !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: transparent !important;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--text-muted) !important;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-muted) !important;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: none !important;
}
.flatpickr-day {
  color: var(--default-text-color) !important;
  font-weight: 500 !important;
}
.flatpickr-day.nextMonthDay,
.flatpickr-day.prevMonthDay {
  opacity: 0.5 !important;
}
.flatpickr-day.today {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--default-background) !important;
  border-color: var(--default-border) !important;
}
.flatpickr-day.today:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--default-border) !important;
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--default-border) !important;
}
.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--default-border) !important;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--default-background) !important;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--text-muted) !important;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  inset-inline-start: 0 !important;
}
.flatpickr-months,
.flatpickr-weekdays {
  background-color: var(--primary01) !important;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  inset-block-start: 0 !important;
  padding-block-start: 0.313rem !important;
  padding-inline: 0.313rem !important;
  padding-block-end: 0 !important;
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
@media (min-width: 420px) {
  .flatpickr-time .flatpickr-am-pm {
    padding: 0 1.875rem 0 0.5rem;
  }
}
.flatpickr-weekdays {
  border-block-end: 1px solid var(--default-border) !important;
}
.numInputWrapper span.arrowUp {
  top: -0.125rem !important;
}
.flatpickr-current-month .numInputWrapper {
  width: 3.5rem !important;
}
.flatpickr-calendar.hasTime {
  width: auto;
}
[dir=rtl] .flatpickr-months .numInputWrapper span {
  left: 0 !important;
  right: inherit !important;
}
@media (max-width: 575.98px) {
  .flatpickr-calendar {
    width: 250px !important;
  }
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  margin: -1px 2.25rem 0 0 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  padding-block: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0.5ch !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  stroke: var(--primary-color);
}
.flatpickr-day {
  border-radius: 0.3rem !important;
}
.numInputWrapper:hover {
  background: transparent !important;
}
.numInputWrapper span {
  border: 0px !important;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-block-end-color: var(--primary-color) !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fff !important;
}*/
.numInputWrapper span:hover {
  background: transparent !important;
}
.numInputWrapper span.arrowUp:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-end: 0.25rem solid var(--primary05) !important;
  inset-block-start: 75% !important;
}
/*.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-block-start-color: var(--primary-color) !important;
}
.numInputWrapper span.arrowDown:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-start: 0.25rem solid var(--primary05) !important;
  inset-block-start: 15% !important;
}
span.flatpickr-weekday {
  color: var(--primary08) !important;
  font-weight: 700 !important;
}
.flatpickr-months .flatpickr-month {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.flatpickr-monthDropdown-months,
.numInput {
  color: var(--primary-color) !important;
}*/
.pcr-app {
  background: var(--custom-white) !important;
}
.pcr-app .pcr-interaction .pcr-result {
  color: var(--default-text-color) !important;
  background: var(--default-background) !important;
}
.theme-container button,
.theme-container1 button,
.theme-container2 button {
  display: none;
}
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
  margin-inline-end: 0.75em !important;
}
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
  margin-inline-start: 0.75em !important;
}
/*.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 var(--default-border) !important;
}*/
.noUi-horizontal .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
  inset-inline-end: -0.063rem !important;
  top: -0.375rem !important;
}
.noUi-handle:after,
.noUi-handle:before {
  height: 0.35rem !important;
  width: 1px !important;
  inset-inline-start: 0.3rem !important;
  top: 0.25rem !important;
}
.noUi-handle:after {
  inset-inline-start: 0.45rem !important;
}
.noUi-horizontal {
  height: 0.35rem !important;
}
.noUi-vertical {
  width: 0.35rem !important;
}
.noUi-vertical .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
}
.noUi-target {
  background: var(--default-background) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.noUi-handle {
  border: 1px solid var(--default-border) !important;
  background: var(--custom-white) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
#result {
  border: 1px solid var(--default-border) !important;
}
.noUi-handle:after,
.noUi-handle:before {
  background: var(--black-3) !important;
}
.noUi-marker {
  position: absolute;
  background: var(--default-border) !important;
}
.noUi-tooltip {
  border: 1px solid var(--default-border) !important;
  border-radius: 0.3rem !important;
  background: var(--custom-white) !important;
  color: var(--default-text-color) !important;
  padding: 0.25rem 0.75rem !important;
  font-size: 0.8125rem !important;
  line-height: 1 !important;
}
#slider-fit {
  padding: 0 1rem;
}
.noUi-connect {
  background: var(--primary-color) !important;
}
.noUi-vertical .noUi-handle {
  bottom: -0.275rem !important;
  inset-inline-end: -0.375rem;
}
#primary1-colored-slider .noUi-connect {
  background-color: var(--primary-tint1-color) !important;
}
#primary2-colored-slider .noUi-connect {
  background-color: var(--primary-tint2-color) !important;
}
#primary3-colored-slider .noUi-connect {
  background-color: var(--primary-tint3-color) !important;
}
#secondary-colored-slider .noUi-connect {
  background-color: rgb(var(--secondary-rgb)) !important;
}
#warning-colored-slider .noUi-connect {
  background-color: rgb(var(--warning-rgb)) !important;
}
#info-colored-slider .noUi-connect {
  background-color: rgb(var(--info-rgb)) !important;
}
#success-colored-slider .noUi-connect {
  background-color: rgb(var(--success-rgb)) !important;
}
#danger-colored-slider .noUi-connect {
  background-color: rgb(var(--danger-rgb)) !important;
}
#slider-round {
  height: 0.625rem !important;
}
#slider-round .noUi-handle {
  height: 1.125rem !important;
  width: 1.125rem !important;
  inset-block-start: -0.313rem !important;
  inset-inline-end: -0.563rem;
  border-radius: 50px !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--custom-white) !important;
}
#slider-round .noUi-handle:before,
#slider-round .noUi-handle:after {
  display: none !important;
}
#slider-square {
  border-radius: 0 !important;
}
#slider-square .noUi-handle {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: var(--primary-color) !important;
  height: 1.125rem !important;
  width: 1.125rem !important;
  inset-block-start: -0.45rem !important;
  inset-inline-end: -0.563rem !important;
}
#slider-square .noUi-handle:before,
#slider-square .noUi-handle:after {
  display: none !important;
}
#color1,
#color2,
#color3 {
  margin: 0.625rem !important;
  display: inline-block !important;
  height: 12.5rem !important;
}
#colorpicker {
  height: 15rem !important;
  width: 19.375rem !important;
  margin: 0 auto !important;
  padding: 0.625rem !important;
  border: 1px solid var(--default-border) !important;
}
#result {
  margin: 4.25rem 0 4.25rem 4rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: var(--gray-5);
  background: var(--gray-5);
  border: 1px solid #fff;
  box-shadow: 0 0 0.625rem;
}
#color1 .noUi-connect {
  background: rgb(var(--danger-rgb)) !important;
}
#color2 .noUi-connect {
  background: rgb(var(--secondary-rgb)) !important;
}
#color3 .noUi-connect {
  background: var(--primary-color) !important;
}
#slider-hide .noUi-tooltip {
  display: none;
}
#slider-hide .noUi-active .noUi-tooltip {
  display: block;
}
.c-1-color {
  background: rgb(var(--secondary-rgb)) !important;
}
.c-2-color {
  background: rgb(var(--warning-rgb)) !important;
}
.c-3-color {
  background: rgb(var(--info-rgb)) !important;
}
.c-4-color {
  background: rgb(var(--danger-rgb)) !important;
}
.c-5-color {
  background: #4d5ddb !important;
}
#slider-toggle {
  height: 3.125rem;
}
#slider-toggle.off .noUi-handle {
  border-color: rgb(var(--success-rgb)) !important;
}
.glightbox {
  overflow: hidden;
}
@media (min-width: 769px) {
  .gslide-image img {
    border-radius: 0.3rem;
  }
  .glightbox-clean .gclose,
  .glightbox-clean .gnext,
  .glightbox-clean .gprev {
    background-color: rgba(255, 255, 255, 0.05) !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: 0.875rem;
  }
}
#external-events .fc-event {
  cursor: move;
  margin: 0 0 0.4rem 0;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.35rem;
}
#calendar-container {
  position: relative;
  z-index: 1;
}
#calendar {
  max-width: 68.75rem;
  margin: 1.25rem auto;
}
#map,
#map1,
#map-popup,
#map-custom-icon,
#interactive-map {
  height: 18.75rem;
  z-index: 10;
}
.jvm-zoom-btn {
  background-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color) !important;
  border: 1px solid var(--default-border) !important;
  font-size: 20px;
}
#vector-map,
#marker-map,
#marker-image-map,
#lines-map,
#us-map,
#canada-map,
#spain-map,
#russia-map {
  height: 21.875rem;
}
.jvm-tooltip {
  background-color: #1a1c1e !important;
}
#vector-map #jvm-regions-group path,
#marker-map #jvm-regions-group path,
#marker-image-map #jvm-regions-group path,
#lines-map #jvm-regions-group path,
#users-map #jvm-regions-group path {
  fill: rgba(var(--dark-rgb), 0.08) !important;
}
.jvm-zoom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
.jvm-zoom-btn.jvm-zoomin {
  inset-block-start: 5px !important;
}
#jvm-markers-labels-group text {
  fill: var(--text-muted);
}
#google-map,
#google-map-overlay,
#map-layers,
#map-markers,
#streetview-map,
#map-geofencing {
  height: 18.75rem;
}
.google-map-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1.25rem;
  line-height: 0.875rem;
  opacity: 0.8;
  background: var(--primary-color);
  border: solid 0.188rem var(--primary-color);
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.625rem var(--black-3);
  text-shadow: 0.063rem 0.063rem 0.063rem var(--gray-6);
  padding: 0 0.25rem;
}
.google-overlay_arrow {
  inset-inline-start: 50%;
  margin-inline-start: -1rem;
  width: 0;
  height: 0;
  position: absolute;
}
.google-overlay_arrow.above {
  inset-block-end: -0.938rem;
  border-inline-start: 0.938rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-start: 1rem solid #336699;
}
.google-overlay_arrow.below {
  inset-block-start: -0.938rem;
  border-inline-start: 1rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-end: 1rem solid #336699;
}
.content-wrapper {
  width: 100%;
}
.apexcharts-svg,
.apexcharts-canvas {
  width: 100% !important;
}
#pie-basic .apexcharts-canvas,
#donut-update .apexcharts-canvas,
#pie-monochrome .apexcharts-canvas,
#donut-gradient .apexcharts-canvas,
#donut-pattern .apexcharts-canvas,
#pie-image .apexcharts-canvas,
#polararea-basic .apexcharts-canvas,
#polararea-monochrome .apexcharts-canvas {
  margin: 0 auto;
}
.apexcharts-legend-text {
  color: var(--default-text-color) !important;
  padding-inline-start: 0.9375rem !important;
  margin-inline-start: -0.625rem !important;
}
.apexcharts-text.apexcharts-yaxis-label tspan,
.apexcharts-text.apexcharts-xaxis-label tspan {
  fill: var(--text-muted);
}
.apexcharts-canvas .apexcharts-series.apexcharts-heatmap-series rect {
  stroke: var(--custom-white);
}
.apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble circle {
  stroke: var(--custom-white);
}
.apexcharts-yaxis .apexcharts-text {
  fill: var(--text-muted);
}
.chartjs-chart {
  max-height: 18.75rem;
}
#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-inline-start: 0;
  padding-block-start: 1.25rem;
  background: #fff;
  border: 1px solid #ddd;
}
#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;
}
#chart-year.chart-quarter-activated {
  transform: translateX(0);
  transition: 1s ease transform;
}
#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;
}
#chart-quarter.active {
  transition: 1.1s ease-in-out transform;
  transform: translateX(0);
  z-index: 1;
}
@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }
  #chart-quarter {
    transform: translateX(-50%);
  }
}
.echart-charts {
  height: 20rem;
}
.card.custom-card .card-body.p-0 .simplebar-track {
  right: 0;
}
.card.custom-card .simplebar-track {
  right: -18px;
}
.simplebar-scrollbar:before {
  background: var(--gray-4) !important;
  border-radius: 0.3rem !important;
  inset-inline-end: 0 !important;
  width: 0.375rem;
}
.simplebar-track.simplebar-horizontal {
  display: none;
}
.simplebar-track.simplebar-vertical {
  width: 8px !important;
}
.dropzone {
  border: 0.125rem dashed var(--default-border) !important;
  background-color: transparent !important;
}
.dropzone .dz-message .dz-button {
  font-size: 1.25rem !important;
  color: var(--default-text-color) !important;
}
.dropzone .dz-preview:hover {
  z-index: 8 !important;
}
.dropzone .dz-preview {
  border-radius: 1.25rem;
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-progress {
  z-index: 10 !important;
}
.filepond--drop-label {
  color: var(--default-text-color) !important;
  border-radius: 0.3rem !important;
}
.filepond--credits {
  display: none;
}
.filepond--panel-root {
  background-color: var(--custom-white) !important;
  border: 0.125rem dashed var(--default-border) !important;
}
.filepond--drop-label.filepond--drop-label label {
  padding: 1.5em !important;
  font-size: 0.8125rem;
}
.filepond--root {
  margin-block-end: 0 !important;
}
.filepond--file {
  background-color: var(--primary-color) !important;
}
.single-fileupload {
  width: 8rem !important;
  height: 8rem !important;
  margin: 0 auto !important;
}
.ql-container.ql-bubble .ql-editor {
  overflow-y: auto !important;
}
.ql-bubble .ql-editor blockquote {
  border-inline-start: 4px solid var(--default-background) !important;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid var(--default-border) !important;
}
.ql-snow .ql-picker {
  color: var(--default-text-color) !important;
}
.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  stroke: var(--default-text-color) !important;
}
.ql-snow .ql-fill {
  fill: var(--default-text-color) !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px !important;
}
.ql-snow .ql-picker-options .ql-picker-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ql-editor {
  min-height: 15.62rem !important;
  overflow: visible !important;
}
.ql-snow .ql-formats {
  border: 1px solid var(--default-border);
  border-radius: 0.5rem;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--default-border) !important;
  border-radius: 0.5rem !important;
}
.ql-snow .ql-picker-options {
  background-color: var(--custom-white) !important;
}
.ql-snow .ql-tooltip {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  color: var(--default-text-color) !important;
}
.ql-snow .ql-tooltip input[type=text] {
  border: 1px solid var(--default-border) !important;
  background-color: var(--default-background) !important;
  color: var(--default-text-color) !important;
  outline: 0;
}
.ql-snow .ql-tooltip {
  transform: translatex(12.5rem) !important;
  z-index: 100 !important;
}
.ql-toolbar.ql-snow {
  border-radius: 0.3rem 0.3rem 0 0 !important;
}
.ql-snow .ql-picker-label {
  padding-inline-start: 0.5rem !important;
  padding-inline-end: 0.125rem !important;
}
.ql-snow .ql-formats .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  inset-inline-end: 0 !important;
  inset-inline-start: auto !important;
}
.ql-container {
  border-radius: 0 0 0.3rem 0.3rem !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--default-text-color) !important;
}
.ql-editor {
  text-align: start !important;
}
.ql-editor .ql-align-right {
  text-align: end !important;
}
.ql-container {
  font-family: var(--default-font-family) !important;
}
.ql-snow .ql-editor {
  padding: 1.25rem;
}
.ql-bubble {
  border: 1px solid var(--default-border) !important;
  border-radius: 0.3rem !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-inline-start: -1.5em !important;
  margin-inline-end: 0.3em !important;
  text-align: start !important;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-inline-start: 1.5em !important;
}
.ql-toolbar.ql-snow .ql-formats {
  margin: 0.25rem;
}
[dir=rtl] .ql-tooltip.ql-editing {
  left: 0 !important;
  top: 0 !important;
}
[dir=rtl] .ql-bubble .ql-toolbar .ql-formats:first-child {
  margin-right: 12px;
}
[dir=rtl] .ql-bubble .ql-toolbar .ql-formats {
  margin: 8px 0px 8px 12px;
}
[dir=rtl] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  right: 0 !important;
  left: inherit !important;
}
[dir=rtl] .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-right: 5px !important;
  padding-left: inherit !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  padding: 2px 4px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px !important;
}
.select2.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-inline-start: 12px !important;
  padding-inline-end: 20px !important;
  color: var(--default-text-color) !important;
  line-height: 2.33rem !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 0.3rem !important;
}
.select2-container--default .select2-selection--single {
  background-color: var(--form-control-bg) !important;
  border: 0px solid var(--input-border) !important;
  border-radius: 0.3rem !important;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.37rem !important;
}
.select2-dropdown {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 0.3rem !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid var(--input-border) !important;
  border-radius: 0.3rem !important;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--default-background) !important;
  color: var(--default-text-color) !important;
}
.select2-results__option--selectable {
  font-size: 0.813rem;
}
.select2-container--default .select2-results__option--selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.select2-container--default .select2-results__option--selected.select2-results__option--highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.select2-search__field {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}
.select2-search__field:focus-visible {
  outline: none !important;
}
.select2-container--default .select2-selection--multiple {
  background-color: var(--form-control-bg) !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 0.3rem !important;
}
.select2-container .select2-selection--multiple {
  min-height: 2.25rem !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 0.3rem !important;
  margin-block-start: 0.375rem !important;
  padding: 0px 18px !important;
}
.select2-selection--multiple .select2-search__field {
  background-color: transparent !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-inline-end: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
  inset-block-start: -0.3rem !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
}
.select2-selection--multiple .select2-selection__choice__display {
  font-size: 0.75rem !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: var(--primary-color) !important;
}
.select2-results__option span img,
.select2-selection__rendered span img {
  width: 1.45rem;
  height: 1.45rem;
  border-radius: 1.25rem;
  margin-inline-end: 0.25rem;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}
.select2-container .select2-search--inline .select2-search__field {
  margin-block-start: 0.5rem !important;
}
.select2-container--disabled.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--disabled .select2-selection.select2-selection--multiple {
  background-color: var(--default-background) !important;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  font-weight: 400 !important;
  height: 1.25rem !important;
  font-size: 1.5625rem !important;
  width: 1.25rem !important;
  position: absolute !important;
  inset-inline-end: 0.625rem !important;
}
.select2-selection__clear {
  color: var(--text-muted);
}
.select2-dropdown {
  z-index: 10 !important;
}
[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__clear {
  margin-left: 20px !important;
  padding-left: 0px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px !important;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 10px !important;
  right: auto !important;
}
.select2-container--open .select2-dropdown--above {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  overflow: hidden;
}
.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  overflow: hidden;
}
.gridjs-table {
  width: 100%;
}
table.gridjs-table {
  text-align: start !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}
.gridjs-wrapper {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.gridjs-wrapper:nth-last-of-type(2) {
  border-radius: 0 !important;
}
.gridjs-container {
  color: var(--default-text-color) !important;
}
th.gridjs-th {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  color: var(--default-text-color) !important;
  padding: 0.75rem !important;
}
td.gridjs-td {
  border: 1px solid var(--default-border) !important;
  padding: 0.75rem !important;
}
.gridjs-tbody,
td.gridjs-td {
  background-color: var(--custom-white) !important;
}
.gridjs-footer {
  background-color: var(--custom-white) !important;
  border-bottom-width: 0 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  box-shadow: none !important;
  padding: 0.75rem 0 0 0 !important;
}
.gridjs-pagination {
  color: var(--default-text-color) !important;
}
.gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 0.3rem !important;
  border-top-left-radius: 0.3rem !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}
@media (max-width: 575.98px) {
  .gridjs-search-input {
    width: 12.5rem !important;
  }
}
[dir=rtl] .gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}
[dir=rtl] .gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-left-radius: 0.3rem !important;
  border-top-left-radius: 0.3rem !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}
.gridjs-pagination .gridjs-pages button {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  padding: 0.375rem 0.75rem !important;
  color: var(--default-text-color) !important;
}
.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: none !important;
  margin-inline-end: 0 !important;
}
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  font-weight: 600 !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.gridjs-pagination .gridjs-pages {
  float: right !important;
}
input.gridjs-input {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  border-radius: 0.3rem !important;
  font-size: 0.8125rem !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.6 !important;
  color: var(--default-text-color) !important;
}
input.gridjs-input:focus {
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
}
button.gridjs-sort {
  float: right !important;
  height: 1.25rem !important;
  width: 0.625rem !important;
}
button.gridjs-sort-desc {
  background-position-y: 54% !important;
  background-size: 0.5rem !important;
}
button.gridjs-sort-asc {
  background-position-y: 44% !important;
  background-size: 0.5rem !important;
}
th.gridjs-th-fixed {
  background-color: rgb(var(--light-rgb)) !important;
}
#grid-header-fixed .gridjs-wrapper {
  border-block-start: 1px solid var(--default-border) !important;
  border-block-end: 1px solid var(--default-border) !important;
}
#grid-header-fixed .gridjs-container .gridjs-wrapper .gridjs-thead .gridjs-tr th {
  top: -1px !important;
}
.dataTables_wrapper .dataTables_paginate {
  margin: 0;
  text-align: end;
}
.dataTables_wrapper .dataTables_paginate .pagination {
  margin-block-end: 0;
  justify-content: end;
}
div.dataTables_scrollBody > table#datatable-basic {
  margin-block-end: 0.375rem !important;
}
.dataTables_filter {
  text-align: end !important;
}
.dataTables_info {
  padding-block-start: 0.625rem;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc_disabled:before {
  content: "\f235" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 0.813rem !important;
  font-size: 0.5rem !important;
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_desc_disabled:after {
  content: "\f229" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 1.25rem !important;
  font-size: 0.5rem !important;
}
table.dataTable > thead .sorting,
table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_desc,
table.dataTable > thead .sorting_asc_disabled,
table.dataTable > thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.2;
}
[dir=rtl] table.dataTable thead > tr > th.sorting:before,
[dir=rtl] table.dataTable thead > tr > th.sorting:after,
[dir=rtl] table.dataTable thead > tr > th.sorting_asc:before,
[dir=rtl] table.dataTable thead > tr > th.sorting_asc:after,
[dir=rtl] table.dataTable thead > tr > th.sorting_desc:before,
[dir=rtl] table.dataTable thead > tr > th.sorting_desc:after,
[dir=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:before,
[dir=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:after,
[dir=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:before,
[dir=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:after,
[dir=rtl] table.dataTable thead > tr > td.sorting:before,
[dir=rtl] table.dataTable thead > tr > td.sorting:after,
[dir=rtl] table.dataTable thead > tr > td.sorting_asc:before,
[dir=rtl] table.dataTable thead > tr > td.sorting_asc:after,
[dir=rtl] table.dataTable thead > tr > td.sorting_desc:before,
[dir=rtl] table.dataTable thead > tr > td.sorting_desc:after,
[dir=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:before,
[dir=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:after,
[dir=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:before,
[dir=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:after {
  right: auto !important;
  left: 10px !important;
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 0.8;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0 0.25rem;
}
.dataTables_wrapper .dataTables_scrollHead table.dataTable {
  margin-block-end: 0;
}
.dataTables_wrapper .dataTables_scrollBody #datatable-basic {
  margin-block-start: -3px;
  border-block-start-color: transparent !important;
}
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_asc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_asc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_desc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_desc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_asc_disabled:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_asc_disabled:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_desc_disabled:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > thead .sorting_desc_disabled:after {
  display: none;
}
div.dt-button-info {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
}
div.dt-button-info h2 {
  background-color: var(--custom-white) !important;
  border-bottom: 1px solid var(--default-border) !important;
}
@media (max-width: 767.98px) {
  .data-table-btn {
    margin: 0.5625rem 0 0.5rem 0 !important;
  }
  .dataTables_length,
  .dataTables_filter,
  .dataTables_info {
    text-align: center;
  }
  .dataTables_filter {
    margin-block-start: 0.5rem;
  }
  .dataTables_paginate .pagination {
    justify-content: center !important;
  }
  .dataTables_info {
    padding-block-start: 0;
    padding-block-end: 0.5rem;
  }
  div.dtr-modal div.dtr-modal-display {
    width: 95% !important;
    height: 95% !important;
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  }
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center !important;
  }
}
@media (max-width: 575.98px) {
  .dataTables_paginate .pagination .paginate_button .page-link {
    font-size: 0.625rem;
    padding: 0.25rem 0.5rem;
  }
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control {
  padding-inline-start: 1.875rem !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  color: #fff !important;
  border: 0.15em solid #fff !important;
  box-shadow: 0 0 0.2em var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
  content: "-";
  background-color: rgb(var(--success-rgb)) !important;
}
table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-block-start: 0 !important;
}
table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-block-end: 1px solid var(--default-border) !important;
}
table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 4.688rem !important;
}
div.dtr-modal div.dtr-modal-close {
  top: 0px !important;
  border: 0px solid var(--default-border) !important;
  font-size: 1.5rem !important;
  background-color: transparent !important;
}
div.dtr-modal div.dtr-modal-background {
  background: rgba(0, 0, 0, 0.3) !important;
}
.dtr-modal-content h2 {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
.dt-button {
  font-size: 0.8125rem !important;
  border-radius: 0.3rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  padding: 0.375rem 0.75rem !important;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}
.dt-buttons {
  float: left !important;
}
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  padding-inline-end: 1.625rem;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: start !important;
}
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
  border-inline-start-width: 1px !important;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-inline-end-width: 1px !important;
}
div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
  margin-right: 0.2em;
}
[dir=rtl] div.dtr-modal div.dtr-modal-close {
  left: 6px;
  right: auto;
}
[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-left: 0;
  padding-right: initial;
}
[dir=rtl] div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
  margin-right: 0.5rem;
  margin-left: 0.2em;
}
[dir=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-right: 0.5rem;
  margin-left: 0;
}
[dir=rtl] table.table-bordered.dataTable th:first-child,
[dir=rtl] table.table-bordered.dataTable th:first-child,
[dir=rtl] table.table-bordered.dataTable td:first-child,
[dir=rtl] table.table-bordered.dataTable td:first-child {
  border-right-width: 1px;
  border-left-width: 0;
}
[dir=rtl] table.table-bordered.dataTable th:last-child,
[dir=rtl] table.table-bordered.dataTable th:last-child,
[dir=rtl] table.table-bordered.dataTable td:last-child,
[dir=rtl] table.table-bordered.dataTable td:last-child {
  border-inline-start-width: 1px;
}
[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-inline-end: 0;
}
[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-inline-start: 0;
}
[dir=rtl] .dt-buttons {
  float: right !important;
}
table.dataTable > tbody > tr.selected > * {
  background-color: var(--primary01);
  color: var(--default-text-color) !important;
  box-shadow: none !important;
}
@media (min-width: 768px) {
  .data-table-btn {
    position: absolute;
    inset-inline-start: 12.5rem;
    z-index: 1;
  }
}
#file-export_wrapper .dt-buttons button {
  margin: 0.25rem;
}
.data-table-btn {
  margin: -0.4375rem 0 0 0;
}
div:where(.swal2-container) input:where(.swal2-input),
div:where(.swal2-container) input:where(.swal2-file),
div:where(.swal2-container) textarea:where(.swal2-textarea) {
  border: 1px solid var(--default-border) !important;
}
.swal2-container .swal2-title {
  font-size: 1.15rem;
  padding: 2rem 2rem 0.5rem;
}
.swal2-container .swal2-footer {
  padding: 1.5rem 1.5rem 0;
}
.swal2-container .swal2-popup {
  background: var(--custom-white);
  color: var(--default-text-color);
  padding: 0 0 2rem;
}
.swal2-container .swal2-center > .swal2-popup {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.swal2-container .swal2-actions {
  margin: 1rem auto 0;
}
.swal2-container .swal2-styled.swal2-confirm {
  margin: 0;
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}
.swal2-container .swal2-styled.swal2-confirm:focus,
.swal2-container .swal2-styled.swal2-cancel:focus,
.swal2-container .swal2-styled.swal2-deny:focus {
  box-shadow: none;
}
.swal2-container .swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
  color: #fff;
}
.swal2-container .swal2-footer {
  border-top: 1px solid var(--default-border);
}
.swal2-container .swal2-icon {
  width: 4rem;
  height: 4rem;
  margin: 2rem auto 0rem;
}
.swal2-container .swal2-icon.swal2-question {
  border-color: rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}
.swal2-container .swal2-icon.swal2-error {
  border-color: rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}
.swal2-container .swal2-icon.swal2-info {
  border-color: rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}
.swal2-container .swal2-icon.swal2-warning {
  border-color: rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}
.swal2-container .swal2-icon .swal2-icon-content {
  font-size: 3rem;
}
.swal2-container .swal2-image {
  border-radius: 0.3rem;
}
.swal2-container .swal2-html-container {
  margin: 0rem 1.6rem 0.3rem;
  font-size: 0.8rem;
  color: var(--text-muted);
}
.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  inset-inline-start: 1em;
}
.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 1.9em;
  width: 2em;
  height: 0.3em;
  background-color: rgb(var(--danger-rgb));
}
.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  inset-inline-end: 1em;
  inset-block-start: 1.875rem;
}
.swal2-container .swal2-close:focus {
  box-shadow: none;
}
.swal2-container .swal2-deny,
.swal2-container .swal2-cancel {
  margin-inline-start: 0.625rem;
}
.swal2-container .swal2-close {
  font-size: 2rem;
  color: var(--text-muted);
  opacity: 0.5;
}
.swal2-container .swal2-close:hover {
  color: var(--primary-color);
}
.swal2-container .swal2-styled.swal2-deny {
  background-color: rgb(var(--danger-rgb));
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}
.swal2-container .swal2-styled.swal2-cancel {
  background-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color);
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}
.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.05em;
  inset-inline-end: 0.45em;
  width: 2em;
}
.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.375em;
  inset-inline-start: 0.9125em;
  width: 1.2625em;
}
.swal2-container .swal2-file:focus,
.swal2-container .swal2-input:focus,
.swal2-container .swal2-textarea:focus {
  border: 1px solid var(--default-border);
  box-shadow: none;
}
[dir=rtl] .swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  inset-inline-start: 1.9125em;
}
.swiper {
  border-radius: 0.3rem;
}
[dir=rtl] .swiper {
  direction: ltr;
}
[dir=rtl] .swiper-backface-hidden .swiper-slide {
  direction: rtl;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev {
  width: 1.563rem !important;
  height: 1.563rem !important;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.3rem;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0.75rem !important;
  font-weight: 800 !important;
}
.swiper-horizontal1 .swiper-slide {
  height: auto;
}
.swiper-pagination-bullet {
  width: 1.25rem !important;
  height: 0.25rem !important;
  border-radius: 0.3rem !important;
  background: #fff !important;
}
.swiper-pagination-bullet-active {
  background: #fff !important;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: rgb(var(--success-rgb)) !important;
}
.swiper-pagination {
  color: #fff;
}
.swiper-pagination.swiper-pagination-fraction {
  color: #fff;
}
.custom-pagination .swiper-pagination-bullet {
  width: 1.5rem !important;
  height: 1.5rem !important;
  padding: 0.188rem;
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
  opacity: 1 !important;
}
.custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff !important;
  color: #000 !important;
}
.swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.swiper-scrollbar-drag {
  background-color: #fff !important;
}
.swiper.vertical {
  height: 21.875rem;
}
.swiper-preview {
  height: 80%;
  width: 100%;
}
.swiper-view {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.swiper-view .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.swiper-view .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-preview .swiper-wrapper {
  margin-block-end: 0.65rem;
}
.swiper-thumbs .swiper-slide img {
  border-radius: 0.3rem;
}
pre[class*=language-]:after,
pre[class*=language-]:before {
  display: none !important;
}
pre[class*=language-] > code {
  border-inline-start: 1px solid var(--default-border) !important;
  box-shadow: none !important;
  background-color: rgb(var(--light-rgb)) !important;
  border: 1px solid var(--default-border);
  border-radius: 0.3rem;
  background-image: none !important;
  white-space: pre-wrap;
}
:not(pre) > code[class*=language-],
pre[class*=language-] {
  background-color: var(--custom-white) !important;
  margin-bottom: 0 !important;
  max-height: 400px;
  overflow-y: hidden !important;
  border-radius: 0.3rem;
}
code[class*=language-],
pre[class*=language-] {
  color: var(--default-text-color) !important;
  font-size: 0.82rem !important;
}
pre[class*=language-] {
  margin: 0 !important;
}
code[class*=language-] {
  padding: 1rem !important;
}
.prism-toggle {
  margin: 0.25rem;
}
[dir=rtl] pre[class*=language-] > code {
  border-left: 0;
  text-align: right;
}
#draggable-left .card,
#draggable-right .card {
  touch-action: none;
}
.star-rating {
  touch-action: none;
}
.star-rating .star-value {
  touch-action: none;
}
.fg-emoji-picker {
  top: auto !important;
  bottom: 120px !important;
}
@media (max-width: 420px) {
  .fg-emoji-picker {
    bottom: 136px !important;
    left: 13px !important;
  }
}
.fg-emoji-picker-search input {
  padding: 10px !important;
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
  border-bottom: 1px solid var(--default-border) !important;
}
.fg-emoji-picker-grid > li {
  flex: 0 0 14.2857142857% !important;
  height: 38px !important;
}
.fg-emoji-picker-grid > li:hover {
  background-color: rgb(var(--light-rgb)) !important;
  border-radius: 0.3rem !important;
}
.fg-emoji-picker .fg-emoji-picker-all-categories {
  height: 288px !important;
}
.fg-emoji-picker * {
  color: var(--default-text-color) !important;
  font-family: var(--default-font-family) !important;
  fill: var(--default-text-color) !important;
}
.fg-emoji-picker-categories li.active {
  background-color: var(--primary01) !important;
}
.fg-emoji-picker-categories {
  background: var(--custom-white) !important;
}
.fg-emoji-picker-categories ul {
  border-bottom: 1px solid var(--default-border) !important;
}
a.fg-emoji-picker-close-button {
  background-color: rgb(var(--light-rgb)) !important;
}
.fg-emoji-picker-search svg {
  width: 45px !important;
  height: 39px !important;
  inset-inline-end: 0 !important;
  top: 0 !important;
  padding: 10px !important;
  border-inline-start: 1px solid var(--default-border) !important;
  fill: var(--default-text-color) !important;
  border-bottom: 1px solid var(--default-border);
}
.fg-emoji-picker {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
}
.fg-emoji-picker-grid > li {
  background-color: var(--custom-white) !important;
}
.fg-emoji-picker-categories a:hover {
  background-color: var(--primary01) !important;
}
.fg-emoji-picker .fg-emoji-picker-category {
  padding-top: 0 !important;
}
.fg-emoji-picker-search {
  height: 38px !important;
}
.plyr__control--overlaid {
  background: var(--primary-color) !important;
}
.audio-control .plyr__controls__item svg {
  fill: var(--default-text-color) !important;
  opacity: 0.5;
}
.plyr__controls__item.plyr__time--current {
  color: var(--default-text-color) !important;
  opacity: 0.5;
}
.plyr--full-ui input[type=range] {
  color: var(--primary-color) !important;
}
.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true],
.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true],
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
  background: var(--primary-color) !important;
  color: #fff !important;
}
.plyr--audio .plyr__controls {
  background: var(--custom-white) !important;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: 0.15 !important;
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: var(--custom-black) !important;
}
.shepherd-button {
  background: rgb(var(--success-rgb)) !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
}
.shepherd-element {
  background: var(--custom-white) !important;
}
.shepherd-header {
  background-color: rgb(var(--light-rgb)) !important;
  padding: 0.5rem !important;
}
.shepherd-title {
  font-weight: 500 !important;
}
.shepherd-text {
  font-size: 13px !important;
}
.shepherd-title,
.shepherd-text {
  color: var(--default-text-color) !important;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: rgb(var(--light-rgb)) !important;
  border: 1px solid var(--input-border);
}
.shepherd-arrow:before {
  background: var(--custom-white) !important;
}
.autoComplete_wrapper > ul {
  z-index: 10 !important;
}
.autoComplete_wrapper > input {
  border-color: var(--input-border) !important;
  color: var(--default-text-color) !important;
  background-color: var(--form-control-bg) !important;
  font-size: 0.875rem !important;
  font-weight: var(--default-font-weight) !important;
  line-height: 1.5 !important;
  border-radius: 0.35rem !important;
  padding: 0.5rem 0.85rem !important;
  background-image: none !important;
  height: inherit !important;
}
.autoComplete_wrapper > input:focus {
  border-color: var(--primary05) !important;
  box-shadow: 0 0 4px var(--primary05) !important;
}
.autoComplete_wrapper > input:focus::-moz-placeholder {
  padding: 0 !important;
}
.autoComplete_wrapper > input:focus::placeholder {
  padding: 0 !important;
}
.autoComplete_wrapper > input::-moz-placeholder {
  font-size: 0.8rem !important;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color) !important;
}
.autoComplete_wrapper > input::placeholder {
  font-size: 0.8rem !important;
  font-weight: 400;
  opacity: 0.6;
  color: var(--default-text-color) !important;
}
.autoComplete_wrapper > input::-moz-placeholder:focus {
  padding: 0 !important;
  font-size: 0.8rem !important;
}
.autoComplete_wrapper > input::placeholder:focus {
  padding: 0 !important;
  font-size: 0.8rem !important;
}
.autoComplete_wrapper > ul {
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  margin-top: 0 !important;
}
.autoComplete_wrapper > ul .no_result {
  padding: 0.5rem !important;
}
.autoComplete_wrapper > ul > li {
  font-size: 0.85rem !important;
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}
.autoComplete_wrapper > ul > li:hover {
  background-color: var(--primary01) !important;
  color: var(--default-text-color) !important;
}
.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  color: rgb(var(--orange-rgb)) !important;
  font-weight: bold;
  padding: 0 !important;
}
#autoComplete_list_2 p {
  padding: 0.5rem !important;
  margin-block-end: 0 !important;
}
.autoComplete_wrapper > input {
  width: auto !important;
}
@media (min-width: 367px) {
  .telephone-input-btn {
    margin-inline-start: 0.5rem;
  }
}
@media (max-width: 366.98px) {
  .telephone-input-btn {
    margin-block-start: 0.5rem;
  }
}
.hide {
  display: none;
}
input.error {
  border: 1px solid rgb(var(--danger-rgb));
}
#error-msg {
  color: rgb(var(--danger-rgb));
}
#valid-msg {
  color: rgb(var(--success-rgb));
}
.iti__search-input:focus-visible {
  outline: none;
}
[dir=rtl] .selected-dial-code-input .iti--allow-dropdown input.iti__tel-input,
[dir=rtl] .selected-dial-code-input .iti--allow-dropdown input.iti__tel-input[type=tel],
[dir=rtl] .selected-dial-code-input .iti--allow-dropdown input.iti__tel-input[type=text],
[dir=rtl] .selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input,
[dir=rtl] .selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input[type=tel],
[dir=rtl] .selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input[type=text] {
  padding-right: 71px !important;
  padding-left: 6px !important;
}
.selected-dial-code-input .iti--allow-dropdown input.iti__tel-input,
.selected-dial-code-input .iti--allow-dropdown input.iti__tel-input[type=tel],
.selected-dial-code-input .iti--allow-dropdown input.iti__tel-input[type=text],
.selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input,
.selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input[type=tel],
.selected-dial-code-input .iti--show-selected-dial-code input.iti__tel-input[type=text] {
  padding-left: 71px !important;
  padding-right: 6px !important;
}
.iti__search-input {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}
.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--default-border) !important;
}
.iti--inline-dropdown .iti__dropdown-content {
  border: 1px solid var(--default-border) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.iti__dropdown-content {
  background-color: var(--custom-white) !important;
}
.iti__country.iti__highlight {
  background-color: var(--primary01) !important;
}
.iti--show-selected-dial-code .iti__selected-flag {
  background-color: rgb(var(--light-rgb)) !important;
}
.tagify {
  --tag-inset-shadow-size: 0 !important;
}
.tagify__tag {
  background-color: rgb(var(--light-rgb));
}
.tagify.tagify--mix.form-control {
  padding: 0.5rem;
}
.tagify__tag > div::before {
  box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--gray-3) inset !important;
}
.tagify:hover:not(.tagify--focus):not(.tagify--invalid) {
  border-color: var(--input-border) !important;
}
.tagify {
  border-color: var(--input-border) !important;
}
.tagify.tagify--focus {
  border-color: var(--primary05) !important;
  box-shadow: 0 0 4px var(--primary05) !important;
}
.tagify__tag {
  margin-inline: 7px 0 !important;
  margin-block: 7px !important;
}
.tagify__tag > div {
  font-size: 13px;
}
.tagify__input {
  margin: 3px !important;
  line-height: 1.85rem !important;
}
.tagify {
  --tags-disabled-bg: var(--gray-3) !important;
  --tag-bg: var(--gray-3) !important;
  --tag-hover: rgba(var(--primary-rgb), 0.15) !important;
  --tag-text-color: var(--default-text-color) !important;
  --tag-text-color--edit: var(--default-text-color) !important;
  --tag-invalid-color: rgba(var(--danger-rgb), 0.5) !important;
  --tag-invalid-bg: rgba(var(--danger-rgb), 0.2) !important;
  --tag-remove-bg: rgba(var(--danger-rgb), 0.1) !important;
  --tag-remove-btn-color: var(--default-text-color) !important;
  --tag-remove-btn-bg--hover: rgba(var(--danger-rgb), 0.3) !important;
  --tag-pad: 0.2em 0.5em !important;
}
.tagify__tag__removeBtn {
  margin: 0 0.25rem !important;
}
.tagify__tag:focus div::before,
.tagify__tag:hover:not([readonly]) div::before {
  --tag-bg-inset: 0px !important;
}
.tagify__tag-text {
  padding: 3px;
}
.tags-look {
  border: 1px solid var(--default-border) !important;
  background-color: var(--custom-white) !important;
}
.tags-look .tagify__dropdown__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  padding: 0.3em 0.5em;
  border: 1px solid var(--default-border);
  background: var(--custom-white);
  margin: 0.2em;
  font-size: 0.85em;
  color: var(--default-text-color);
  transition: 0s;
}
.tags-look .tagify__dropdown__item--active {
  color: var(--default-text-color);
}
.tags-look .tagify__dropdown__item:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.tags-look .tagify__dropdown__item--hidden {
  max-width: 0;
  max-height: initial;
  padding: 0.3em 0;
  margin: 0.2em 0;
  white-space: nowrap;
  text-indent: -20px;
  border: 0;
}
.tagify__dropdown {
  border-top: 1px solid var(--primary-color) !important;
}
.tagify__tag .tagify__tag__avatar-wrap {
  width: 22px;
  height: 22px;
  white-space: normal;
  border-radius: 50%;
  margin-right: 5px;
  transition: 0.12s ease-out;
  vertical-align: middle;
}
.tagify__tag img {
  width: 100%;
  vertical-align: top;
}
.tagify__dropdown.users-list .tagify__dropdown__item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 1em;
  grid-template-areas: "avatar name" "avatar email";
}
.tagify__dropdown.users-list header.tagify__dropdown__item {
  grid-template-areas: "add remove-tags" "remaning .";
}
.tagify__dropdown.users-list .tagify__dropdown__item:hover .tagify__dropdown__item__avatar-wrap {
  transform: scale(1.2);
}
.tagify__dropdown.users-list .tagify__dropdown__item__avatar-wrap {
  grid-area: avatar;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(var(--light-rgb));
  transition: 0.1s ease-out;
}
.tagify__dropdown.users-list img {
  width: 100%;
  vertical-align: top;
}
.tagify__dropdown.users-list header.tagify__dropdown__item > div,
.tagify__dropdown.users-list .tagify__dropdown__item strong {
  grid-area: name;
  width: 100%;
  align-self: center;
}
.tagify__dropdown.users-list span {
  grid-area: email;
  width: 100%;
  font-size: 0.9em;
  opacity: 0.6;
}
.tagify__dropdown.users-list .tagify__dropdown__item__addAll {
  border-bottom: 1px solid var(--default-border);
  gap: 0;
}
.tagify__dropdown.users-list .remove-all-tags {
  grid-area: remove-tags;
  justify-self: self-end;
  font-size: 0.8em;
  padding: 0.2em 0.3em;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.tagify__dropdown.users-list .remove-all-tags:hover {
  color: #fff;
  background: rgb(var(--orange-rgb));
}
#users-list .tagify__tag {
  white-space: nowrap;
}
#users-list .tagify__tag img {
  width: 100%;
  vertical-align: top;
  pointer-events: none;
}
#users-list .tagify__tag:hover .tagify__tag__avatar-wrap {
  transform: scale(1.6) translateX(-10%);
}
#users-list .tagify__tag .tagify__tag__avatar-wrap {
  width: 16px;
  height: 16px;
  white-space: normal;
  border-radius: 50%;
  background: rgb(var(--light-rgb));
  margin-right: 5px;
  transition: 0.12s ease-out;
}
.users-list .tagify__dropdown__itemsGroup:empty {
  display: none;
}
.users-list .tagify__dropdown__itemsGroup::before {
  content: attr(data-title);
  display: inline-block;
  font-size: 0.9em;
  padding: 4px 6px;
  /*margin: var(--tagify-dd-item-pad);*/
  font-style: italic;
  border-radius: 4px;
  background: rgb(var(--success-rgb));
  color: var(--custom-white);
  font-weight: 600;
}
.users-list .tagify__dropdown__itemsGroup:not(:first-of-type) {
  border-top: 1px solid var(--default-border);
}
.tagify__dropdown__wrapper {
  border: 0 !important;
  box-shadow: none !important;
  background-color: var(--custom-white) !important;
}
.tagify__dropdown {
  padding: 0.25rem !important;
  border-bottom-width: 0;
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.3rem !important;
}
.tagify__dropdown__item--active {
  background: var(--primary-color) !important;
  color: #fff !important;
}
.remove-all-tags {
  color: #fff;
}
.tagify--mix .tagify__input {
  padding: 0 !important;
  margin: 0 !important;
}
.tagify__input::before {
  color: var(--default-text-color) !important;
}
.dual-listbox .dual-listbox__search {
  border-color: var(--input-border) !important;
  color: var(--default-text-color);
  background-color: var(--form-control-bg);
  font-size: 0.875rem;
  font-weight: var(--default-font-weight);
  line-height: 1.5;
  border-radius: 0.35rem;
  padding: 0.5rem 0.85rem;
  outline: none;
}
.dual-listbox .dual-listbox__search::-webkit-input-placeholder {
  color: var(--default-text-color) !important;
  opacity: 0.3;
}
.dual-listbox .dragging {
  background: rgb(var(--light-rgb)) !important;
}
.dual-listbox .drop-above {
  border-top: 1px solid var(--default-border) !important;
}
.dual-listbox .dual-listbox__title {
  border-left: 1px solid var(--default-border) !important;
  border-right: 1px solid var(--default-border) !important;
  border-top: 1px solid var(--default-border) !important;
  font-weight: 500 !important;
}
.dual-listbox .dual-listbox__available,
.dual-listbox .dual-listbox__selected {
  border: 1px solid var(--default-border) !important;
}
.dual-listbox .dual-listbox__item {
  border-bottom: 1px solid var(--default-border) !important;
}
.dual-listbox .dual-listbox__button {
  background-color: var(--primary-color) !important;
  border-radius: 0.3rem;
}
.dual-listbox .dual-listbox__item.dual-listbox__item--selected {
  background-color: var(--primary01) !important;
}
.dual-listbox .dual-listbox__available,
.dual-listbox .dual-listbox__selected {
  width: auto !important;
}
.toastify-right {
  inset-inline-end: 15px !important;
}
.toast-close {
  padding: 0 5px 0 15px !important;
}
[dir=rtl] .toast-close {
  padding: 0 15px 0 5px !important;
}
[dir=rtl] .toastify-right {
  right: inherit !important;
}
.ribbon {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 120px;
  padding: 6px 0;
  z-index: 2;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 500 12px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon.ribbon-primary:before,
.ribbon.ribbon-primary:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-primary span {
  background-color: var(--primary-color);
}
.ribbon.ribbon-secondary:before,
.ribbon.ribbon-secondary:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-secondary span {
  background-color: rgb(var(--secondary-rgb));
}
.ribbon.ribbon-warning:before,
.ribbon.ribbon-warning:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-warning span {
  background-color: rgb(var(--warning-rgb));
}
.ribbon.ribbon-info:before,
.ribbon.ribbon-info:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-info span {
  background-color: rgb(var(--info-rgb));
}
.ribbon.ribbon-success:before,
.ribbon.ribbon-success:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-success span {
  background-color: rgb(var(--success-rgb));
}
.ribbon.ribbon-danger:before,
.ribbon.ribbon-danger:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-danger span {
  background-color: rgb(var(--danger-rgb));
}
.ribbon.ribbon-orange:before,
.ribbon.ribbon-orange:after {
  border: 3px solid var(--black-6);
}
.ribbon.ribbon-orange span {
  background-color: rgb(var(--orange-rgb));
}
.ribbon-top-left {
  top: -7px;
  inset-inline-start: -7px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  inset-inline-end: 0;
  z-index: 1;
}
.ribbon-top-left::after {
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
}
.ribbon-top-left span {
  inset-inline-end: -12px;
  top: 20px;
  transform: rotate(-45deg);
}
[dir=rtl] .ribbon-top-left span {
  transform: rotate(45deg);
}
.ribbon-top-right {
  top: -7px;
  inset-inline-end: -7px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  inset-inline-start: 0;
  z-index: 1;
}
.ribbon-top-right::after {
  bottom: 0;
  inset-inline-end: 0;
  z-index: 1;
}
.ribbon-top-right span {
  inset-inline-start: -12px;
  top: 20px;
  transform: rotate(45deg);
}
[dir=rtl] .ribbon-top-right span {
  transform: rotate(315deg);
}
.ribbon-bottom-right {
  bottom: -7px;
  inset-inline-end: -7px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
}
.ribbon-bottom-right::after {
  top: 0;
  inset-inline-end: 0;
  z-index: 1;
}
.ribbon-bottom-right span {
  inset-inline-start: -12px;
  bottom: 20px;
  transform: rotate(-225deg);
}
[dir=rtl] .ribbon-bottom-right span {
  transform: rotate(225deg);
}
.ribbon-bottom-left {
  bottom: -7px;
  inset-inline-start: -7px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  inset-inline-end: 0;
  z-index: 1;
}
.ribbon-bottom-left::after {
  top: 0;
  inset-inline-start: 0;
  z-index: 1;
}
.ribbon-bottom-left span {
  inset-inline-end: -12px;
  bottom: 20px;
  transform: rotate(225deg);
}
[dir=rtl] .ribbon-bottom-left span {
  transform: rotate(-225deg);
}
.ribbon-2 {
  display: inline-block;
  color: #fff;
  position: absolute;
}
.ribbon-2.ribbon-primary {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-color) 0%,
                  rgb(var(--secondary-rgb)) 100%);
}
.ribbon-2.ribbon-primary:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-secondary {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--secondary-rgb)) 0%,
                  #7289FF 100%);
}
.ribbon-2.ribbon-secondary:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-success {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--success-rgb)) 0%,
                  #009CA4 100%);
}
.ribbon-2.ribbon-success:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-info {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--info-rgb)) 0%,
                  #4990E1 100%);
}
.ribbon-2.ribbon-info:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-warning {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--warning-rgb)) 0%,
                  #9EA53C 100%);
}
.ribbon-2.ribbon-warning:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-danger {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--danger-rgb)) 0%,
                  #DE4980 100%);
}
.ribbon-2.ribbon-danger:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-orange {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--orange-rgb)) 0%,
                  #E5647E 100%);
}
.ribbon-2.ribbon-orange:before {
  border-top: 10px solid var(--black-8);
}
.ribbon-2.ribbon-left {
  padding: 5px 40px 5px 20px;
  top: 10px;
  inset-inline-start: -10px;
}
.ribbon-2.ribbon-left:after {
  border-inline-end: 12px solid var(--custom-white);
  border-top: 14px solid transparent;
  border-bottom: 16px solid transparent;
  inset-inline-end: 0;
  top: 0;
}
.ribbon-2.ribbon-left:before {
  border-inline-start: 10px solid transparent;
  inset-inline-start: 0;
  bottom: -10px;
}
.ribbon-2.ribbon-right {
  padding: 5px 20px 5px 40px;
  top: 10px;
  inset-inline-end: -10px;
}
.ribbon-2.ribbon-right:after {
  border-inline-start: 12px solid var(--custom-white);
  border-top: 14px solid transparent;
  border-bottom: 16px solid transparent;
  inset-inline-start: 0;
  top: 0;
}
.ribbon-2.ribbon-right:before {
  border-inline-end: 10px solid transparent;
  inset-inline-end: 0;
  bottom: -10px;
}
.ribbon-2:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}
.ribbon-2:before {
  height: 0;
  width: 0;
  position: absolute;
  content: "";
}
[dir=rtl] .ribbon-3.top-left span:after {
  border-radius: 50px 0 0 0;
}
[dir=rtl] .ribbon-3.top-right span:after {
  border-radius: 0 50px 0 0;
}
.ribbon-3 {
  position: absolute;
  inset-block-start: -8px;
  color: #fff;
}
.ribbon-3.top-left {
  border-start-start-radius: 10px;
  inset-inline-start: 10px;
}
.ribbon-3.top-left span:after {
  inset-inline-end: -7px;
  border-radius: 0 50px 0 0;
}
.ribbon-3.top-right {
  border-start-end-radius: 10px;
  inset-inline-end: 10px;
}
.ribbon-3.top-right span:after {
  inset-inline-start: -7px;
  border-radius: 50px 0 0 0;
}
.ribbon-3 span {
  position: relative;
  display: block;
  text-align: center;
  font-size: 13px;
  line-height: 1;
  padding: 10px;
  z-index: 6;
  width: 33px;
}
.ribbon-3 span:after {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  inset-block-start: 0;
}
.ribbon-3:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 16px solid transparent;
  border-inline-end: 17px solid transparent;
  z-index: 6;
}
.ribbon-3.ribbon-success {
  background: rgba(var(--success-rgb), 1);
}
.ribbon-3.ribbon-success span:after {
  background: #0d9b80;
}
.ribbon-3.ribbon-success:after {
  border-block-start: 10px solid rgba(var(--success-rgb), 1);
}
.ribbon-3.ribbon-primary {
  background: rgba(var(--primary-rgb), 1);
}
.ribbon-3.ribbon-primary span:after {
  background: var(--primary-color);
}
.ribbon-3.ribbon-primary:after {
  border-block-start: 10px solid rgba(var(--primary-rgb), 1);
}
.ribbon-3.ribbon-secondary {
  background: rgba(var(--secondary-rgb), 1);
}
.ribbon-3.ribbon-secondary span:after {
  background: #a017d1;
}
.ribbon-3.ribbon-secondary:after {
  border-block-start: 10px solid rgba(var(--secondary-rgb), 1);
}
.ribbon-3.ribbon-warning {
  background: rgba(var(--warning-rgb), 1);
}
.ribbon-3.ribbon-warning span:after {
  background: #d98415;
}
.ribbon-3.ribbon-warning:after {
  border-block-start: 10px solid rgba(var(--warning-rgb), 1);
}
.ribbon-3.ribbon-info {
  background: rgba(var(--info-rgb), 1);
}
.ribbon-3.ribbon-info span:after {
  background: #148fc7;
}
.ribbon-3.ribbon-info:after {
  border-block-start: 10px solid rgba(var(--info-rgb), 1);
}
.ribbon-3.ribbon-danger {
  background: rgba(var(--danger-rgb), 1);
}
.ribbon-3.ribbon-danger span:after {
  background: #d31236;
}
.ribbon-3.ribbon-danger:after {
  border-block-start: 10px solid rgba(var(--danger-rgb), 1);
}
.ribbon-4 {
  position: absolute;
  inset-block-start: 0;
  color: #fff;
  width: 30px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.ribbon-4 span {
  position: relative;
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  padding: 12px 2px;
  z-index: 6;
}
.ribbon-4.top-left {
  inset-inline-start: 10px;
}
.ribbon-4.top-left:after {
  inset-inline-start: 0;
}
.ribbon-4.top-right {
  inset-inline-end: 10px;
}
.ribbon-4.top-right:after {
  inset-inline-end: 0;
}
.ribbon-4:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  border-block-end: 8px solid transparent;
}
.ribbon-4.ribbon-primary span {
  background: var(--primary-color);
}
.ribbon-4.ribbon-primary:after {
  border-inline-start: 15px solid var(--primary-color);
  border-inline-end: 15px solid var(--primary-color);
}
.ribbon-4.ribbon-secondary span {
  background: rgb(var(--secondary-rgb));
}
.ribbon-4.ribbon-secondary:after {
  border-inline-start: 15px solid rgb(var(--secondary-rgb));
  border-inline-end: 15px solid rgb(var(--secondary-rgb));
}
.ribbon-4.ribbon-warning span {
  background: rgb(var(--warning-rgb));
}
.ribbon-4.ribbon-warning:after {
  border-inline-start: 15px solid rgb(var(--warning-rgb));
  border-inline-end: 15px solid rgb(var(--warning-rgb));
}
.ribbon-4.ribbon-info span {
  background: rgb(var(--info-rgb));
}
.ribbon-4.ribbon-info:after {
  border-inline-start: 15px solid rgb(var(--info-rgb));
  border-inline-end: 15px solid rgb(var(--info-rgb));
}
.ribbon-4.ribbon-success span {
  background: rgb(var(--success-rgb));
}
.ribbon-4.ribbon-success:after {
  border-inline-start: 15px solid rgb(var(--success-rgb));
  border-inline-end: 15px solid rgb(var(--success-rgb));
}
.ribbon-4.ribbon-danger span {
  background: rgb(var(--danger-rgb));
}
.ribbon-4.ribbon-danger:after {
  border-inline-start: 15px solid rgb(var(--danger-rgb));
  border-inline-end: 15px solid rgb(var(--danger-rgb));
}
.ribbon-5 {
  position: absolute;
  width: 90px;
  height: 90px;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  display: flex;
  align-items: end;
  justify-content: center;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.2);
}
.ribbon-5.ribbon-primary {
  background-color: var(--primary-color);
}
.ribbon-5.ribbon-secondary {
  background-color: rgb(var(--secondary-rgb));
}
.ribbon-5.ribbon-warning {
  background-color: rgb(var(--warning-rgb));
}
.ribbon-5.ribbon-info {
  background-color: rgb(var(--info-rgb));
}
.ribbon-5.ribbon-success {
  background-color: rgb(var(--success-rgb));
}
.ribbon-5.ribbon-danger {
  background-color: rgb(var(--danger-rgb));
}
.ribbon-5.ribbon-dark {
  background-color: rgb(var(--dark-rgb));
}
.ribbon-5.ribbon-orange {
  background-color: rgb(var(--orange-rgb));
}
.ribbon-5.top-left {
  top: -2.8125rem;
  inset-inline-start: -2.8125rem;
  transform: rotate(315deg);
}
.ribbon-5.top-right {
  top: -2.8125rem;
  inset-inline-end: -2.8125rem;
  transform: rotate(45deg);
}
.ribbon-5.bottom-left {
  bottom: -2.8125rem;
  inset-inline-start: -2.8125rem;
  transform: rotate(225deg);
}
.ribbon-5.bottom-right {
  bottom: -2.8125rem;
  inset-inline-end: -2.8125rem;
  transform: rotate(135deg);
}
[dir=rtl] .ribbon-5.top-left {
  transform: rotate(-315deg);
}
[dir=rtl] .ribbon-5.top-right {
  transform: rotate(-45deg);
}
[dir=rtl] .ribbon-5.bottom-left {
  transform: rotate(-225deg);
}
[dir=rtl] .ribbon-5.bottom-right {
  transform: rotate(-135deg);
}
.ribbon-6 {
  color: #fff;
  padding: 2px 8px;
  position: absolute;
  inset-block-start: 10px;
  z-index: 6;
  font-size: 13px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.2);
}
.ribbon-6.ribbon-primary {
  background-color: var(--primary-color);
}
.ribbon-6.ribbon-primary.ribbon-left:after {
  border-inline-start: 12px solid var(--primary-color);
}
.ribbon-6.ribbon-primary.ribbon-right:after {
  border-inline-end: 12px solid var(--primary-color);
}
.ribbon-6.ribbon-secondary {
  background-color: rgb(var(--secondary-rgb));
}
.ribbon-6.ribbon-secondary.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--secondary-rgb));
}
.ribbon-6.ribbon-secondary.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--secondary-rgb));
}
.ribbon-6.ribbon-warning {
  background-color: rgb(var(--warning-rgb));
}
.ribbon-6.ribbon-warning.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--warning-rgb));
}
.ribbon-6.ribbon-warning.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--warning-rgb));
}
.ribbon-6.ribbon-info {
  background-color: rgb(var(--info-rgb));
}
.ribbon-6.ribbon-info.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--info-rgb));
}
.ribbon-6.ribbon-info.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--info-rgb));
}
.ribbon-6.ribbon-success {
  background-color: rgb(var(--success-rgb));
}
.ribbon-6.ribbon-success.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--success-rgb));
}
.ribbon-6.ribbon-success.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--success-rgb));
}
.ribbon-6.ribbon-danger {
  background-color: rgb(var(--danger-rgb));
}
.ribbon-6.ribbon-danger.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--danger-rgb));
}
.ribbon-6.ribbon-danger.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--danger-rgb));
}
.ribbon-6.ribbon-dark {
  background-color: rgb(var(--dark-rgb));
}
.ribbon-6.ribbon-dark.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--dark-rgb));
}
.ribbon-6.ribbon-dark.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--dark-rgb));
}
.ribbon-6.ribbon-orange {
  background-color: rgb(var(--orange-rgb));
}
.ribbon-6.ribbon-orange.ribbon-left:after {
  border-inline-start: 12px solid rgb(var(--orange-rgb));
}
.ribbon-6.ribbon-orange.ribbon-right:after {
  border-inline-end: 12px solid rgb(var(--orange-rgb));
}
.ribbon-6.ribbon-left {
  inset-inline-start: 0;
}
.ribbon-6.ribbon-left:after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-block-end: 11px solid transparent;
  width: 0;
}
.ribbon-6.ribbon-right {
  inset-inline-end: 0;
}
.ribbon-6.ribbon-right:after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: -12px;
  border-block-end: 12px solid transparent;
  border-block-start: 11px solid transparent;
  width: 0;
}
.widget-card {
  position: relative;
  /*background-image: url("media-25-OKB2ORJF.jpg");*/
  z-index: 1;
  padding-block-start: 3.735rem;
  padding-block-end: 3.735rem;
}
.widget-card:before {
  content: "";
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  background-color: var(--primary08);
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: -1;
}
.widget-card2 {
  position: relative;
  /*background-image: url("media-35-AH2L4S6Q.jpg");*/
  z-index: 1;
  padding-block-start: 3.735rem;
  padding-block-end: 3.735rem;
}
.widget-card2:before {
  content: "";
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  background-color: rgba(var(--primary-tint1-rgb), 0.8);
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: -1;
}
.visit-gender.male:before {
  background-color: var(--primary-color);
}
.visit-gender.female:before {
  background-color: rgb(var(--secondary-rgb));
}
.visit-gender {
  position: relative;
}
.visit-gender::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 6px;
  border-radius: 0.1rem;
  inset-block-start: 7px;
  inset-inline-start: -30px;
}
#chart-10,
#chart-11,
#chart-12,
#chart-13 {
  position: absolute;
  inset-block-start: -0.5rem;
  inset-inline-end: 1rem;
}
.widgets-task-list {
  list-style: none;
  position: relative;
  margin-block-end: 0;
}
.widgets-task-list li {
  margin-block-end: 22px;
  position: relative;
  position: relative;
}
.widgets-task-list li:last-child {
  margin-block-end: 0;
}
.widgets-task-list li:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: var(--custom-white);
  inset-inline-start: -27px;
  inset-block-start: 7px;
  border-radius: 50%;
}
.widgets-task-list li:nth-child(1):before {
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 4px 2px var(--primary05);
}
.widgets-task-list li:nth-child(2):before {
  border: 2px solid var(--primary-tint1-color);
  box-shadow: 0 0 4px 2px rgba(var(--primary-tint1-rgb), 0.5);
}
.widgets-task-list li:nth-child(3):before {
  border: 2px solid var(--primary-tint2-color);
  box-shadow: 0 0 4px 2px rgba(var(--primary-tint2-rgb), 0.5);
}
.widgets-task-list li:nth-child(4):before {
  border: 2px solid var(--primary-tint3-color);
  box-shadow: 0 0 4px 2px rgba(var(--primary-tint3-rgb), 0.5);
}
.widgets-task-list li:nth-child(5):before {
  border: 2px solid rgb(var(--secondary-rgb));
  box-shadow: 0 0 4px 2px rgba(var(--secondary-rgb), 0.5);
}
.widgets-task-list li:nth-child(6):before {
  border: 2px solid rgb(var(--success-rgb));
  box-shadow: 0 0 4px 2px rgba(var(--success-rgb), 0.5);
}
.widgets-task-list li:nth-child(7):before {
  border: 2px solid rgb(var(--orange-rgb));
  box-shadow: 0 0 4px 2px rgba(var(--orange-rgb), 0.5);
}
.widgets-task-list li:nth-child(8):before {
  border: 2px solid rgb(var(--info-rgb));
  box-shadow: 0 0 4px 2px rgba(var(--info-rgb), 0.5);
}
.widgets-task-list li:nth-child(9):before {
  border: 2px solid rgb(var(--warning-rgb));
  box-shadow: 0 0 4px 2px rgba(var(--warning-rgb), 0.5);
}
.widgets-task-list:before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  height: 90%;
  inset-inline-start: 9px;
  border-inline-start: 1px dashed var(--default-border);
}
#activecustomers .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--text-muted);
}
.widget-circle-chart {
  height: 218px;
}
[data-vertical-style=closed][data-toggled=close-menu-close] .app-sidebar {
  display: none;
}
@media (min-width: 992px) {
  [data-vertical-style=closed][data-toggled=close-menu-close] .app-content {
    margin-inline-start: 0;
  }
  [data-vertical-style=closed][data-toggled=close-menu-close] .app-header {
    padding-inline-start: 0;
  }
}
[data-vertical-style=closed][data-toggled=close-menu-open] .app-sidebar {
  display: block;
}
@media (min-width: 992px) {
  [data-vertical-style=closed][data-toggled=close-menu-open] .app-content {
    margin-inline-start: 15rem;
  }
  [data-vertical-style=closed][data-toggled=close-menu-open] .app-header {
    padding-inline-start: 15rem;
  }
}
@media (min-width: 1600px) {
  [data-vertical-style=detached][data-width=default] .page {
    width: 95%;
    margin: 0 auto;
    padding-inline-start: 10.25rem;
  }
  [data-vertical-style=detached][data-width=default] .main-content {
    padding: 0 10rem 0 0.75rem;
  }
  [data-vertical-style=detached][data-width=default][dir=rtl] .main-content {
    padding: 0 0.75rem 0 10rem;
  }
}
@media (min-width: 992px) {
  [data-vertical-style=detached] .page {
    width: 95%;
    margin: 0 auto;
    padding-inline-start: 1.25rem;
  }
  [data-vertical-style=detached] .app-sidebar {
    inset-block-start: calc(64px + 1.5rem);
    inset-block-end: 1.5rem;
    inset-inline-start: inherit;
    border-radius: 0.3rem;
    border: 0px solid var(--menu-border-color);
    height: 90% !important;
  }
  [data-vertical-style=detached] .app-sidebar:before {
    border-radius: 0.3rem;
  }
  [data-vertical-style=detached] .app-sidebar .main-sidebar-header {
    display: none;
  }
  [data-vertical-style=detached] .app-header .sidemenu-toggle.header-link.animated-arrow.hor-toggle.horizontal-navtoggle {
    margin-inline-start: 1.25rem;
  }
  [data-vertical-style=detached] .main-sidebar {
    height: 100%;
    margin-block-start: 0;
    box-shadow: none !important;
  }
  [data-vertical-style=detached] .app-content {
    margin-inline-start: 15rem;
    position: relative;
  }
  [data-vertical-style=detached][data-page-style=classic] .app-sidebar {
    border: 1px solid var(--menu-border-color);
  }
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-white {
    display: block;
  }
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar {
    width: 5rem;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide-menu,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge {
    display: none !important;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item {
    justify-content: center;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .side-menu__icon {
    margin-inline-end: 0;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category:before {
    content: "\f3c2";
    font-family: remixicon !important;
    position: absolute;
    font-size: 8px;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    opacity: 1;
    color: var(--menu-prime-color);
  }
  [data-vertical-style=detached][data-toggled=detached-close] .app-content {
    margin-inline-start: 5rem;
    position: relative;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar {
    width: 15rem;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
    width: 15rem;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block !important;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none !important;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
    justify-content: flex-start;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.625rem;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category {
    padding: 0.75rem 1.65rem;
  }
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
    display: none;
  }
  [data-vertical-style=detached] .app-header {
    padding-inline-start: 0;
  }
  [data-vertical-style=detached] .app-header .main-header-container {
    width: 94%;
  }
  [data-vertical-style=detached] .app-header .horizontal-logo {
    padding: 1rem 0;
    display: block;
  }
  [data-vertical-style=detached] .app-header .horizontal-logo img {
    height: 1.5rem;
    line-height: 1.5rem;
  }
  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-logo {
    display: block;
  }
  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-dark,
  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-white,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-logo,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-white,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=detached] .footer {
    box-shadow: none;
    background-color: transparent !important;
  }
  [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
  [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before,
  [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
  [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
  [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before {
    border-radius: 0.3rem;
  }
}
@media (min-width: 992px) {
  [data-vertical-style=doublemenu] .slide-menu.double-menu-active {
    display: block !important;
  }
  [data-vertical-style=doublemenu] .app-sidebar {
    width: 5rem;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 100%;
    box-shadow: none;
    margin-block-start: 0;
  }
  [data-vertical-style=doublemenu] .app-sidebar .slide-menu.child1 {
    padding-block-end: 3rem;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header {
    width: 5rem;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    justify-content: center;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block !important;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none !important;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-menu-container {
    margin-block-start: 3.75rem;
  }
  [data-vertical-style=doublemenu] .app-sidebar .main-menu > .slide {
    padding: 0 0.6rem;
  }
  [data-vertical-style=doublemenu] .app-sidebar .category-name,
  [data-vertical-style=doublemenu] .app-sidebar .side-menu__label,
  [data-vertical-style=doublemenu] .app-sidebar .side-menu__angle {
    display: none;
  }
  [data-vertical-style=doublemenu] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
  }
  [data-vertical-style=doublemenu] .app-sidebar .slide__category {
    display: none;
  }
  [data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  [data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  [data-vertical-style=doublemenu] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }
  [data-vertical-style=doublemenu] .app-header {
    padding-inline-start: 17rem;
  }
  [data-vertical-style=doublemenu] .app-content {
    margin-inline-start: 17rem;
  }
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    box-shadow: none;
    transition: none !important;
    height: 100% !important;
    inset-inline-end: 0;
    border-inline-end: 1px solid var(--menu-border-color);
  }
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2,
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 0 !important;
    position: relative !important;
    height: auto !important;
    inset-block-start: 0 !important;
    border-inline-end: 0;
  }
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2 .slide:nth-child(2),
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 .slide:nth-child(2) {
    padding-block-start: 0rem;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 .slide,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide {
    padding-inline-start: 0;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item:before,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item:before,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item:before {
    display: none;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 .side-menu__angle,
  [data-vertical-style=doublemenu] .slide-menu.child2 .side-menu__angle,
  [data-vertical-style=doublemenu] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }
  [data-vertical-style=doublemenu] .slide-menu.child2,
  [data-vertical-style=doublemenu] .slide-menu.child3 {
    min-width: 10rem;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 {
    min-width: 12rem;
  }
  [data-vertical-style=doublemenu] .slide-menu.child1 .slide:nth-child(2) {
    padding-block-start: 0.75rem;
  }
  [data-vertical-style=doublemenu] .side-menu__label1 {
    display: block !important;
    font-size: 0.938rem;
    font-weight: 500;
    color: var(--menu-prime-color);
    border-block-end: 1px solid var(--menu-border-color);
    padding: 1.5rem 1rem !important;
    width: 12rem;
    height: 4.25rem;
  }
  [data-vertical-style=doublemenu] .slide-menu {
    display: none;
  }
  [data-vertical-style=doublemenu] .slide-menu.double-menu-active {
    visibility: visible !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    border-radius: 0 !important;
    border-block-start: 0;
    overflow: hidden scroll;
  }
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: none !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block !important;
  }
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-page-style=classic] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid var(--default-border);
  }
  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-header {
    padding-inline-start: 5rem;
  }
  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-content {
    margin-inline-start: 5rem;
  }
  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .slide.has-sub .slide-menu {
    display: none !important;
  }
  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .main-sidebar {
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  }
  [data-vertical-style=doublemenu][data-toggled=double-menu-open] .app-sidebar .main-sidebar .slide.side-menu__label1 {
    border-inline-end: 1px solid var(--default-border);
  }
}
@media (min-width: 992px) {
  [data-nav-layout=horizontal] {
  }
  [data-nav-layout=horizontal][data-theme-mode=light] .app-sidebar .side-menu__item.active,
  [data-nav-layout=horizontal][data-theme-mode=light] .app-sidebar .side-menu__item:hover,
  [data-nav-layout=horizontal][data-theme-mode=dark] .app-sidebar .side-menu__item.active,
  [data-nav-layout=horizontal][data-theme-mode=dark] .app-sidebar .side-menu__item:hover {
    background-color: transparent;
  }
  [data-nav-layout=horizontal] .app-sidebar {
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    font-size: 0.9rem;
    width: 0.9rem;
    height: 0.9rem;
  }
  [data-nav-layout=horizontal] .sidemenu-layout-styles {
    display: none;
  }
  [data-nav-layout=horizontal] .main-menu {
    display: flex !important;
    transition: all 0.5s ease;
  }
  [data-nav-layout=horizontal] .main-menu-container {
    display: inline-flex;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item {
    padding: 0.45rem 1rem;
  }
  [data-nav-layout=horizontal] .side-menu__label .badge {
    display: none;
  }
  [data-nav-layout=horizontal] .mega-menu {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
  [data-nav-layout=horizontal] .app-header {
    padding-inline-start: 0 !important;
    z-index: 104;
    box-shadow: none;
  }
  [data-nav-layout=horizontal] .app-header .main-header-container .header-element.header-search {
    margin-inline-start: 1.25rem;
  }
  [data-nav-layout=horizontal] .app-content {
    min-height: calc(100vh - 10.5rem);
    margin-block-start: 7rem;
    margin-inline-start: 0rem !important;
  }
  [data-nav-layout=horizontal] .app-content > .container-fluid {
    width: 94%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-inline: 7px;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-layout=horizontal] .app-sidebar .simplebar-mask {
    overflow: visible;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-menu > .slide {
    margin: 0 0.1875rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
    box-shadow: none;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__item:before {
    display: none;
  }
  [data-nav-layout=horizontal] .simplebar-offset {
    position: inherit !important;
  }
  [data-nav-layout=horizontal] .simplebar-wrapper {
    width: 100%;
  }
  [data-nav-layout=horizontal] .main-sidebar .simplebar-vertical {
    visibility: hidden !important;
  }
  [data-nav-layout=horizontal] .main-sidebar,
  [data-nav-layout=horizontal] .main-header-container {
    width: 94%;
    margin: 0 auto;
  }
  [data-nav-layout=horizontal] .horizontal-logo {
    padding: 0.5rem 0;
    display: block;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  [data-nav-layout=horizontal] .horizontal-logo .header-logo img {
    height: 1.7rem;
    line-height: 1.7rem;
  }
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-dark,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-white,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-logo,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-white,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=horizontal] .main-header-container .sidemenu-toggle {
    display: none;
  }
  [data-nav-layout=horizontal] .app-sidebar {
    width: 100% !important;
    height: auto;
    border-block-end: 1px solid var(--menu-border-color);
    inset-block-start: 4.25rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 li {
    padding-inline-start: 0.5rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__item:before {
    inset-block-start: 1rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .simplebar-content {
    padding: 0 !important;
    overflow: hidden;
  }
  [data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper {
    overflow: visible !important;
    height: auto;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
    padding: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-inline-start: 1.5rem !important;
    padding: 0.1875rem 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-end: 100% !important;
    padding: 0.1875rem 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
    position: absolute !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    width: 100%;
    display: flex;
    border-radius: 0;
    padding: 0.92rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    inset-inline-end: 0.25rem;
    display: block;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.25rem;
    margin-block-end: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    background-color: var(--menu-bg);
    inset-block-start: 100% !important;
    min-width: 12rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide {
    padding: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
    border-radius: 0 0 0.25rem 0.25rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.has-sub,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide {
    width: 100%;
    display: flex;
    padding: 0 0.1875rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 {
    border-radius: 0.25rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub {
    position: static;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=horizontal] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide .slide-menu.child3,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3 {
    box-shadow: 0 0 0.375rem var(--black-1);
  }
  [data-nav-layout=horizontal] .app-sidebar .main-menu {
    margin-block-end: 0;
  }
  [data-nav-layout=horizontal] .main-sidebar-header {
    display: none !important;
  }
  [data-nav-layout=horizontal] .main-sidebar {
    margin-block-start: 0;
    padding-block-end: 3rem;
  }
  [data-nav-layout=horizontal] .slide__category {
    display: none;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: 1.438rem;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    top: 0.563rem;
    padding: 0.375rem;
    color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--default-border);
    border-radius: 3.125rem;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    top: 0.313rem;
    padding: 0.375rem;
    color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--default-border);
    border-radius: 3.125rem;
    background: var(--custom-white);
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    inset-inline-end: -2%;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: -2%;
  }
  [data-nav-layout=horizontal][page-style=classic] .app-sidebar {
    border-block-end: 1px solid var(--default-border);
  }
  [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-right,
  [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-left {
    transform: rotate(180deg);
  }
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=dark][data-icon-overlay=open] .app-sidebar:hover {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
  [data-nav-layout=horizontal][data-menu-styles=light] .main-menu-container .slide-right,
  [data-nav-layout=horizontal][data-menu-styles=light] .main-menu-container .slide-left {
    --custom-white: #fff;
    --default-border: #f3f3f3;
  }
  [data-nav-layout=horizontal][data-page-style=modern] .app-sidebar {
    border-block-end: 1px solid var(--header-border-color);
  }
  [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  [data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu.child1.force-left,
  [data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu.child1.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu.child1.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu.child1.force-left {
    inset-inline-end: 0 !important;
  }
  [data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu.child2.force-left,
  [data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu.child3.force-left,
  [data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu.child2.force-left,
  [data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu.child3.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu.child2.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu.child3.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu.child2.force-left,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu.child3.force-left {
    inset-inline-start: -100% !important;
  }
}
@media (max-width: 991.98px) {
  [data-nav-layout=horizontal] .horizontal-logo .header-logo img {
    height: 1.7rem;
    line-height: 1.7rem;
  }
}
@media (min-width: 992px) {
  .icon-click .app-sidebar,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
    width: 5rem;
  }
  .icon-click .app-sidebar .main-sidebar,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }
  .icon-click .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header {
    width: 5rem;
    justify-content: center;
  }
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none;
  }
  .icon-click .app-sidebar .category-name,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .category-name,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .category-name,
  .icon-click .app-sidebar .side-menu__label,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__label,
  .icon-click .app-sidebar .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__angle {
    display: none;
  }
  .icon-click .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
  }
  .icon-click .app-sidebar .slide__category,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }
  .icon-click .app-sidebar .slide__category:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category:before {
    content: "";
    position: absolute;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    width: 0.35rem;
    border-radius: 3.125rem;
    height: 0.35rem;
    border: 1px solid var(--menu-prime-color);
    opacity: 1;
  }
  .icon-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  .icon-click .app-sidebar .simplebar-mask,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  .icon-click .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }
  .icon-click .app-header,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-header,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-header {
    padding-inline-start: 5rem;
  }
  .icon-click .app-content,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-content,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-content {
    margin-inline-start: 5rem;
  }
  .icon-click .slide.side-menu__label1,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.side-menu__label1,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1 {
    display: block;
    padding: 0.5rem 1rem !important;
    border-block-end: 1px solid var(--default-border);
  }
  .icon-click .slide.has-sub .slide-menu,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0 0 0.375rem var(--black-1);
    transition: none !important;
  }
  .icon-click .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child2,
  .icon-click .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 12rem !important;
  }
  .icon-click .slide-menu.child1,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1,
  .icon-click .slide-menu.child2,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2,
  .icon-click .slide-menu.child3,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 {
    min-width: 12rem;
  }
  .icon-click .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item,
  .icon-click .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item,
  .icon-click .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  .icon-click .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item:before,
  .icon-click .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item:before,
  .icon-click .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item:before {
    display: none;
  }
  .icon-click .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .side-menu__angle,
  .icon-click .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .side-menu__angle,
  .icon-click .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 0.75rem;
    inset-block-start: 0.65rem;
  }
}
[data-nav-layout=horizontal][data-nav-style=icon-click] .mega-menu {
  -moz-columns: 1;
  columns: 1;
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-menu > .slide {
    padding: 0 0.6rem;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
    position: absolute;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide {
    padding-inline-start: 0;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide a {
    border-radius: 0;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .side-menu__label .badge {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
@media (min-width: 992px) {
  .icon-click .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__icon {
    color: var(--menu-prime-color);
  }
}
@media (min-width: 992px) {
  .icon-hover .app-sidebar,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
    width: 5rem;
  }
  .icon-hover .app-sidebar .main-sidebar,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }
  .icon-hover .app-sidebar .main-sidebar-header,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header {
    width: 5rem;
    justify-content: center;
  }
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none;
  }
  .icon-hover .app-sidebar .category-name,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .category-name,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .category-name,
  .icon-hover .app-sidebar .side-menu__label,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__label,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__label,
  .icon-hover .app-sidebar .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__angle {
    display: none;
  }
  .icon-hover .app-sidebar .side-menu__icon,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
  }
  .icon-hover .app-sidebar .slide__category,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }
  .icon-hover .app-sidebar .slide__category:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category:before {
    content: "";
    position: absolute;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    width: 0.35rem;
    border-radius: 3.125rem;
    height: 0.35rem;
    border: 1px solid var(--menu-prime-color);
    opacity: 1;
  }
  .icon-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  .icon-hover .app-sidebar .simplebar-mask,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-mask,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  .icon-hover .app-sidebar .simplebar-placeholder,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }
  .icon-hover .app-header,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-header,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-header {
    padding-inline-start: 5rem;
  }
  .icon-hover .app-content,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-content,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-content {
    margin-inline-start: 5rem;
  }
  .icon-hover .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.side-menu__label1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
    display: block;
    padding: 0.5rem 1rem !important;
    border-block-end: 1px solid var(--default-border);
  }
  .icon-hover .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0 0 0.375rem var(--black-1);
    transition: none !important;
  }
  .icon-hover .slide.has-sub .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child2,
  .icon-hover .slide.has-sub .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.81rem !important;
  }
  .icon-hover .slide-menu.child1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1,
  .icon-hover .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2,
  .icon-hover .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 {
    min-width: 12rem;
  }
  .icon-hover .slide-menu.child1 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item,
  .icon-hover .slide-menu.child2 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item,
  .icon-hover .slide-menu.child3 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  .icon-hover .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item:before,
  .icon-hover .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item:before,
  .icon-hover .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item:before {
    display: none;
  }
  .icon-hover .slide-menu.child1 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .side-menu__angle,
  .icon-hover .slide-menu.child2 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .side-menu__angle,
  .icon-hover .slide-menu.child3 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 0.75rem;
    inset-block-start: 0.65rem;
  }
  .icon-hover .slide.has-sub:hover .slide-menu.child1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 {
    display: block !important;
  }
  .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
    display: block !important;
  }
  .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
    display: block !important;
  }
}
[data-nav-layout=horizontal][data-nav-style=icon-hover] .mega-menu {
  -moz-columns: 1;
  columns: 1;
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-hover] .app-sidebar .main-menu .slide .side-menu__item:hover > .side-menu__item .side-menu__icon {
    color: #fff;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-menu > .slide {
    padding: 0 0.6rem;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
    position: absolute;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 {
    padding: 0.1875rem;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide {
    padding-inline-start: 0;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide a {
    border-radius: 0;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .side-menu__label .badge {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
@media (min-width: 992px) {
  .icon-hover .app-sidebar .side-menu__icon,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__icon {
    color: var(--menu-prime-color);
  }
}
@media (min-width: 992px) {
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar {
    width: 5rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header {
    width: 5rem;
    justify-content: center;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide.has-sub.open .slide-menu {
    display: none !important;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
    padding: 1.65rem;
    position: relative;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category:before {
    content: "\f3c2";
    font-family: remixicon !important;
    position: absolute;
    font-size: 8px;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    opacity: 1;
    color: var(--menu-prime-color);
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .side-menu__item {
    justify-content: center;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .sidebar-profile {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-header {
    padding-inline-start: 5rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-content {
    margin-inline-start: 5rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar {
    width: 15rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
    width: 15rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
    justify-content: flex-start;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.625rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category {
    padding: 0.75rem 1.2rem;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-logo {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-dark {
    display: block;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-logo {
    display: none;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .desktop-logo {
    display: block;
  }
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .toggle-logo {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar {
    position: absolute;
    width: 9rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar {
    overflow: visible;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar-header {
    width: 9rem;
    justify-content: center;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide {
    padding: 0;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide__category,
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__angle {
    display: none;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide.has-sub.open .slide-menu {
    display: none !important;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item {
    border-radius: 0;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item,
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open])[data-menu-position=fixed] .app-sidebar {
    position: fixed;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-header {
    padding-inline-start: 9rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close] .app-content {
    margin-inline-start: 9rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar {
    width: 15rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .main-sidebar-header {
    width: 15rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .side-menu__icon {
    margin-block-end: 0;
    margin-inline-end: 0.625rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide {
    padding: 0rem 0.625rem;
  }
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu.child1 li,
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child2 li,
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child3 li {
    padding: 0;
    padding-inline-start: 1.5rem;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-vertical-style=icontext][data-toggled=icon-text-close] .app-sidebar .slide .side-menu__label .badge {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    display: flex !important;
    padding-top: 0.93rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.93rem;
    padding-inline-start: 0.75rem;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-block-end: 0 !important;
    margin-inline-end: 0.5rem !important;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    display: block !important;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu {
    inset-inline-start: auto !important;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-block-start: 100% !important;
  }
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-header {
    padding-inline-start: 9.5rem;
  }
}
[data-nav-style=menu-click][data-toggled=menu-click-closed] .app-header {
  padding-inline-start: 9.5rem;
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=menu-click] [data-menu-styles=color] .app-sidebar .main-menu .slide .side-menu__item:hover > .side-menu__item .side-menu__icon {
    color: #fff;
    fill: #fff;
  }
}
@media (min-width: 992px) {
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar {
    position: absolute;
  }
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item {
    border-radius: 0.5rem;
    margin: 0.25rem 0 !important;
  }
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item:last-child {
    margin: 0.25rem 0 !important;
  }
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}
@media (min-width: 992px) {
  .menu-click .app-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
    width: 9.5rem;
  }
  .menu-click .app-sidebar .main-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }
  .menu-click .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header {
    width: 9.5rem;
    justify-content: center;
  }
  .menu-click .app-sidebar .side-menu__icon,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }
  .menu-click .app-sidebar .slide,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide {
    padding: 0;
  }
  .menu-click .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1,
  .menu-click .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2,
  .menu-click .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 {
    min-width: 12rem;
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
    display: none;
  }
  .menu-click .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 0.5rem;
    inset-block-start: 0.65rem;
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
    display: none;
  }
  .menu-click .app-sidebar .slide__category,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide__category,
  .menu-click .app-sidebar .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__angle {
    display: none;
  }
  .menu-click .app-sidebar .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__item,
  .menu-click .app-sidebar .side-menu__label,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }
  .menu-click .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 9.5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0 0 0.375rem var(--black-1);
    transition: none !important;
  }
  .menu-click .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
  .menu-click .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 12rem !important;
  }
  .menu-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  .menu-click .app-sidebar .simplebar-mask,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  .menu-click .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }
  .menu-click .app-content,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-content,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-content {
    margin-inline-start: 9.5rem;
  }
}
@media (max-width: 991.98px) {
  .menu-click .app-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
    width: 15rem;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide .side-menu__label .badge {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
@media (min-width: 992px) {
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    display: flex !important;
    padding-top: 0.93rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.93rem;
    padding-inline-start: 0.75rem;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-block-end: 0 !important;
    margin-inline-end: 0.5rem !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    display: block !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-block-start: 100% !important;
  }
}
@media (min-width: 992px) {
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar {
    position: absolute;
  }
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item {
    border-radius: 0.5rem;
    margin: 0.25rem !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item:last-child {
    margin: 0.25rem !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}
@media (min-width: 992px) {
  .menu-hover .app-sidebar,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar {
    width: 9.5rem;
  }
  .menu-hover .app-sidebar .main-sidebar,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }
  .menu-hover .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header {
    width: 9.5rem;
    justify-content: center;
  }
  .menu-hover .app-sidebar .side-menu__icon,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }
  .menu-hover .app-sidebar .slide,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide {
    padding: 0;
  }
  .menu-hover .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1,
  .menu-hover .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2,
  .menu-hover .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 {
    min-width: 12rem;
    display: none !important;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
    display: none;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
    display: none;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide__category,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide__category,
  .menu-hover .app-sidebar .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__angle {
    display: none;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 0.5rem;
    inset-block-start: 0.65rem;
  }
  .menu-hover .app-sidebar .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item,
  .menu-hover .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 9.5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0 0 0.375rem var(--black-1);
    transition: none !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 12rem !important;
  }
  .menu-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  .menu-hover .app-sidebar .simplebar-mask,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  .menu-hover .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }
  .menu-hover .app-header,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-header,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-header {
    padding-inline-start: 9.5rem;
  }
  .menu-hover .app-content,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-content,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-content {
    margin-inline-start: 9.5rem;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide .side-menu__label .badge {
    display: none;
  }
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
  [data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
.main-sidebar {
  margin-block-start: 4.25rem;
  padding-block-start: 0.5rem;
  padding-block-end: 5rem;
  height: 100vh;
  position: relative;
  overflow: auto;
  box-shadow: 6px 0px 16px 0px rgba(0, 0, 0, 0.05);
}
[data-nav-layout=vertical] .app-sidebar {
  height: 100%;
}
[data-nav-layout=vertical] .app-sidebar .side-menu__item {
  margin-block: 4px;
}
[dir=rtl] .app-sidebar .fe-chevron-right {
  transform: rotate(180deg);
}
[dir=rtl] .sidemenu-toggle .header-link .header-link-icon {
  transform: rotate(180deg);
}
.app-sidebar {
  width: 15rem;
  background: var(--menu-bg);
  border-inline-end: 1px solid var(--menu-border-color);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 103;
  transition: all 0.05s ease;
}
.app-sidebar .main-sidebar-header {
  height: 4.25rem;
  width: 15rem;
  position: fixed;
  display: flex;
  background: var(--menu-bg);
  z-index: 9;
  align-items: center;
  justify-content: center;
  padding: 0.813rem 1rem;
  border-block-end: 1px solid var(--menu-border-color);
  border-inline-end: 1px solid var(--menu-border-color);
  transition: all 0.05s ease;
}
.app-sidebar .main-sidebar-header .header-logo img {
  height: 1.7rem;
  line-height: 1.7rem;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-white,
.app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: none;
}
.app-sidebar .slide-menu.child1 .side-menu__item:hover,
.app-sidebar .slide-menu.child2 .side-menu__item:hover,
.app-sidebar .slide-menu.child3 .side-menu__item:hover {
  color: var(--primary-color);
}
.app-sidebar .slide__category {
  color: var(--menu-prime-color);
  font-size: 0.65rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  padding: 0.75rem 1.75rem;
  white-space: nowrap;
  position: relative;
  opacity: 0.5;
}
.app-sidebar .slide {
  margin: 0;
  padding: 0 0.5rem;
}
.app-sidebar .side-menu__item {
  padding: 0.65rem 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--menu-prime-color);
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 0.5rem;
}
.app-sidebar .side-menu__item.active {
  color: var(--primary-color);
}
.app-sidebar .side-menu__item.active .side-menu__label,
.app-sidebar .side-menu__item.active .side-menu__angle {
  color: var(--primary-color);
}
.app-sidebar .side-menu__item.active .side-menu__icon {
  color: var(--menu-prime-color);
}
.app-sidebar .side-menu__item:hover {
  background-color: var(--default-background);
}
.app-sidebar .side-menu__item.active {
  font-weight: 500;
}
.app-sidebar .side-menu__item svg.side-menu__icon {
  width: 20px;
  height: 20px;
}
.app-sidebar .slide-menu.child1 .side-menu__item,
.app-sidebar .slide-menu.child2 .side-menu__item,
.app-sidebar .slide-menu.child3 .side-menu__item {
  background-color: transparent !important;
}
.app-sidebar .slide-menu.child1 .side-menu__item.active,
.app-sidebar .slide-menu.child2 .side-menu__item.active,
.app-sidebar .slide-menu.child3 .side-menu__item.active {
  background-color: transparent !important;
}
.app-sidebar .slide-menu {
  padding: 0rem;
}
.app-sidebar .slide-menu.child1 .side-menu__item,
.app-sidebar .slide-menu.child2 .side-menu__item {
  padding: 0.45rem 1rem;
}
.app-sidebar .slide-menu.child1 .side-menu__item:before,
.app-sidebar .slide-menu.child2 .side-menu__item:before,
.app-sidebar .slide-menu.child3 .side-menu__item:before {
  position: absolute;
  content: "\f1ae";
  font-family: remixicon !important;
  font-size: 12px;
  inset-inline-start: -4px;
  opacity: 0.8;
}
.app-sidebar .slide-menu.child1 li,
.app-sidebar .slide-menu.child2 li,
.app-sidebar .slide-menu.child3 li {
  padding: 0;
  position: relative;
}
.app-sidebar .slide-menu.child1 li {
  padding-inline-start: 2rem;
}
.app-sidebar .slide-menu.child2 li {
  padding-inline-start: 1.5rem;
}
.app-sidebar .slide-menu.child3 li {
  padding-inline-start: 0.75rem;
}
.app-sidebar .side-menu__label {
  white-space: nowrap;
  color: var(--menu-prime-color);
  position: relative;
  font-size: 0.85rem;
  line-height: 1;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.app-sidebar .side-menu__icon {
  margin-inline-end: 0.75rem;
  line-height: 0;
  font-size: 1rem;
  text-align: center;
  color: var(--menu-prime-color);
  border-radius: 0.3rem;
}
.app-sidebar svg.side-menu_icon {
  width: 1rem;
  height: 1rem;
}
.app-sidebar .side-menu__angle {
  transform-origin: center;
  position: absolute;
  inset-inline-end: 1.25rem;
  line-height: 1;
  font-size: 1rem;
  color: var(--menu-prime-color);
  transition: all 0.05s ease;
  opacity: 0.8;
}
.app-sidebar .slide.side-menu__label1 {
  display: none;
}
.horizontal-logo {
  padding: 1.25rem 0rem;
}
.slide.has-sub .slide-menu {
  transform: translate(0, 0) !important;
  visibility: visible !important;
}
.nav ul li {
  list-style-type: none;
}
.nav > ul {
  padding-inline-start: 0px;
}
.slide-menu {
  display: none;
}
.slide.has-sub {
  display: grid;
}
.slide.has-sub.open > .side-menu__item .side-menu__angle {
  transform: rotate(180deg);
}
@media (min-width: 992px) {
  [data-toggled=open] .page {
    position: absolute;
  }
}
@media (max-width: 991.98px) {
  .horizontal-logo .header-logo .desktop-logo,
  .horizontal-logo .header-logo .desktop-dark,
  .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  .horizontal-logo .header-logo .toggle-logo {
    display: block;
  }
  .main-content {
    padding-block-start: 4.25rem;
  }
  .main-sidebar-header {
    display: none !important;
  }
  .main-sidebar {
    margin-block-start: 0;
  }
  .app-sidebar {
    inset-block-start: 0;
  }
  .main-menu {
    margin: 0 !important;
  }
}
.slide-left,
.slide-right {
  display: none;
}
[data-nav-layout=vertical] .main-menu > .slide.active .slide-menu .side-menu__item:hover .side-menu__angle,
[data-nav-layout=vertical] .main-menu > .slide:hover .slide-menu .side-menu__item:hover .side-menu__angle {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
[data-nav-layout=vertical] .slide-menu .side-menu__item:hover .side-menu__angle {
  color: var(--menu-prime-color) !important;
  fill: var(--menu-prime-color) !important;
}
@media (min-width: 992px) {
  .horizontal-logo {
    display: none;
  }
}
#responsive-overlay {
  visibility: hidden;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 101;
  background-color: rgba(15, 23, 42, 0.5);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}
#responsive-overlay.active {
  visibility: visible;
}
.sidebar-profile {
  letter-spacing: 1px;
}
.sidebar-premium-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-premium-btn button {
  letter-spacing: 1px;
}
@media (min-width: 992px) {
  [data-toggled=icon-click-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=icon-hover-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=icon-text-close]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=menu-click-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=menu-hover-closed]:not([data-nav-layout=horizontal]) .app-content {
    min-height: calc(100vh + 50rem);
  }
}
.main-customer-sidebar {
  padding: 0;
  max-height: 100vh;
  position: relative;
  overflow: auto;
}
.main-customer-menu {
  margin-block-end: 0;
  border-bottom: 0;
  border-radius: 0.85rem;
}
.customer-sidebar {
  height: 100%;
  background: var(--menu-bg);
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 100;
  transition: all 320ms ease;
}
.customer-sidebar .slide {
  margin: 0;
}
.customer-sidebar .slide:last-child .side-menu__item {
  border-bottom: 0px;
}
.customer-sidebar .slide.has-sub.open .side-menu__angle {
  transform: rotate(180deg) !important;
}
.customer-sidebar .side-menu__item {
  border-bottom: 1px solid var(--default-border);
  padding: 1rem 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--default-text-color);
  font-weight: 500;
}
.customer-sidebar .side-menu__item.active,
.customer-sidebar .side-menu__item:hover {
  color: var(--primary-color);
  background-color: transparent;
}
.customer-sidebar .side-menu__item.active .side-menu__label,
.customer-sidebar .side-menu__item.active .side-menu__angle,
.customer-sidebar .side-menu__item:hover .side-menu__label,
.customer-sidebar .side-menu__item:hover .side-menu__angle {
  color: var(--primary-color);
}
.customer-sidebar .side-menu__item.active .side-menu__icon,
.customer-sidebar .side-menu__item:hover .side-menu__icon {
  color: var(--primary-color);
}
.customer-sidebar .side-menu__item.active:before,
.customer-sidebar .side-menu__item:hover:before {
  color: var(--primary-color) !important;
}
.customer-sidebar .slide-menu.child1 .side-menu__item.active,
.customer-sidebar .slide-menu.child2 .side-menu__item.active,
.customer-sidebar .slide-menu.child3 .side-menu__item.active {
  background-color: transparent;
}
.customer-sidebar .slide-menu.child1 .slide .side-menu__item,
.customer-sidebar .slide-menu.child2 .slide .side-menu__item,
.customer-sidebar .slide-menu.child3 .slide .side-menu__item {
  position: relative;
}
.customer-sidebar .slide-menu.child1 .slide .side-menu__item.active:before,
.customer-sidebar .slide-menu.child2 .slide .side-menu__item.active:before,
.customer-sidebar .slide-menu.child3 .slide .side-menu__item.active:before {
  color: var(--primary-color);
}
.customer-sidebar .slide-menu.child1 .slide .side-menu__item:before,
.customer-sidebar .slide-menu.child2 .slide .side-menu__item:before,
.customer-sidebar .slide-menu.child3 .slide .side-menu__item:before {
  position: absolute;
  content: "\f1ae";
  font-family: remixicon !important;
  font-size: 1rem;
  inset-inline-start: 1rem;
  font-weight: 500;
  color: var(--default-text-color);
}
.customer-sidebar .slide-menu {
  padding: 0rem;
}
.customer-sidebar .slide-menu.child1 .side-menu__item,
.customer-sidebar .slide-menu.child2 .side-menu__item {
  padding: 0.8rem 2.5rem;
  opacity: 0.9;
}
.customer-sidebar .slide-menu.child1 .side-menu__item:hover,
.customer-sidebar .slide-menu.child2 .side-menu__item:hover,
.customer-sidebar .slide-menu.child3 .side-menu__item:hover {
  color: var(--primary-color);
}
.customer-sidebar .slide-menu.child1 li,
.customer-sidebar .slide-menu.child2 li,
.customer-sidebar .slide-menu.child3 li {
  padding: 0;
  position: relative;
}
.customer-sidebar .side-menu__label {
  white-space: nowrap;
  color: var(--menu-prime-color);
  position: relative;
  font-size: 0.8rem;
  line-height: 1;
  vertical-align: middle;
}
.customer-sidebar .side-menu__icon {
  margin-inline-end: 0.5rem;
  width: 1.15rem;
  height: 1.5rem;
  font-size: 1rem;
  text-align: center;
  color: var(--primary-color);
  border-radius: 0.3rem;
}
.customer-sidebar .side-menu__angle {
  transform-origin: center;
  position: absolute;
  inset-inline-end: 0.75rem;
  font-size: 0.85rem;
  color: var(--menu-prime-color);
  transition: all 320ms ease;
}
.customer-sidebar .slide.side-menu__label1 {
  display: none;
}
.customer-sidebar .slide.has-sub .slide-menu {
  transform: translate(0, 0) !important;
  visibility: visible !important;
}
.nav ul li {
  list-style-type: none;
}
.nav > ul {
  padding-inline-start: 0px;
}
.slide-menu {
  display: none;
}
.slide.has-sub {
  display: grid;
}
.slide.has-sub.open > .side-menu__item .side-menu__angle {
  transform: rotate(180deg);
}
@media (max-width: 991.98px) {
  .main-customer-sidebar {
    margin-block-start: 0;
  }
  .customer-sidebar {
    inset-block-start: 3.75rem;
  }
}
.landing-body.customer-related-portal .app-sidebar .side-menu__item {
  padding: 1rem;
}
.landing-body.customer-related-portal .app-sidebar .side-menu__icon {
  width: auto;
  height: auto;
  line-height: 1;
  padding: 0;
  color: var(--primary-color);
  font-size: 1.05rem;
  margin-inline-end: 0.25rem;
}
.main-chart-wrapper {
  position: relative;
  overflow: hidden;
}
.main-chart-wrapper .chat-info,
.main-chart-wrapper .main-chat-area,
.main-chart-wrapper .chat-user-details {
  background-color: var(--custom-white);
  height: calc(100vh - 8rem);
  border-radius: 0.3rem;
}
.main-chart-wrapper .chat-users-tab,
.main-chart-wrapper .chat-groups-tab,
.main-chart-wrapper .chat-contacts-tab {
  max-height: calc(100vh - 17.5rem);
}
.main-chart-wrapper .chat-content {
  max-height: calc(100vh - 17.5rem);
}
.main-chart-wrapper .chat-content .simplebar-content-wrapper .simplebar-content {
  margin-top: auto;
}
.main-chart-wrapper .chat-content ul li {
  margin-block-end: 1rem;
}
.main-chart-wrapper .chat-content ul li:last-child {
  margin-block-end: 0;
}
.main-chart-wrapper .responsive-chat-close,
.main-chart-wrapper button.responsive-userinfo-open {
  display: none;
}
.main-chart-wrapper .chat-info {
  position: relative;
}
.main-chart-wrapper .chat-info .tab-style-6 {
  padding: 1rem;
  border-radius: 0;
}
.main-chart-wrapper .chat-info .nav-link {
  background-color: var(--primary005);
}
.main-chart-wrapper .chat-info .tab-pane {
  padding: 0;
}
.main-chart-wrapper .chat-info .chat-groups-tab li {
  padding: 0.625rem 1.25rem;
}
.main-chart-wrapper .chat-info .chat-groups-tab .group-indivudial {
  color: var(--primary-color);
  font-weight: 400;
}
.main-chart-wrapper .chat-info .chat-contacts-tab > li {
  padding: 0.625rem 1.25rem;
}
.main-chart-wrapper .chat-info .chat-contacts-tab .incoming-call-success i,
.main-chart-wrapper .chat-info .chat-contacts-tab .outgoing-call-success i {
  color: rgb(var(--success-rgb));
  font-size: 0.875rem;
}
.main-chart-wrapper .chat-info .chat-contacts-tab .incoming-call-failed i,
.main-chart-wrapper .chat-info .chat-contacts-tab .outgoing-call-failed i {
  color: rgb(var(--danger-rgb));
  font-size: 0.875rem;
}
.main-chart-wrapper .chat-info .chat-users-tab li,
.main-chart-wrapper .chat-info .chat-groups-tab li {
  padding: 0.625rem 1rem;
  border-inline-start: 2px solid transparent;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg {
  color: var(--text-muted);
  max-width: 11.25rem;
  display: inline-block;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-msg {
  color: rgb(var(--success-rgb)) !important;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-read-icon {
  display: none;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon {
  line-height: 1;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon i {
  font-size: 1rem;
  color: rgb(var(--success-rgb));
}
.main-chart-wrapper .chat-info .chat-users-tab li.active,
.main-chart-wrapper .chat-info .chat-groups-tab li.active {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--default-text-color);
  border-inline-start: 3px solid var(--primary-color);
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread.active,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread.active {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-msg {
  color: var(--default-text-color);
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-read-icon i {
  color: var(--text-muted);
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-inactive .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-inactive .chat-read-icon {
  display: none;
}
.main-chart-wrapper .main-chat-area {
  position: relative;
}
.main-chart-wrapper .main-chat-area .main-chat-head {
  padding: 1rem;
}
.main-chart-wrapper .main-chat-area .chatnameperson,
.main-chart-wrapper .main-chat-area .chatting-user-info {
  font-weight: 600;
}
.main-chart-wrapper .main-chat-area .chat-content {
  position: relative;
  z-index: 1;
  padding: 1rem;
}
.main-chart-wrapper .main-chat-area .chat-content:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  /*background-image: url("pattern-1-IJFIFZ4Z.svg");*/
  background-repeat: repeat;
  z-index: -1;
  opacity: 0.018;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
}
.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info {
  color: var(--default-text-color);
  font-size: 0.813rem;
}
.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-weight: 500;
}
.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time .chat-read-mark i {
  color: rgb(var(--success-rgb));
  margin-inline-end: 0.3rem;
}
.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div {
  margin-block-end: 0.4rem;
  padding: 1rem;
  /*width: -moz-fit-content;*/
  width: fit-content;
}
.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div p {
  font-size: 0.813rem;
}
.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 0.3rem;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .main-chat-msg div {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  color: var(--default-text-color);
  border-start-start-radius: 1.3rem;
  border-start-end-radius: 1.3rem;
  border-end-end-radius: 1.3rem;
  border-end-start-radius: 0;
  font-weight: 500;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .msg-sent-time {
  margin-inline-start: 0.25rem;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  justify-content: end;
  text-align: end;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .main-chat-msg div {
  background-color: var(--primary09);
  color: #fff;
  border-start-start-radius: 1.3rem;
  border-start-end-radius: 1.3rem;
  border-end-end-radius: 0;
  border-end-start-radius: 1.3rem;
  font-weight: 500;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .msg-sent-time {
  margin-inline-end: 0.25rem;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  display: flex;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .chat-list-inner,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .chat-list-inner {
  display: flex;
  align-items: end;
  max-width: 75%;
}
.main-chart-wrapper .main-chat-area .chat-footer {
  width: 100%;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.main-chart-wrapper .main-chat-area .chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  border-top: 1px solid var(--default-border);
  background-color: var(--custom-white);
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-end: 0;
}
.main-chart-wrapper .main-chat-area .chat-day-label {
  text-align: center;
  color: var(--text-muted);
  margin-block-end: 2rem;
  opacity: 0.75;
  position: relative;
}
.main-chart-wrapper .main-chat-area .chat-day-label span {
  padding: 0.188rem 0.5rem;
  font-size: 0.7rem;
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  border-radius: 0.3rem;
  color: var(--primary-tint2-color);
}
@media (min-width: 992px) {
  .main-chart-wrapper .chat-info {
    min-width: 25rem;
    max-width: 25rem;
  }
}
.main-chart-wrapper .main-chat-area {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
@media (max-width: 1275.98px) and (min-width: 992px) {
  .main-chart-wrapper .chat-info {
    min-width: 25rem;
    max-width: 25rem;
  }
  .main-chart-wrapper .main-chat-area {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}
@media (max-width: 991.98px) {
  .main-chart-wrapper .chat-info {
    width: 100%;
  }
  .main-chart-wrapper .main-chat-area {
    display: none;
    min-width: 100%;
    max-width: 100%;
  }
  .main-chart-wrapper .responsive-chat-close {
    display: block;
  }
}
.chat-user-details .avatar {
  outline: 0.25rem solid var(--primary02);
}
.chat-user-details .shared-files li {
  margin-block-end: 1rem;
}
.chat-user-details .shared-files li:last-child {
  margin-block-end: 0;
}
.chat-user-details .shared-files .shared-file-icon i {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  padding: 1.125rem;
  justify-content: center;
  font-size: 1.125rem;
}
.chat-user-details .chat-media img {
  width: 100%;
  border-radius: 0.3rem;
  margin-bottom: 1.25rem;
}
@media (max-width: 1400px) {
  .chat-user-details.open {
    display: block;
    inset-inline-end: 0;
    inset-block-start: 0.5rem;
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
    border-inline-start: 1px solid var(--default-border);
  }
  button.responsive-userinfo-open {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .main-chart-wrapper.responsive-chat-open .chat-info {
    display: none;
  }
  .main-chart-wrapper.responsive-chat-open .main-chat-area {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media (max-width: 354px) {
  .main-chart-wrapper .chat-contacts-tab,
  .main-chart-wrapper .chat-groups-tab,
  .main-chart-wrapper .chat-users-tab {
    max-height: calc(100vh - 19.5rem);
  }
}
.chat-contacts-tab li {
  border-bottom: 1px solid var(--default-border);
}
.chat-contacts-tab li:last-child {
  border-bottom: 0;
}
[data-page-style=modern] .main-chat-area .rightIcons .btn-outline-light {
  border-color: var(--default-border);
}
[dir=rtl] .chat-footer .btn-send {
  transform: rotate(180deg);
}
[data-theme-mode=dark] .main-chat-area .chat-content:before {
  filter: invert(1);
}
@media screen and (min-width: 576px) {
  [dir=rtl] .ecommerce-more-link::before,
  [dir=rtl] .ecommerce-more-link:after {
    inset-inline-end: 0.5rem;
  }
}
.ecommerce-more-link[aria-expanded=true]:after {
  display: block;
}
.ecommerce-more-link[aria-expanded=true]:before {
  display: none;
}
.products-navigation-card .form-check-label {
  font-size: 0.813rem;
  font-weight: 500;
}
.swiper-view-details .swiper-slide {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border);
  border-radius: 0.3rem !important;
}
.swiper-view-details .swiper-slide.swiper-slide-thumb-active {
  background-color: rgb(var(--light-rgb)) !important;
}
.swiper-preview-details .swiper-button-next {
  background-color: var(--black-1);
  color: var(--custom-white) !important;
}
.swiper-preview-details .swiper-button-prev {
  background-color: var(--black-1);
  color: var(--custom-white) !important;
}
.product-colors {
  width: 1.15rem;
  height: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--default-border);
  margin-inline-end: 0.5rem;
  background-color: rgb(var(--light-rgb));
}
.product-colors.color-2.selected {
  border: 0.125rem solid #f78aeb;
}
.product-colors.color-2 i {
  color: #f78aeb;
}
.product-colors.color-4.selected {
  border: 0.125rem solid #ff9594;
}
.product-colors.color-4 i {
  color: #ff9594;
}
.product-colors.color-1.selected {
  border: 0.125rem solid #8a90e7;
}
.product-colors.color-1 i {
  color: #8a90e7;
}
.product-colors.color-3.selected {
  border: 0.125rem solid #f78ab6;
}
.product-colors.color-3 i {
  color: #f78ab6;
}
.product-colors.color-5.selected {
  border: 0.125rem solid #b688f3;
}
.product-colors.color-5 i {
  color: #b688f3;
}
.ecommerce-assurance {
  border: 1px dashed var(--primary03);
  border-radius: 0.3rem;
  padding: 1rem 1.5rem;
}
.ecommerce-assurance svg {
  width: 3rem;
  height: 3rem;
}
.product-images {
  padding-inline-start: 0.5rem;
}
.product-images .products-review-images img {
  width: 3.125rem;
  height: 3.125rem;
  margin-inline-end: 0.25rem;
  border-radius: 0.3rem;
  background-color: var(--primary01);
}
.similar-product-name {
  max-width: 80%;
}
.product-quantity-container {
  width: 10.5rem;
}
.product-quantity-container .input-group input.form-control:focus {
  box-shadow: none;
}
.product-quantity-container .input-group .product-quantity-minus.btn:focus,
.product-quantity-container .input-group .product-quantity-minus.btn:hover,
.product-quantity-container .input-group .product-quantity-plus.btn:focus,
.product-quantity-container .input-group .product-quantity-plus.btn:hover {
  border-color: var(--input-border);
}
.product-quantity-container .cart-input-group {
  width: 9rem;
}
.product-quantity-container .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.cart-summary-nav.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link {
  background-color: rgb(var(--light-rgb));
}
.cart-summary-nav.tab-style-8.nav-tabs.scaleX.nav-tabs > .nav-item > .nav-link.active {
  background-color: var(--primary005);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}
.cart-items01 {
  width: 31rem;
}
.quantity-icon {
  vertical-align: text-top;
  padding-block-start: 1px;
}
.cart-empty svg {
  width: 6.25rem;
  height: 6.25rem;
  margin-block-end: 1.25rem;
  fill: var(--default-text-color);
}
.product-checkout .tab-style-2 .nav-item .nav-link {
  padding: 0.85rem 2rem;
}
.product-checkout .form-floating input,
.product-checkout .form-floating textarea {
  font-size: 0.813rem;
  font-weight: 400;
}
.product-checkout .shipping-method-container,
.product-checkout .payment-card-container {
  position: relative;
  padding: 0.625rem;
  border: 1px solid var(--input-border);
  border-radius: 0.3rem;
}
.product-checkout .shipping-method-container .form-check-input,
.product-checkout .payment-card-container .form-check-input {
  position: absolute;
  inset-inline-end: 0.75rem;
  inset-block-start: 1.2rem;
}
@media (min-width: 576px) {
  .product-checkout .shipping-method-container .shipping-partner-details,
  .product-checkout .shipping-method-container .saved-card-details,
  .product-checkout .payment-card-container .shipping-partner-details,
  .product-checkout .payment-card-container .saved-card-details {
    width: 30rem;
  }
}
.checkout-payment-success {
  text-align: center;
}
.checkout-payment-success img {
  width: 200px;
  height: 200px;
}
@media screen and (max-width: 575px) {
  #shipped-tab-pane .btn-group .btn {
    width: 100%;
    border-radius: 0.3rem;
  }
}
.order-track .accordion {
  position: relative;
}
.order-track .accordion:before {
  content: "";
  inset-inline-start: 14px;
  inset-block-start: 30px;
  inset-inline-end: 0;
  inset-block-end: 0;
  position: absolute;
  width: 1px;
  border-inline-start: 1px dashed var(--primary-color);
  height: 72%;
  opacity: 0.2;
}
.order-track .accordion:last-child::before {
  content: none;
}
.order-track .track-order-icon {
  padding: 2px;
}
.add-products .tab-style-2 .nav-item .nav-link {
  padding: 0.85rem 2rem;
}
.color-selection .choices__input {
  width: 6.25rem;
}
.product-documents-container .filepond--root {
  width: 100%;
}
.product-documents-container .filepond--panel-root {
  border-color: var(--input-border) !important;
  border-radius: 0.3rem;
}
.product-documents-container .filepond--root .filepond--drop-label label {
  color: var(--text-muted);
}
#product-features {
  max-height: 14.75rem;
  overflow-y: scroll;
}
.carousel-indicators [data-bs-target] {
  background-color: #fff;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  opacity: 1;
  border: 3px solid var(--primary02);
  transition: all ease 0.5s;
}
.carousel-indicators [data-bs-target][data-bs-target].active {
  border-color: var(--primary-color);
}
.heading-section {
  text-align: center;
}
.heading-description {
  margin-block-end: 0;
  color: var(--default-text-color);
}
.top-left-badge {
  padding: 0.45rem 0.65rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
  inset-inline-start: 1rem;
  display: grid;
  inset-inline-start: 1rem;
  align-content: stretch;
  align-items: baseline;
  justify-items: start;
}
.card.custom-card.card-style-2:hover {
  box-shadow: 0 3px 10px 0px var(--black-1);
}
.card-style-2 {
  overflow: hidden;
}
.card-style-2 .img-box-2 {
  transition: transform ease 1.5s;
  display: flex;
  justify-content: center;
}
.card-style-2 .img-box-2:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-block-start: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-style-2 .card-img-top {
  position: relative;
}
.card-style-2 .btn-style-1 {
  position: absolute;
  inset-inline-end: 1rem;
  inset-block-end: 1rem;
  box-shadow: none !important;
}
.card-style-2:hover {
  border-color: var(--primary02);
}
.card-style-2:hover .btns-container-1 {
  bottom: 30%;
  z-index: 2;
}
.card-style-2:hover .img-box-2 .bg-primary-transparent {
  background-color: var(--primary02) !important;
}
.card-style-2 .btns-container-1 {
  position: absolute;
  inset-inline-start: 0;
  text-align: center;
  justify-content: center;
  bottom: -10%;
  display: flex;
  width: 100%;
  z-index: -1;
  transition: all 0.5s;
}
.card.custom-card.card-style-3:hover {
  box-shadow: 0 3px 10px 0px var(--black-1);
}
.ecommerce-more-link {
  position: relative;
  padding-block-start: 0.6rem;
  padding-inline-end: 1.5rem;
  padding-block-end: 0.6rem;
  padding-inline-start: 0.75rem;
  background-color: var(--primary01);
  color: var(--primary-color);
  border-radius: 0.3rem;
  font-weight: 500;
  width: 100%;
  font-size: 0.75rem;
  display: block;
}
.ecommerce-more-link:hover {
  color: var(--primary-color);
}
.ecommerce-more-link:before {
  position: absolute;
  content: "\f64d";
  inset-inline-end: 0.75rem;
  font-family: bootstrap-icons !important;
  inset-block-start: 0.625rem;
  font-weight: bold;
}
.ecommerce-more-link:after {
  position: absolute;
  content: "\f2ea";
  inset-inline-end: 0.75rem;
  font-family: bootstrap-icons !important;
  inset-block-start: 0.625rem;
  font-weight: bold;
  display: none;
}
.ecommerce-gallery img {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  border-radius: 0.3rem;
  -o-object-fit: contain;
  object-fit: contain;
}
.tag-badge {
  position: absolute;
  inset-block-start: 0.75rem;
  inset-inline-start: 0.75rem;
}
.ecommerce-gallery {
  max-height: 430px;
  display: flex;
  justify-content: center;
  background-color: var(--primary01);
  border-radius: 0.3rem;
}
.glightbox.card {
  z-index: 0;
}
.glightbox.card:hover .view-lightbox {
  display: flex;
  position: absolute;
  inset-block-end: 40%;
  inset-block-start: auto;
  inset-inline-start: 40%;
  content: "";
  z-index: 1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
}
.glightbox.card:hover .view-lightbox:hover {
  color: var(--white-8);
}
.glightbox.card:hover:after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.glightbox.card .view-lightbox {
  display: none;
}
.ad-gallery {
  display: none;
}
@media (min-width: 769px) {
  .glightbox-clean .gslide-media {
    box-shadow: none !important;
  }
}
.glightbox-clean .gslide-description {
  background-color: transparent !important;
}
.glightbox-clean .gslide-title {
  color: #fff !important;
}
.glightbox-clean .gslide-title {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  font-family: var(--default-font-family) !important;
  margin-bottom: 0 !important;
}
.glightbox-clean .gdesc-inner {
  text-align: center;
}
.similar-products-image img {
  width: 4.97rem;
  height: 4.97rem;
  border-radius: 0.3rem;
  background-color: var(--primary005);
}
.filter-bw {
  filter: grayscale(1);
}
.card-style-6 .card-style-6-avatar .avatar {
  border: 1px solid var(--default-border);
  color: var(--default-text-color);
}
.card-style-6:has(.form-check-input:checked) {
  border-color: var(--primary05);
  background-color: rgba(var(--secondary-rgb), 0.08);
  border-style: dashed;
}
.card-style-6:has(.form-check-input:checked) .card-style-6-avatar .avatar {
  background-color: var(--primary-color);
  color: #fff;
}
.file-manager-folders,
.selected-file-details {
  background-color: var(--custom-white);
}
.folder-svg-container svg {
  width: 3rem;
  height: 3rem;
}
.file-details img {
  width: 150px;
  height: 150px;
  background-color: rgb(var(--light-rgb));
  border-radius: 50%;
}
@media (max-width: 1200px) {
  .selected-file-details.open {
    width: 19.5rem;
    position: absolute;
    display: block;
    inset-inline-end: 0;
    inset-block-start: 0.5rem;
    box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
    border-inline-start: 1px solid var(--default-border);
  }
}
.file-manager-folders.open {
  display: block;
}
@media (max-width: 365px) {
  .file-manager-container .file-folders-container {
    max-height: calc(100vh - 12.9rem);
  }
}
ul.files-main-nav {
  margin-block-end: 0;
}
ul.files-main-nav li {
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  margin-block-end: 0.08rem;
}
ul.files-main-nav li:last-child {
  margin-block-end: 0;
}
ul.files-main-nav li div {
  color: var(--text-muted);
}
ul.files-main-nav li:hover div {
  color: var(--primary-color);
}
ul.files-main-nav li.active {
  background-color: var(--primary01);
}
ul.files-main-nav li.active div {
  color: var(--primary-color);
}
ul.files-main-nav li div.filemanager-upgrade-storage {
  width: 235px;
  height: auto;
  background-color: rgb(var(--light-rgb));
  border: 2px dashed var(--default-border);
  border-radius: 0.3rem;
  text-align: center;
  color: var(--default-text-color);
  padding: 1rem;
}
ul.files-main-nav li div.filemanager-upgrade-storage img {
  width: 150px;
  height: 150px;
}
#file-manager-storage .apexcharts-pie line,
#file-manager-storage .apexcharts-pie circle {
  stroke: transparent;
}
#file-manager-storage .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--text-muted) !important;
}
#file-manager-storage .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: var(--default-text-color) !important;
}
@media (min-width: 992px) {
  [data-nav-style=menu-hover][data-nav-layout=horizontal][data-theme-mode=dark] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1::before {
    border-block-start-color: rgba(0, 0, 0, 0.1);
    border-inline-start-color: rgba(0, 0, 0, 0.1);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal][data-theme-mode=dark][dir=rtl] .slide.has-sub.open .slide-menu.child1::before {
    /*border-inline-end-color: var(--border);*/
    border-inline-start-color: transparent !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item .side-menu__angle {
    color: var(--default-text-color);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item .side-menu__angle {
    color: var(--menu-prime-color) !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active {
    color: var(--primary-color);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active .side-menu__angle {
    color: var(--primary-color) !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active:before {
    border-color: var(--primary-color) !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item {
    border-radius: 0.25rem;
    padding: 1.35rem 1rem;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 {
    border-radius: 0.25rem;
    padding: 0.55rem 0.3rem;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 .side-menu__item {
    padding: 0.5rem 1rem;
    font-weight: 500;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 {
    overflow: visible !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1::before {
    content: "";
    position: inherit !important;
    inset-block-start: -7px;
    inset-inline-start: 10%;
    width: 13px;
    height: 13px;
    z-index: 99999;
    border: 1px solid transparent;
    /*border-block-start-color: var(--border);
    border-inline-start-color: var(--border);*/
    transform: rotate(45deg);
    background-color: var(--custom-white);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item {
    padding-inline-end: 1.3rem;
    padding-inline-start: 1.3rem;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover {
    background-color: transparent !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 .slide .side-menu__item:before {
    inset-block-start: 0.838rem;
    inset-inline-start: 0.65rem;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__angle {
    color: var(--primary-color) !important;
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__label {
    color: var(--default-text-color);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover {
    background-color: var(--primary-color);
    color: var(--primary-color);
  }
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active .side-menu__label,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__label,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__angle {
    color: var(--primary-color);
  }
  .flex-none {
    flex: none;
  }
  [data-nav-layout=horizontal] .landing-body .app-sidebar .main-menu > .slide {
    margin: 0;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=horizontal] .landing-body .landing-page-wrapper .app-sidebar.sticky.sticky-pin {
    position: fixed;
  }
}
.reviews-container .heading-section {
  position: relative;
}
.reviews-container .heading-section:before {
  position: absolute;
  content: "";
  width: 200px;
  background-position: center;
  height: 200px;
  inset-inline-start: -85px;
  z-index: 0;
  bottom: -38px;
  background:
          linear-gradient(
                  45deg,
                  rgba(255, 255, 255, 0.1),
                  transparent);
  border-radius: 50%;
}
.reviews-container .card {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: none;
  margin-block-end: 0;
}
.reviews-container .card .card-body {
  color: #fff;
}
.testimonialSwiperService {
  padding: 0 0 3.375rem 0 !important;
}
.landing-body {
  background-color: var(--custom-white);
}
.landing-body .app-sidebar {
  border-bottom: 0;
}
.landing-body .app-sidebar .side-menu__item {
  padding: 0.8rem 1rem;
}
.landing-body .app-sidebar .side-menu__item.active,
.landing-body .app-sidebar .side-menu__item:hover {
  color: var(--primary-color) !important;
}
.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active {
  font-weight: normal;
}
.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active .side-menu__label {
  color: var(--primary-color) !important;
}
.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active .side-menu__angle {
  color: var(--primary-color) !important;
}
.landing-body .accordion.accordion-primary .accordion-button.collapsed:after {
  background-color: var(--primary01);
  color: var(--primary-color);
}
.landing-body .landing-Features {
  position: relative;
  width: 100%;
  height: 100%;
  inset-block-start: 0;
  background: rgba(var(--primary-rgb), 1);
  z-index: 1;
}
.landing-body .landing-Features:before {
  /*background-image: url("media-80-XSQK7UY5.jpg");*/
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: -1;
  opacity: 0.075;
}
@media (min-width: 992px) {
  .landing-body .app-sidebar {
    inset-block-start: 0;
    padding: 0.5rem 0;
    height: auto !important;
    background-color: var(--custom-white);
    box-shadow: none;
    border-inline-end: 0;
  }
  .landing-body .app-sidebar .main-sidebar {
    height: auto !important;
    width: 100%;
  }
  .landing-body .app-sidebar.sticky.sticky-pin {
    background-color: var(--custom-white);
    box-shadow: 0 0.25rem 1rem var(--black-1);
  }
  .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item .side-menu__angle {
    color: var(--menu-prime-color);
  }
  .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item:hover .side-menu__angle {
    color: var(--primary-color) !important;
  }
  .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__label {
    color: var(--custom-black);
  }
  .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-dark,
  .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
    display: none;
  }
  .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-logo {
    display: block;
  }
  .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__item:hover .side-menu__label {
    color: var(--primary-color) !important;
  }
  .landing-body .app-sidebar.app-sidebar .slide.has-sub.open .slide-menu.child1::before {
    border-block-start-color: var(--default-border) !important;
    border-inline-start-color: var(--default-border) !important;
  }
  .landing-body .app-header {
    display: none;
  }
  .landing-body .main-sidebar-header {
    display: block !important;
  }
  .landing-body .main-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .landing-body .main-menu-container .slide-left,
  .landing-body .main-menu-container .slide-right {
    display: none;
  }
  .landing-body .main-content {
    padding: 0;
    min-height: calc(100vh - 7.9rem);
  }
  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }
  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-dark,
  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-white {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .landing-body .app-sidebar .side-menu__item.active,
  .landing-body .app-sidebar .side-menu__item:hover {
    background-color: transparent;
  }
  .landing-body .main-menu-container .main-menu {
    padding-inline: 0px !important;
    width: 100%;
  }
  .landing-body .slide.has-sub.open > .side-menu__item .side-menu__angle {
    transform: rotate(90deg);
  }
  .landing-body .app-sidebar .slide-menu {
    padding-inline-start: 2rem;
  }
  .landing-body .app-sidebar .slide {
    padding: 0;
  }
}
.landing-body .main-menu-container .main-menu {
  padding-inline-start: 0;
}
.landing-body .app-content {
  margin-block-start: 4rem;
}
.landing-body .section {
  padding: 4.375rem 0;
  background-size: cover;
  position: relative;
}
.landing-body .landing-banner {
  position: relative;
  width: 100%;
  inset-block-start: 0;
  background-color: var(--primary-color);
  z-index: 1;
}
.landing-body .landing-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-image: url("media-88-XWXEPDVK.jpg");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}
.landing-body .landing-banner .landing-banner-heading {
  text-shadow: 1px 1px var(--black-1);
  font-size: 2.1rem;
  font-weight: 500;
  color: var(--default-text-color);
}
@media (max-width: 767.98px) {
  .landing-body .landing-main-image {
    display: none;
  }
  .landing-body .landing-banner .main-banner-container {
    padding: 1rem;
  }
}
@media (max-width: 1115.98px) {
  .landing-body .landing-main-image::before,
  .landing-body .landing-main-image::after {
    display: none;
  }
}
@media (max-width: 480px) {
  .landing-body .landing-banner .section {
    padding: 2.375rem 0;
  }
}
.landing-body .landing-main-image {
  position: relative;
  z-index: 10;
}
.landing-body .landing-main-image:before {
  position: absolute;
  content: "";
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  background-color: var(--custom-white);
  opacity: 0.07;
  z-index: -1;
  inset-block-start: -36px;
  inset-inline-start: 116px;
}
.landing-body .landing-main-image img {
  z-index: 11;
  position: relative;
  margin-block-start: -10px;
}
.landing-body .landing-page-wrapper {
  position: relative;
  min-height: calc(100vh - 3.4rem);
}
.landing-body .app-sidebar .side-menu__label {
  font-weight: 500;
}
.landing-body .landing-section-heading {
  position: relative;
  font-weight: 600;
  text-decoration-line: overline;
}
.landing-body .landing-footer {
  background-color: rgb(25, 32, 56);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.landing-body .landing-footer .landing-footer-list li {
  margin-bottom: 0.5rem;
}
.landing-body .landing-footer .landing-footer-list li:last-child {
  margin-bottom: 0;
}
.landing-body .landing-footer .landing-footer-logo {
  height: 1.7rem;
  line-height: 1.7rem;
}
.landing-body .landing-main-footer {
  background-color: rgb(25, 32, 56);
}
.landing-body .section-bg {
  background-color: var(--primary01);
}
.card.custom-card.landing-card .card-body {
  padding: 2rem;
}
.sub-card-companies img {
  background: rgba(255, 255, 255, 0.1);
  height: 77px;
  border-radius: 50%;
  border-inline-start: 1px solid rgba(255, 255, 255, 0.15);
  padding: 20px;
}
@media (min-width: 992px) {
  [data-theme-mode=dark] .landing-body .landing-logo-container .horizontal-logo .desktop-logo {
    display: none;
  }
  [data-theme-mode=dark] .landing-body .landing-logo-container .horizontal-logo .desktop-dark,
  [data-theme-mode=dark] .landing-body .landing-logo-container .horizontal-logo .desktop-white {
    display: block;
  }
}
@media (max-width: 991.98px) {
  [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .desktop-logo,
  [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .desktop-dark {
    display: none;
  }
  [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .desktop-white {
    display: block;
  }
}
[data-theme-mode=dark] .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-dark,
[data-theme-mode=dark] .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-logo {
  display: none;
}
[data-theme-mode=dark] .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
  display: block;
}
[data-theme-mode=dark] .section-bg {
  background-color: var(--primary01);
}
@media (max-width: 420px) {
  .landing-body .landing-banner .main-banner-container {
    padding: 1rem;
  }
  .landing-body .landing-banner .landing-banner-heading {
    font-size: 2rem;
  }
}
@media (max-width: 992px) {
  .landing-body .app-sidebar .slide-menu.child1 li,
  .landing-body .app-sidebar .slide-menu.child2 li,
  .landing-body .app-sidebar .slide-menu.child3 li {
    padding: 0;
    padding-inline-start: 0;
    position: relative;
  }
}
.custom-form-group {
  position: relative;
  display: flex;
  align-items: center;
}
.custom-form-group .form-control {
  padding-inline-end: 7rem;
  padding-inline-start: 1.25rem;
}
.custom-form-group .form-control-lg ~ .custom-form-btn {
  inset-inline-end: 0.7rem;
}
.custom-form-group .custom-form-btn {
  position: absolute;
  inset-inline-end: 0.5rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-body .landing-main-footer .landing-footer-list li:not(:first-child)::before {
  position: absolute;
  content: "";
  width: 0.3rem;
  height: 0.6rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  inset-inline-start: -0.2rem;
  inset-block-start: 0.35rem;
}
.landing-body .landing-main-footer .landing-footer-list li:not(:first-child)::before .review-quote {
  position: absolute;
  inset-inline-end: 0.8rem;
  inset-block-start: 1rem;
  font-size: 1.875rem;
  line-height: 0;
  color: var(--primary07);
  background-color: transparent;
  padding: 0.625rem;
}
.landing-body .landing-main-footer .landing-footer-list li:not(:first-child) {
  position: relative;
}
.landing-body .landing-main-footer .landing-footer-list li {
  display: inline-block;
  padding: 0 12px;
}
@media (max-width: 991.98px) {
  .landing-body .main-content {
    padding-block-start: 4.25rem;
  }
  .landing-body .animated-arrow span {
    inset-block-start: 1.15rem;
  }
}
.landing-body .animated-arrow {
  z-index: 8;
}
@media (min-width: 992px) {
  .landing-body .slide.has-sub.open > .side-menu__item .side-menu__angle {
    transform: rotate(270deg);
  }
}
.landing-body .main-content.landing-main {
  background-color: var(--default-body-bg-color);
}
.main-mail-container {
  position: relative;
  overflow: hidden;
}
.mail-navigation,
.total-mails,
.mail-recepients {
  background-color: var(--custom-white);
  height: calc(100vh - 10rem);
  overflow: hidden;
  border-radius: 0.3rem;
}
.mail-info-body {
  max-height: calc(100vh - 8.3rem);
}
@media (min-width: 1400px) {
  .total-mails .mail-msg .mail-msg-content {
    display: inline-block;
  }
}
@media (min-width: 1400px) and (max-width: 1489.98px) {
  .responsive-mail-action-icons .dropdown {
    display: block;
  }
  .responsive-mail-action-icons .close-button {
    display: none;
  }
  .mail-action-icons {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .responsive-mail-action-icons {
    display: block;
    display: flex;
  }
  .mail-action-icons {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .mail-recepients {
    display: none;
  }
}
@media (min-width: 1489px) {
  .responsive-mail-action-icons {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .mail-navigation {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .mail-navigation {
    min-width: 20rem;
    max-width: 20rem;
  }
}
.total-mails {
  width: 100%;
}
.total-mails .mail-messages {
  max-height: calc(100vh - 18.5rem);
}
.total-mails .mail-messages li {
  padding: 1.1rem 1rem 0.625rem 1rem;
  border-bottom: 1px solid var(--default-border);
}
.total-mails .mail-messages li.active,
.total-mails .mail-messages li:hover {
  background-color: var(--primary005);
}
.total-mails .mail-messages li:last-child {
  border-bottom: 0;
}
.total-mails .mail-messages li .avatar.mail-msg-avatar {
  width: 2rem;
  height: 2rem;
}
.total-mails .mail-messages li .avatar.mail-msg-avatar.online:before,
.total-mails .mail-messages li .avatar.mail-msg-avatar.offline:before {
  width: 0.55rem;
  height: 0.55rem;
}
.total-mails .mail-messages li .mail-msg .mail-starred i {
  color: var(--text-muted);
  opacity: 0.5;
}
.total-mails .mail-messages li .mail-msg .mail-starred.true i {
  color: rgb(var(--warning-rgb));
  opacity: 1;
}
.mail-recepients {
  min-width: 4.4rem;
  max-width: 4.4rem;
}
.mail-recepients .total-mail-recepients {
  max-height: calc(100vh - 12.4rem);
}
.mail-recepients .mail-recepeint-person .avatar {
  width: 2rem;
  height: 2rem;
  margin-block-end: 1rem;
}
.mail-recepients .mail-recepeint-person .avatar.online:before,
.mail-recepients .mail-recepeint-person .avatar.offline:before {
  width: 0.55rem;
  height: 0.55rem;
}
.mail-recepients .mail-recepeint-person:last-child {
  margin-block-end: 0;
}
.mail-navigation ul.mail-main-nav {
  max-height: calc(100vh - 14.5rem);
  padding: 1rem;
  margin-block-end: 0;
}
.mail-navigation ul.mail-main-nav li {
  padding: 0.55rem 0.8rem;
  border-radius: var(--default-border);
  border-radius: 0.3rem;
  font-weight: 500;
}
.mail-navigation ul.mail-main-nav li div {
  color: var(--text-muted);
}
.mail-navigation ul.mail-main-nav li.active {
  background-color: var(--primary01);
}
.mail-navigation ul.mail-main-nav li.active div {
  color: var(--primary-color);
  font-weight: 600;
}
.mail-navigation ul.mail-main-nav li:hover div {
  color: var(--primary-color);
}
@media (min-width: 576px) {
  .mail-msg-content {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .mail-msg-content {
    max-width: 180px;
  }
}
.mails-information {
  width: 100%;
}
.mails-information .mail-info-header {
  padding: 0.75rem;
  border-block-end: 1px solid var(--default-border);
}
.mails-information .mail-info-footer {
  padding: 0.75rem;
  border-block-start: 1px solid var(--default-border);
}
.mails-information .mail-attachment {
  padding: 0.25rem;
  width: 12rem;
  height: 2.75rem;
  border: 1px solid var(--default-border);
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
}
.mails-information .mail-attachment .attachment-icon svg,
.mails-information .mail-attachment .attachment-icon i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 2rem;
  margin-inline-end: 0.5rem;
}
.mails-information .mail-attachment .attachment-name {
  max-width: 7rem;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
}
.mail-reply .ql-toolbar.ql-snow .ql-formats {
  margin-top: 5px;
  margin-bottom: 5px;
}
#mail-compose-editor .ql-editor {
  min-height: 12.62rem !important;
}
.mail-compose .ql-toolbar.ql-snow .ql-formats {
  margin-top: 5px;
  margin-bottom: 5px;
}
.offcanvas.offcanvas-end.mail-info-offcanvas {
  width: 50rem;
}
.mail-notification-settings,
.mail-security-settings {
  width: 60%;
}
@media (max-width: 575.98px) {
  #account-settings .btn-group label {
    font-size: 0.625rem;
  }
}
.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  padding: 10px !important;
}
.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  display: none;
}
.mail-settings-tab.nav-tabs-header .nav-item .nav-link {
  padding: 0.65rem 0.8rem;
  border: 0;
}
.mail-settings-tab.nav-tabs-header .nav-item .nav-link:hover,
.mail-settings-tab.nav-tabs-header .nav-item .nav-link:focus {
  border: 0;
}
.TASK-kanban-board {
  display: flex;
  overflow-x: auto;
  align-items: stretch;
  margin-bottom: 0.75rem;
  padding-bottom: 1rem;
}
.TASK-kanban-board .kanban-tasks-type {
  min-width: 20rem;
  margin-inline-end: 0.5rem;
  width: 100%;
}
.TASK-kanban-board .kanban-tasks-type .kanban-tasks .card {
  touch-action: none;
}
.TASK-kanban-board::-webkit-scrollbar-thumb {
  background-color: rgba(var(--dark-rgb), 0.1);
  border-radius: 0.3125rem;
}
.TASK-kanban-board::-webkit-scrollbar-track {
  border-radius: 0.3125rem;
}
.TASK-kanban-board .task-image .kanban-image {
  height: 150px;
  width: 100%;
}
.TASK-kanban-board .kanban-content {
  margin-top: 0.75rem;
}
.TASK-kanban-board .kanban-task-description {
  color: var(--text-muted);
}
.TASK-kanban-board .kanban-tasks-type.new .kanban-tasks .card {
  border: 1px solid rgba(var(--primary-rgb), 0.2);
}
.TASK-kanban-board .kanban-tasks-type.todo .kanban-tasks .card {
  border: 1px solid rgba(var(--primary-tint1-rgb), 0.2);
}
.TASK-kanban-board .kanban-tasks-type.in-progress .kanban-tasks .card {
  border: 1px solid rgba(var(--primary-tint2-rgb), 0.2);
}
.TASK-kanban-board .kanban-tasks-type.inreview .kanban-tasks .card {
  border: 1px solid rgba(var(--primary-tint3-rgb), 0.2);
}
.TASK-kanban-board .kanban-tasks-type.completed .kanban-tasks .card {
  border: 1px solid rgba(var(--secondary-rgb), 0.2);
}
.TASK-kanban-board #new-tasks .card.custom-card:last-child,
.TASK-kanban-board #todo-tasks .card.custom-card:last-child,
.TASK-kanban-board #inprogress-tasks .card.custom-card:last-child,
.TASK-kanban-board #inreview-tasks .card.custom-card:last-child,
.TASK-kanban-board #completed-tasks .card.custom-card:last-child {
  margin-bottom: 0;
}
.TASK-kanban-board #new-tasks,
.TASK-kanban-board #todo-tasks,
.TASK-kanban-board #inprogress-tasks,
.TASK-kanban-board #inreview-tasks,
.TASK-kanban-board #completed-tasks {
  position: relative;
  max-height: 35rem;
}
.TASK-kanban-board #new-tasks .simplebar-content,
.TASK-kanban-board #todo-tasks .simplebar-content,
.TASK-kanban-board #inprogress-tasks .simplebar-content,
.TASK-kanban-board #inreview-tasks .simplebar-content,
.TASK-kanban-board #completed-tasks .simplebar-content {
  padding: 0 1rem 0 0 !important;
}
.TASK-kanban-board .task-Null {
  position: relative;
  min-height: 12.5rem;
}
.TASK-kanban-board .task-Null::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--custom-white);
  border-radius: 0.3rem;
  /*background-image: url("media-92-EVJRGFI7.svg");*/
  background-size: cover;
  background-position: center;
  height: 12.5rem;
  width: 100%;
  margin: 0 auto;
}
.TASK-kanban-board .view-more-button {
  margin-right: 1rem;
}
.task-details-key-tasks {
  list-style-type: decimal;
}
.task-details-key-tasks li {
  margin-bottom: 0.5rem;
  color: var(--text-muted);
}
.task-details-key-tasks li:last-child {
  margin-bottom: 0;
}
.task-description {
  font-size: 0.875rem;
}
.avatar {
  position: relative;
  height: 2.625rem;
  width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 500;
}
.avatar a.badge:hover {
  color: #fff;
}
.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
}
.avatar svg {
  width: 1.5rem;
  height: 1.5rem;
}
.avatar.avatar-rounded {
  border-radius: 50%;
}
.avatar.avatar-rounded img {
  border-radius: 50%;
}
.avatar.avatar-radius-0 {
  border-radius: 0;
}
.avatar.avatar-radius-0 img {
  border-radius: 0;
}
.avatar .avatar-badge {
  position: absolute;
  inset-block-start: -4%;
  inset-inline-end: -0.375rem;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.625rem;
  border: 2px solid var(--custom-white);
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.online:before,
.avatar.offline:before {
  position: absolute;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  inset-inline-end: 0;
  inset-block-end: 0;
  border: 2px solid var(--custom-white);
}
.avatar.online:before {
  background-color: rgb(var(--success-rgb));
}
.avatar.offline:before {
  background-color: var(--gray-5);
}
.avatar.avatar-xs {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
}
.avatar.avatar-xs .avatar-badge {
  padding: 0.25rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
  inset-block-start: -25%;
  inset-inline-end: -0.5rem;
}
.avatar.avatar-sm {
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: 0.75rem;
}
.avatar.avatar-sm .avatar-badge {
  padding: 0.3rem;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.5rem;
  inset-block-start: -38%;
  inset-inline-end: -0.5rem;
}
.avatar.avatar-sm.online:before,
.avatar.avatar-sm.offline:before {
  width: 0.5rem;
  height: 0.5rem;
}
.avatar.avatar-sm svg {
  width: 1rem;
  height: 1rem;
}
.avatar.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}
.avatar.avatar-md .avatar-badge {
  padding: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.65rem;
  inset-block-start: -6%;
  inset-inline-end: -13%;
}
.avatar.avatar-md.online:before,
.avatar.avatar-md.offline:before {
  width: 0.75rem;
  height: 0.75rem;
}
.avatar.avatar-md svg {
  width: 1.25rem;
  height: 1.25rem;
}
.avatar.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
}
.avatar.avatar-lg .avatar-badge {
  inset-block-start: -15%;
  inset-inline-end: -0.25%;
}
.avatar.avatar-lg.online:before,
.avatar.avatar-lg.offline:before {
  width: 0.8rem;
  height: 0.8rem;
}
.avatar.avatar-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.avatar.avatar-xl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.25rem;
}
.avatar.avatar-xl .avatar-badge {
  inset-block-start: -8%;
  inset-inline-end: -0.2%;
}
.avatar.avatar-xl.online:before,
.avatar.avatar-xl.offline:before {
  width: 0.95rem;
  height: 0.95rem;
}
.avatar.avatar-xxl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 1.5rem;
}
.avatar.avatar-xxl .avatar-badge {
  inset-block-start: -4%;
  inset-inline-end: 0rem;
}
.avatar.avatar-xxl.online:before,
.avatar.avatar-xxl.offline:before {
  width: 1.05rem;
  height: 1.05rem;
  inset-block-end: 0.25rem;
}
.avatar-list-stacked {
  padding: 0;
}
.avatar-list-stacked .avatar {
  margin-inline-end: -0.65rem !important;
  border: 2px solid transparent;
  vertical-align: middle;
  transition: transform ease 200ms;
}
.avatar-list-stacked .avatar:last-child {
  margin-inline-end: 0 !important;
}
.avatar-list-stacked .avatar:hover {
  z-index: 1;
  transform: scale(1.15);
  border: 2px solid var(--custom-white);
}
[dir=rtl] .avatar-list-stacked .ri-arrow-right-s-line {
  transform: rotate(180deg);
}
.color-container {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
}
.text-container {
  padding: 0.25rem 0.5rem;
  border-radius: 0.3rem;
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}
.bg-body {
  background-color: var(--default-body-bg-color) !important;
}
.bg-primary {
  background-color: var(--primary-color) !important;
}
.bg-primary.bg-opacity-10 {
  background-color: var(--primary01) !important;
}
.bg-primary.bg-opacity-25 {
  background-color: var(--primary03) !important;
}
.bg-primary.bg-opacity-50 {
  background-color: var(--primary05) !important;
}
.bg-primary.bg-opacity-75 {
  background-color: var(--primary08) !important;
}
.bg-primary.bg-opacity-100 {
  background-color: var(--primary-color) !important;
}
.bg-primary1 {
  background-color: var(--primary-tint1-color) !important;
}
.bg-primary1.bg-opacity-10 {
  background-color: rgba(var(--primary-tint1-rgb), 0.1) !important;
}
.bg-primary1.bg-opacity-25 {
  background-color: rgba(var(--primary-tint1-rgb), 0.25) !important;
}
.bg-primary1.bg-opacity-50 {
  background-color: rgba(var(--primary-tint1-rgb), 0.5) !important;
}
.bg-primary1.bg-opacity-75 {
  background-color: rgba(var(--primary-tint1-rgb), 0.75) !important;
}
.bg-primary1.bg-opacity-100 {
  background-color: rgba(var(--primary-tint1-rgb), 1) !important;
}
.bg-primary2 {
  background-color: var(--primary-tint2-color) !important;
}
.bg-primary2.bg-opacity-10 {
  background-color: rgba(var(--primary-tint2-rgb), 0.1) !important;
}
.bg-primary2.bg-opacity-25 {
  background-color: rgba(var(--primary-tint2-rgb), 0.25) !important;
}
.bg-primary2.bg-opacity-50 {
  background-color: rgba(var(--primary-tint2-rgb), 0.5) !important;
}
.bg-primary2.bg-opacity-75 {
  background-color: rgba(var(--primary-tint2-rgb), 0.75) !important;
}
.bg-primary2.bg-opacity-100 {
  background-color: rgba(var(--primary-tint2-rgb), 1) !important;
}
.bg-primary3 {
  background-color: var(--primary-tint3-color) !important;
}
.bg-primary3.bg-opacity-10 {
  background-color: rgba(var(--primary-tint3-rgb), 0.1) !important;
}
.bg-primary3.bg-opacity-25 {
  background-color: rgba(var(--primary-tint3-rgb), 0.25) !important;
}
.bg-primary3.bg-opacity-50 {
  background-color: rgba(var(--primary-tint3-rgb), 0.5) !important;
}
.bg-primary3.bg-opacity-75 {
  background-color: rgba(var(--primary-tint3-rgb), 0.75) !important;
}
.bg-primary3.bg-opacity-100 {
  background-color: rgba(var(--primary-tint3-rgb), 1) !important;
}
.bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
}
.bg-secondary.bg-opacity-10 {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
}
.bg-secondary.bg-opacity-25 {
  background-color: rgba(var(--secondary-rgb), 0.25) !important;
}
.bg-secondary.bg-opacity-50 {
  background-color: rgba(var(--secondary-rgb), 0.5) !important;
}
.bg-secondary.bg-opacity-75 {
  background-color: rgba(var(--secondary-rgb), 0.75) !important;
}
.bg-secondary.bg-opacity-100 {
  background-color: rgba(var(--secondary-rgb), 1) !important;
}
.bg-info {
  background-color: rgb(var(--info-rgb)) !important;
}
.bg-info.bg-opacity-10 {
  background-color: rgba(var(--info-rgb), 0.1) !important;
}
.bg-info.bg-opacity-25 {
  background-color: rgba(var(--info-rgb), 0.25) !important;
}
.bg-info.bg-opacity-50 {
  background-color: rgba(var(--info-rgb), 0.5) !important;
}
.bg-info.bg-opacity-75 {
  background-color: rgba(var(--info-rgb), 0.75) !important;
}
.bg-info.bg-opacity-100 {
  background-color: rgba(var(--info-rgb), 1) !important;
}
.bg-success {
  background-color: rgb(var(--success-rgb)) !important;
}
.bg-success.bg-opacity-10 {
  background-color: rgba(var(--success-rgb), 0.1) !important;
}
.bg-success.bg-opacity-25 {
  background-color: rgba(var(--success-rgb), 0.25) !important;
}
.bg-success.bg-opacity-50 {
  background-color: rgba(var(--success-rgb), 0.5) !important;
}
.bg-success.bg-opacity-75 {
  background-color: rgba(var(--success-rgb), 0.75) !important;
}
.bg-success.bg-opacity-100 {
  background-color: rgba(var(--success-rgb), 1) !important;
}
.bg-warning {
  background-color: rgb(var(--warning-rgb)) !important;
}
.bg-warning.bg-opacity-10 {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
}
.bg-warning.bg-opacity-25 {
  background-color: rgba(var(--warning-rgb), 0.25) !important;
}
.bg-warning.bg-opacity-50 {
  background-color: rgba(var(--warning-rgb), 0.5) !important;
}
.bg-warning.bg-opacity-75 {
  background-color: rgba(var(--warning-rgb), 0.75) !important;
}
.bg-warning.bg-opacity-100 {
  background-color: rgba(var(--warning-rgb), 1) !important;
}
.bg-danger {
  background-color: rgb(var(--danger-rgb)) !important;
}
.bg-danger.bg-opacity-10 {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
}
.bg-danger.bg-opacity-25 {
  background-color: rgba(var(--danger-rgb), 0.25) !important;
}
.bg-danger.bg-opacity-50 {
  background-color: rgba(var(--danger-rgb), 0.5) !important;
}
.bg-danger.bg-opacity-75 {
  background-color: rgba(var(--danger-rgb), 0.75) !important;
}
.bg-danger.bg-opacity-100 {
  background-color: rgba(var(--danger-rgb), 1) !important;
}
.bg-dark {
  background-color: rgb(var(--dark-rgb)) !important;
}
.bg-dark.bg-opacity-10 {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
}
.bg-dark.bg-opacity-25 {
  background-color: rgba(var(--dark-rgb), 0.25) !important;
}
.bg-dark.bg-opacity-50 {
  background-color: rgba(var(--dark-rgb), 0.5) !important;
}
.bg-dark.bg-opacity-75 {
  background-color: rgba(var(--dark-rgb), 0.75) !important;
}
.bg-dark.bg-opacity-100 {
  background-color: rgba(var(--dark-rgb), 1) !important;
}
.bg-light {
  background-color: rgb(var(--light-rgb)) !important;
}
.bg-light.bg-opacity-10 {
  background-color: rgba(var(--light-rgb), 0.1) !important;
}
.bg-light.bg-opacity-25 {
  background-color: rgba(var(--light-rgb), 0.25) !important;
}
.bg-light.bg-opacity-50 {
  background-color: rgba(var(--light-rgb), 0.5) !important;
}
.bg-light.bg-opacity-75 {
  background-color: rgba(var(--light-rgb), 0.75) !important;
}
.bg-light.bg-opacity-100 {
  background-color: rgba(var(--light-rgb), 1) !important;
}
.bg-white {
  background-color: var(--custom-white) !important;
}
.bg-pink {
  background-color: rgb(var(--pink-rgb)) !important;
}
.bg-orange {
  background-color: rgb(var(--orange-rgb)) !important;
}
.bg-purple {
  background-color: rgb(var(--purple-rgb)) !important;
}
.bg-green {
  background-color: rgb(var(--green-rgb)) !important;
}
.bg-teal {
  background-color: rgb(var(--teal-rgb)) !important;
}
.bg-blue {
  background-color: #0082FF !important;
}
.bg-indigo {
  background-color: #4d5ddb !important;
}
.bg-yellow {
  background-color: #fff621 !important;
}
.bg-primary-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-color) 0%,
                  rgb(var(--secondary-rgb)) 100%) !important;
  color: #fff;
}
.bg-primary1-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint1-color) 0%,
                  #AB54E3 100%) !important;
  color: #fff;
}
.bg-primary2-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint2-color) 0%,
                  #FF6C5D 100%) !important;
  color: #fff;
}
.bg-primary3-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-tint3-color) 0%,
                  #FF663C 100%) !important;
  color: #fff;
}
.bg-secondary-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--secondary-rgb)) 0%,
                  #7289FF 100%) !important;
  color: #fff;
}
.bg-warning-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--warning-rgb)) 0%,
                  #9EA53C 100%) !important;
  color: #fff;
}
.bg-info-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--info-rgb)) 0%,
                  #4990E1 100%) !important;
  color: #fff;
}
.bg-success-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--success-rgb)) 0%,
                  #009CA4 100%) !important;
  color: #fff;
}
.bg-danger-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--danger-rgb)) 0%,
                  #DE4980 100%) !important;
  color: #fff;
}
.bg-orange-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--orange-rgb)) 0%,
                  #E5647E 100%) !important;
  color: #fff;
}
.bg-purple-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--purple-rgb)) 0%,
                  #0086FF 100%) !important;
  color: #fff;
}
.bg-teal-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--teal-rgb)) 0%,
                  #3AE3C7 100%) !important;
  color: #fff;
}
.bg-light-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--light-rgb)) 0%,
                  #D7FCFF 100%) !important;
  color: #000;
}
.bg-dark-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  rgb(var(--dark-rgb)) 0%,
                  #16657A 100%) !important;
  color: #fff;
}
.bg-outline-primary {
  background-color: var(--custom-white);
  border: 1px solid var(--primary-color);
  color: var(--primary-color) !important;
}
.bg-outline-primary1 {
  background-color: var(--custom-white);
  border: 1px solid var(--primary-tint1-color);
  color: var(--primary-tint1-color);
}
.bg-outline-primary2 {
  background-color: var(--custom-white);
  border: 1px solid var(--primary-tint2-color);
  color: var(--primary-tint2-color);
}
.bg-outline-primary3 {
  background-color: var(--custom-white);
  border: 1px solid var(--primary-tint3-color);
  color: var(--primary-tint3-color);
}
.bg-outline-secondary {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--secondary-rgb));
  color: rgb(var(--secondary-rgb));
}
.bg-outline-warning {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}
.bg-outline-info {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}
.bg-outline-success {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}
.bg-outline-danger {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}
.bg-outline-dark {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--dark-rgb));
  color: rgb(var(--dark-rgb));
}
.bg-outline-light {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--light-rgb));
  color: var(--custom-black);
}
.bg-gray-100 {
  background-color: var(--gray-1);
}
.bg-gray-200 {
  background-color: var(--gray-2);
}
.bg-gray-300 {
  background-color: var(--gray-3);
}
.bg-gray-400 {
  background-color: var(--gray-4);
}
.bg-gray-500 {
  background-color: var(--gray-5);
}
.bg-gray-600 {
  background-color: var(--gray-6);
}
.bg-gray-700 {
  background-color: var(--gray-7);
}
.bg-gray-800 {
  background-color: var(--gray-8);
}
.bg-gray-900 {
  background-color: var(--gray-9);
}
.bg-primary-transparent {
  background-color: rgba(var(--primary-rgb), 0.1) !important;
  color: var(--primary-color) !important;
}
.bg-primary-transparent:hover {
  background-color: rgba(var(--primary-rgb), 0.1) !important;
  color: var(--primary-color) !important;
}
.bg-primary1-transparent {
  background-color: rgba(var(--primary-tint1-rgb), 0.1) !important;
  color: var(--primary-tint1-color) !important;
}
.bg-primary1-transparent:hover {
  background-color: rgba(var(--primary-tint1-rgb), 0.1) !important;
  color: var(--primary-tint1-color) !important;
}
.bg-primary2-transparent {
  background-color: rgba(var(--primary-tint2-rgb), 0.1) !important;
  color: var(--primary-tint2-color) !important;
}
.bg-primary2-transparent:hover {
  background-color: rgba(var(--primary-tint2-rgb), 0.1) !important;
  color: var(--primary-tint2-color) !important;
}
.bg-primary3-transparent {
  background-color: rgba(var(--primary-tint3-rgb), 0.1) !important;
  color: var(--primary-tint3-color) !important;
}
.bg-primary3-transparent:hover {
  background-color: rgba(var(--primary-tint3-rgb), 0.1) !important;
  color: var(--primary-tint3-color) !important;
}
.bg-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb)) !important;
}
.bg-secondary-transparent:hover {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb)) !important;
}
.bg-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb)) !important;
}
.bg-info-transparent:hover {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb)) !important;
}
.bg-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb)) !important;
}
.bg-success-transparent:hover {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb)) !important;
}
.bg-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb)) !important;
}
.bg-warning-transparent:hover {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb)) !important;
}
.bg-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb)) !important;
}
.bg-danger-transparent:hover {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb)) !important;
}
.bg-light-transparent {
  background-color: rgba(var(--light-rgb), 0.1) !important;
  color: rgb(var(--light-rgb)) !important;
}
.bg-light-transparent:hover {
  background-color: rgba(var(--light-rgb), 0.1) !important;
  color: var(--default-text-color) !important;
}
.bg-dark-transparent {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
  color: rgb(var(--dark-rgb)) !important;
}
.bg-dark-transparent:hover {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
  color: rgb(var(--dark-rgb)) !important;
}
.bg-pink-transparent {
  background-color: rgba(var(--pink-rgb), 0.1) !important;
  color: rgb(var(--pink-rgb)) !important;
}
.bg-pink-transparent:hover {
  background-color: rgba(var(--pink-rgb), 0.1) !important;
  color: rgb(var(--pink-rgb)) !important;
}
.bg-orange-transparent {
  background-color: rgba(var(--orange-rgb), 0.1) !important;
  color: rgb(var(--orange-rgb)) !important;
}
.bg-orange-transparent:hover {
  background-color: rgba(var(--orange-rgb), 0.1) !important;
  color: rgb(var(--orange-rgb)) !important;
}
.bg-purple-transparent {
  background-color: rgba(var(--purple-rgb), 0.1) !important;
  color: rgb(var(--purple-rgb)) !important;
}
.bg-purple-transparent:hover {
  background-color: rgba(var(--purple-rgb), 0.1) !important;
  color: rgb(var(--purple-rgb)) !important;
}
.bg-teal-transparent {
  background-color: rgba(var(--teal-rgb), 0.1) !important;
  color: rgb(var(--teal-rgb)) !important;
}
.bg-teal-transparent:hover {
  background-color: rgba(var(--teal-rgb), 0.1) !important;
  color: rgb(var(--teal-rgb)) !important;
}
.bg-green-transparent {
  background-color: rgba(var(--green-rgb), 0.1) !important;
  color: rgb(var(--green-rgb)) !important;
}
.bg-green-transparent:hover {
  background-color: rgba(var(--green-rgb), 0.1) !important;
  color: rgb(var(--green-rgb)) !important;
}
.bg-indigo-transparent {
  background-color: rgba(77, 93, 219, 0.1) !important;
  color: #4d5ddb !important;
}
.bg-indigo-transparent:hover {
  background-color: rgba(77, 93, 219, 0.1) !important;
  color: #4d5ddb !important;
}
.bg-yellow-transparent {
  background-color: rgba(255, 246, 33, 0.1) !important;
  color: #fff621 !important;
}
.bg-yellow-transparent:hover {
  background-color: rgba(255, 246, 33, 0.1) !important;
  color: #fff621 !important;
}
.bg-blue-transparent {
  background-color: rgba(0, 130, 255, 0.1) !important;
  color: #0082FF !important;
}
.bg-blue-transparent:hover {
  background-color: rgba(0, 130, 255, 0.1) !important;
  color: #0082FF !important;
}
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: rgb(255, 255, 255) !important;
}
.bg-white-transparent:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: rgb(255, 255, 255) !important;
}
.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: rgb(0, 0, 0) !important;
}
.bg-black-transparent:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: rgb(0, 0, 0) !important;
}
.text-bg-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.text-bg-primary1 {
  background-color: var(--primary-tint1-color) !important;
  color: #fff !important;
}
.text-bg-primary2 {
  background-color: var(--primary-tint2-color) !important;
  color: #fff !important;
}
.text-bg-primary3 {
  background-color: var(--primary-tint3-color) !important;
  color: #fff !important;
}
.text-bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
  color: #fff !important;
}
.text-bg-warning {
  background-color: rgb(var(--warning-rgb)) !important;
  color: #fff !important;
}
.text-bg-info {
  background-color: rgb(var(--info-rgb)) !important;
  color: #fff !important;
}
.text-bg-success {
  background-color: rgb(var(--success-rgb)) !important;
  color: #fff !important;
}
.text-bg-danger {
  background-color: rgb(var(--danger-rgb)) !important;
  color: #fff !important;
}
.text-bg-light {
  background-color: rgb(var(--light-rgb)) !important;
  color: #000 !important;
}
.text-bg-dark {
  background-color: rgb(var(--dark-rgb)) !important;
  color: #fff !important;
}
.svg-primary svg {
  fill: var(--primary-color);
}
.svg-primary1 svg {
  fill: var(--primary-tint1-color);
}
.svg-primary2 svg {
  fill: var(--primary-tint2-color);
}
.svg-primary3 svg {
  fill: var(--primary-tint3-color);
}
.svg-secondary svg {
  fill: rgb(var(--secondary-rgb));
}
.svg-success svg {
  fill: rgb(var(--success-rgb));
}
.svg-danger svg {
  fill: rgb(var(--danger-rgb));
}
.svg-warning svg {
  fill: rgb(var(--warning-rgb));
}
.svg-white svg {
  fill: #fff;
}
.svg-black svg {
  fill: #000;
}
.svg-pink svg {
  fill: rgb(var(--pink-rgb));
}
.svg-orange svg {
  fill: rgb(var(--orange-rgb));
}
.svg-purple svg {
  fill: rgb(var(--purple-rgb));
}
.svg-indigo svg {
  fill: #4d5ddb;
}
.svg-info svg {
  fill: rgb(var(--info-rgb));
}
.svg-yellow svg {
  fill: #fff621;
}
.svg-light svg {
  fill: rgb(var(--light-rgb));
}
.svg-dark svg {
  fill: rgb(var(--dark-rgb));
}
.svg-teal svg {
  fill: rgb(var(--teal-rgb));
}
.svg-default svg {
  fill: var(--default-text-color);
}
.bd-blue-100 {
  background-color: rgba(0, 130, 255, 0.1);
  color: var(--default-text-color);
}
.bd-blue-200 {
  background-color: rgba(0, 130, 255, 0.2);
  color: var(--default-text-color);
}
.bd-blue-300 {
  background-color: rgba(0, 130, 255, 0.3);
  color: var(--default-text-color);
}
.bd-blue-400 {
  background-color: rgba(0, 130, 255, 0.4);
  color: var(--default-text-color);
}
.bd-blue-500 {
  background-color: rgba(0, 130, 255, 0.5);
  color: var(--default-text-color);
}
.bd-blue-600 {
  background-color: rgba(0, 130, 255, 0.6);
  color: #fff;
}
.bd-blue-700 {
  background-color: rgba(0, 130, 255, 0.7);
  color: #fff;
}
.bd-blue-800 {
  background-color: rgba(0, 130, 255, 0.8);
  color: #fff;
}
.bd-blue-900 {
  background-color: rgba(0, 130, 255, 0.9);
  color: #fff;
}
.bd-blue {
  background-color: #0082FF;
  color: #fff;
}
.bd-indigo-100 {
  background-color: rgba(77, 93, 219, 0.1);
  color: var(--default-text-color);
}
.bd-indigo-200 {
  background-color: rgba(77, 93, 219, 0.2);
  color: var(--default-text-color);
}
.bd-indigo-300 {
  background-color: rgba(77, 93, 219, 0.3);
  color: var(--default-text-color);
}
.bd-indigo-400 {
  background-color: rgba(77, 93, 219, 0.4);
  color: var(--default-text-color);
}
.bd-indigo-500 {
  background-color: rgba(77, 93, 219, 0.5);
  color: var(--default-text-color);
}
.bd-indigo-600 {
  background-color: rgba(77, 93, 219, 0.6);
  color: #fff;
}
.bd-indigo-700 {
  background-color: rgba(77, 93, 219, 0.7);
  color: #fff;
}
.bd-indigo-800 {
  background-color: rgba(77, 93, 219, 0.8);
  color: #fff;
}
.bd-indigo-900 {
  background-color: rgba(77, 93, 219, 0.9);
  color: #fff;
}
.bd-indigo {
  background-color: #4d5ddb;
  color: #fff;
}
.bd-purple-100 {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: var(--default-text-color);
}
.bd-purple-200 {
  background-color: rgba(var(--purple-rgb), 0.2);
  color: var(--default-text-color);
}
.bd-purple-300 {
  background-color: rgba(var(--purple-rgb), 0.3);
  color: var(--default-text-color);
}
.bd-purple-400 {
  background-color: rgba(var(--purple-rgb), 0.4);
  color: var(--default-text-color);
}
.bd-purple-500 {
  background-color: rgba(var(--purple-rgb), 0.5);
  color: var(--default-text-color);
}
.bd-purple-600 {
  background-color: rgba(var(--purple-rgb), 0.6);
  color: #fff;
}
.bd-purple-700 {
  background-color: rgba(var(--purple-rgb), 0.7);
  color: #fff;
}
.bd-purple-800 {
  background-color: rgba(var(--purple-rgb), 0.8);
  color: #fff;
}
.bd-purple-900 {
  background-color: rgba(var(--purple-rgb), 0.9);
  color: #fff;
}
.bd-purple {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
}
.bd-pink-100 {
  background-color: rgba(var(--pink-rgb), 0.1);
  color: var(--default-text-color);
}
.bd-pink-200 {
  background-color: rgba(var(--pink-rgb), 0.2);
  color: var(--default-text-color);
}
.bd-pink-300 {
  background-color: rgba(var(--pink-rgb), 0.3);
  color: var(--default-text-color);
}
.bd-pink-400 {
  background-color: rgba(var(--pink-rgb), 0.4);
  color: var(--default-text-color);
}
.bd-pink-500 {
  background-color: rgba(var(--pink-rgb), 0.5);
  color: var(--default-text-color);
}
.bd-pink-600 {
  background-color: rgba(var(--pink-rgb), 0.6);
  color: #fff;
}
.bd-pink-700 {
  background-color: rgba(var(--pink-rgb), 0.7);
  color: #fff;
}
.bd-pink-800 {
  background-color: rgba(var(--pink-rgb), 0.8);
  color: #fff;
}
.bd-pink-900 {
  background-color: rgba(var(--pink-rgb), 0.9);
  color: #fff;
}
.bd-pink {
  background-color: rgb(var(--pink-rgb));
  color: #fff;
}
.bd-red-100 {
  background-color: rgba(208, 61, 70, 0.1);
  color: var(--default-text-color);
}
.bd-red-200 {
  background-color: rgba(208, 61, 70, 0.2);
  color: var(--default-text-color);
}
.bd-red-300 {
  background-color: rgba(208, 61, 70, 0.3);
  color: var(--default-text-color);
}
.bd-red-400 {
  background-color: rgba(208, 61, 70, 0.4);
  color: var(--default-text-color);
}
.bd-red-500 {
  background-color: rgba(208, 61, 70, 0.5);
  color: var(--default-text-color);
}
.bd-red-600 {
  background-color: rgba(208, 61, 70, 0.6);
  color: #fff;
}
.bd-red-700 {
  background-color: rgba(208, 61, 70, 0.7);
  color: #fff;
}
.bd-red-800 {
  background-color: rgba(208, 61, 70, 0.8);
  color: #fff;
}
.bd-red-900 {
  background-color: rgba(208, 61, 70, 0.9);
  color: #fff;
}
.bd-red {
  background-color: #d03d46;
  color: #fff;
}
.bd-orange-100 {
  background-color: rgba(var(--orange-rgb), 0.1);
  color: var(--default-text-color);
}
.bd-orange-200 {
  background-color: rgba(var(--orange-rgb), 0.2);
  color: var(--default-text-color);
}
.bd-orange-300 {
  background-color: rgba(var(--orange-rgb), 0.3);
  color: var(--default-text-color);
}
.bd-orange-400 {
  background-color: rgba(var(--orange-rgb), 0.4);
  color: var(--default-text-color);
}
.bd-orange-500 {
  background-color: rgba(var(--orange-rgb), 0.5);
  color: var(--default-text-color);
}
.bd-orange-600 {
  background-color: rgba(var(--orange-rgb), 0.6);
  color: #fff;
}
.bd-orange-700 {
  background-color: rgba(var(--orange-rgb), 0.7);
  color: #fff;
}
.bd-orange-800 {
  background-color: rgba(var(--orange-rgb), 0.8);
  color: #fff;
}
.bd-orange-900 {
  background-color: rgba(var(--orange-rgb), 0.9);
  color: #fff;
}
.bd-orange {
  background-color: rgb(var(--orange-rgb));
  color: #fff;
}
.bd-yellow-100 {
  background-color: rgba(255, 246, 33, 0.1);
  color: var(--default-text-color);
}
.bd-yellow-200 {
  background-color: rgba(255, 246, 33, 0.2);
  color: var(--default-text-color);
}
.bd-yellow-300 {
  background-color: rgba(255, 246, 33, 0.3);
  color: var(--default-text-color);
}
.bd-yellow-400 {
  background-color: rgba(255, 246, 33, 0.4);
  color: var(--default-text-color);
}
.bd-yellow-500 {
  background-color: rgba(255, 246, 33, 0.5);
  color: var(--default-text-color);
}
.bd-yellow-600 {
  background-color: rgba(255, 246, 33, 0.6);
  color: #fff;
}
.bd-yellow-700 {
  background-color: rgba(255, 246, 33, 0.7);
  color: #fff;
}
.bd-yellow-800 {
  background-color: rgba(255, 246, 33, 0.8);
  color: #fff;
}
.bd-yellow-900 {
  background-color: rgba(255, 246, 33, 0.9);
  color: #fff;
}
.bd-yellow {
  background-color: #fff621;
  color: #fff;
}
.bd-green-100 {
  background-color: rgba(var(--green-rgb), 0.1);
  color: var(--default-text-color);
}
.bd-green-200 {
  background-color: rgba(var(--green-rgb), 0.2);
  color: var(--default-text-color);
}
.bd-green-300 {
  background-color: rgba(var(--green-rgb), 0.3);
  color: var(--default-text-color);
}
.bd-green-400 {
  background-color: rgba(var(--green-rgb), 0.4);
  color: var(--default-text-color);
}
.bd-green-500 {
  background-color: rgba(var(--green-rgb), 0.5);
  color: var(--default-text-color);
}
.bd-green-600 {
  background-color: rgba(var(--green-rgb), 0.6);
  color: #fff;
}
.bd-green-700 {
  background-color: rgba(var(--green-rgb), 0.7);
  color: #fff;
}
.bd-green-800 {
  background-color: rgba(var(--green-rgb), 0.8);
  color: #fff;
}
.bd-green-900 {
  background-color: rgba(var(--green-rgb), 0.9);
  color: #fff;
}
.bd-green {
  background-color: rgb(var(--green-rgb));
  color: #fff;
}
.bd-teal-100 {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: var(--default-text-color);
}
.bd-teal-200 {
  background-color: rgba(var(--teal-rgb), 0.2);
  color: var(--default-text-color);
}
.bd-teal-300 {
  background-color: rgba(var(--teal-rgb), 0.3);
  color: var(--default-text-color);
}
.bd-teal-400 {
  background-color: rgba(var(--teal-rgb), 0.4);
  color: var(--default-text-color);
}
.bd-teal-500 {
  background-color: rgba(var(--teal-rgb), 0.5);
  color: var(--default-text-color);
}
.bd-teal-600 {
  background-color: rgba(var(--teal-rgb), 0.6);
  color: #fff;
}
.bd-teal-700 {
  background-color: rgba(var(--teal-rgb), 0.7);
  color: #fff;
}
.bd-teal-800 {
  background-color: rgba(var(--teal-rgb), 0.8);
  color: #fff;
}
.bd-teal-900 {
  background-color: rgba(var(--teal-rgb), 0.9);
  color: #fff;
}
.bd-teal {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
}
.bd-cyan-100 {
  background-color: rgba(0, 209, 209, 0.1);
  color: var(--default-text-color);
}
.bd-cyan-200 {
  background-color: rgba(0, 209, 209, 0.2);
  color: var(--default-text-color);
}
.bd-cyan-300 {
  background-color: rgba(0, 209, 209, 0.3);
  color: var(--default-text-color);
}
.bd-cyan-400 {
  background-color: rgba(0, 209, 209, 0.4);
  color: var(--default-text-color);
}
.bd-cyan-500 {
  background-color: rgba(0, 209, 209, 0.5);
  color: var(--default-text-color);
}
.bd-cyan-600 {
  background-color: rgba(0, 209, 209, 0.6);
  color: #fff;
}
.bd-cyan-700 {
  background-color: rgba(0, 209, 209, 0.7);
  color: #fff;
}
.bd-cyan-800 {
  background-color: rgba(0, 209, 209, 0.8);
  color: #fff;
}
.bd-cyan-900 {
  background-color: rgba(0, 209, 209, 0.9);
  color: #fff;
}
.bd-cyan {
  background-color: #00D1D1;
  color: #fff;
}
.bd-gray-100 {
  background-color: rgba(134, 153, 163, 0.1);
  color: var(--default-text-color);
}
.bd-gray-200 {
  background-color: rgba(134, 153, 163, 0.2);
  color: var(--default-text-color);
}
.bd-gray-300 {
  background-color: rgba(134, 153, 163, 0.3);
  color: var(--default-text-color);
}
.bd-gray-400 {
  background-color: rgba(134, 153, 163, 0.4);
  color: var(--default-text-color);
}
.bd-gray-500 {
  background-color: rgba(134, 153, 163, 0.5);
  color: var(--default-text-color);
}
.bd-gray-600 {
  background-color: rgba(134, 153, 163, 0.6);
  color: #fff;
}
.bd-gray-700 {
  background-color: rgba(134, 153, 163, 0.7);
  color: #fff;
}
.bd-gray-800 {
  background-color: rgba(134, 153, 163, 0.8);
  color: #fff;
}
.bd-gray-900 {
  background-color: rgba(134, 153, 163, 0.9);
  color: #fff;
}
.bd-gray {
  background-color: #8699A3;
  color: #fff;
}
[data-theme-mode=dark] .invert-1 {
  filter: invert(1);
}
.backdrop-blur {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}
.border-container {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: rgba(var(--light-rgb), 0.3);
}
.border {
  border: 1px solid var(--default-border) !important;
}
.border.border-dashed {
  border-style: dashed !important;
}
.border.border-dotted {
  border-style: dotted !important;
}
.border.border-primary {
  border: 1px solid var(--primary-color) !important;
  opacity: 1;
}
.border.border-primary.border-opacity-10 {
  border-color: var(--primary01) !important;
}
.border.border-primary.border-opacity-25 {
  border-color: var(--primary03) !important;
}
.border.border-primary.border-opacity-50 {
  border-color: var(--primary05) !important;
}
.border.border-primary.border-opacity-75 {
  border-color: var(--primary08) !important;
}
.border.border-primary.border-opacity-100 {
  border-color: var(--primary-color) !important;
}
.border.border-primary1 {
  border: 1px solid rgba(var(--primary-tint1-rgb), 1) !important;
  opacity: 1;
}
.border.border-primary1.border-opacity-10 {
  border-color: rgba(var(--primary-tint1-rgb), 0.1) !important;
}
.border.border-primary1.border-opacity-25 {
  border-color: rgba(var(--primary-tint1-rgb), 0.25) !important;
}
.border.border-primary1.border-opacity-50 {
  border-color: rgba(var(--primary-tint1-rgb), 0.5) !important;
}
.border.border-primary1.border-opacity-75 {
  border-color: rgba(var(--primary-tint1-rgb), 0.75) !important;
}
.border.border-primary1.border-opacity-100 {
  border-color: rgba(var(--primary-tint1-rgb), 1) !important;
}
.border.border-primary2 {
  border: 1px solid rgba(var(--primary-tint2-rgb), 1) !important;
  opacity: 1;
}
.border.border-primary2.border-opacity-10 {
  border-color: rgba(var(--primary-tint2-rgb), 0.1) !important;
}
.border.border-primary2.border-opacity-25 {
  border-color: rgba(var(--primary-tint2-rgb), 0.25) !important;
}
.border.border-primary2.border-opacity-50 {
  border-color: rgba(var(--primary-tint2-rgb), 0.5) !important;
}
.border.border-primary2.border-opacity-75 {
  border-color: rgba(var(--primary-tint2-rgb), 0.75) !important;
}
.border.border-primary2.border-opacity-100 {
  border-color: rgba(var(--primary-tint2-rgb), 1) !important;
}
.border.border-primary3 {
  border: 1px solid rgba(var(--primary-tint3-rgb), 1) !important;
  opacity: 1;
}
.border.border-primary3.border-opacity-10 {
  border-color: rgba(var(--primary-tint3-rgb), 0.1) !important;
}
.border.border-primary3.border-opacity-25 {
  border-color: rgba(var(--primary-tint3-rgb), 0.25) !important;
}
.border.border-primary3.border-opacity-50 {
  border-color: rgba(var(--primary-tint3-rgb), 0.5) !important;
}
.border.border-primary3.border-opacity-75 {
  border-color: rgba(var(--primary-tint3-rgb), 0.75) !important;
}
.border.border-primary3.border-opacity-100 {
  border-color: rgba(var(--primary-tint3-rgb), 1) !important;
}
.border.border-secondary {
  border: 1px solid rgb(var(--secondary-rgb)) !important;
  opacity: 1;
}
.border.border-secondary.border-opacity-10 {
  border-color: rgba(var(--secondary-rgb), 0.1) !important;
}
.border.border-secondary.border-opacity-25 {
  border-color: rgba(var(--secondary-rgb), 0.25) !important;
}
.border.border-secondary.border-opacity-50 {
  border-color: rgba(var(--secondary-rgb), 0.5) !important;
}
.border.border-secondary.border-opacity-75 {
  border-color: rgba(var(--secondary-rgb), 0.75) !important;
}
.border.border-secondary.border-opacity-100 {
  border-color: rgba(var(--secondary-rgb), 1) !important;
}
.border.border-info {
  border: 1px solid rgb(var(--info-rgb)) !important;
  opacity: 1;
}
.border.border-info.border-opacity-10 {
  border-color: rgba(var(--info-rgb), 0.1) !important;
}
.border.border-info.border-opacity-25 {
  border-color: rgba(var(--info-rgb), 0.25) !important;
}
.border.border-info.border-opacity-50 {
  border-color: rgba(var(--info-rgb), 0.5) !important;
}
.border.border-info.border-opacity-75 {
  border-color: rgba(var(--info-rgb), 0.75) !important;
}
.border.border-info.border-opacity-100 {
  border-color: rgba(var(--info-rgb), 1) !important;
}
.border.border-success {
  border: 1px solid rgb(var(--success-rgb)) !important;
  opacity: 1;
}
.border.border-success.border-opacity-10 {
  border-color: rgba(var(--success-rgb), 0.1) !important;
}
.border.border-success.border-opacity-25 {
  border-color: rgba(var(--success-rgb), 0.25) !important;
}
.border.border-success.border-opacity-50 {
  border-color: rgba(var(--success-rgb), 0.5) !important;
}
.border.border-success.border-opacity-75 {
  border-color: rgba(var(--success-rgb), 0.75) !important;
}
.border.border-success.border-opacity-100 {
  border-color: rgba(var(--success-rgb), 1) !important;
}
.border.border-warning {
  border: 1px solid rgb(var(--warning-rgb)) !important;
  opacity: 1;
}
.border.border-warning.border-opacity-10 {
  border-color: rgba(var(--warning-rgb), 0.1) !important;
}
.border.border-warning.border-opacity-25 {
  border-color: rgba(var(--warning-rgb), 0.25) !important;
}
.border.border-warning.border-opacity-50 {
  border-color: rgba(var(--warning-rgb), 0.5) !important;
}
.border.border-warning.border-opacity-75 {
  border-color: rgba(var(--warning-rgb), 0.75) !important;
}
.border.border-warning.border-opacity-100 {
  border-color: rgba(var(--warning-rgb), 1) !important;
}
.border.border-danger {
  border: 1px solid rgb(var(--danger-rgb)) !important;
  opacity: 1;
}
.border.border-danger.border-opacity-10 {
  border-color: rgba(var(--danger-rgb), 0.1) !important;
}
.border.border-danger.border-opacity-25 {
  border-color: rgba(var(--danger-rgb), 0.25) !important;
}
.border.border-danger.border-opacity-50 {
  border-color: rgba(var(--danger-rgb), 0.5) !important;
}
.border.border-danger.border-opacity-75 {
  border-color: rgba(var(--danger-rgb), 0.75) !important;
}
.border.border-danger.border-opacity-100 {
  border-color: rgba(var(--danger-rgb), 1) !important;
}
.border.border-pink {
  border: 1px solid rgb(var(--pink-rgb)) !important;
  opacity: 1;
}
.border.border-pink.border-opacity-10 {
  border-color: rgba(var(--pink-rgb), 0.1) !important;
}
.border.border-pink.border-opacity-25 {
  border-color: rgba(var(--pink-rgb), 0.25) !important;
}
.border.border-pink.border-opacity-50 {
  border-color: rgba(var(--pink-rgb), 0.5) !important;
}
.border.border-pink.border-opacity-75 {
  border-color: rgba(var(--pink-rgb), 0.75) !important;
}
.border.border-pink.border-opacity-100 {
  border-color: rgba(var(--pink-rgb), 1) !important;
}
.border.border-teal {
  border: 1px solid rgb(var(--teal-rgb)) !important;
  opacity: 1;
}
.border.border-teal.border-opacity-10 {
  border-color: rgba(var(--teal-rgb), 0.1) !important;
}
.border.border-teal.border-opacity-25 {
  border-color: rgba(var(--teal-rgb), 0.25) !important;
}
.border.border-teal.border-opacity-50 {
  border-color: rgba(var(--teal-rgb), 0.5) !important;
}
.border.border-teal.border-opacity-75 {
  border-color: rgba(var(--teal-rgb), 0.75) !important;
}
.border.border-teal.border-opacity-100 {
  border-color: rgba(var(--teal-rgb), 1) !important;
}
.border.border-orange {
  border: 1px solid rgb(var(--orange-rgb)) !important;
  opacity: 1;
}
.border.border-orange.border-opacity-10 {
  border-color: rgba(var(--orange-rgb), 0.1) !important;
}
.border.border-orange.border-opacity-25 {
  border-color: rgba(var(--orange-rgb), 0.25) !important;
}
.border.border-orange.border-opacity-50 {
  border-color: rgba(var(--orange-rgb), 0.5) !important;
}
.border.border-orange.border-opacity-75 {
  border-color: rgba(var(--orange-rgb), 0.75) !important;
}
.border.border-orange.border-opacity-100 {
  border-color: rgba(var(--orange-rgb), 1) !important;
}
.border.border-white {
  border: 1px solid #fff !important;
  opacity: 1;
}
.border.border-white.border-opacity-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.border.border-white.border-opacity-25 {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.border.border-white.border-opacity-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
.border.border-white.border-opacity-75 {
  border-color: rgba(255, 255, 255, 0.75) !important;
}
.border.border-white.border-opacity-100 {
  border-color: white !important;
}
.border.border-light {
  border: 1px solid rgb(var(--light-rgb)) !important;
  opacity: 1;
}
.border.border-light.border-opacity-10 {
  border-color: rgba(var(--light-rgb), 0.1) !important;
}
.border.border-light.border-opacity-25 {
  border-color: rgba(var(--light-rgb), 0.25) !important;
}
.border.border-light.border-opacity-50 {
  border-color: rgba(var(--light-rgb), 0.5) !important;
}
.border.border-light.border-opacity-75 {
  border-color: rgba(var(--light-rgb), 0.75) !important;
}
.border.border-light.border-opacity-100 {
  border-color: rgba(var(--light-rgb), 1) !important;
}
.border.border-dark {
  border: 1px solid rgb(var(--dark-rgb)) !important;
  opacity: 1;
}
.border.border-dark.border-opacity-10 {
  border-color: rgba(var(--dark-rgb), 0.1) !important;
}
.border.border-dark.border-opacity-25 {
  border-color: rgba(var(--dark-rgb), 0.25) !important;
}
.border.border-dark.border-opacity-50 {
  border-color: rgba(var(--dark-rgb), 0.5) !important;
}
.border.border-dark.border-opacity-75 {
  border-color: rgba(var(--dark-rgb), 0.75) !important;
}
.border.border-dark.border-opacity-100 {
  border-color: rgba(var(--dark-rgb), 1) !important;
}
.border.border-opacity-10 {
  --bs-border-opacity: 0.1 !important;
}
.border.border-opacity-25 {
  --bs-border-opacity: 0.25 !important;
}
.border.border-opacity-50 {
  --bs-border-opacity: 0.5 !important;
}
.border.border-opacity-75 {
  --bs-border-opacity: 0.75 !important;
}
.border.border-opacity-100 {
  --bs-border-opacity: 1 !important;
}
.border.border-1 {
  border-width: 1px !important;
}
.border.border-2 {
  border-width: 2px !important;
}
.border.border-3 {
  border-width: 3px !important;
}
.border.border-4 {
  border-width: 4px !important;
}
.border.border-5 {
  border-width: 5px !important;
}
.border.border-block-start-dashed {
  border-top-style: dashed;
}
.border.border-block-start-dotted {
  border-top-style: dotted;
}
.border.border-inline-end-dashed {
  border-inline-end-style: dashed;
}
.border.border-inline-end-dotted {
  border-inline-end-style: dotted;
}
.border.border-block-end-dashed {
  border-bottom-style: dashed;
}
.border.border-block-end-dotted {
  border-bottom-style: dotted;
}
.border.border-inline-start-dashed {
  border-inline-start-style: dashed;
}
.border.border-inline-start-dotted {
  border-inline-start-style: dotted;
}
.border.border-0 {
  border: 0 !important;
}
.border.border-top-0 {
  border-block-start: 0 !important;
}
.border.border-end-0 {
  border-inline-end: 0 !important;
}
.border.border-bottom-0 {
  border-block-end: 0 !important;
}
.border.border-start-0 {
  border-inline-start: 0 !important;
}
.border-top {
  border-top: 1px solid var(--default-border) !important;
}
.border-top.border-block-start-dashed {
  border-top-style: dashed !important;
}
.border-top.border-block-start-dotted {
  border-top-style: dotted !important;
}
.border-top.border-primary {
  border-color: var(--primary-color) !important;
}
.border-top.border-primary1 {
  border-color: var(--primary-tint1-color) !important;
}
.border-top.border-primary2 {
  border-color: var(--primary-tint2-color) !important;
}
.border-top.border-primary3 {
  border-color: var(--primary-tint3-color) !important;
}
.border-top.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}
.border-top.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}
.border-top.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}
.border-top.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}
.border-top.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}
.border-top.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}
.border-top.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}
.border-end {
  border-inline-end: 1px solid var(--default-border) !important;
}
.border-end.border-inline-end-dashed {
  border-inline-end-style: dashed !important;
}
.border-end.border-inline-end-dotted {
  border-inline-end-style: dotted !important;
}
.border-end.border-primary {
  border-color: var(--primary-color) !important;
}
.border-end.border-primary1 {
  border-color: var(--primary-tint1-color) !important;
}
.border-end.border-primary2 {
  border-color: var(--primary-tint2-color) !important;
}
.border-end.border-primary3 {
  border-color: var(--primary-tint3-color) !important;
}
.border-end.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}
.border-end.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}
.border-end.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}
.border-end.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}
.border-end.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}
.border-end.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}
.border-end.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}
.border-bottom {
  border-bottom: 1px solid var(--default-border) !important;
}
.border-bottom.border-block-end-dashed {
  border-bottom-style: dashed !important;
}
.border-bottom.border-block-end-dotted {
  border-bottom-style: dotted !important;
}
.border-bottom.border-primary {
  border-color: var(--primary-color) !important;
}
.border-bottom.border-primary1 {
  border-color: var(--primary-tint1-color) !important;
}
.border-bottom.border-primary2 {
  border-color: var(--primary-tint2-color) !important;
}
.border-bottom.border-primary3 {
  border-color: var(--primary-tint3-color) !important;
}
.border-bottom.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}
.border-bottom.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}
.border-bottom.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}
.border-bottom.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}
.border-bottom.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}
.border-bottom.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}
.border-bottom.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}
.border-start {
  border-inline-start: 1px solid var(--default-border) !important;
}
.border-start.border-inline-start-dashed {
  border-inline-start-style: dashed !important;
}
.border-start.border-inline-start-dotted {
  border-inline-start-style: dotted !important;
}
.border-start.border-primary {
  border-color: var(--primary-color) !important;
}
.border-start.border-primary1 {
  border-color: var(--primary-tint1-color) !important;
}
.border-start.border-primary2 {
  border-color: var(--primary-tint2-color) !important;
}
.border-start.border-primary3 {
  border-color: var(--primary-tint3-color) !important;
}
.border-start.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}
.border-start.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}
.border-start.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}
.border-start.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}
.border-start.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}
.border-start.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}
.border-start.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}
.rounded {
  border-radius: 0.3rem !important;
}
.border-white-1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.vr {
  background-color: var(--default-border);
  opacity: 1;
}
.op-1-1 {
  opacity: 1;
}
.op-0 {
  opacity: 0;
}
.op-1 {
  opacity: 0.1;
}
.op-2 {
  opacity: 0.2;
}
.op-3 {
  opacity: 0.3;
}
.op-4 {
  opacity: 0.4;
}
.op-5 {
  opacity: 0.5;
}
.op-6 {
  opacity: 0.6;
}
.op-7 {
  opacity: 0.7;
}
.op-8 {
  opacity: 0.8;
}
.op-9 {
  opacity: 0.9;
}
.fs-sm {
  font-size: 0.6875rem;
}
.fs-base {
  font-size: 0.875rem;
}
.fs-lg {
  font-size: 1.125rem;
}
.fs-1 {
  font-size: 0.0625rem;
}
.fs-2 {
  font-size: 0.125rem;
}
.fs-3 {
  font-size: 0.1875rem;
}
.fs-4 {
  font-size: 0.25rem;
}
.fs-5 {
  font-size: 0.3125rem;
}
.fs-6 {
  font-size: 0.375rem;
}
.fs-7 {
  font-size: 0.4375rem;
}
.fs-8 {
  font-size: 0.5rem;
}
.fs-9 {
  font-size: 0.5625rem;
}
.fs-10 {
  font-size: 0.625rem;
}
.fs-11 {
  font-size: 0.6875rem;
}
.fs-12 {
  font-size: 0.75rem;
}
.fs-13 {
  font-size: 0.8125rem;
}
.fs-14 {
  font-size: 0.875rem;
}
.fs-15 {
  font-size: 0.9375rem;
}
.fs-16 {
  font-size: 1rem;
}
.fs-17 {
  font-size: 1.0625rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-19 {
  font-size: 1.1875rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.fs-21 {
  font-size: 1.3125rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-23 {
  font-size: 1.4375rem;
}
.fs-24 {
  font-size: 1.5rem;
}
.fs-25 {
  font-size: 1.5625rem;
}
.fs-26 {
  font-size: 1.625rem;
}
.fs-27 {
  font-size: 1.6875rem;
}
.fs-28 {
  font-size: 1.75rem;
}
.fs-29 {
  font-size: 1.8125rem;
}
.fs-30 {
  font-size: 1.875rem;
}
.fs-32 {
  font-size: 2rem;
}
.fs-34 {
  font-size: 2.125rem;
}
.fs-36 {
  font-size: 2.25rem;
}
.fs-38 {
  font-size: 2.375rem;
}
.fs-40 {
  font-size: 2.5rem;
}
.text-default {
  color: var(--default-text-color) !important;
}
.text-muted {
  color: var(--text-muted) !important;
}
.text-primary {
  color: var(--primary-color) !important;
  opacity: 1;
}
.text-primary.text-opacity-75 {
  color: var(--primary08) !important;
}
.text-primary.text-opacity-25 {
  color: var(--primary03) !important;
}
.text-primary.text-opacity-50 {
  color: var(--primary05) !important;
}
.text-primary1 {
  color: var(--primary-tint1-color) !important;
  opacity: 1;
}
.text-primary1.text-opacity-75 {
  color: var(--primary08) !important;
}
.text-primary1.text-opacity-25 {
  color: var(--primary03) !important;
}
.text-primary1.text-opacity-50 {
  color: var(--primary05) !important;
}
.text-primary2 {
  color: var(--primary-tint2-color) !important;
  opacity: 1;
}
.text-primary2.text-opacity-75 {
  color: rgba(var(--primary-tint1-rgb), 0.75) !important;
}
.text-primary2.text-opacity-25 {
  color: rgba(var(--primary-tint1-rgb), 0.25) !important;
}
.text-primary2.text-opacity-50 {
  color: rgba(var(--primary-tint1-rgb), 0.5) !important;
}
.text-primary3 {
  color: var(--primary-tint3-color) !important;
  opacity: 1;
}
.text-primary3.text-opacity-75 {
  color: rgba(var(--primary-tint2-rgb), 0.75) !important;
}
.text-primary3.text-opacity-25 {
  color: rgba(var(--primary-tint2-rgb), 0.25) !important;
}
.text-primary3.text-opacity-50 {
  color: rgba(var(--primary-tint2-rgb), 0.5) !important;
}
.text-secondary {
  color: rgb(var(--secondary-rgb)) !important;
  opacity: 1;
}
.text-secondary.text-opacity-75 {
  color: rgba(var(--primary-tint3-rgb), 0.75) !important;
}
.text-secondary.text-opacity-25 {
  color: rgba(var(--primary-tint3-rgb), 0.25) !important;
}
.text-secondary.text-opacity-50 {
  color: rgba(var(--primary-tint3-rgb), 0.5) !important;
}
.text-info {
  color: rgb(var(--info-rgb)) !important;
  opacity: 1;
}
.text-info.text-opacity-75 {
  color: rgba(var(--info-rgb), 0.75) !important;
}
.text-info.text-opacity-25 {
  color: rgba(var(--info-rgb), 0.25) !important;
}
.text-info.text-opacity-50 {
  color: rgba(var(--info-rgb), 0.5) !important;
}
.text-success {
  color: rgb(var(--success-rgb)) !important;
  opacity: 1;
}
.text-success.text-opacity-75 {
  color: rgba(var(--success-rgb), 0.75) !important;
}
.text-success.text-opacity-25 {
  color: rgba(var(--success-rgb), 0.25) !important;
}
.text-success.text-opacity-50 {
  color: rgba(var(--success-rgb), 0.5) !important;
}
.text-warning {
  color: rgb(var(--warning-rgb)) !important;
  opacity: 1;
}
.text-warning.text-opacity-75 {
  color: rgba(var(--warning-rgb), 0.75) !important;
}
.text-warning.text-opacity-25 {
  color: rgba(var(--warning-rgb), 0.25) !important;
}
.text-warning.text-opacity-50 {
  color: rgba(var(--warning-rgb), 0.5) !important;
}
.text-danger {
  color: rgb(var(--danger-rgb)) !important;
  opacity: 1;
}
.text-danger.text-opacity-75 {
  color: rgba(var(--danger-rgb), 0.75) !important;
}
.text-danger.text-opacity-25 {
  color: rgba(var(--danger-rgb), 0.25) !important;
}
.text-danger.text-opacity-50 {
  color: rgba(var(--danger-rgb), 0.5) !important;
}
.text-blue {
  color: #0082FF !important;
}
.text-green {
  color: rgb(var(--green-rgb)) !important;
}
.text-pink {
  color: rgb(var(--pink-rgb)) !important;
}
.text-teal {
  color: rgb(var(--teal-rgb)) !important;
}
.text-orange {
  color: rgb(var(--orange-rgb)) !important;
}
.text-indigo {
  color: #4d5ddb !important;
}
.text-purple {
  color: rgb(var(--purple-rgb)) !important;
}
.text-dark {
  color: rgb(var(--dark-rgb)) !important;
}
.text-light {
  color: rgb(var(--light-rgb)) !important;
}
.text-gray {
  color: var(--gray-5) !important;
}
.text-yellow {
  color: #fff621 !important;
}
.text-black {
  color: var(--custom-black) !important;
}
.text-white {
  color: var(--custom-white) !important;
}
.text-fixed-white {
  color: #fff !important;
}
.text-fixed-black {
  color: #000 !important;
}
.text-gray-1 {
  color: var(--gray-1);
}
.text-gray-2 {
  color: var(--gray-2);
}
.text-gray-3 {
  color: var(--gray-3);
}
.text-gray-4 {
  color: var(--gray-4);
}
.text-gray-5 {
  color: var(--gray-5);
}
.text-gray-6 {
  color: var(--gray-6);
}
.text-gray-7 {
  color: var(--gray-7);
}
.text-gray-8 {
  color: var(--gray-8);
}
.text-gray-9 {
  color: var(--gray-9);
}
a {
  text-decoration: none;
  color: rgba(var(--dark-rgb), var(--bs-link-opacity, 1));
}
a:hover {
  opacity: 1;
  color: rgba(var(--dark-rgb), var(--bs-link-opacity, 1));
}
a.link-underline {
  text-decoration-color: rgba(var(--dark-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-primary {
  text-decoration-color: rgba(var(--primary-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-primary1 {
  text-decoration-color: rgba(var(--primary-tint1-rgb), 1) !important;
}
a.link-underline-primary2 {
  text-decoration-color: rgba(var(--primary-tint2-rgb), 1) !important;
}
a.link-underline-primary3 {
  text-decoration-color: rgba(var(--primary-tint3-rgb), 1) !important;
}
a.link-underline-secondary {
  text-decoration-color: rgba(var(--secondary-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-warning {
  text-decoration-color: rgba(var(--warning-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-info {
  text-decoration-color: rgba(var(--info-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-success {
  text-decoration-color: rgba(var(--success-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-danger {
  text-decoration-color: rgba(var(--danger-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-light {
  text-decoration-color: rgba(var(--light-rgb), var(--bs-link-underline-opacity)) !important;
}
a.link-underline-dark {
  text-decoration-color: rgba(var(--dark-rgb), var(--bs-link-underline-opacity)) !important;
}
.link-primary {
  color: var(--primary-color) !important;
  text-decoration-color: rgba(var(--primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover,
.link-primary:focus,
.link-primary:active {
  text-decoration-color: rgba(var(--primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: var(--primary-color) !important;
}
.link-primary1 {
  color: var(--primary-tint1-color) !important;
  text-decoration-color: rgba(var(--primary-tint1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary1:hover,
.link-primary1:focus,
.link-primary1:active {
  text-decoration-color: rgba(var(--primary-tint1-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: var(--primary-tint1-color) !important;
}
.link-primary2 {
  color: var(--primary-tint2-color) !important;
  text-decoration-color: rgba(var(--primary-tint2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary2:hover,
.link-primary2:focus,
.link-primary2:active {
  text-decoration-color: rgba(var(--primary-tint1-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: var(--primary-tint2-color) !important;
}
.link-primary3 {
  color: var(--primary-tint3-color) !important;
  text-decoration-color: rgba(var(--primary-tint3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary3:hover,
.link-primary3:focus,
.link-primary3:active {
  text-decoration-color: rgba(var(--primary-tint1-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: var(--primary-tint1-color) !important;
}
.link-secondary {
  color: rgb(var(--secondary-rgb)) !important;
  text-decoration-color: rgba(var(--secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover,
.link-secondary:focus,
.link-secondary:active {
  text-decoration-color: rgba(var(--secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--secondary-rgb)) !important;
}
.link-success {
  color: rgb(var(--success-rgb)) !important;
  text-decoration-color: rgba(var(--success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover,
.link-success:focus,
.link-success:active {
  text-decoration-color: rgba(var(--success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--success-rgb)) !important;
}
.link-danger {
  color: rgb(var(--danger-rgb)) !important;
  text-decoration-color: rgba(var(--danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover,
.link-danger:focus,
.link-danger:active {
  text-decoration-color: rgba(var(--danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--danger-rgb)) !important;
}
.link-warning {
  color: rgb(var(--warning-rgb)) !important;
  text-decoration-color: rgba(var(--warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover,
.link-warning:focus,
.link-warning:active {
  text-decoration-color: rgba(var(--warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--warning-rgb)) !important;
}
.link-info {
  color: rgb(var(--info-rgb)) !important;
  text-decoration-color: rgba(var(--info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover,
.link-info:focus,
.link-info:active {
  text-decoration-color: rgba(var(--info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--info-rgb)) !important;
}
.link-light {
  color: rgb(var(--light-rgb)) !important;
  text-decoration-color: rgba(var(--light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover,
.link-light:focus,
.link-light:active {
  text-decoration-color: rgba(var(--light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--light-rgb)) !important;
}
.link-dark {
  color: rgb(var(--dark-rgb)) !important;
  text-decoration-color: rgba(var(--dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover,
.link-dark:focus,
.link-dark:active {
  text-decoration-color: rgba(var(--dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--dark-rgb)) !important;
}
.link-body-emphasis {
  color: rgb(var(--dark-rgb)) !important;
  text-decoration-color: rgba(var(--default-text-color), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover,
.link-body-emphasis:focus,
.link-body-emphasis:active {
  text-decoration-color: rgba(var(--default-text-color), var(--bs-link-underline-opacity, 1)) !important;
  color: rgb(var(--dark-rgb)) !important;
}
.blockquote-container {
  padding: 1.25rem;
  border: 1px solid var(--default-border);
  border-block-start: 4px solid var(--default-border);
  border-radius: 0.3rem;
  position: relative;
}
.blockquote-container:before {
  position: absolute;
  content: "\f6b0";
  font-family: bootstrap-icons !important;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.813rem;
  font-weight: 400;
  border: 1px solid var(--default-border);
  background-color: var(--custom-white);
  top: -0.875rem;
  align-items: center;
  display: flex;
  padding: 0px;
  justify-content: center;
  border-radius: 3.125rem;
  inset-inline-start: 50%;
  box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.blockquote.custom-blockquote {
  font-size: 0.85rem;
  font-weight: 400;
  padding: 1rem;
  border-radius: 0.3rem;
  position: relative;
}
.blockquote.custom-blockquote:before {
  content: "\ec52";
  font-family: "remixicon" !important;
  z-index: 0;
  font-size: 4rem;
  position: absolute;
  inset-block-end: -1.5rem;
  inset-inline-end: -0.25rem;
}
.blockquote.custom-blockquote .quote-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  position: absolute;
  inset-block-start: 0rem;
  inset-inline-start: -1rem;
  background-color: var(--primary-tint2-color);
}
.blockquote.custom-blockquote .quote-icon i {
  font-size: 1rem;
  font-weight: 500;
}
.blockquote.custom-blockquote.primary {
  background-color: var(--primary01);
  border-inline-start: 2px solid var(--primary-color);
}
.blockquote.custom-blockquote.primary .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.primary:before {
  color: var(--primary03);
}
.blockquote.custom-blockquote.primary2 {
  background-color: rgba(var(--primary-tint2-rgb), 0.1);
  border-inline-start: 2px solid var(--primary-tint2-color);
}
.blockquote.custom-blockquote.primary2 .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.primary2:before {
  color: rgba(var(--primary-tint2-rgb), 0.2);
}
.blockquote.custom-blockquote.primary1 {
  background-color: rgba(var(--primary-tint1-rgb), 0.1);
  border-inline-start: 2px solid var(--primary-tint1-color);
  border-block-start: 2px solid var(--primary-tint1-color);
}
.blockquote.custom-blockquote.primary1 .quote-icon i {
  color: var(--primary-tint1-color);
}
.blockquote.custom-blockquote.primary1:before {
  color: rgba(var(--primary-tint1-rgb), 0.1);
}
.blockquote.custom-blockquote.primary3 {
  background-color: rgba(var(--primary-tint3-rgb), 0.1);
  border-inline-start: 2px solid var(--primary-tint3-color);
}
.blockquote.custom-blockquote.primary3 .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.primary3:before {
  color: rgba(var(--primary-tint3-rgb), 0.1);
}
.blockquote.custom-blockquote.secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--secondary-rgb));
}
.blockquote.custom-blockquote.secondary .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.secondary:before {
  color: rgba(var(--secondary-rgb), 0.1);
}
.blockquote.custom-blockquote.info {
  background-color: rgba(var(--info-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--info-rgb));
}
.blockquote.custom-blockquote.info .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.info:before {
  color: rgba(var(--info-rgb), 0.1);
}
.blockquote.custom-blockquote.warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--warning-rgb));
}
.blockquote.custom-blockquote.warning .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.warning:before {
  color: rgba(var(--warning-rgb), 0.1);
}
.blockquote.custom-blockquote.success {
  background-color: rgba(var(--success-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--success-rgb));
}
.blockquote.custom-blockquote.success .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.success:before {
  color: rgba(var(--success-rgb), 0.1);
}
.blockquote.custom-blockquote.danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--danger-rgb));
}
.blockquote.custom-blockquote.danger .quote-icon i {
  color: #fff;
}
.blockquote.custom-blockquote.danger:before {
  color: rgba(var(--danger-rgb), 0.1);
}
[data-theme-mode=dark] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}
[data-theme-mode=dark] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(33, 37, 41, 0.3) !important;
}
[data-theme-mode=dark] .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(33, 37, 41, 0.3) !important;
}
@media screen and (min-width: 576px) {
  .w-sm-auto {
    width: auto;
  }
}
@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}
.w-auto {
  width: auto;
}
.w-95 {
  width: 95%;
}
[dir=rtl] .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.transform-none {
  transform: none !important;
}
.text-gradient {
  background:
          linear-gradient(
                  to bottom right,
                  var(--primary-color) 0%,
                  rgb(var(--secondary-rgb)) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
}
.focus-ring:focus {
  --bs-focus-ring-color: rgba(var(--primary-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-primary1:focus {
  --bs-focus-ring-color: rgba(var(--primary-tint1-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-primary2:focus {
  --bs-focus-ring-color: rgba(var(--primary-tint2-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-primary3:focus {
  --bs-focus-ring-color: rgba(var(--primary-tint3-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-secondary:focus {
  --bs-focus-ring-color: rgba(var(--secondary-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-success:focus {
  --bs-focus-ring-color: rgba(var(--success-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-danger:focus {
  --bs-focus-ring-color: rgba(var(--danger-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-warning:focus {
  --bs-focus-ring-color: rgba(var(--warning-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-info:focus {
  --bs-focus-ring-color: rgba(var(--info-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-light:focus {
  --bs-focus-ring-color: rgba(var(--light-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring.focus-ring-dark:focus {
  --bs-focus-ring-color: rgba(var(--dark-rgb), var(--bs-focus-ring-opacity));
}
