:global(body){
    background-color: rgb(249, 250, 252);
}

.custom-code{
    background-color: #e8e8e8;
    color: #0b0b0b;
    padding: 10px;
    border-radius: 5px;
}
.custom-activity-list {
    margin-block-end: 0;
    position: relative;
}
.custom-activity-list:before {
    position: absolute;
    content: "";
    width: 1px;
    background-color: var(--default-border);
    height: 82%;
    inset-inline-start: 18px;
    inset-block-start: 12px;
}
.custom-activity-list li {
    position: relative;
    margin-block-end: 1.04rem;
    padding-inline-start: 2.75rem;
}
.custom-activity-list li .activity-time {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 2px;
}
.custom-activity-list li:before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--custom-white);
    inset-inline-start: 15px;
    inset-block-start: 7px;
}
.custom-activity-list li:last-child {
    margin-block-end: 0;
}
.custom-activity-list li:before {
    background-color: rgba(92, 103, 247, 1);
    box-shadow: 0px 0px 0px 4px rgba(92, 103, 247, 0.15);
}
.custom-activity-list:before {
    position: absolute;
    content: "";
    width: 1px;
    background-color: var(--default-border);
    height: calc(100% - 30px);
    inset-inline-start: 18px;
    inset-block-start: 12px;
}

.userDashBoard .fw-medium {
    font-weight: 600 !important;
}
